import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import { connect } from 'react-redux'
// import { assignHashToUser } from '../../redux/actions/userActions'
import { saveFeedback } from '../../utilities/common-helpers'
import { LoadingOutlined, VerifiedOutlined, CheckCircleOutlined, WechatOutlined} from '@ant-design/icons'
import Modal from '../../components/elements/common/Modal'
// import { countryFlags } from './data/country-flags'
// import TextareaAutosize from 'react-textarea-autosize';



// import './customer-url-view.css';
// import '../../utilities/weixin/weui.min.css';



// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'
// import { Popconfirm, message } from 'antd'

import { useIntl, FormattedMessage } from "react-intl"


var sha256 = require('sha256')



const Link = require("react-router-dom").Link

const mapState = ({ user }) => ({
  user: user,
})

const FeedbackJump = ({title}) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [modal_title, setModal_title] = useState('')
  const [modal_content, setModal_content] = useState([''])


  useEffect(() => {
    const title = "Luxanda | Feedback Form"
    title && (document.title = title);


  }, []);

  // console.log (localStorage.ugh)

  const openModal = () => {

      setModal_title('modal_title_feedback')
      setModal_content(['modal_content_feedback'])


    document.getElementById("modal-backdrop").style.display = "flex"
    document.getElementById("modal").style.display = "flex"
    document.getElementById("modal").classList.add("show")
  }



  const fieldChanged = (type, value) => {
    if (type == 'feedback'){
       setFeedback(value);
     }
   }

   const handleSubmit = async (e) => {
     e.preventDefault()
     // console.log (saveFeedback(feedback, localStorage.ugh))

     try {
       setIsUploading(true)
       const date_created = new Date()

       const ugh = localStorage.ugh
       const body = {date_created, feedback, ugh}

       const response = await fetch(`${process.env.REACT_APP_API_URL}feedback`, {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(body),
       }).then((response) => {
         // console.log (response)
         openModal()
         setIsUploading(false)
       })


       // window.location = `getzo/${localStorage.ugh}`;

     } catch (err) {
        console.error(err.message)       // message.error (err.message)
     }



   }

   const redirectUserOnModalDismiss = () => {
     window.location = `getzo/${localStorage.ugh}`
   }

  function makeForm() {
    return (
      <>
        <div className="col-lg-12">
            <div className="form-group">
                <label className="form">{intl.formatMessage({ id: 'title_feedback_form' })}</label>
                <textarea
                    className={`form-control`}
                    style={{resize: 'vertical', transition: 'all 0s ease'}}
                    type="text"
                    rows="5"
                    placeholder={intl.formatMessage({ id: 'title_feedback_placeholder' })}
                    value={feedback}
                    onChange={e => fieldChanged('feedback', e.target.value)}
                />
            </div>
        </div>
        <div className="col-lg-3" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <div className="form-group">

            <button className="ps-btn" style={{width: '100%'}} onClick={(e)=> handleSubmit(e)}>
              <FormattedMessage
                id={isUploading ? "label_loading" : "survey_submit"}
              />
            </button>

          </div>
        </div>
      </>
    )
  }

  return (
    <section className="ps-my-account ps-page--account">


    <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">
              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>{intl.formatMessage({ id: 'title_feedback_form' })}</h3>
                    </div>
                    {localStorage.ugh &&
                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`p/${localStorage.ugh}`}>
                        <button className="ps-btn">
                          <FormattedMessage
                            id="action_back_to_product"
                          />
                        </button>
                      </Link>
                    </div>}
                  </div>
                </div>

                <div className="ps-form__content">

                  {!loading ? makeForm() :
                    <input
                      className="form-control-plaintext"
                      style={{backgroundColor: "white", fontSize: '26px', fontWeight: '600', padding: '0px'}}
                      type="text"
                      placeholder={intl.formatMessage({ id: 'label_loading' })}
                      readOnly={true}
                      value=''
                      />}






                </div>
              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>

    </div>
    <Modal title={modal_title} content={modal_content} redirectUserOnModalDismiss={redirectUserOnModalDismiss}/>
  </section>

  );
}

export default FeedbackJump
