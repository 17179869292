export const variant_list = [
  {name: 'Size', code: 'size', placeholder: 'Small'},
  {name: 'Color', code: 'color', placeholder: 'Blue'},
{name: 'Material', code: 'material', placeholder: 'Leather'},


];


// exports.weightUnits = weightUnits
// exports.dimensionUnits = dimensionUnits
// export default {weightUnits, dimensionUnits}
