// USER REDUCER TYPES
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const SET_USER_HASH = 'SET_USER_HASH'
export const SET_USER_ORDER_REF = 'SET_USER_ORDER_REF'
export const LOADING_USER = 'LOADING_USER'
// UI REDUCER TYPES
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
// DATA REDUCER TYPES


//SEARCH REDUCERS
export const MULTI_SEARCH_FETCH_SUCCESS = 'MULTI_SEARCH_FETCH_SUCCESS';
export const MULTI_SEARCH_FETCH_ERROR = 'MULTI_SEARCH_FETCH_ERROR';

export const SINGLE_SEARCH_FETCH_SUCCESS = 'SINGLE_SEARCH_FETCH_SUCCESS';
export const SINGLE_SEARCH_FETCH_ERROR = 'SINGLE_SEARCH_FETCH_ERROR';

export const ALL_PRODUCTS_FETCH_SUCCESS = 'ALL_PRODUCTS_FETCH_SUCCESS'
export const ALL_PRODUCTS_FETCH_ERROR = 'ALL_PRODUCTS_FETCH_ERROR'

export const SEARCH_QUERY_EMPTY = 'SEARCH_QUERY_EMPTY';

//LANG REDUCERS
export const CHANGE_LANG = 'CHANGE_LANG'
