import React, { Component } from 'react';
import PropTypes from 'prop-types'
import BreadCrumb from '../../components/elements/common/BreadCrumb';
import MyAccountDashboard from '../../components/partials/account/MyAccountDashboard';
import ContainerPage from '../../components/layouts/ContainerPage';

import { connect } from 'react-redux'

class MyAccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadCrumb: [
                {
                    text: 'Home',
                    url: '/',
                },
                {
                    text: 'My Account',
                },
            ],
        };
    }

    render() {
        const {
          user: {
            credentials: {email, date_created, imageUrl, bio, bid, website, address},
            loading,
            authenticated
          }
        } = this.props

        let profileMarkup = !loading ? (authenticated ? (
          <div>AUTHENTICATED</div>
        ) : (
          <div>Not authenticated, please signup/login</div>
        )) : (<p>Loading...</p>)
        return (
            <ContainerPage title="My Account">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={this.state.breadCrumb} />
                    <MyAccountDashboard />
                </div>
            </ContainerPage>
        );
    }
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

MyAccountPage.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(MyAccountPage);
