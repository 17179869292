import React, { Component, useEffect,  useState } from 'react'
import { Form, Input } from 'antd'
import { MailOutlined, LoadingOutlined } from '@ant-design/icons'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import { Popconfirm, message } from 'antd'
// import axios from 'axios'
// import Video from "/static/videos/luxanda-6976209.mp4"

import { useLocation } from 'react-router-dom'
import { saveDeviceActions } from '../../utilities/common-helpers'



// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const Homepage = ({collectionSlug, title}) => {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState('')


  let location = useLocation()

  const title_new = "Luxanda | Authentic Quality Canadian Goods"
  title_new && (document.title = title_new);



  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
    // getProducts(tab.slug);
  }

  function handleSubmit(e) {

      // e.preventDefault();

      createSubscriberWithEmail(email)
    //  Router.push(`/search?keyword=${keyword}`);
  }

  async function createSubscriberWithEmail (email) {
    try {
      setLoading(true)
      const date_created = new Date()
      const body = { date_created, email };
      // console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}sub`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.data.code == 409){
          //notify client of email already subscribed
          message.error(data.data.msg)
          saveDeviceActions(location.pathname, "User failed to subscribe: " + email + " e.code: " + data.data.code, null)
        } else {
          //success
          message.success('You have successfully subscribed to Luxanda')
          saveDeviceActions(location.pathname, "User Subscribed: " + email, null)
        }

        setLoading(false)
      })


      // window.location = "/";

    } catch (err) {
      console.error(err.message);
    }
  }




  return (

    <div>
      <div className="ps-video">
        <video autoPlay loop muted src={'/static/videos/luxanda-6976209.mp4'} type="video/mp4" />
      </div>
      <div className="ps-logo" >
        <img src={'/static/imgs/logo-white.png'} alt="" style={{position: 'absolute', top: '50px', left: '50px'}} />
      </div>
      <section className="ps-my-account ps-page--account">

          <div className="container">


              <div className="row" style={{marginTop: '30%'}}>
              <div className="col-lg-12">
                <div className="ps-page__content" style={{textAlign: 'center', paddingBottom: '25px'}}>
                <Player
                  autoplay
                  loop
                  src="/static/animations/36089-waiting.json"
                  style={{width: '100%', maxWidth: '100px'}}
                >
                <Controls visible={false}/>
                </Player>
                  <h3 className="hero_title" style={{color: '#ffffff'}}>We are coming soon</h3>
                  <div style={{color: 'white', fontSize: '18px'}}>If you want to get notified when the website goes live, subscribe to our mailing list!</div>
                </div>
              </div>


                <div className="col-lg-12">

                  <div className="ps-page__content">
                  <Form
                      className="ps-form--signup"
                      initialValues={{ remember: true }}
                      onFinish={handleSubmit}>

                      <div className="ps-form__input" style={{maxWidth: '450px'}}>
                        <Form.Item
                        name="email"
                        rules={[{
                              type: 'email',
                              message: 'This is not a valid E-mail address',
                            },
                            {
                              required: true,
                              message: 'Please input your E-mail',
                            },
                          ]}
                          style={{fontSize: '40px'}}>
                          <Input
                              className="form-control"
                              type="email"
                              prefix={<MailOutlined className="site-form-item-icon"/>}
                              placeholder="E-mail Address"
                              onChange={(e) => setEmail(e.target.value)}
                          />
                          </Form.Item>

                      </div>
                      <div/>
                      <button
                        type="submit"
                        className="ps-btn"
                        disabled={loading}>
                        {loading ? (<>Subscribing... <loadingOutlined size="Large"/></>
                        ) : ('Subscribe')}
                      </button>

                  </Form>
                </div>

              </div>


            </div>
            <div style={{height: '5vh'}}/>
          </div>
      </section>
    </div>





  );

}


export default Homepage;
