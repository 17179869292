import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { Pagination } from 'antd';
import axios from 'axios'
import Product from '../../elements/products/Product';
import ProductWide from '../../elements/products/ProductWide';

import { useHistory } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getAllProductsAction } from '../../../redux/actions/searchActions'

import { generateTempArray } from '../../../utilities/common-helpers';
import TemplateProduct from '../../elements/templates/TemplateProduct';

const mapState = ({user, searchResults}) => ({
  user: user,
  productsLoading: searchResults.isLoading,
  products: searchResults.searchResults
})



const ShopItems = ({  columns, productsPerPage, searchType, searchQuery, page }) => {


  const dispatch = useDispatch()
  const {user, productsLoading, products } = useSelector(mapState)


  const history = useHistory()
  const { url } = useLocation()
  const { activePage } = queryString.parse(url)


  const [listView, setListView] = useState(true);
  const [productItems, setProductItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(productsLoading);
  const [latestDoc_id, setLatestDoc_id] = useState(null)
  const [dataRetrievalCounter, setDataRetrievalCounter] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [classes, setClasses] = useState(
      'col-xl-4 col-lg-4 col-md-3 col-sm-6 col-6'
  );

  let location = useLocation()

  useEffect(() => {
    dispatch(getAllProductsAction())
    setProductItems(products)
    handleSetColumns();
  },[dispatch])

  useEffect(() => {
    //MANUAL DELAY TO SHOW LOADING SECTIONS
    setTimeout(()=>{
      setLoading(productsLoading)
    }, 250)


    //Navigate to the correct Page # from URL
    let pathArray = location.pathname.split('/')
    let currentPageFromUrl = pathArray[2].split('=').pop()
    if (currentPageFromUrl > 0 ){
      setCurrentPage(currentPageFromUrl)
    }
    else {
      setCurrentPage(1)
    }
  })



  function handleSetColumns() {
      switch (columns) {
          case 2:
              setClasses('col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6');
              return 2;
              break;
          case 4:
              setClasses('col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6');
              return 4;
              break;
          case 6:
              setClasses('col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6');
              return 6;
              break;

          default:
              setClasses('col-xl-4 col-lg-4 col-md-3 col-sm-6 col-6');
      }
  }


  function handlePagination(currentPage) {
    page = currentPage
    setCurrentPage(currentPage)
      history.push(`/shop/page=${currentPage}`)
  }


    function handleChangeViewMode(e) {
        e.preventDefault();
        setListView(!listView);
    }




    // function handleScroll() {
    //   console.log ("at bottom")
    // }


    let productItemsView;

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage
    let currentProducts


    //MUST CHECK IF PRODUCTS IS NOT NULL, OTHERWISE SLICING WILL NOT WORK FOR PAGINATION
    if (!Array.isArray(products)) return null;
    if (products.length < 1) {
        productItemsView = <p>No product found.</p>
    } else {
      currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct)
      if (!loading) {
          if (currentProducts && currentProducts.length > 0) {
              if (listView) {
                  const items = currentProducts.map((item) => (
                      <div className={classes} key={item.pid}>
                          <Product product={item} />
                      </div>
                  ));
                  productItemsView = (
                      <div className="ps-shop-items">
                          <div className="row">{items}</div>
                      </div>
                  );
              } else {
                  productItemsView = currentProducts.map((item) => (
                      <ProductWide product={item} />
                  ));
              }
          } else {
              productItemsView = <p>No product found.</p>;
          }
      } else {
          const skeletonItems = generateTempArray(currentProducts.length).map((item) => (
              <div className={classes} key={item}>
                  <TemplateProduct />
              </div>
          ));
          productItemsView = <div className="row">{skeletonItems}</div>;
      }
    }


    return (
        <div className="ps-shopping">
            <div className="ps-shopping__header">
                <p>
                    <strong className="mr-2">{products.length}</strong>
                    Products Found
                </p>
                <div className="ps-shopping__actions">
                    <div className="ps-shopping__view">
                        <p>View</p>
                        <ul className="ps-tab-list">
                            <li className={listView === true ? 'active' : ''}>
                                <a
                                    href="#"
                                    onClick={(e) => handleChangeViewMode(e)}>
                                    <i className="icon-grid"></i>
                                </a>
                            </li>
                            <li className={listView !== true ? 'active' : ''}>
                                <a
                                    href="#"
                                    onClick={(e) => handleChangeViewMode(e)}>
                                    <i className="icon-list4"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="ps-shopping__content">{productItemsView}</div>
            <div className="ps-shopping__footer text-center">
                <div className="ps-pagination">
                    <Pagination
                        total={products.length}
                        pageSize={productsPerPage}
                        responsive={true}
                        showSizeChanger={false}
                        current={currentPage !== undefined ? parseInt(currentPage) : 1}
                        onChange={(e) => handlePagination(e)}
                    />
                </div>
            </div>
        </div>
    );
};



export default ShopItems;
