import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form, Input } from 'antd';
import { MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
// import axios from 'axios'
import { Popconfirm, message } from 'antd'

import { connect } from 'react-redux'
import {signupUser } from '../../redux/actions/userActions'

const Link = require("react-router-dom").Link

class signup extends Component {

  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      password_confirm: '',
      errors: {}
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.ui.errors){
      this.setState({errors: nextProps.ui.errors})
    }
  }

  handleSubmit = (event) => {
    console.log(this.state)
    this.setState({
      loading: true
    })

    const userData = {
      email: this.state.email,
      password: this.state.password,
      password_confirm: this.state.password_confirm
    }
    message.error("Sorry, but registration is currently closed.")
    // this.props.signupUser(userData, this.props.history)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }


  render () {
    const {ui: {loading}} = this.props
    const { errors } = this.state;

    const title = "Luxanda | Signup "
    title && (document.title = title);


    const validateMessages = {
      required: '${label} is required',
      types: {
       // email: this.state.errors.email,
        password: this.state.errors.password,
        password_confirm: this.state.errors.confirmPassword,
      }

    };

    return (
      <div className="ps-my-account">
          <div className="container">
              <Form
                  requiredMark={'optional'}
                  className="ps-form--account"
                  layout="vertical"
                  validateMessages={validateMessages}
                  onFinish={this.handleSubmit}>
                  <ul className="ps-tab-list">
                      <li>
                          <Link to="/signin">
                              Login
                          </Link>
                      </li>
                      <li className="active">
                          <Link to="/signup">
                              Register
                          </Link>
                      </li>
                  </ul>
                  <div className="ps-tab active" id="register">
                      <div className="ps-form__content">

                          <div className="form-group">
                              <Form.Item
                                  name="email"
                                  label="E-mail"
                                  rules={[
                                      {
                                          required: true,
                                          type: 'email',
                                      },
                                  ]}>
                                  <Input
                                      className="form-control"
                                      type="email"
                                      placeholder="Your E-mail Address"
                                      name="email"
                                      label="E-mail"
                                      value={this.state.email}
                                      onChange={this.handleChange}
                                      prefix={<MailOutlined className="site-form-item-icon" />}
                                  />
                              </Form.Item>
                          </div>
                          <div className="form-group">
                              <Form.Item
                                  name="password"
                                  label="Password"
                                  rules={[
                                      {
                                          required: true,
                                          message:
                                              'Please enter your password',
                                      },
                                  ]}>
                                  <Input
                                      className="form-control"
                                      type="password"
                                      placeholder="Password"
                                      name="password"
                                      label="Password"
                                      value={this.state.password}
                                      onChange={this.handleChange}
                                      prefix={<LockOutlined className="site-form-item-icon" />}
                                  />
                              </Form.Item>
                          </div>
                          <div className="form-group">
                              <Form.Item
                                  name="password_confirm"
                                  rules={[
                                      {
                                          required: true,
                                          message:
                                              'Please re-enter your password',
                                      },
                                  ]}>
                                  <Input
                                      className="form-control"
                                      type="password"
                                      placeholder="Confirm Password"
                                      name="password_confirm"
                                      label="Confirm Password"
                                      value={this.state.password_confirm}
                                      onChange={this.handleChange}
                                      prefix={<LockOutlined className="site-form-item-icon" />}
                                  />
                              </Form.Item>
                          </div>
                          <div className="form-group submit">
                            {(errors) && (
                              <div>
                                <p className="error-message">{errors.general}{errors.error}{errors.confirmPassword}{errors.email}</p>
                              </div>
                            )}
                              <button
                                  type="submit"
                                  className="ps-btn ps-btn--fullwidth"
                                  disabled={loading}>
                                  {loading ? (<>Setting up your account <LoadingOutlined size="large"/></>
                                  ) : ('Register')}
                              </button>
                          </div>
                      </div>
                      <div className="ps-form__footer">

                      </div>
                  </div>
              </Form>
          </div>
      </div>
    )
  }
}

signup.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})
export default connect(mapStateToProps, { signupUser })(signup)
