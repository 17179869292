import React, { Component, useEffect, useState, Suspense } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {formatter, formatPhoneNumber} from '../../utilities/common-helpers'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/orderDetail/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'

// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link


const mapState = ({ user }) => ({
  user: user,
})

const AdminCustomerView = ({ title }) => {

  const {user } = useSelector(mapState)




  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(false)
  const location = useLocation()
  const pathArray = location.pathname.split('/')
  const [customer, setCustomer] = useState({})
  const [orders, setOrders] = useState([])
  const [orderDetails, setOrderDetails] = useState([])
  const [promotions, setPromotions] = useState([])




  async function getOrdersByCustomerID() {
    setLoadingProperties(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/cid/${pathArray[3]}`)
    const ordersArray = await res.json();
    setOrders(ordersArray);

    const data_order_detail = new Array(ordersArray.length)
    const data_promos = new Array(ordersArray.length)




    for (var i = 0; i < ordersArray.length; i++){
      let data_od = await getOrderDetailsByOrderID(ordersArray[i].id)
      data_order_detail[i] = (data_od)
      data_promos[i] = new Array(data_od.length)

      for (var x = 0; x < data_od.length; x++){
        let data_p = await getPromotionsByActiveSku_onload(data_od[i].sku_id)
        data_promos[i][x] = (data_p[0])
      }


    }

    setPromotions(data_promos)
    setOrderDetails(data_order_detail)

    setLoadingProperties(false)
  }

  async function getOrderDetailsByOrderID(order_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}od/oid/${order_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
  }

  async function getPromotionsByActiveSku_onload(record_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}promos/sku/${record_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
  }

  async function getCustomer() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${pathArray[3]}`)
    const customerArray = await res.json();
    setCustomer(customerArray);
    setLoading(false)
  }

  const getOrderTotal = (index) => {
    let total = 0
    if (promotions.length > 0 && promotions[index].length > 0 && orderDetails.length > 0 && orderDetails[index].length > 0){
      for (var i = 0; i < promotions[index].length; i++){
        total += parseInt(promotions[index][i].price) * parseInt(orderDetails[index][i].quantity)
      }
    }
    return (formatter.format(total) + " USD")
  }

  const getOrderQuantity = (index) => {
    let total = 0
    if (orderDetails.length > 0 && orderDetails[index].length > 0){
      for (var i = 0; i < promotions[index].length; i++){
        total += parseInt(orderDetails[index][i].quantity)
      }
    }
    return (total)
  }








  useEffect(() => {
    // document.title = 'Luxanda | Customers'
    getCustomer()
    getOrdersByCustomerID()
  }, []);

  useEffect(() => {
    const oldTitle = document.title;
    const title = "Luxanda | Customer " + pathArray[3]
    title && (document.title = title);
    // following line is optional, but will reset title when component unmounts
    return () => document.title = oldTitle;
  }, [title])

  // console.log ('orders')
  // console.log (orders)
  // console.log ('orderDetails')
  // console.log (orderDetails)
  // console.log ('promotions')
  // console.log (promotions)
  //






  function handleSubmit(e) {
    console.log ("navigating")
  }

  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '500px',
    maxHeight: '500px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const row = {
    justifyContent: 'center',
  }

  const tableRow_cancelled = {
    fontSize: '16px',
    padding: '0.5rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'red',
    vertialAlign: 'middle',
    textAlign: 'center'
  }

  const tableRow_awaiting_fulfillment = {
    fontSize: '16px',
    padding: '0.5rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'green',
    vertialAlign: 'middle',
    textAlign: 'center'
  }

  const tableRow_default = {
    fontSize: '16px',
    padding: '0.5rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: '#dddddd',
    backgroundColor: '#efefef',
    vertialAlign: 'middle',
    textAlign: 'center'
  }

  const frozenColumn_left = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '-1px',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_second = {
    minWidth:'100px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 6rem)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  let modified_view

  if (customer.modified_by) {
    modified_view = (<div>Last modified by {customer.modified_by} on {moment(customer.date_modified).format('lll')}</div>)
  }









  return (

    <section className="ps-my-account ps-page--account">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">
            {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING RECORDS</h5></div> : null}
              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>Customer ID: {customer.id}</h3>
                      <h5><strong>Date Created: </strong>{moment(customer.date_created).format('lll')} {modified_view}</h5>
                    </div>
                    {user.credentials.role == 'administrator' ?
                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`/admin/customer/${customer.id}/edit`}>
                        <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit Customer</button>
                      </Link>
                    </div> : null }
                  </div>
                </div>


                <div className="ps-form__content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Customer Information</h3></label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form">Name</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            placeHolder='Loading...'
                            value={customer.customer_name}
                            readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form">Phone Number</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            placeHolder='Loading...'
                            value={formatPhoneNumber(customer.phone_number)}
                            readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ps-form__content" style={{marginTop: '50px'}}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Order Information</h3></label>
                      </div>
                    </div>



                    <div className="col-lg-12">
                      {!loading  ?
                        (orders.length > 0 ?
                      <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '0px'}}>
                        <thead>
                          <tr>
                            <th style={{position: 'sticky', left: '0', backgroundColor: '#f1f1f1'}}></th>
                            <th>Order Number</th>
                            <th>Reference Number</th>
                            <th>Order Date</th>
                            <th>Order Status</th>
                            <th>Order Total</th>
                            <th># of Items</th>
                            <th>Total Quantity</th>

                            <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {orders.map((order, i) =>
                          <tr key={i}>
                            <td style={frozenColumn_left}><b>Item {i + 1}</b></td>
                            <td style={{minWidth:'10rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      value={order.order_number}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{minWidth:'10rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      value={order.order_reference}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      value={moment(order.order_date).format('lll')}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{minWidth:'10rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext'
                                      style={order.order_status == 'Cancelled by Customer' || order.order_status == 'Cancelled by Admin' ? tableRow_cancelled : (order.order_status == 'Awaiting Fulfillment' ? tableRow_awaiting_fulfillment : tableRow_default)}
                                      type="text"
                                      value={order.order_status}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{minWidth:'6rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext '
                                      style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                      type="text"
                                      value={getOrderTotal(i)}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{minWidth:'1rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                      type="text"
                                      value={loadingProperties ? null : orderDetails[i].length}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{minWidth:'1rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <div className="box" style={{display: 'flex'}}>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                      type="text"
                                      value={getOrderQuantity(i)}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={frozenColumn_right}>
                            <Link to={`/admin/o/${order.id}`}>
                              <button className="ps-btn-delete">
                                <i className='icon-eye'></i>
                              </button>
                              </Link>
                            </td>
                          </tr>)}
                        </tbody>
                      </table> : <div>No Results</div>) : <div>Loading...</div> }
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </section>

  );

}


export default AdminCustomerView;
