import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'
import moment from 'moment'

import { connect } from 'react-redux'
import { assignHashToUser } from '../../redux/actions/userActions'

import { useLocation } from 'react-router-dom'
import { saveDeviceActions,formatter, formatPhoneNumber } from '../../utilities/common-helpers'

import { VerifiedOutlined, CheckCircleOutlined, WarningOutlined} from '@ant-design/icons'

import { useIntl, FormattedMessage } from "react-intl"




// const device = require('device-ip-location');

// import ShopItems from '../../components/partials/shop/ShopItems';

// var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link

var sha256 = require('sha256')


const mapState = ({ user, lang }) => ({
  user: user,
  lang
})

const CustomerOrderJumpView = ({title}) => {
  const intl = useIntl()
  const dateFormat = 'YYYY-MM-DD'
  const dispatch = useDispatch();
  const { user, lang } = useSelector(mapState)

  const [orderDetails, setOrderDetails] = useState([]);
  const [order, setOrder] = useState({})
  const [customer, setCustomer] = useState({})


  const [orderReferenceSnippet, setOrderReferenceSnippet] = useState(null)
  const [formState, setFormState] = useState("")

  const location = useLocation()
  const pathArray = location.pathname.split('/')
  const [dateChecked, setDateChecked] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [errors, setErrors] = useState("")


  const [urlDetails, setURLDetails] = useState({})
  // const [foundProduct, setFoundProduct] = useState(false)
  const [foundOrder, setFoundOrder] = useState(false)
  const [urlItemType, setUrlItemType] = useState('')







   const ua = navigator.userAgent.toLowerCase()
    const [isWXWork, setIsWXWork] = useState(ua.match(/wxwork/i) == 'wxwork')

    const [isWeixin, setIsWeixin] = useState(!isWXWork && ua.match(/micromessenger/i) == 'micromessenger')
    const [isMobile, setIsMobile] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)






  // async function getOrderByID(orderReferenceSnippet_value) {
  //   // console.log (orderReferenceSnippet_value)
  //   if (orderReferenceSnippet_value){
  //     // console.log (orderReferenceSnippet_value)
  //     setLoading(true)
  //     const res = await fetch(`${process.env.REACT_APP_API_URL}o/id_hash/${pathArray[2]}/${orderReferenceSnippet_value}`)
  //     const order_object = await res.json();
  //     // console.log ("order_object")
  //     // console.log (order_object)
  //
  //     setLoading(false)
  //
  //
  //
  //
  //     if (order_object.length != 0 && res.ok){
  //       setFormState('found')
  //       setOrder(order_object[0])
  //
  //
  //       // console.log (deadline_date_data)
  //       // console.log (moment(deadline_date_data, dateFormat).diff( moment(), 'days'))
  //       getCustomer(order_object[0].customer_id);
  //       getOrderDetailsByOrderID(order_object[0].id)
  //     } else {
  //        setFormState('')
  //     }
  //
  //     if (!res.ok || order_object.length == 0){
  //
  //       setErrors(intl.formatMessage({ id: 'message_order_not_found_try_again' }))
  //       // saveDeviceActions(location.pathname, "Search Order: Order Not Found", pathArray[4])
  //       saveDeviceActions(location.pathname, "User Visit - Order - Order Not Found - hash: " + pathArray[2], null, null, null )
  //
  //     } else {
  //       // saveDeviceActions(location.pathname, "Navigation: Order Information Page (successful validation)", pathArray[4])
  //       saveDeviceActions(location.pathname, "User Visit - Order - Found - order_id: " + order_object[0].id + ' customer_id: ' + order_object[0].customer_id, null, null, null )
  //       // console.log ("Saving Hash to user prop: " + pathArray[3])
  //       const userOrderHash = pathArray[2]
  //       if (!user.orderHash){
  //         const title = "Luxanda | Order: " + order_object[0].order_number
  //         title && (document.title = title);
  //
  //         dispatch({type:"SET_USER_HASH", payload:{orderHash: userOrderHash}})
  //         dispatch({type: "SET_USER_ORDER_REF", payload:{orderRef: orderReferenceSnippet}})
  //       }
  //       // assignHashToUser({orderHash: userOrderHash})
  //     }
  //   }
  //
  // }
  //
  //
  //
  // async function getOrderDetailsByOrderID(order_id) {
  //   setLoadingProperties(true)
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}od/oid/${order_id}/v=c`)
  //   const orderDetailsArray = await res.json()
  //
  //   let variant_selection = []
  //
  //   setOrderDetails(orderDetailsArray)
  //
  //
  //
  //
  //
  //   setLoadingProperties(false)
  //
  //
  // }







  // async function getCustomer(customer_id) {
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${customer_id}`)
  //   const customerArray = await res.json();
  //   // console.log ("customerArray")
  //   // console.log (customerArray)
  //   setCustomer(customerArray);
  //   // getOrderByID();
  // }


  async function setupURL() {
    setLoading(true)
    try{
      const urlArray = await getURLDetails()


      setURLDetails(urlArray);

      console.log (urlArray)

      let is_order_found = false



      if (urlArray.order_id){
        saveDeviceActions(location.pathname, "User Visit - Order - Pre-Validation Page with order_id: " + urlArray.order_id, urlArray.url_item_type, null, null)
        setUrlItemType(urlArray.url_item_type)
        is_order_found = true
        setFoundOrder(true)
      }



    } catch (err) {
      console.log (err)
    }
    setLoading(false)
  }

  async function getURLDetails() {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}u/ugh/${pathArray[2]}`)
      .then((res) => {
          resolve(res.json())
      })
    })
  }


  // useEffect(() => {
  //   if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
  //     setIsMobile(true)
  //   } else {
  //     setIsDesktop(true)
  //   }
  //   setOrderReferenceSnippet(user.orderRef)
  //   saveDeviceActions(location.pathname, "User Visit - Order - Pre-Validation Page: " + pathArray[2], null, null, null )
  //
  //   if (user.orderHash !== pathArray[2] && (!user.orderRef || user.orderRed != [])){
  //     // console.log ('First Load')
  //     //window.location = `/o/${pathArray[2]}`
  //   } else {
  //
  //     // getOrderByID(user.orderRef);
  //   }
  //
  //
  //   const title = "Luxanda | Order Pre-Verification"
  //   title && (document.title = title);
  //
  //   document.querySelector('meta[name="og:image"]').setAttribute("content", "%PUBLIC_URL%/static/imgs/hero-preview.jpg")
  //   document.querySelector('meta[name="og:title"]').setAttribute("content", 'Luxanda | Order Page')
  //   // assignHashToUser
  //
  //
  // }, []);

  useEffect(() => {
    setupURL();
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
      setIsMobile(true)
    } else {
      setIsDesktop(true)
    }

    setOrderReferenceSnippet(user.orderRef)
    saveDeviceActions(location.pathname, "User Visit - Order - Pre-Validation Page: " + pathArray[2], null, null, null )

    const title = "Luxanda | Order Pre-Verification"
    title && (document.title = title);

    document.querySelector('meta[name="og:image"]').setAttribute("content", "%PUBLIC_URL%/static/imgs/hero-preview.jpg")
    document.querySelector('meta[name="og:title"]').setAttribute("content", 'Luxanda | Order Page')
  }, []);












  function handleSubmit(e) {
    // console.log ("navigating")
  }


  function submissionForm(){
    return(
      <div className="row" style={{placeContent: 'center', height: '10vh'}}>


        {foundOrder &&
        <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
          <Link to={`/order/${sha256('GETZO_ORDER-' + urlDetails.order_id)}`}>
            <button className="ps-btn" onClick={e => handleSubmit(e)}>
              <FormattedMessage
                id="action_view_order"
              />
            </button>
          </Link>
        </div>}
      </div>
    )
  }


















  return (
    <section className="ps-my-account ps-page--account">


        <div className="container">
            <div className="row">



              <div className="col-lg-12">

                <div className="ps-page__content">
                  <form className="ps-form--product-form" >
        


                    <div className="ps-form__content" style={{display: 'flex', flexDirection: 'column'}}>
                      <div className="ps-form__header">
                          <h3>
                            <FormattedMessage
                              id="title_order_jump"
                            />
                          </h3>
                      </div>
                      <img src={'/static/imgs/thank-you.jpg'} alt="/static/imgs/thank-you.jpg" style={{width: '200px', alignSelf: 'center', paddingBottom: '2rem'}}/>
                      {submissionForm()}
                    </div>
                  </form>
              </div>

            </div>


          </div>
          <div style={{height: '5vh'}}/>
        </div>
    </section>

  );

}

// <section className="ps-my-account ps-page--account">
//
//
//     <div className="container">
//         <div className="row">
//
//
//
//           <div className="col-lg-12">
//
//             <div className="ps-page__content">
//               <form className="ps-form--product-form" >
//                 <div className="ps-form__header">
//                   <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
//                       <div style={{display: 'flex', flexDirection: 'column'}}>
//                         <h3>{user.orderHash !== pathArray[2] ? intl.formatMessage({ id: 'label_order_id'}) + pathArray[2] : 'Hello'}</h3>
//                       </div>
//                     </div>
//                 </div>
//
//
//                 <div className="ps-form__content" style={{display: 'flex', flexDirection: 'column'}}>
//                   <div className="ps-form__header">
//                       <h3>
//                         <FormattedMessage
//                           id="title_order_jump"
//                         />
//                       </h3>
//                   </div>
//                   <img src={'/static/imgs/thank-you.jpg'} alt="/static/imgs/thank-you.jpg" style={{width: '200px', alignSelf: 'center', paddingBottom: '2rem'}}/>
//                   {submissionForm()}
//                 </div>
//               </form>
//           </div>
//
//         </div>
//
//
//       </div>
//       <div style={{height: '5vh'}}/>
//     </div>
// </section>


export default CustomerOrderJumpView;
