let adminLinks = [
    {
        text: 'Orders',
        url: '/admin/orders',
        icon: 'icon-highlight',
    },
    // {
    //     text: 'Order Details',
    //     url: '/admin/order-details',
    //     icon: 'icon-papers',
    // },
    {
        text: 'Products',
        url: '/admin/products',
        icon: 'icon-cart',
    },
    // {
    //     text: 'Variants',
    //     url: '/admin/variants',
    //     icon: 'icon-layers',
    // },
    // {
    //     text: 'Options',
    //     url: '/admin/variant_options',
    //     icon: 'icon-spell-check',
    // },
    // {
    //     text: 'Promotions',
    //     url: '/admin/promotions',
    //     icon: 'icon-pushpin',
    // },
    {
        text: 'Customers',
        url: '/admin/customers',
        icon: 'icon-users',
    },
    {
        text: 'Brands',
        url: '/admin/brands',
        icon: 'icon-store',
    },
    {
        text: 'URLs',
        url: '/admin/urls',
        icon: 'icon-link',
    },
];

exports.adminLinks = adminLinks
