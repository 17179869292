import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'

import { variant_list } from './data/variant_list'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormNewProductVariant = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)




  const [name, setName] = useState(variant_list[0].code);
  const [product_id, setProduct_id] = useState();
  const [products, setProducts] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);



  let location = useLocation()


  useEffect(() => {
    getProducts()
  }, []);

  async function getProducts() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`)
    const productsArray = await res.json()
    setProducts(productsArray);
    setLoadingDropdowns(false)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log (product_id)
    // console.log (name)

    try {
      setLoading(true)
      const date_created = new Date()
      const created_by = user.credentials.email
      const body = { date_created, created_by, product_id, name };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}variant`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.msg)
        } else {
          window.location = "/admin/variants"
        }
        setLoading(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  };



  function getValueFromDataArray (data,id) {
    for (let i = 0; i < data.length; i++){
        if (data[i].id == id){
          return data[i]
      }
    }
  }





  // const fieldChanged = (type, value) => {
  //   if (type == 'name'){
  //      setName(value);
  //   } else if (type == 'Variant Type')
  //
  //
  // };

  const handleSelect = (item, type) =>{
    if (type == 'product_id'){
      setProduct_id(item.target.value)
    } else if (type == 'name'){
      setName(item.target.value)
    }

  }

  function setDropdown (type) {
    if (type == 'products'){
      if (products.length > 0){
         return (
           products.map((item) => (
            <option value={item.id} key={item.id} >
                    {item.name} - {item.variant} - {item.sku}
            </option>
          )))
        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }

    } else if (type == 'name'){
      return variant_list.map((item) => (
          <option value={item.code} key={item.code}  >
                  {item.name}
            </option>
      ));
    }
  }


  const dropdown_variant_names = setDropdown ('name')
  const dropdown_products = setDropdown('products')

  let product_data = getValueFromDataArray(products, product_id)






    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Create New Product Variant</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">


                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Product ID</label>
                          {loadingDropdowns ? <div>Loading...</div> :
                          <select className="form-control" defaultValue={0} value={product_id} onChange={ e=> handleSelect(e, 'product_id')} >
                                  {products.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {dropdown_products}
                              </select>}
                      </div>
                  </div>


                  <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">Variant Type</label>
                        <select className="form-control" value={name} onChange={ e=> handleSelect(e, 'name')} >
                          {dropdown_variant_names}
                        </select>
                    </div>
                  </div>








                    <div class="separator"></div>

                </div>

                <div className="form-group submit">
                    <button
                      className="ps-btn"
                      onClick={e => handleSubmit(e)}
                      disabled={loading}
                      >
                      {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Save Product Variant')}
                      </button>
                </div>

            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormNewProductVariant;
