import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'


import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Popconfirm, message, Tooltip, Switch, Button, Modal  } from 'antd'


import { urlItemTypeList } from './data/url_item_types'
import { urlDestinationTypeList } from './data/url_destination_types'
import moment from 'moment'
import {makeID, formatter, formatPhoneNumber, saveDeviceActions} from '../../../utilities/common-helpers'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link
var sha256 = require('sha256')



const mapState = ({ user }) => ({
  user: user,
})



const FormEditURL = () => {

  const current_date = new Date()
  const url_seed_prefix = "Getzo" + current_date.getFullYear() + ":"

  const {user, selectedUnit } = useSelector(mapState)

  const dateFormat = 'YYYY-MM-DD'
  // console.log (user)


  // const [pic, setPic] = useState("")
  // const [name, setName] = useState("");

  // const [product_name, setProduct_name] = useState("")




  const [isModalVisible, setIsModalVisible] = useState(false);

  const [brand_id, setBrand_id] = useState(0)
  const [product_id, setProduct_id] = useState(0);
  const [sku_id, setSku_id] = useState(0)
  const [customer_id, setCustomer_id] = useState(0)
  const [order_id, setOrder_id] = useState(0);
  const [isAssigned, setIsAssigned] = useState(false)
  // const [existingUrl, setExistingUrl] = useState

  const [url, set_url] = useState("")
  const [url_seed, setUrl_seed] = useState("");
  const [ug_hash, setUg_hash] = useState("");
  const [url_item_type, setUrl_item_type] = useState("");
  const [url_destination_type, setUrl_destination_type] = useState("")
  const [url_item_selected, setUrl_item_selected] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [loadingDropdowns_customers, setLoadingDropdowns_customers] = useState(false)
  const [loadingDropdowns_orders, setLoadingDropdowns_orders] = useState(false)
  const [loadingDropdowns_brands, setLoadingDropdowns_brands] = useState(false)
  const [loadingDropdowns_products, setLoadingDropdowns_products] = useState(false)
  const [loadingDropdowns_skus, setLoadingDropdowns_skus] = useState(false)
  const [selected_skus_view, setSelected_skus_view] = useState([''])
  const [selected_pics_view, setSelected_pics_view] = useState([''])
  const [loadingProperties, setLoadingProperties] = useState(false)



  const [brands, setBrands] = useState([])
  const [customers, setCustomers] = useState([])
  const [orders, setOrders] = useState([])
  const [products, setProducts] = useState([])
  const [skus, setSkus] = useState([])

  const [isProductAttachedToOrder, setIsProductAttachedToOrder] = useState(false)

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [orderDetail, setOrderDetail] = useState([])
  const [foundOrderDetailMatch, setFoundOrderDetailMatch] = useState(false)



  let location = useLocation()
  const pathArray = location.pathname.split('/')

  // function generateUniqueString(url_destination, value) {
  //   let uniqueString
  //
  //   if (!value) {
  //     // Calculate the parts of the unique string
  //     const currentYear = moment().year();
  //     const lastDigitOfYearDiff = (currentYear - getzo_date.year()) % 10;
  //     const month = moment().format('M').replace('10', 'O').replace('11', 'N').replace('12', 'D');
  //     const hourOfMonth = moment().diff(moment().startOf('month'), 'hours').toString().padStart(3, '0');
  //     const minuteOfHour = moment().format('mm').toString().padStart(2, '0');
  //
  //     // Construct and return the unique string
  //     uniqueString = `G2${lastDigitOfYearDiff}${month}${hourOfMonth}${minuteOfHour}01`;
  //
  //     setUrl_seed(uniqueString)
  //     setUg_hash(uniqueString)
  //     set_url(`https://www.luxanda.cn/${url_destination}/${uniqueString}`)
  //
  //     setIsCustomCode(false)
  //     setGetzo_year(lastDigitOfYearDiff)
  //     setGetzo_month(month)
  //     setGetzo_hour(hourOfMonth)
  //     setGetzo_minute(minuteOfHour)
  //     // setGetzo_version()
  //   } else {
  //     setIsCustomCode(true)
  //     setUrl_seed(value)
  //     setUg_hash(value)
  //     set_url(`https://www.luxanda.cn/${url_destination}/${value}`)
  //   }
  //
  //   return uniqueString;
  // }



  useEffect(() => {
    // setUrl_item_type(urlItemTypeList[0].code)
    // setUrl_destination_type(urlItemTypeList[0].destination)
    getURL()
    getOrders()
    getCustomers()
    getProducts()
    getBrands()
  }, []);

  async function getURL() {
    setLoadingProperties(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/id/${pathArray[3]}`)
    const url_data = await res.json();
    const modified_by = user.credentials.email

    saveDeviceActions(location.pathname, "URL - PRE-EDITED - url_id: " + pathArray[3], "URL PRE-EDIT", modified_by, url_data)
    // console.log (url_data)
    setUrl_item_type(url_data.url_item_type)
    setUrl_destination_type(url_data.url_destination_type)
    setUrl_seed(url_data.url_seed)
    setUg_hash(url_data.ug_hash)
    setProduct_id(url_data.product_id)
    setSku_id(url_data.sku_id)
    setOrder_id(url_data.order_id)
    setIsProductAttachedToOrder(url_data.flag_assigned_to_order)
    // `https://www.luxanda.cn/${url_destination}/${value}`
    set_url(`https://www.luxanda.cn/${url_data.url_destination_type}/${url_data.url_seed}`)
    if (url_data.sku_id != 0){
      getOrdersWhereOrderDetailsContainSKU(url_data.sku_id)
    }

    if (url_data.product_id || url_data.order_id ){
      setIsAssigned(true)
    }

    getSkusByProductID(url_data.product_id).then(data => {
      console.log (data)
      for (var i = 0; i < data.length; i++){
        if (url_data.sku_id == data[i].id){
          setSelected_skus_view(data[i].sku)
          setSelected_pics_view(data[i].pic_url)
          break
        }
      }
      setLoadingProperties(false)
    })

    const title = "Luxanda | Edit URL: " + pathArray[3]
    title && (document.title = title);




    // setProduct(url_data);
    // setName(url_data.name)
    // setDescription(url_data.description)


  }


  async function getOrders() {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`)
    const ordersArray = await res.json()
    // console.log (ordersArray)

    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getOrdersByCustomerID(customer_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/cid/${customer_record_id}`)
    const ordersArray = await res.json()
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getOrdersWhereOrderDetailsContainSKU(sku_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/od_sid/${sku_record_id}`)
    const ordersArray = await res.json()
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getOrdersByCustomerIDWhereOrderDetailsContainSKU(sku_record_id, customer_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/c/${customer_record_id}/od_sid/${sku_record_id}`)
    const ordersArray = await res.json()
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }


  async function getCustomers() {
    // console.log ("loading...")
    setLoadingDropdowns_customers(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/all`)
    const customersArray = await res.json()
    // console.log (customersArray)
    setCustomers(customersArray);
    setLoadingDropdowns_customers(false)
  }

  async function getSkusByProductID(product_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_skus(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${product_record_id}`)
    const skuArray = await res.json()
    // console.log (skuArray)
    setSkus(skuArray);
    setLoadingDropdowns_skus(false)
    return skuArray
  }

  async function getBrands() {
    // console.log ("loading...")
    setLoadingDropdowns_brands(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
    const brandsArray = await res.json()
    setBrands(brandsArray);
    setLoadingDropdowns_brands(false)
  }

  async function getProducts() {
    // console.log ("loading...")
    setLoadingDropdowns_products(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`)
    const productsArray = await res.json()
    setProducts(productsArray);
    setLoadingDropdowns_products(false)
  }

  async function getProductsByBrandID(brand_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_products(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/brands/${brand_record_id}`)
    const productsArray = await res.json()
    setProducts(productsArray);
    setLoadingDropdowns_products(false)
  }


  const handleSubmit = async () => {

    // e.preventDefault();

    let formCompleted = true

    if (!url_seed){
      formCompleted = false
      message.warning ('Please enter a URL Seed or Generate one automatically')
    }

    if (url_destination_type == 'tp'){
      formCompleted = false
      message.warning ("The Tamper NFC destination hasn't been developed yet")
    }

    if (isAssigned){
      if (url_destination_type == 'o'){
        if (order_id == 0){
          formCompleted = false
          message.warning ("Please select an Order")
        }
      } else if (url_destination_type == 'p'){
        if (product_id == 0){
          formCompleted = false
          message.warning ("Please select a Product")
        } else if (sku_id == 0){
          formCompleted = false
          message.warning ("Please select a SKU")
        }

        if (order_id == 0 && isProductAttachedToOrder){
          formCompleted = false
          message.warning ("Please select an Order")
        }
      }
    }

    if (formCompleted){
      updateURL()
    }

  };






  async function updateURL () {
    try {
      setLoading(true)



      const modified_by = user.credentials.email
      const date_modified = new Date()
      const flag_assigned_to_order = isProductAttachedToOrder

      const body = { date_modified, modified_by, date_modified, modified_by, url, url_item_type, ug_hash, url_destination_type, url_seed, order_id, product_id, sku_id, flag_assigned_to_order };
      saveDeviceActions(location.pathname, "URL - EDITED - url_id: " + pathArray[3], "URL EDIT", modified_by, body)


      const response = await fetch(`${process.env.REACT_APP_API_URL}u/id/${pathArray[3]}/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {
        if (data.data.code == 409){
          message.error(data.data.msg)
        } else if (data.code == 1002){
            message.error('Something went wrong updating the URL')
        } else {
          message.success('URL successfully updated')
          // window.location = "/admin/urls"
          setTimeout(function() {
            window.location = "/admin/urls"
          }, 1000)
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }


  // async function createAssignedURL () {
  //   try {
  //     setLoading(true)
  //     const created_by = user.credentials.email
  //     const date_created = new Date()
  //     const flag_assigned_to_order = isProductAttachedToOrder
  //     const body = { date_created, created_by, url, url_item_type, ug_hash, url_destination_type, url_seed, order_id, product_id, sku_id, flag_assigned_to_order };
  //     // console.log (body)
  //
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}url/a=assigned`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(body),
  //     }).then(response => response.json())
  //     .then(data => {
  //       if (data.data.code == 409){
  //         message.error(data.data.msg)
  //       } else if (data.code == 1002){
  //         message.error('Something went wrong creating the assigned URL')
  //       } else {
  //         message.success('URL successfully created')
  //         // window.location = "/admin/urls"
  //         setTimeout(function() {
  //           window.location = "/admin/urls"
  //         }, 1000)
  //       }
  //       setLoading(false)
  //     })
  //
  //   } catch (err) {
  //     message.error (err.message)
  //     console.error(err.message);
  //   }
  // }





  const fieldChanged = (type, value) => {

    if (type == 'url_seed'){
       const seed = url_seed_prefix + value
       setUrl_seed(value);
       setUg_hash(sha256(seed))
       set_url("https://www.luxanda.cn/getzo/" + sha256(seed))

     } else if (type == 'isProductAttachedToOrder'){
       setIsProductAttachedToOrder(value)
         setOrder_id(0)
         if (value == true && sku_id != 0){
           //Automatically search to see if an Order Detail exists for this product
           getOrdersWhereOrderDetailsContainSKU(sku_id)
         }
     }
  };

  // const handleGenerate = (e) => {
  //   e.preventDefault()
  //   const generatedValue = makeID(26)
  //   setUrl_seed(generatedValue)
  //   const seed = url_seed_prefix + generatedValue
  //   setUg_hash(sha256(seed))
  //   set_url("https://www.luxanda.cn/getzo/" + sha256(seed))
  // }

  function handleSearch(e, type) {
    e.preventDefault()
    if (type == 'customers') {
      if (isProductAttachedToOrder){
        if (customer_id == 0){
          getOrdersWhereOrderDetailsContainSKU(sku_id)
        }  else {
          setOrder_id(0)
          getOrdersByCustomerIDWhereOrderDetailsContainSKU(sku_id, customer_id)
        }
      } else {
        if (customer_id == 0){

          getOrders()
        } else {
          setOrder_id(0)
          getOrdersByCustomerID(customer_id)
          // getOrdersByStatus(order_status)
        }
      }
    } else if (type == 'brands'){
      setSku_id(0)
      setSelected_skus_view(null)
      setSelected_pics_view(null)

      if (brand_id == 0){

        getProducts()
      } else {
        setProduct_id(0)

        getProductsByBrandID(brand_id)
        // getOrdersByStatus(order_status)
      }
    }
  }

  const handleAssignSwitch = (switchValue) => {
    setIsAssigned(switchValue)
    if (switchValue == false){
      setProduct_id(0)
      setOrder_id(0)
      setSku_id(0)
      setSelected_skus_view(null)
      setSelected_pics_view(null)
    }
  }

  const handleSelect = (item, type) =>{

    // console.log (item.target.value)
    if (type == 'order_id'){
      setOrder_id(item.target.value)
    } else if (type == 'customer_id'){
      setCustomer_id(item.target.value)
    } else if (type == 'brand_id'){
      setBrand_id(item.target.value)
    } else if (type == 'sku_id'){
      setOrder_id(0)
      setSelected_skus_view(item.target.selectedOptions[0].dataset.sku)
      setSelected_pics_view(item.target.selectedOptions[0].dataset.pic)
      setSku_id(item.target.value)

      if (isProductAttachedToOrder){
        //if order and sku already selected, look for orders that contain
        if (item.target.value != 0){
          getOrdersWhereOrderDetailsContainSKU(item.target.value)
        }
      }
    } else if (type == 'product_id'){
      setOrder_id(0)
      setSku_id(0)
      setSelected_skus_view(null)
      setSelected_pics_view(null)
      setProduct_id(item.target.value)
      getSkusByProductID(item.target.value)
    } else if (type == 'url_item_type'){
      setUrl_item_type(item.target.value)
      setUrl_item_selected(true)
      if (item.target.value == 'tyl'){
        setUrl_destination_type('o')
        setSelected_skus_view(null)
        setSelected_pics_view(null)
        setProduct_id(0)
        setOrder_id(0)
        setSku_id(0)
        setIsProductAttachedToOrder(false)
        getOrders()
      } else if (item.target.value == 'ptnfc'){
        setUrl_destination_type('tp')
        setSelected_skus_view(null)
        setSelected_pics_view(null)
        setProduct_id(0)
        setOrder_id(0)
        setSku_id(0)
        setIsProductAttachedToOrder(false)

      } else {
        setUrl_destination_type('p')
      }
    } else if (type == 'url_destination_type'){
      setSelected_skus_view(null)
      setSelected_pics_view(null)
      setProduct_id(0)
      setOrder_id(0)
      setSku_id(0)
      setIsProductAttachedToOrder(false)
      setUrl_destination_type(item.target.value)
    }
  }



  function getValueFromDataArray (data,id) {
    for (let i = 0; i < data.length; i++){
      if (data[i].id == id){
        return data[i]
      }
    }
  }

  function setSelectedId_View (type) {
    if (type == 'orders'){
       if (orders.length > 0){
          return (
            orders.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.order_number}, REF: {item.order_reference} - ({moment(item.order_date).format(dateFormat)})
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'products'){
      if (products.length > 0){
         return (
           products.map((item) => (
            <option value={item.id} key={item.id} >
                    {item.name} - {(item.description).substring(0, 45) + '...'}
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }

    } else if (type == 'customers'){
       if (customers.length > 0){
          return (
            customers.map((item) => (
            <option value={item.id} key={item.id}  >
                    {formatPhoneNumber(item.phone_number)} - {item.customer_name}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'skus'){
       if (skus.length > 0){
          return (
            skus.map((item) => (
            <option value={item.id} data-sku={item.sku} data-pic={item.pic_url} key={item.id}  >
                    {item.combo_identifier} (Quantity: {item.quantity})
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    }
  }



  const dropdown_orders = setSelectedId_View('orders')
  const dropdown_products = setSelectedId_View('products')
  const dropdown_brands = setSelectedId_View('brands')
  const dropdown_customers = setSelectedId_View('customers')
  const dropdown_skus = setSelectedId_View('skus')

  let order_data = getValueFromDataArray(orders, order_id)
  // let product_data = getValueFromDataArray(products, product_id)


  function setURLTypeDropdown (type) {
    let data
    if (type == 'url_item_types'){
      data = urlItemTypeList
    } else if (type == 'url_destination_types'){
      data = urlDestinationTypeList
    }
    return data.map((item) => (
        <option value={item.code} key={item.code}  >
                {item.name}
          </option>
    ));
  }



  const url_item_typesDropdown = setURLTypeDropdown ('url_item_types')
  const url_destination_typesDropdown = setURLTypeDropdown ('url_destination_types')



  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(50% - 8px)',
    height: 'calc(50% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  // console.log ("product_id")
  // console.log (product_id)
  // console.log ("brand_id")
  // console.log (brand_id)
  // console.log ("sku_id")
  // console.log (sku_id)
  // console.log ("selected_skus_view")
  // console.log (selected_skus_view)
  // console.log ("selected_pics_view")
  // console.log (selected_pics_view)

  // function dialog_confirm (e) {
  //   // console.log (e)
  //   handleSubmit();
  //   // message.success(intl.formatMessage({ id: 'message_cancel_success' }))
  // }
  //
  // function dialog_cancel (e) {
  //   // console.log (e)
  //   // message.error(intl.formatMessage({ id: 'message_cancel_success' }))
  // }

  const showModal = (e) => {
    e.preventDefault()
    setIsModalVisible(true);
  };

  const dialog_confirm = () => {
    setIsModalVisible(false);
    handleSubmit();
  };

  const dialog_cancel = () => {
    setIsModalVisible(false);
  };


    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Edit URL</h3>
            </div>

            <div className="ps-form__content">
            {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING RECORDS</h5></div> : null}

              <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                      <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <label className="form"><h3>URL Setup</h3></label>
                        <div style={{display:'flex', alignItems: 'center'}}>
                          <label className="form" style={{marginBottom: '0px', paddingRight: '10px'}}>
                            <Tooltip placement="topRight" title="Use this toggle to assign a URL to a Product or an Order.  Leave it untoggled if you intend on assigning in the future.">
                              <InfoCircleOutlined />
                            </Tooltip> {isAssigned ? 'Assigned' : 'Unassigned'}
                          </label>
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={isAssigned}
                            onChange={value => handleAssignSwitch(value)}
                          />
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label className="form">
                          <Tooltip placement="topLeft" title="This is used to create a unique ID for the URL.">
                            <InfoCircleOutlined />
                          </Tooltip> Getzo ID
                        </label>
                          <div className="input-group mb-2" >
                            <input
                                className="form-control-plaintext"
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Loading..."
                                value={url_seed}
                                onChange={e => fieldChanged('url_seed', e.target.value)}
                                readOnly={true}
                            />

                          </div>

                        <div style={{color: '#ffb81c'}}><b>{url_seed ? "The Getzo ID cannot be changed!" : null}</b></div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">
                          <Tooltip placement="topLeft" title="This is the item on which the QR Code or NFC will be encoded/embedded on">
                            <InfoCircleOutlined />
                          </Tooltip> Item
                        </label>
                        <select className="form-control" value={url_item_type} onChange={ e=> handleSelect(e, 'url_item_type')} >
                          {url_item_typesDropdown}
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">
                          <Tooltip placement="topLeft" title="This is the destination for the URL">
                            <InfoCircleOutlined />
                          </Tooltip> URL Destination
                        </label>
                        <select className="form-control" value={url_destination_type} onChange={ e=> handleSelect(e, 'url_destination_type')} >
                          {url_destination_typesDropdown}
                        </select>
                        <div style={{color: '#ffb81c'}}><b>{url_item_selected ? <span><WarningOutlined /> This is automatically selected for you. Change at your discretion.</span> : null}</b></div>
                    </div>
                </div>
              </div>
            </div>





            {url_destination_type == 'o' && isAssigned == true ?
            <div className="ps-form__content" style={{marginTop: '50px'}}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form"><h3>Order Information</h3></label>
                  </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">Order ID</label>

                        {loadingDropdowns_orders ? <div>Loading...</div> :
                        <select className="form-control" defaultValue={0} value={order_id} onChange={ e=> handleSelect(e, 'order_id')} >
                            {orders.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                              {dropdown_orders}
                        </select>}
                    </div>
                </div>
                <div className="col-lg-6">
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                    <div style={{width: '40rem', display: 'flex', alignItems: 'center'}}>
                      <div className="form-group">
                        <label className="form">
                          <Tooltip placement="topLeft" title="You can filter the list of Orders by a Customer">
                            <InfoCircleOutlined />
                          </Tooltip> Search by Customers:
                        </label>
                        {loadingDropdowns_customers ? <div>Loading...</div> :
                          <select className="form-control" value={customer_id} onChange={ e=> handleSelect(e, 'customer_id')} >
                              {customers.length === 0 ? null : <option value={0}>All</option>}
                            {dropdown_customers}
                          </select>}
                      </div>
                      <div style={{marginLeft: '30px'}}>
                        <button className="ps-btn" onClick={e => handleSearch(e, 'customers')}>Go</button>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label className="form">Order Number</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={order_data ? order_data.order_number : 'Not Available'}
                            readOnly={true}
                          />
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="form-group">
                        <label className="form">Customer Name</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={order_data ? order_data.customer_name : 'Not Available'}
                            readOnly={true}
                          />
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="form-group">
                        <label className="form">Order Reference</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={order_data ? order_data.order_reference : 'Not Available'}
                            readOnly={true}
                          />
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="form-group">
                        <label className="form">Customer Phone Number</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={order_data ? formatPhoneNumber(order_data.customer_phone_number) : 'Not Available'}
                            readOnly={true}
                          />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label className="form">Order Date</label>
                          <input
                              className='form-control-plaintext'
                              style={{backgroundColor: 'white', fontSize: '16px'}}
                              type="text"
                              value={order_data ? order_data.order_date : 'Not Available'}
                              readOnly={true}
                            />
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="form-group">
                        <label className="form">Order Status</label>
                          <input
                              className='form-control-plaintext'
                              style={{backgroundColor: 'white', fontSize: '16px'}}
                              type="text"
                              value={order_data ? order_data.order_status : 'Not Available'}
                              readOnly={true}
                            />
                    </div>
                </div>
              </div>
            </div> : null}


              {url_destination_type == 'p' && isAssigned == true ?
              <div className="ps-form__content" style={{marginTop: '50px'}}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Product Information</h3></label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div style={thumb} >
                        <div style={thumbInner}>
                          <img src={selected_pics_view ? selected_pics_view : null} style={img} alt="" border="0" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Product</label>
                          {loadingDropdowns_products ? <div>Loading...</div> :
                          <select className="form-control" defaultValue={0} value={product_id} onChange={ e=> handleSelect(e, 'product_id')} >
                              {products.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                            {dropdown_products}
                          </select>}
                      </div>
                  </div>
                  <div className="col-lg-6">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{width: '40rem', display: 'flex', alignItems: 'center'}}>
                        <div className="form-group">
                          <label className="form">
                            <Tooltip placement="topLeft" title="You can filter the list of Products by a Brand">
                              <InfoCircleOutlined />
                            </Tooltip> Search by Brand:
                          </label>
                          {loadingDropdowns_brands ? <div>Loading...</div> :
                            <select className="form-control" value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                                {brands.length === 0 ? null : <option value={0}>All</option>}
                              {dropdown_brands}
                            </select>}
                        </div>
                        <div style={{marginLeft: '30px'}}>
                          <button className="ps-btn" onClick={e => handleSearch(e, 'brands')}>Go</button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">SKU</label>
                          {loadingDropdowns_skus ? <div>Loading...</div> :
                          <select className="form-control" defaultValue={0} value={sku_id} onChange={ e=> handleSelect(e, 'sku_id')} >
                              {skus.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                            {dropdown_skus}
                          </select>}
                      </div>
                  </div>
                  {sku_id != 0 ?
                  <div className="separator"></div> : null}
                  {sku_id != 0 ?
                  <div className="col-lg-12">
                    <div className="ps-checkbox" style={{marginTop: '0px', marginBottom: '30px'}}>
                      <input
                        className="form-control"
                        type="checkbox"
                        id="product-options"
                        checked={isProductAttachedToOrder}
                        onChange={e => fieldChanged('isProductAttachedToOrder', e.target.checked)}
                        />
                      <label for="product-options">Is this product in an existing order?</label>
                    </div>
                  </div> : null}
                  {isProductAttachedToOrder &&  sku_id != 0?
                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Order ID</label>

                          {loadingDropdowns_orders ? <div>Loading...</div> :
                          <select className="form-control" defaultValue={0} value={order_id} onChange={ e=> handleSelect(e, 'order_id')} >
                              {orders.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {dropdown_orders}
                          </select>}
                      </div>
                  </div> : null}
                  {isProductAttachedToOrder &&  sku_id != 0 ?
                  <div className="col-lg-6">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                      <div style={{width: '40rem', display: 'flex', alignItems: 'center'}}>
                        <div className="form-group">
                          <label className="form">
                            <Tooltip placement="topLeft" title="You can filter the list of Orders by a Customer">
                              <InfoCircleOutlined />
                            </Tooltip> Search by Customers:
                          </label>
                          {loadingDropdowns_customers ? <div>Loading...</div> :
                            <select className="form-control" value={customer_id} onChange={ e=> handleSelect(e, 'customer_id')} >
                                {customers.length === 0 ? null : <option value={0}>All</option>}
                              {dropdown_customers}
                            </select>}
                        </div>
                        <div style={{marginLeft: '30px'}}>
                          <button className="ps-btn" onClick={e => handleSearch(e, 'customers')}>Go</button>
                        </div>
                      </div>

                    </div>
                  </div> : null}

                  {order_id && order_id != 0 ?
                  <div style={{display: 'contents'}}>
                    <div className="separator"></div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order Number</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                value={order_data ? order_data.order_number : 'Not Available'}
                                readOnly={true}
                              />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="form-group">
                            <label className="form">Customer Name</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                value={order_data ? order_data.customer_name : 'Not Available'}
                                readOnly={true}
                              />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order Reference</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                value={order_data ? order_data.order_reference : 'Not Available'}
                                readOnly={true}
                              />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="form-group">
                            <label className="form">Customer Phone Number</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                value={order_data ? formatPhoneNumber(order_data.customer_phone_number) : 'Not Available'}
                                readOnly={true}
                              />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order Date</label>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  value={order_data ? order_data.order_date : 'Not Available'}
                                  readOnly={true}
                                />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="form-group">
                            <label className="form">Order Status</label>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  value={order_data ? order_data.order_status : 'Not Available'}
                                  readOnly={true}
                                />
                        </div>
                    </div>
                  </div> : null}




              </div>
            </div> : null}
































              {url_destination_type == 'tp' && isAssigned == true ?
              <div className="ps-form__content" style={{marginTop: '50px'}}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Tamper Information</h3></label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group">
                          <input
                              className='form-control-plaintext'
                              style={{backgroundColor: 'white', fontSize: '16px'}}
                              type="text"
                              value={"This function hasn't been developed yet.  Please select a different URL Destination."}
                              readOnly={true}
                          />
                      </div>
                  </div>

              </div>
            </div> : null}






            <div className="ps-form__content" style={{marginTop: '50px'}}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form"><h3>URL Information</h3></label>
                    </div>
                </div>

                <div className="col-sm-auto">
                    <div className="form-group">
                      {url_seed ?
                      <div className="input-group mb-12">
                        <QRCode value={url} size='256'/>
                      </div> : null}
                      <label className="form" style={{paddingTop: '25px'}}>QR Code</label>
                      <div><b>{url_item_type}</b> to <b>{url_destination_type}</b> URL:</div>
                      <div ><a href={url_seed ? url : null} target="_blank" style={{fontSize: '16px'}}>{url_seed ? url : 'Generate a seed first'}</a></div>
                    </div>
                </div>
                <div className="separator"></div>


                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">Item:</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={url_item_type}
                            readOnly={true}
                          />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">URL Destination:</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={url_destination_type}
                            readOnly={true}
                          />
                    </div>
                </div>












                </div>




            </div>




            <div className="form-group submit" style={{marginTop:"5rem"}}>


                <button
                  className="ps-btn"
                  onClick={e => showModal(e)}
                  disabled={loading}
                  >
                  {loading ? (<>Updating... <LoadingOutlined size="large"/></>) : ('Update URL')}
                </button>
                <Modal title="Customer Experience Might Be Affected!" visible={isModalVisible} onOk={dialog_confirm} onCancel={dialog_cancel}>
                  <p>Please be careful with your edits as this URL may already be in circulation!</p>
                </Modal>

            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormEditURL;
