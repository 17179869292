import React, { Component, useEffect, useState } from 'react'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


import ShopItems from '../../components/partials/shop/ShopItems';
import FormNewOrder from '../../components/shared/forms/form-new-order';


const Link = require("react-router-dom").Link

const AdminOrderNewPage = () => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);




  async function getOrders() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`);
    const ordersArray = await res.json();
    setOrders(ordersArray);
  }

  useEffect(() => {
    getOrders();
  }, []);





  // async function getProducts() {
  //   const res = await fetch('/p/all');
  //   const productsArray = await res.json();
  //   setProducts(productsArray);
  // }

  // useEffect(() => {
  //   getProducts();
  // }, []);

  //
  // function handleChangeTab(e, tab) {
  //   e.preventDefault();
  //   // setCurrentCollection(tab.name);
  //   // getProducts(tab.slug);
  // }

  const img = {
    display: 'block',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  return (

        <section className="ps-my-account ps-page--account">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ps-page__content">
                      <FormNewOrder/>
                    </div>
                  </div>
                </div>

<div style={{height: '5vh'}}/>
                <form className="ps-form--product-form" >
                    <div className="ps-form__header">
                        <h3>Existing Orders</h3>
                    </div>
                    <div className="ps-form__content">
                      <div className="form-group">

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="ps-page__content">
                          <table className="table mt-5">
                            <thead>
                              <tr>
                                <th>Order Number</th>
                                <th>Order Date</th>
                                <th>Order Reference</th>
                                <th>Customer ID</th>

                                <th>Order Details</th>
                                <th>Date Created</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>

                            {orders.map((order) => (
                              <tr key={order.id}>
                                <td style={tableRow}>{order.order_number}</td>
                                <td style={tableRow}>{order.order_date}</td>
                                <td style={tableRow}>{order.order_reference}</td>
                                <td style={tableRow}>
                                  <Link to={`/admin/c/${order.customer_id}`}>
                                    <button className="ps-btn">{order.customer_id}</button>
                                  </Link>
                                </td>

                                <td style={tableRow}>Button</td>
                                <td style={tableRow}>{order.date_created}</td>
                                <td style={tableRow}>
                                  <Link to={`/admin/o/${order.id}`}>
                                    <button className="ps-btn">View</button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                          </div>
                        </div>
                      </div>


                      </div>
                    </div>
                </form>
                <div style={{height: '5vh'}}/>




            </div>
        </section>


  );

}


export default AdminOrderNewPage;
