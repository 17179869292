import React from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
// import Link from 'next/link';
import MiniCart from './MiniCart';
import AccountQuickLinks from './AccountQuickLinks';

import { signoutUser } from '../../../../redux/actions/userActions'

const Link = require("react-router-dom").Link

const HeaderActions = (props) => {
    let { compare, wishlist, auth } = props;
      const {ui: {loading}, user: {user}} = props
    // auth = {isLoggedIn: false}

     // console.log (props)
    // views
    let headerAuthView;


    // console.log (Boolean(props.user.authenticated))

    if (props.user.authenticated && Boolean(props.user.authenticated) === true) {
        headerAuthView = <AccountQuickLinks isLoggedIn={true} profileImageUrl={props.user.credentials.imageUrl}/>;
    } else {
        headerAuthView = <AccountQuickLinks isLoggedIn={false} />;
    }
    return (
        <div className="header__actions">
            <Link to="/account/compare">
                <div className="header__extra">
                    <i className="icon-tag"></i>
                    <span className={compare ? "" : "active"}>
                        <i>{compare ? compare.compareTotal : 0}</i>
                    </span>
                </div>
            </Link>
            <Link to="/account/wishlist">
                <div className="header__extra">
                    <i className="icon-heart"></i>
                    <span className={wishlist ? "" : "active"}>
                        <i>{wishlist ? wishlist.wishlistTotal : 0}</i>
                    </span>
                </div>
            </Link>
            <MiniCart />

            {headerAuthView}
        </div>
    );
};


HeaderActions.propTypes = {
  // classes: PropTypes.object.isRequired
  signoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

const mapActionsToProps = {
  signoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(HeaderActions)
// export default HeaderActions
// export default connect((state) => state)(HeaderActions);
