import React, { Component, useEffect, useState } from 'react'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const QRVerification = ({title}) => {
  const [productItems, setProductItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState([]);




  async function getOrders(orderReference) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/ref/${orderReference}`);

    const orderArray = await res.json();

    setOrder(orderArray);
  }



  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
    // getProducts(tab.slug);
  }




  return (
    <div className="ps-product-list">
        <div className="ps-container">
            <div className="ps-section__header">
                <h3>{title}</h3>
                <ul className="ps-section__links">
                    <li>
                        <Link to={`/shop`}>
                            <a>View All{title}</a>
                        </Link>
                    </li>
                </ul>
            </div>
            <ShopItems columns={6} productsPerPage={3} searchType={null} searchQuery={null} page={1}/>

        </div>
    </div>

  );

}


export default QRVerification;
