import React, { Component } from 'react';
import CurrencyDropdown from './modules/CurrencyDropdown';
// import Link from 'next/link';
// import LanguageSwitcher from './modules/LanguageSwitcher';
import MobileHeaderActions from './modules/MobileHeaderActions';
import { message } from 'antd'
const Link = require("react-router-dom").Link




class HeaderMobile extends Component {
    constructor({ props }) {
        super(props);
    }



    render() {
      function handleSubmit(e) {
          e.preventDefault();
          console.log ('ERROR')
          message.error('Sorry, but this feature is not supported in your Region.')
          //Router.push(`/search?keyword=${keyword}`);
      }
        return (
            <header className="header header--mobile">
                <div className="header__top">
                    <div className="header__left">
                        <p>Welcome to Luxanda!</p>
                    </div>
                    <div className="header__right">
                        <ul className="navigation__extra">
                            <li>
                                <Link to="/vendor/become-a-vendor">
                                    <div className="active">Sell on Luxanda</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/account/order-tracking">
                                    <div className="active">Track your order</div>
                                </Link>
                            </li>
                            <li className="active">
                                <CurrencyDropdown />
                            </li>
                            <li className="active">
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="navigation--mobile">
                    <div className="navigation__left">

                            <div className="ps-logo">
                                <img
                                    src="/static/imgs/logo-black.png"
                                    alt="Luxanda"
                                />
                            </div>

                    </div>
                    <MobileHeaderActions />
                </div>
                <div className="ps-search--mobile">
                    <form
                        className="ps-form--search-mobile"
                        onClick={e => handleSubmit(e)}
                        >
                        <div className="form-group--nest">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="I'm shopping for..."
                            />
                            <button>
                                <i className="icon-magnifier"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </header>
        );
    }
}

export default HeaderMobile;
