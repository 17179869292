import React, { Component } from 'react'

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
// import jwtDecode from 'jwt-decode'
// import axios from 'axios'
// import './utilities/popper.min.js'
// import $ from 'jquery'
// import Popper from 'popper.js'
// import './utilities/bootstrap.bundle.min.js'



import './App.css';
import './scss/home-default.scss';
import './scss/style.scss';

// redux
// import { Provider } from 'react-redux'
// import store from './redux/store'

//Top Bar
import HeaderDefault from './components/shared/headers/HeaderDefault'
import HeaderMobile from './components/shared/headers/HeaderMobile'
import FooterDefault from './components/shared/footers/FooterDefault'
// import DefaultLayout from '../components/layouts/DefaultLayout'

import ScrollToTop from './utilities/ScrollToTop'

//Pages
import homepage from './pages/homepage/Homepage'
import home from './pages/homepage/HomeJSX'
import signin from './pages/account/signin'
import signup from './pages/account/signup'

//MINIPROGRAM PAGES
import AdminOrders from './pages/adminControl/admin-orders'
import AdminOrdersNew from './pages/adminControl/admin-order-new'
import AdminOrderEdit from './pages/adminControl/admin-order-edit'
import AdminOrderView from './pages/orders/order-view'
import CustomerOrderView from './pages/orders/order-customer-view'
import CustomerOrderJumpView from './pages/orders/order-customer-jump-view'
import BrandDocumentCustomerView from './pages/brands/brand-document-customer-view'


import AdminProducts from './pages/adminControl/admin-products'
import AdminProductsNew from './pages/adminControl/admin-product-new'
import AdminProductEdit from './pages/adminControl/admin-product-edit'
import UrlCustomerView from './pages/urls/customer-url-view'
import ProductAdminView from './pages/products-mp/product-admin-view'

import AdminOrderDetails from './pages/adminControl/admin-order-details'
import AdminOrderDetailNew from './pages/adminControl/admin-order-detail-new'
import AdminOrderDetailEdit from './pages/adminControl/admin-order-detail-edit'
import AdminOrderDetailView from './pages/order-details/order-detail-view'
import CustomerOrderDetailView from './pages/order-details/order-detail-customer-view'

import CustomerWarrantyView from './pages/warranty/customer-warranty-view'



import AdminPromotions from './pages/adminControl/admin-promotions'
import AdminPromotionNew from './pages/adminControl/admin-promotion-new'
import AdminPromotionEdit from './pages/adminControl/admin-promotion-edit'
import AdminPromotionView from './pages/promotions/promotion-view'

import AdminCustomers from './pages/adminControl/admin-customers'
import AdminCustomerNew from './pages/adminControl/admin-customer-new'
import AdminCustomerEdit from './pages/adminControl/admin-customer-edit'
import AdminCustomerView from './pages/customers/customer-view'

import AdminBrands from './pages/adminControl/admin-brands'
import AdminBrandNew from './pages/adminControl/admin-brand-new'
import AdminBrandEdit from './pages/adminControl/admin-brand-edit'
import AdminBrandView from './pages/brands/brand-view'


import AdminUrls from './pages/adminControl/admin-urls'
import AdminUrlNew from './pages/adminControl/admin-url-new'
import AdminUrlEdit from './pages/adminControl/admin-url-edit'
import AdminUrlView from './pages/urls/admin-url-view'


// import CustomerSkuView from './pages/skus/customer-sku-view'

import AdminProductVariants from './pages/adminControl/admin-product-variants'
import AdminProductVariantNew from './pages/adminControl/admin-product-variant-new'
// import AdminProductVariantEdit from './pages/adminControl/admin-product-variant-edit'
// import AdminProductVariantView from './pages/adminControl/admin-product-variant-view'

import AdminProductVariantOptions from './pages/adminControl/admin-product-variant-options'
import AdminProductVariantOptionNew from './pages/adminControl/admin-product-variant-option-new'

import FeedbackJump from './pages/feedback/FeedbackJump'
import Feedback from './pages/feedback/Feedback'

// import ProductJumpPage from './pages/products-mp/product-jump-page'
// import AdminProductVariantOptionEdit from './pages/adminControl/admin-product-variant-option-edit'
// import AdminProductVariantOptionView from './pages/adminControl/admin-product-variant-option-view'
















import qrVerification from './pages/qr-verification/QRVerification'
// import productsMP from './pages/products-mp/ProductsMP'
// import productCreate from './pages/products-mp/ProductCreate'





// import orderViewMP from './pages/orders-mp/OrderViewMP'


// import ordersMP from './pages/orders-mp/OrdersMP'
// import orderCreate from './pages/orders-mp/OrderCreate'



import MyAccountPage from './pages/account/my-account'
import AccountInformationPage from './pages/account/account-information'
import AccountNotificationsPage from './pages/account/account-notifications'

import NewProductPage from './pages/store/products/new-product'
// import MyAccount from '../../components/partials/account/MyAccount';

// import MyAccount_UserInformation from './components/partials/account/MyAccount_UserInformation'
import AuthRoute from './utilities/AuthRoute'
import AuthAccountRoute from './utilities/AuthAccountRoute'

// //TEST
// import InputTodo from "./test/InputTodo";
// import ListTodos from "./test/ListTodos";
import { connect } from "react-redux"
import { IntlProvider, FormattedMessage } from "react-intl"
// import { useLocation } from 'react-router-dom'


import language_cn from "./locale/cn/translation"
import language_en from "./locale/en/translation"
import language_fr from "./locale/fr/translation"

const messages = {
  en: language_en,
  cn: language_cn,
  fr: language_fr,
}








class App extends Component {





  render () {

    let lang = this.props.lang
    // const location = useLocation()

    // console.log (lang)
    // console.log (messages[lang.default])

    // <Route exact path="/getzo/jump/:product?" component={ProductJumpPage} />
    // <Route exact path="/getzo/jump/:product?" render={() => {window.location.href="jump-mp.html"}} />

    // {window.location.pathname !== '/' ? <HeaderMobile/> : null}
    // {window.location.pathname !== '/' ? <HeaderDefault/> : null}
    // console.log (window.location.pathname)


    // console.log (pathArray)
    return (


          <IntlProvider locale={lang.default} messages={messages[lang.default]} defaultLocale={lang.default}>
          <div className="App">
            <Router getUserConfirmation={(message, callback)=> {
            // this is the default behavior
            const allowTransition = window.confirm(message);
            callback(allowTransition);
            }}>

            {window.location.pathname !== '/' ? <HeaderMobile/> : <HeaderMobile/>}
            {window.location.pathname !== '/' ? <HeaderDefault/> : <HeaderDefault/>}


              <ScrollToTop>
                <Switch>
                <Route exact path="/" component={signin}/>
                  <Route exact path="/shop/:pageNumber" component={home}/>
                  <Route exact path="/signin" component={signin} />
                  <Route exact path="/signup" component={signup} />
                  <AuthAccountRoute exact path="/account/dashboard" component={MyAccountPage} />
                  <AuthAccountRoute exact path="/store/products/new" component={NewProductPage} />
                  <AuthAccountRoute exact path="/account/account-information" component={AccountInformationPage} />
                  <AuthAccountRoute exact path="/account/account-notifications" component={AccountNotificationsPage} />
                  <AuthAccountRoute exact path="/(shop)?/page=:page?" component={home}/>
                  <Route exact path="/verification/qr" component={qrVerification} />


                  <AuthAccountRoute exact path="/admin/products" component={AdminProducts}/>
                  <AuthAccountRoute exact path="/admin/products/new" component={AdminProductsNew} />
                  <AuthAccountRoute exact path="/admin/p/:p?/edit" component={AdminProductEdit} />
                  <Route exact path="/p/:hash?" component={UrlCustomerView} />
                  <Route exact path="/getzo/:hash?" component={UrlCustomerView} /> //LEGACY PATH
                  <Route exact path="/warranty/:hash?" component={CustomerWarrantyView} />
                    <Route exact path="/feedback" component={Feedback} key="feedback-key"/>
                    <Route exact path="/feedback/:hash?/:urlActionType?/jump" component={FeedbackJump} key="feedback-jump-key"/>

                  <Route exact path="/o/:hash?" component={CustomerOrderJumpView} />
                  <Route exact path="/c/:hash?" component={CustomerOrderJumpView} />

                  <Route exact path="/order/:hash?" component={CustomerOrderView} />
                  <Route exact path="/tp/:hash?" component={CustomerOrderView} />
                  <Route exact path="/b/:brand?/d/:doc?" component={BrandDocumentCustomerView}/>




                  <AuthAccountRoute exact path="/admin/p/:product?" component={ProductAdminView} />





                  <AuthAccountRoute exact path="/admin/promotions" component={AdminPromotions}/>
                  <AuthAccountRoute exact path="/admin/promotions/new" component={AdminPromotionNew} />
                  <AuthAccountRoute exact path="/admin/promotion/:p?/edit" component={AdminPromotionEdit} />
                  <AuthAccountRoute exact path="/admin/promotion/:p?" component={AdminPromotionView}/>



                  <AuthAccountRoute exact path="/admin/customers" component={AdminCustomers}/>
                  <AuthAccountRoute exact path="/admin/customers/new" component={AdminCustomerNew} />
                  <AuthAccountRoute exact path="/admin/customer/:c?/edit" component={AdminCustomerEdit} />
                  <AuthAccountRoute exact path="/admin/customer/:c?" component={AdminCustomerView} />

                  <AuthAccountRoute exact path="/admin/brands" component={AdminBrands}/>
                  <AuthAccountRoute exact path="/admin/brands/new" component={AdminBrandNew} />
                  <AuthAccountRoute exact path="/admin/brand/:b?/edit" component={AdminBrandEdit} />
                  <AuthAccountRoute exact path="/admin/brand/:b?" component={AdminBrandView} />

                  <AuthAccountRoute exact path="/admin/order-details" component={AdminOrderDetails}/>
                  <AuthAccountRoute exact path="/admin/order-details/new" component={AdminOrderDetailNew} />
                  <AuthAccountRoute exact path="/admin/order-detail/:od?" component={AdminOrderDetailView} />
                  <AuthAccountRoute exact path="/admin/order-detail/:od?/edit" component={AdminOrderDetailEdit} />
                  <AuthAccountRoute exact path="/o/:order?/od/:od?" component={CustomerOrderDetailView} />

                  <AuthAccountRoute exact path="/admin/orders" component={AdminOrders}/>
                  <AuthAccountRoute exact path="/admin/orders/new" component={AdminOrdersNew} />
                  <AuthAccountRoute exact path="/admin/o/:order?/edit" component={AdminOrderEdit} />
                  <AuthAccountRoute exact path="/admin/o/:order?" component={AdminOrderView} />
                  // <AuthAccountRoute path="/o/:order?/" component={CustomerOrderView} /> //REMOVED 2023-05-09, not sure what it's for


                  //
                  // import AdminUrls from './pages/adminControl/admin-urls'
                  // import AdminUrlNew from './pages/adminControl/admin-url-new'
                  // import AdminUrlEdit from './pages/adminControl/admin-url-edit'
                  // import AdminUrlView from './pages/urls/admin-url-view'


                  <AuthAccountRoute exact path="/admin/urls" component={AdminUrls}/>
                  <AuthAccountRoute exact path="/admin/urls/new" component={AdminUrlNew} />
                  <AuthAccountRoute exact path="/admin/url/:url?/edit" component={AdminUrlEdit} />
                  <AuthAccountRoute exact path="/admin/url/:url?" component={AdminUrlView} />




                  <AuthAccountRoute exact path="/admin/variants" component={AdminProductVariants}/>
                  <AuthAccountRoute exact path="/admin/variants/new" component={AdminProductVariantNew} />


                  <AuthAccountRoute exact path="/admin/variant_options" component={AdminProductVariantOptions}/>
                  <AuthAccountRoute exact path="/admin/variant_options/new" component={AdminProductVariantOptionNew} />











                </Switch>
              </ScrollToTop>

              <FooterDefault/>

            </Router>
          </div>
          </IntlProvider>


    );
  }
}


// <Route exact path="/shop/:pageNumber" component={home}/>
// <Route exact path="/signin" component={signin} />
// <Route exact path="/signup" component={signup} />
// <AuthAccountRoute exact path="/account/dashboard" component={MyAccountPage} />
// <AuthAccountRoute exact path="/store/products/new" component={NewProductPage} />
// <AuthAccountRoute exact path="/account/account-information" component={AccountInformationPage} />
// <AuthAccountRoute exact path="/account/account-notifications" component={AccountNotificationsPage} />
// <AuthAccountRoute exact path="/(shop)?/page=:page?" component={home}/>
// <AuthAccountRoute exact path="/verification/qr" component={qrVerification} />
//
//
// <AuthAccountRoute exact path="/admin/products" component={AdminProducts}/>
// <AuthAccountRoute exact path="/admin/products/new" component={AdminProductsNew} />
// <AuthAccountRoute exact path="/admin/p/:p?/edit" component={AdminProductEdit} />
// <Route exact path="/getzo/:product?" component={ProductCustomerView} />
// <AuthAccountRoute exact path="/admin/p/:product?" component={ProductAdminView} />
//
//
//
//
//
// <AuthAccountRoute exact path="/admin/promotions" component={AdminPromotions}/>
// <AuthAccountRoute exact path="/admin/promotions/new" component={AdminPromotionNew} />
// <AuthAccountRoute exact path="/admin/promotion/:p?/edit" component={AdminPromotionEdit} />
// <AuthAccountRoute exact path="/admin/promotion/:p?" component={AdminPromotionView}/>
//
//
//
// <AuthAccountRoute exact path="/admin/customers" component={AdminCustomers}/>
// <AuthAccountRoute exact path="/admin/customers/new" component={AdminCustomerNew} />
// <AuthAccountRoute exact path="/admin/customer/:c?/edit" component={AdminCustomerEdit} />
// <AuthAccountRoute exact path="/admin/customer/:c?" component={AdminCustomerView} />
//
// <AuthAccountRoute exact path="/admin/brands" component={AdminBrands}/>
// <AuthAccountRoute exact path="/admin/brands/new" component={AdminBrandNew} />
// <AuthAccountRoute exact path="/admin/brand/:b?/edit" component={AdminBrandEdit} />
// <AuthAccountRoute exact path="/admin/brand/:b?" component={AdminBrandView} />
//
// <AuthAccountRoute exact path="/admin/order-details" component={AdminOrderDetails}/>
// <AuthAccountRoute exact path="/admin/order-details/new" component={AdminOrderDetailNew} />
// <AuthAccountRoute exact path="/admin/order-detail/:od?" component={AdminOrderDetailView} />
// <AuthAccountRoute exact path="/admin/order-detail/:od?/edit" component={AdminOrderDetailEdit} />
// <Route exact path="/o/:order?/od/:od?" component={CustomerOrderDetailView} />
//
// <AuthAccountRoute exact path="/admin/orders" component={AdminOrders}/>
// <AuthAccountRoute exact path="/admin/orders/new" component={AdminOrdersNew} />
// <AuthAccountRoute exact path="/admin/o/:order?/edit" component={AdminOrderEdit} />
// <AuthAccountRoute exact path="/admin/o/:order?" component={AdminOrderView} />
// <Route path="/o/:order?/" component={CustomerOrderView} />
//
// //
// // import AdminUrls from './pages/adminControl/admin-urls'
// // import AdminUrlNew from './pages/adminControl/admin-url-new'
// // import AdminUrlEdit from './pages/adminControl/admin-url-edit'
// // import AdminUrlView from './pages/urls/admin-url-view'
//
//
// <AuthAccountRoute exact path="/admin/urls" component={AdminUrls}/>
// <AuthAccountRoute exact path="/admin/urls/new" component={AdminUrlNew} />
// <AuthAccountRoute exact path="/admin/u/:url?/edit" component={AdminUrlEdit} />
// <AuthAccountRoute exact path="/admin/u/:url?" component={AdminUrlView} />
//
//
//
//
// <Route exact path="/admin/variants" component={AdminProductVariants}/>
// <Route exact path="/admin/variants/new" component={AdminProductVariantNew} />
//
//
// <Route exact path="/admin/variant_options" component={AdminProductVariantOptions}/>
// <Route exact path="/admin/variant_options/new" component={AdminProductVariantOptionNew} />
function mapStateToProps(state) {
  return { lang: state.lang };
}

export default connect(mapStateToProps, null)(App)
// export default App;
