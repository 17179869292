import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio, Popconfirm, message } from 'antd';
import { Prompt } from 'react-router-dom';
// import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined } from '@ant-design/icons'

import {formatter, formatPhoneNumber} from '../../../utilities/common-helpers'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link






const mapState = ({ user }) => ({
  user: user,
})



const FormEditCustomer = () => {

  const {user, selectedUnit } = useSelector(mapState)

  const date = new Date()
  const [date_modified, setDate_modified] = useState("");


const [customer, setCustomer] = useState({})
  const [customer_name, setCustomer_name] = useState("");
  const [phone_number, setPhone_number] = useState("");

  const [loading, setLoading] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);




  let location = useLocation()
  const pathArray = location.pathname.split('/')



  useEffect(() => {
    getCustomer()
  }, []);

  async function getCustomer() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${pathArray[3]}`)
    const customerArray = await res.json();

    setCustomer(customerArray);
    setCustomer_name(customerArray.customer_name)
    setPhone_number(customerArray.phone_number)

    const title = "Luxanda | Edit Customer: " + pathArray[3]
    title && (document.title = title);
  }



  async function editRecord(e, record_id) {
    e.preventDefault();

    try {
      setLoading(true)
      const date_modified = new Date()
      const modified_by = user.credentials.email
      const body = { date_modified, modified_by, customer_name, phone_number };

      const response = await fetch(`${process.env.REACT_APP_API_URL}c/id/${record_id}/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        setLoading(false)
        console.log ("Record Updated")
        window.location = "/admin/customers";
      })

    } catch (err) {
      console.error(err.message);
      message.error(err.message)
    }
  };



  const fieldChanged = (type, value) => {
    if (type == 'customer_name'){
       setCustomer_name(value);
    } else if (type == 'phone_number'){
      setPhone_number(value);
    }
  };








    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Edit Customer</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Customer Information</h3></label>
                      </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Customer Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Customer Name"
                                value={customer_name}
                                onChange={e => fieldChanged('customer_name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Phone Number</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Phone Number"
                                value={phone_number}
                                onChange={e => fieldChanged('phone_number', e.target.value)}
                            />
                        </div>
                    </div>



                </div>

            </div>
            <div className="form-group submit"  style={{marginTop:"5rem"}}>
                <button
                  className="ps-btn"
                  onClick={e => editRecord(e, customer.id)}
                  disabled={loading}
                  >
                  {loading ? (<>Updating... <LoadingOutlined size="large"/></>) : ('Update Customer')}
                  </button>
            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormEditCustomer;
