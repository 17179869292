import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormEditPromotion = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)
  const date = new Date()

  const [date_modified, setDate_modified] = useState("");


  const [promotion, setPromotion] = useState({});
  const [promotion_date, setPromotion_date] = useState("");

  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [brand_id, setBrand_id] = useState("");

  const [brands, setBrands] = useState([])
  const [productsByBrand, setProductsByBrand] = useState([])


  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [brandsDropdown, setBrandsDropdown] = useState([])
  const [productsDropdown, setProductsDropdown] = useState([])




  let location = useLocation()
  const pathArray = location.pathname.split('/')


  async function getPromotion() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}promo/id/${pathArray[3]}`)
    const promotionArray = await res.json();
    console.log (promotionArray)



    setPromotion(promotionArray);
    setPromotion_date(promotionArray.promotion_date)
    setPrice(promotionArray.price)
    setCurrency(promotionArray.currency)
    setProduct_id(promotionArray.product_id)
    setBrand_id(promotionArray.brand_id)
    getProductsByBrand(promotionArray.brand_id)

  }



  useEffect(() => {
    getPromotion();
    getBrands();

  }, []);

  async function getBrands() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
    const brandsArray = await res.json()
    setBrands(brandsArray);
    setLoadingDropdowns(false)
  }

  async function getProductsByBrand(selected_brand_id) {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/b/${selected_brand_id}`)
    const productsByBrandArray = await res.json()
    setProductsByBrand(productsByBrandArray);
    setLoadingDropdowns(false)
  }


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //
  //   try {
  //     setLoading(true)
  //     const created_by = user.credentials.email
  //     const body = { date_created, promotion_date, price, currency, product_id, brand_id, created_by };
  //     console.log (body)
  //
  //     const response = await fetch("/promo", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(body),
  //     }).then((response) => {
  //       setLoading(false)
  //     })
  //
  //
  //     window.location = "/admin/promotions";
  //
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // };


  async function editRecord(e, record_id) {
  e.preventDefault();

  try {
    setLoading(true)
    const date_modified = new Date()
    const modified_by = user.credentials.email
    const body = { date_modified, modified_by, promotion_date, price, currency, product_id, brand_id };
    console.log (body)

    const response = await fetch(`${process.env.REACT_APP_API_URL}promo/id/${record_id}/a=edit`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }).then((response) => {
      setLoading(false)
      console.log ("Record Updated")
      window.location = "/admin/promotions";
    })




  } catch (err) {
    console.error(err.message);
  }
};







  const fieldChanged = (type, value) => {
    if (type == 'promotion_date'){

      const converted_date = moment(value)
      console.log (converted_date.toISOString())
         setPromotion_date(converted_date.toISOString());
    } else if (type == 'price'){
      setPrice(value);
    }

    // setDate_created(new Date())
    setCurrency('USD')


  };


  const handleSelect = (item, type) => {
    if (type == 'brand_id'){
      setBrand_id(item.target.value)
      getProductsByBrand(item.target.value)
      setProduct_id("")
    } else if (type == 'product_id'){
      setProduct_id(item.target.value)
    }
  }

  console.log ("savinngggg...")
  console.log ("promotion_date: " + promotion_date)
  console.log ("price: " + price)
  console.log ("brand_id: " + brand_id)
  console.log ("product_id: " + product_id)
  // console.log ("date_created: " + date_created)
  console.log ("currency: " + currency)
  console.log ("brands: ")
  console.log (brands)
  console.log ("products by brands: ")
  console.log (productsByBrand)




  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){


          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.id}
            </option>
          )))


        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }
    } else if (type == 'products'){
      if (productsByBrand.length > 0){
         return (
           productsByBrand.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.variant} - {item.sku}
            </option>
          )))
        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }

    }

  }

  const dropdown_brands = setSelectedId_View('brands')
  const dropdown_products = setSelectedId_View('products')
  const dateFormat = 'YYYY-MM-DD'

    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Edit Promotion</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                  <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form">Promotion Date</label>
                      {promotion_date ?
                        <div className="ant-calendar-picker">
                            <DatePicker
                                allowClear={false}
                                defaultValue={moment(promotion_date, dateFormat)}
                                onChange={e => fieldChanged('promotion_date', e)}
                            />
                        </div> : <div>Loading...</div>
                      }
                    </div>
                  </div>
                    <div class="separator"></div>


                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Brand ID</label>

                            {loadingDropdowns ? <div>Loading...</div> :
                            <select className="form-control" defaultValue={0} value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                                    {brands.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                      {dropdown_brands}
                                </select>}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product ID</label>
                            {loadingDropdowns ? <div>Loading...</div> :


                            <select className="form-control" defaultValue={0} value={product_id} onChange={ e=> handleSelect(e, 'product_id')} >

                                    {productsByBrand.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                  {dropdown_products}
                                </select>}




                        </div>
                    </div>



                    <div class="separator"></div>
                    <div className="col-sm-auto">
                        <div className="form-group">
                          <label className="form">Price</label>
                            <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>

                              <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Price"
                                  value={price}
                                  onChange={e => fieldChanged('price', e.target.value)}
                              />
                              <div class="input-group-append">
                                <span class="input-group-text">USD</span>
                              </div>
                            </div>
                        </div>
                    </div>



                    <div class="separator"></div>

                </div>

                <div className="form-group submit">
                    <button
                      className="ps-btn"
                      onClick={e => editRecord(e, promotion.id)}
                      disabled={loading}
                      >
                      {loading ? (<>Updating... <LoadingOutlined size="large"/></>) : ('Update Promotion')}
                      </button>
                </div>

            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormEditPromotion;
