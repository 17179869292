import React, { Component, useEffect, useState } from 'react'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';
import FormNewProduct from '../../components/shared/forms/form-new-product';


const Link = require("react-router-dom").Link

const AdminProductNewPage = () => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);


  async function getProducts() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`);
    const productsArray = await res.json();
    setProducts(productsArray);
  }

  useEffect(() => {
    getProducts();
  }, []);





  // async function getProducts() {
  //   const res = await fetch('/p/all');
  //   const productsArray = await res.json();
  //   setProducts(productsArray);
  // }

  // useEffect(() => {
  //   getProducts();
  // }, []);

  //
  // function handleChangeTab(e, tab) {
  //   e.preventDefault();
  //   // setCurrentCollection(tab.name);
  //   // getProducts(tab.slug);
  // }

  const img = {
    display: 'block',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  return (

        <section className="ps-my-account ps-page--account">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ps-page__content">
                      <FormNewProduct/>
                    </div>
                  </div>
                </div>

<div style={{height: '5vh'}}/>
                <form className="ps-form--product-form" >
                    <div className="ps-form__header">
                        <h3>Existing Products</h3>
                    </div>
                    <div className="ps-form__content">
                      <div className="form-group">

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="ps-page__content">
                                <table class="table mt-5">
                                  <thead>
                                    <tr>

                                      <th>Name</th>
                                      <th>Brand ID</th>
                                      <th>Description</th>
                                      <th>Variant</th>
                                      <th>SKU</th>

                                      <th>Pic</th>
                                      <th>View</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {/*<tr>
                                    <td>John</td>
                                    <td>Doe</td>
                                    <td>john@example.com</td>
                                  </tr> */}

                                  {products.map((product) => (
                                    <tr key={product.id}>

                                      <td style={tableRow}>{product.name}</td>
                                      <td style={tableRow}>{product.brand_id}</td>
                                      <td style={tableRow}>{product.description}</td>
                                      <td style={tableRow}>{product.variant}</td>
                                      <td style={tableRow}>{product.sku}</td>

                                      <td style={tableRow}><img src={product.pic} style={img} alt="CBR0" border="0" /></td>
                                      <td style={tableRow}>
                                        <Link to={`/p/${product.sku}`}>
                                          <button className="ps-btn">View</button>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </table>
                          </div>
                        </div>
                      </div>


                      </div>
                    </div>
                </form>
                <div style={{height: '5vh'}}/>


            </div>
        </section>

  );

}


export default AdminProductNewPage;
