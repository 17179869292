import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { changeLang } from "../../../../redux/actions/lang"
import {useDispatch, useSelector} from 'react-redux'
import * as icon from './language-icon.png'
import { CaretDownOutlined } from '@ant-design/icons'


// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

// import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// import Popover from "@material-ui/core/Popover";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import LanguageSelectButton from "./elements";
// import { makeStyles } from '@material-ui/core/styles'
// import styled from 'styled-components';
// import { createMuiTheme } from "@material-ui/core/styles"

const mapState = ({ lang }) => ({
  lang: lang,
})


const LanguageSelect = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector(mapState)

  const [default_language, setDefault_language] = useState('English')


  const languageMap = [
    {lan: 'en', label: "English", dir: "ltr", active: true },
    {lan: 'cn', label: "中文", dir: "ltr", active: false },
    {lan: 'fr', label: "Français", dir: "ltr", active: false },
  ]

  function setSelectedId_View (type) {
    return (
      languageMap.map((item) => (
      <option value={item.lan} key={item.lan}  >
        {item.label}
      </option>
    )))
  }


  const [menuOpen,setMenuOpen] = useState(false);

  const dropdown = setSelectedId_View()


  const handleSelect = (e, item) => {
    setMenuOpen(false)
    // e.preventDefault()
    setDefault_language(item)
    // changeLang(item.target.value)
    dispatch({type:"CHANGE_LANG", payload:{default: item}})
  }

  // console.log (default_language)

  return (
    <div className="ps-dropdown" style={{placeSelf: 'center'}}>
        {languageMap ? (
            <div className="ps-btn--language"  onClick={e => setMenuOpen(true)}>
                <img className="image" src='/static/imgs/language-icon.png' />
                <CaretDownOutlined />
            </div>
        ) : (
            'this'
        )}
        {menuOpen ?
          <ul className="menu--dropdown">
              <li>
                  <a
                      onClick={e =>
                          handleSelect(e, 'en')
                      }>
                      English
                  </a>
              </li>
              <li>
                  <a
                      onClick={e =>
                          handleSelect(e, 'fr')
                      }>
                      Français
                  </a>
              </li>
              <li>
                  <a
                      onClick={e =>
                          handleSelect(e, 'cn')
                      }>
                      中文
                  </a>
              </li>


          </ul>
        : null}
    </div>
  );
};


export default LanguageSelect
// export default connect(null, {changeLang})(LanguageSelect);
