import React from 'react';
// import Link from 'next/link';
const Link = require("react-router-dom").Link

// import {
//     StrapiProductPrice,
//     StrapiProductThumbnail,
// } from '~/utilities/product-helper';
// import Rating from '~/components/elements/Rating';

const ProductSearchResult = ({ product }) => {
    return (
        <div className="ps-product ps-product--wide ps-product--search-result">
            <div className="ps-product__thumbnail">
            </div>
            <div className="ps-product__content">
                <Link className="ps-product__title" to="/product/[pid]" as={`/product/${product.id}`}>
                    {product.title}
                </Link>
                <div className="ps-product__rating">
                    <span>{product.ratingCount}</span>
                </div>
            </div>
        </div>
    );
};
export default ProductSearchResult;
