import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'

import { countriesList } from './data/countries'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormEditBrand = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)


  const date = new Date()
  const [date_modified, setDate_modified] = useState("");


  const [brand, setBrand] = useState({})
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");

  const [loading, setLoading] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);



  const location = useLocation()
  const pathArray = location.pathname.split('/')


  async function getBrand() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/id/${pathArray[3]}`)
    const brandArray = await res.json();
    console.log (brandArray)
    setBrand(brandArray);
    setName(brandArray.name)
    setCountry(brandArray.country)

    const title = "Luxanda | Edit Brand: " + pathArray[3]
    title && (document.title = title);
  }

  useEffect(() => {
    getBrand()
  }, []);

  // async function getBrands() {
  //   console.log ("loading...")
  //   setLoadingDropdowns(true)
  //   const res = await fetch('/b/all')
  //   const brandsArray = await res.json()
  //   setBrands(brandsArray);
  //   setLoadingDropdowns(false)
  // }
  //
  // async function getProductsByBrand(selected_brand_id) {
  //   console.log ("loading...")
  //   setLoadingDropdowns(true)
  //   const res = await fetch(`/p/b/${selected_brand_id}`)
  //   const productsByBrandArray = await res.json()
  //   setProductsByBrand(productsByBrandArray);
  //   setLoadingDropdowns(false)
  // }


  async function editRecord(e, record_id) {
    e.preventDefault();

    try {
      setLoading(true)
      const date_modified = new Date()
      const modified_by = user.credentials.email
      const body = { date_modified, modified_by, name, country };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}b/id/${record_id}/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        setLoading(false)
        console.log ("Record Updated")
        window.location = "/admin/brands";
      })




    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  };
  //
  // async function deleteRecord(record_id) {
  //   try {
  //     // setLoading(true)
  //     const deleted_at = new Date()
  //     const deleted_by = userCredentials.email
  //     const body = {deleted_at, deleted_by}
  //     const res = await fetch(`/b/id/${record_id}/a=del`, {
  //       method: "PUT",
  //       headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(body),
  //     }).then((response) => {
  //       // setLoading(false)
  //       console.log ("Record Deleted")
  //       window.location.reload(false)
  //     })
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // }







  const fieldChanged = (type, value) => {
    if (type == 'name'){
       setName(value);
    } else if (type == 'country'){
      setCountry(value);
    }

    // setDate_created(new Date())
  };

  const handleSelect = (item, type) =>{

    if (type == 'country'){
      setCountry(item.target.value)
    }
  }

  function setCountriesDropdown (type) {
      let data
      if (type == 'countries'){
        data = countriesList
      }
      console.log (data)
      return data.map((item) => (
          <option value={item.name} key={item.code}  >
                  {item.name}
            </option>
      ));
    }

  const countriesDropdown = setCountriesDropdown ('countries')



  console.log ("updating...")
  console.log ("name: " + name)
  console.log ("country: " + country)




    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Edit Brand</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                  <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Brand Information</h3></label>
                        </div>
                  </div>




                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Brand Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Brand Name"
                                value={name}
                                onChange={e => fieldChanged('name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-9"></div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Country</label>
                            <select className="form-control" value={country} onChange={ e=> handleSelect(e, 'country')} >
                              {countriesDropdown}
                            </select>
                        </div>
                    </div>








                </div>



            </div>

            <div className="form-group submit" style={{marginTop:"5rem"}}>
                <button
                  className="ps-btn"
                  onClick={e => editRecord(e, brand.id)}
                  disabled={loading}
                  >
                  {loading ? (<>Updating... <LoadingOutlined size="large"/></>) : ('Update Brand')}
                  </button>
            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormEditBrand;
