import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import { connect } from 'react-redux'
import { assignHashToUser } from '../../redux/actions/userActions'
import { saveDeviceActions } from '../../utilities/common-helpers'
import { LoadingOutlined, VerifiedOutlined, CheckCircleOutlined, WechatOutlined} from '@ant-design/icons'
import { countryFlags } from './data/country-flags'
import TextareaAutosize from 'react-textarea-autosize';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css'

import './customer-url-view.css';
// import '../../utilities/weixin/weui.min.css';



// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'
import { Popconfirm, message } from 'antd'

import { useIntl, FormattedMessage } from "react-intl"


var sha256 = require('sha256')



const Link = require("react-router-dom").Link

const mapState = ({ user }) => ({
  user: user,
})

const CustomerSkuView = ({title}) => {
  const intl = useIntl()

  const dispatch = useDispatch();
  const { user } = useSelector(mapState)

  // const [url, setUrl] = useState({})
  const [urlDetails, setURLDetails] = useState({})
  const [product, setProduct] = useState({});
  const [sku_details , setSku_details] = useState([]);
  const [loading, setLoading] = useState(true)
  const [foundProduct, setFoundProduct] = useState(false)
  const [foundOrder, setFoundOrder] = useState(false)
  const [isJumpPage, setIsJumpPage] = useState(false)
  const [loading_jumpPage, setLoading_jumpPage] = useState(false)




  const location = useLocation()
  const pathArray = location.pathname.split('/')


  const ua = navigator.userAgent.toLowerCase()
  const [isWXWork, setIsWXWork] = useState(ua.match(/wxwork/i) == 'wxwork')

  const [isWeixin, setIsWeixin] = useState(!isWXWork && ua.match(/micromessenger/i) == 'micromessenger')
  const [isMobile, setIsMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [urlItemType, setUrlItemType] = useState('')





  async function setupURL() {
    setLoading(true)
    try{
      const urlArray = await getURLDetails()


      setURLDetails(urlArray);

      // console.log (urlArray)

      let is_product_found = false
      let is_order_found = false

      if (urlArray.sku_id){
        is_product_found = true
        setFoundProduct(true)
        getSku(urlArray.sku_id)
        setUrlItemType(urlArray.url_item_type)
        if (urlArray.order_id){
          saveDeviceActions(location.pathname, "User Visit - SKU - sku_id: " + urlArray.sku_id + " with order_id: " + urlArray.order_id, urlArray.url_item_type, null, null)
        } else {
          saveDeviceActions(location.pathname, "User Visit - SKU - sku_id: " + urlArray.sku_id, urlArray.url_item_type, null, null)
        }
      }

      if (urlArray.order_id){
        is_order_found = true
        setFoundOrder(true)
      }

      if (is_order_found && !is_product_found){
        window.location = `order/${sha256('GETZO_ORDER-' + urlArray.order_id)}`
      }

    } catch (err) {
      console.log (err)
    }
    setLoading(false)
  }

  async function getURLDetails() {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}u/ugh/${pathArray[2]}`)
      .then((res) => {
          resolve(res.json())
      })
    })
  }



  async function getProduct(id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/pid/${id}`)
    const productArray = await res.json();


    const title = "Luxanda | " + productArray.brand_name + ": " + productArray.name
    title && (document.title = title);
    document.querySelector('meta[name="og:title"]').setAttribute("content", productArray.name + ' by ' + productArray.brand_name)




    setProduct(productArray);
    setLoading(false)
  }

  async function getSku(record_id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/id/${record_id}`)
    const skuArray = await res.json();
    // skuArray.sku = "KMLG-fghfgh"
    setSku_details(skuArray);
    getProduct(skuArray.product_id)

    // document.getElementsByTagName('meta')["og:image"].content = skuArray.pic_url
    document.querySelector('meta[name="og:image"]').setAttribute("content", skuArray.pic_url)

    setLoading(false)
  }


  useEffect(() => {
    setupURL();
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
      setIsMobile(true)
    } else {
      setIsDesktop(true)
    }
  }, []);



  // useEffect(() => {
  //   const oldTitle = document.title;
  //   const title = "Luxanda | Product " + product.name
  //   title && (document.title = title);
  //   // following line is optional, but will reset title when component unmounts
  //   return () => document.title = oldTitle;
  // }, [title])

  // useEffect(() => {
  //   if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
  //     setIsMobile(true)
  //   } else {
  //     setIsDesktop(true)
  //   }
  // })


  function handleChangeTab(e, tab) {
    e.preventDefault();

  }

  function handleSubmit(e) {
    // console.log ("navigating")
  }

  function handleJumpPage(e) {
    e.preventDefault();
    setIsJumpPage(true)
    setLoading_jumpPage(true)
    generateWeChatLink()
  }

  async function generateWeChatLink(){
    const jumpType = 'product'
    const res = await fetch(`${process.env.REACT_APP_API_URL}wc_jump/${jumpType}/${product.wc_url}`)
    const jump_data = await res.json();
    if (jump_data && jump_data.openlink){
      message.success (intl.formatMessage({ id: 'success_openlink_generated' }))
      window.location.href = jump_data.openlink
    } else {
      message.error (intl.formatMessage({ id: 'error_something_went_wrong' }))
    }
    setLoading_jumpPage(false)
  }

  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '30%',
    minWidth: '150px',
    objectFit: 'cover',
  };

  const mp_link = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: '0px',
    cursor: 'pointer'


  }

  const mp_icon = {
    width: '3rem',
  }



  function makeView() {
    return (
      foundProduct ?
        <div className="row" style={{placeContent: 'center'}}>
          <div className="col-lg-3">
            <div className="form-group">
              {isMobile ? <div style={mp_link} onClick={e => handleJumpPage(e)}>
                <div
                    className="form-control-plaintext"
                    style={{color: "#7a79b0", fontSize: '12px', fontWeight: '600', padding: '0px'}}
                    type="text"
                    placeholder={null}
                    readOnly={true}
                >
                  <FormattedMessage
                    id="message_view_on_wechat"
                  />
                </div>
                <img src="/static/imgs/mini-program-icon-2.png" alt={`Luxanda | WeChat Mini-Program | ${sku_details.sku}`} style={mp_icon}/>
              </div> : null}
              <img src={sku_details && sku_details.pic_url ? sku_details.pic_url : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
              style={{display: 'block',
                  maxWidth: '300px',
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  margin: 'auto',
                }} alt={sku_details && sku_details.pic_url ? sku_details.pic_url : 'Not Found'} border="0" />
            </div>
          </div>

          <div className="col-lg-9">
            <div className="form-group" style={{marginBottom: '0px'}}>
              <div style={{display: 'block'}}>
                {sku_details && sku_details.combo_identifier != 'NO OPTIONS' ?
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <i className='icon-chevron-right' style={{fontSize: '16px', fontWeight: '600', color: '#ffb81c'}}></i>
                    <input
                        className="form-control-plaintext"
                        style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginBottom: '0px', color: '#ffb81c'}}
                        type="text"
                        placeholder={null}
                        readOnly={true}
                        value={sku_details && sku_details.combo_identifier ? sku_details.combo_identifier: ''}
                    />
                </div> : null}
                <input
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '26px', fontWeight: '600', padding: '0px'}}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'label_loading' })}
                    readOnly={true}
                    value={product && product.name ? product.name : ''}
                />
                <div
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginTop: '-7px'}}
                    type="text"
                    placeholder={null}
                    readOnly={true}
                >
                  <FormattedMessage
                    id="product_by"
                  />
                  {product && product.brand_name ? ' ' + product.brand_name : ''}
                </div>
                {product && product.flag_serialization ?
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <CheckCircleOutlined style={{ fontSize: '14px', color: 'lightgrey'}} />
                  <div
                      className="form-control-plaintext"
                      style={{backgroundColor: "white", fontSize: '14px', padding: '0px', color: 'lightgrey', fontStyle: 'italic', fontWeight: '600'}}
                      type="text"
                      placeholder={null}
                      readOnly={true}
                  >
                  <FormattedMessage
                    id="product_limited_production"
                  />
                  {product && product.flag_serialization ? ' ' + sku_details.serial_run_limit : ''}
                  </div>

                </div> : null}
                <div
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '12px', padding: '0px', color: 'lightgrey', fontStyle: 'italic'}}
                    type="text"
                    placeholder={null}
                    readOnly={true}
                  >
                  <FormattedMessage
                    id="product_sku"
                  />
                  {sku_details && sku_details.sku ? ': ' + sku_details.sku : ''}
                </div>
                <div className="separator" style={{margin: '15px 0px 15px'}}></div>
                    <TextareaAutosize
                        className="form-control-plaintext"
                        readOnly={true}
                        style={{display: 'block', backgroundColor: "white", fontSize: '12px', padding: '0px', height: 'auto', resize: 'none', marginTop: '1rem', marginBottom: '5rem'}}
                        value={product && product.description ? product.description : ''}
                    />
                </div>
              </div>

              {sku_details.sku && ((sku_details.sku).includes("KMLG-FILTER") || (sku_details.sku).includes("KMLG-MASK")) ?
              <div
                  className="form-control-plaintext"
                  style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginTop: '-7px'}}
                  type="text"
                  placeholder={null}
                  readOnly={true}
              >
                <FormattedMessage
                  id="title_product_care_videos"
                />
              </div> : null}

              {sku_details.sku && ((sku_details.sku).includes("KMLG-FILTER") || (sku_details.sku).includes("KMLG-MASK")) ?
              <Accordion allowZeroExpanded style={{marginTop: "1rem", marginBottom: "5rem"}}>
              {sku_details.sku && (sku_details.sku).includes("KMLG-MASK") ?
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <FormattedMessage
                          id="title_product_care_unboxing"
                        />
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <video src="https://luxanda-qr-serverless-frontend-1309414541.cos.ap-hongkong.myqcloud.com/static/videos/Vendor%20Informative%20Videos/KMLG/VQHIE8310%20-%20Product%20Unboxing%20-%2050MB.mp4" controls width="100%" height="100%"></video>
                  </AccordionItemPanel>
              </AccordionItem> : null}
              {sku_details.sku && (sku_details.sku).includes("KMLG-MASK") ?
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <FormattedMessage
                          id="title_product_care_how_to_adjust_mask"
                        />
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <video src="https://luxanda-qr-serverless-frontend-1309414541.cos.ap-hongkong.myqcloud.com/static/videos/Vendor%20Informative%20Videos/KMLG/VQHIE8310%20-%20Adjusting%20the%20Strap%20-%2050MB.mp4" controls width="100%" height="100%"></video>
                  </AccordionItemPanel>
              </AccordionItem> : null}


              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <FormattedMessage
                          id="title_product_care_how_to_change_filter"
                        />
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <video src="https://luxanda-qr-serverless-frontend-1309414541.cos.ap-hongkong.myqcloud.com/static/videos/Vendor%20Informative%20Videos/KMLG/VQHIE8310%20-%20Filter%20Change%20-%2050MB.mp4" controls width="100%" height="100%"></video>
                  </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                      <FormattedMessage
                        id="title_product_care_how_to_wash_filter"
                      />
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <video src="https://luxanda-qr-serverless-frontend-1309414541.cos.ap-hongkong.myqcloud.com/static/videos/Vendor%20Informative%20Videos/KMLG/VQHIE8310%20-%20Washing%20the%20Filter%20-%2050MB.mp4" controls width="100%" height="100%"></video>
                  </AccordionItemPanel>
              </AccordionItem>
          </Accordion> : null}

            </div>









              {product && product.warranty_information ?
                <div className="col-lg-3" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <div className="form-group">
                    <Link to={`/warranty/${pathArray[2]}`}>
                      <button className="ps-btn" style={{width: '100%'}}>
                        <FormattedMessage
                          id="action_view_warranty"
                        />
                      </button>
                    </Link>
                  </div>
              </div> : null}

              <div className="col-lg-3" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div className="form-group">
                  <Link to={`/feedback/${pathArray[2]}/${urlItemType}/jump`}>
                    <button className="ps-btn" style={{width: '100%'}}>
                      <FormattedMessage
                        id="action_leave_feedback"
                      />
                    </button>
                  </Link>
                </div>
              </div>


            </div> :
            foundOrder ?
            <div
                className="form-control-plaintext"
                style={{backgroundColor: "white", color: '#888a8c', fontSize: '26px', fontWeight: '600', padding: '0px'}}
                type="text"
                readOnly={true}
            >
              <FormattedMessage
                id="message_url_found_order"
              />
            </div> :
            <div
                className="form-control-plaintext"
                style={{backgroundColor: "white", color: '#888a8c', fontSize: '16px', fontWeight: '600', padding: '0px'}}
                type="text"
                readOnly={true}
            >
              <FormattedMessage
                id="message_url_unassigned"
              />
            </div>
    )
  }


// 正在打开 “Luxanda”...

  return (
    <section className="ps-my-account ps-page--account">
      {loading_jumpPage ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'white', zIndex: '9999', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <LoadingOutlined style={{fontSize: '25px', marginBottom: '2rem'}}/>
      <FormattedMessage
        id="message_openning_wechat"
      />
      </div> : null}

    <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">
              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>{product && product.name ? product.name : null }</h3>
                    </div>
                    {foundOrder && foundProduct?
                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`/order/${sha256('GETZO_ORDER-' + urlDetails.order_id)}`}>
                        <button className="ps-btn" onClick={e => handleSubmit(e)}>
                          <FormattedMessage
                            id="action_view_order"
                          />
                        </button>
                      </Link>
                    </div> : null}
                  </div>
                </div>
                <div className="ps-form__content">


                {!loading ? makeView() :
                  <input
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '26px', fontWeight: '600', padding: '0px'}}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'label_loading' })}
                    readOnly={true}
                    value=''
                    />}


                      <div className="col-lg-12">
                          <div className="form-group">
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <img src={product && product.brand_country ? countryFlags[product.brand_country].mini : null} style={{height: '12px'}}></img>
                              <div className="form-control-plaintext" style={{backgroundColor: "white", fontSize: '12px', padding: '0px', fontWeight: '600', fontStyle: 'bold', marginTop: '5px', width: 'auto'}}>
                              {product && product.brand_country ? 'Product of ' + product.brand_country : null}
                              </div>
                            </div>
                          </div>



                    </div>

                </div>
              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




    </div>
</section>

  );

}


export default CustomerSkuView;
