import { createStore, combineReducers, applyMiddleware, compose }from 'redux'
import thunk from 'redux-thunk'
import userReducer from './reducers/userReducer'
import dataReducer from './reducers/dataReducer'
import uiReducer from './reducers/uiReducer'
import searchReducer from './reducers/searchReducer'
import lang from "./reducers/lang"

const initialState = {};
const middleware = [thunk];
const reducers = combineReducers({
  user: userReducer,
  data: dataReducer,
  ui: uiReducer,
  searchResults: searchReducer,
  lang: lang,
});

const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // ,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
