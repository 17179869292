import React from 'react';
import ModuleProductActions from './modules/ModuleProductActions';


//import Rating from '../Rating';

import {
    StrapiProductBadge,
    StrapiProductPrice,
    StrapiProductThumbnail,
} from '../../../utilities/product-helpers';

const Link = require("react-router-dom").Link

const Product = ({ product }) => {
    // Views
    const priceView = product.price //StrapiProductPrice(product);
    const thumbnailImage = product.pic_array[0] == null ? "nothing" : StrapiProductThumbnail(product.pic_array[0]) //StrapiProductThumbnail(product);
    const badgeView = "10" //StrapiProductBadge(product);
    const bid = product.brand_name

    return (
        <div className="ps-product">
            <div className="ps-product__thumbnail">
                {thumbnailImage}
                {badgeView}
                <ModuleProductActions product={product} />
            </div>
            <div className="ps-product__container">
                <Link to="/shop">
                    <a className="ps-product__vendor">{bid}</a>
                </Link>
                <div className="ps-product__content">
                    <Link to="/product/[pid]" as={`/product/${product.id}`}>
                        <a className="ps-product__title">{product.name}</a>
                    </Link>

                    ${priceView}
                </div>
                <div className="ps-product__content hover">
                    <Link to="/product/[pid]" as={`/product/${product.id}`}>
                        <a className="ps-product__title">{product.name}</a>
                    </Link>
                    ${priceView}
                </div>
            </div>
        </div>
    );
};

export default Product;
