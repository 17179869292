import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'


const mapState = ({ user }) => ({
  user: user,

})

const FormNewProduct = (userCredentials) => {

  const {user, selectedUnit } = useSelector(mapState)
  console.log (user)

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);


  //This is where you can select the default selections [0]: cm, [1]: m
  const defaultUnits_Metric = [weightUnits_metric[1].text, dimensionUnits_metric[1].text]
  const defaultUnits_Imperial = [weightUnits_imperial[1].text, dimensionUnits_imperial[1].text]
// .ps-page--product-form

  //SELECTION STATES
  const [selectedUnit_Type, setSelectedUnit_Type] = useState('metric')
  const [selectedUnit_Weight, setSelectedUnit_Weight] = useState(defaultUnits_Metric[0])
  const [selectedUnit_Dimension, setSelectedUnit_Dimension] = useState(defaultUnits_Metric[1])

  //UPLOADER STATES
  // const [files, setFiles] = useState([])




  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  const uploaderContainer = {
    width: '100%'
  }

  const [files, setFiles] = useState([]);
  console.log (files)
  function Previews(props) {

    const {getRootProps, getInputProps} = useDropzone({
      accept: 'image/*',
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });

    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
          />
        </div>
      </div>
    ));

    useEffect(() => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
      <section style={uploaderContainer}>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <div style={thumbsContainer}>
          {thumbs}
        </div>
      </section>
    );
  }








  const handleSelect = (item, type) =>{

    if (type == 'weight'){
      setSelectedUnit_Weight(item.target.value)
    } else if (type == 'dimension'){
      setSelectedUnit_Dimension(item.target.value)
    } else if (type == 'metric' || type == 'imperial'){
      setSelectedUnit_Type(type)

      //reset defaults
      if (type == 'metric'){
        setSelectedUnit_Weight(defaultUnits_Metric[0])
        setSelectedUnit_Dimension(defaultUnits_Metric[1])
      } else if (type == 'imperial'){
        setSelectedUnit_Weight(defaultUnits_Imperial[0])
        setSelectedUnit_Dimension(defaultUnits_Imperial[1])
      }
    }
  }







  useEffect(() => {
    console.log ("selectedUnitType: " + selectedUnit_Type)
    console.log ("selectedWeightUnit: " + selectedUnit_Weight)
    console.log ("selectedDimensionUnit: " + selectedUnit_Dimension)
  } )



  function setSelectedUnit_View (type) {
    let data
    if (type == 'weight'){
      if (selectedUnit_Type == 'metric'){
        data = weightUnits_metric
      } else if (selectedUnit_Type == 'imperial'){
        data = weightUnits_imperial
      }

    } else if (type == 'dimension'){
      if (selectedUnit_Type == 'metric'){
        data = dimensionUnits_metric
      } else if (selectedUnit_Type == 'imperial'){
        data = dimensionUnits_imperial
      }
    }

    return data.map((item) => (
        <option value={item.text} key={item.order}  >
                {item.text}
          </option>
    ));
  }



  const selectedUnit_Weight_View = setSelectedUnit_View ('weight')
  const selectedUnit_Dimension_View = setSelectedUnit_View ('dimension')


    return (
      <div>
        <form className="ps-form--product-form">
            <div className="ps-form__header">
                <h3>Create New Product</h3>
            </div>
            <div className="ps-form__content">
                <div className="form-group">

                    <Previews/>
                </div>
              </div>
              <div style={{height: '5vh'}}>
              </div>
            <div className="ps-form__content">
                <div className="form-group">
                    <label className="text">
                        {user.credentials.email}
                    </label>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="form">Product Title</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Product Title"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                          <label className="form">Product Description</label>
                            <textarea
                                className="form-control"
                                type="text"
                                placeholder="Product Description"
                                aria-label="with this"
                            />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                          <label className="form">Good to Know</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Good to Know"
                            />
                        </div>
                    </div>
                    <div className="separator"></div>


                    <div className="col-sm-12">
                        <div className="form-group">
                        <label className="form">Units</label>
                          <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="unit_system_radio" id="radio_system1" value="option1" checked={selectedUnit_Type == 'metric' ? true : false} onClick={ e=> handleSelect(e, 'metric')}/>
                              <label className="form-check-label" for="radio_system1">Metric</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="unit_system_radio" id="radio_system2" value="option2" checked={selectedUnit_Type == 'imperial' ? true : false} onClick={ e=> handleSelect(e, 'imperial')}/>
                              <label className="form-check-label" for="radio_system2">Imperial</label>
                            </div>
                          </div>
                        </div>
                      </div>


                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">Weight</label>
                          <div className="input-group">

                            <input
                                className="form-control"
                                type="number"
                                placeholder="Weight"
                            />
                            <div className="input-group-append">
                                <select className="form-control" value={selectedUnit_Weight} onChange={ e=> handleSelect(e, 'weight')} >
                                  {selectedUnit_Weight_View}
                                </select>
                            </div>
                          </div>
                        </div>

                    </div>






                    <div className="col-sm-12">
                    </div>
                      <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form">Dimensions</label>
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">Width</div>
                              </div>
                              <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Width"
                              />
                              <div className="input-group-append">
                                  <select  className="form-control" value={selectedUnit_Dimension} onChange={ e=> handleSelect(e, 'dimension')}>
                                    {selectedUnit_Dimension_View}


                                  </select>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-sm-12">
                      </div>
                      <div className="col-sm-6">
                          <div className="form-group">
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">Length</div>
                              </div>
                              <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Length"
                              />
                              <div className="input-group-append">
                                  <div className="input-group-text">{selectedUnit_Dimension}</div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-sm-12">
                      </div>
                      <div className="col-sm-6">
                          <div className="form-group">
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">Height</div>
                              </div>
                              <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Height"
                              />
                              <div className="input-group-append">

                                    <div className="input-group-text">{selectedUnit_Dimension}</div>

                              </div>
                            </div>
                          </div>

                    </div>
                    <div className="separator"></div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">SKU</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="SKU"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">Barcode</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Barcode"
                            />
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">Inital Quantity</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Initial Quantity"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">Barcode</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Barcode"
                            />
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="col-sm-auto">
                        <div className="form-group">
                          <label className="form">Price</label>
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>

                              <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Price"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">CAD</span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form">Address</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Address"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form">City</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="City"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form">Country</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group submit">
                    <button className="ps-btn">Save Product</button>
                </div>

            </div>
        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
        </div>
    );
};

export default FormNewProduct;


// <div className="col-sm-3">
//   <div className="form-group">
//     <label className="form"></label>
//     <select className="form-control form-control-sm">
//
//       <option>Metric</option>
//       <option>Imperial</option>
//
//     </select>
//   </div>
//
// </div>
