import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
// import Link from 'next/link';
import ProductsTable from '../../shared/tables/ProductsTable';
import {signoutUser} from '../../../redux/actions/userActions'

import { adminLinks } from './data/admin-control-navigation'
import { csrLinks } from './data/csr-control-navigation'

const Link = require("react-router-dom").Link

class AdminProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user: '',
          ui: ''
        };

        adminLinks[0].active = false
        adminLinks[1].active = true
        adminLinks[2].active = false
        adminLinks[3].active = false
        adminLinks[4].active = false

        csrLinks[0].active = false
        csrLinks[1].active = true
        csrLinks[2].active = false
        // adminLinks[5].active = false
        // adminLinks[6].active = false
        // adminLinks[7].active = false
        // adminLinks[8].active = false
    }

    render () {
      // console.log ("Output:")
      // console.log (this.props.user.credentials)
      // console.log ("Output:")
      // console.log (this.props.ui)
      const { user: {user} } = this.props

      const title = "Luxanda | Products"
      title && (document.title = title);

      const handleLogout = (e) => {
          this.props.signoutUser()
      };



      //Views
      const accountLinkView = adminLinks.map((item) => (
          <li key={item.text} className={item.active ? 'active' : ''}>
              <Link to={item.url}>
                  <div>
                      <i className={item.icon}></i>
                      {item.text}
                  </div>
              </Link>
          </li>
      ));

      return (
          <section className="ps-my-account ps-page--account">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-2">
                          <div className="ps-section__left">
                              <aside className="ps-widget--account-dashboard">
                                  <div className="ps-widget__header">
                                      <img src={this.props.user.credentials.imageUrl} />
                                      <figure>
                                          <figcaption>Hello,</figcaption>
                                          <p>{this.props.user.credentials.first_name}</p>
                                      </figure>
                                  </div>
                                  <div className="ps-widget__content">
                                      <ul>
                                          {this.props.user.credentials.role == 'administrator' ? adminLinks.map((link) => (
                                              <li
                                                  key={link.text}
                                                  className={
                                                      link.active ? 'active' : ''
                                                  }>
                                                  <Link to={link.url}>
                                                      <div>
                                                          <i
                                                              className={
                                                                  link.icon
                                                              }></i>
                                                          {link.text}
                                                      </div>
                                                  </Link>
                                              </li>
                                          )) :
                                          csrLinks.map((link) => (
                                              <li
                                                  key={link.text}
                                                  className={
                                                      link.active ? 'active' : ''
                                                  }>
                                                  <Link to={link.url}>
                                                      <div>
                                                          <i
                                                              className={
                                                                  link.icon
                                                              }></i>
                                                          {link.text}
                                                      </div>
                                                  </Link>
                                              </li>
                                          ))}
                                          <li>
                                              <Link to="/" onClick={(e) => handleLogout(e)}>
                                                  <div>
                                                      <i className="icon-power-switch"></i>
                                                      Logout
                                                  </div>
                                              </Link>
                                          </li>
                                      </ul>
                                  </div>
                              </aside>
                          </div>
                      </div>
                      <div className="col-lg-9">
                          <div className="ps-page__content">
                              <ProductsTable userCredentials={this.props.user.credentials}/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      );
    }
};

AdminProducts.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

const mapActionsToProps = {
  signoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(AdminProducts);
