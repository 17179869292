import React from 'react'
// import Link from 'react-router-dom'
import { Link } from 'react-router-dom'
// const Link = require("react-router-dom").Link

const Logo = ({ type }) => {
  let data;
  if (type === 'fashion') {
    data = {
      url: '/home/fashion',
      img: '/static/imgs/logo-black.png'
    }
  }
  else {
    data = {
      url: '/admin/orders',
      img: '/static/imgs/logo-white.png'
    }
  }
  return (

      <Link className="ps-logo" to={data.url}>
        <img src={data.img} alt="" />
      </Link>


  )
}

export default Logo
