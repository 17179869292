import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'

import {formatPhoneNumber, makeCSVExport} from '../../../utilities/common-helpers'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const CustomersTable = ({userCredentials, title}) => {

  // console.log (userCredentials)
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false)

  function handleExport (e) {
    e.preventDefault()
    // console.log (orders)
    makeCSVExport(customers, 'customers_data')
  }


  async function getCustomers() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/all`);
    const customersArray = await res.json();
    setCustomers(customersArray);
    setLoading(false)
  }

  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}

      const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      }).then((response) => {
        // setLoading(false)
        // console.log ("Record Deleted")
        window.location.reload(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getCustomers();
  }, []);

  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
    // getCustomers(tab.slug);
  }

  function handleSubmit(e) {
    // e.preventDefault()
    // console.log ("navigating")
  }

  const img = {
    display: 'block',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solid #dee2e6',
    minWidth: '500px',
  }

  const frozenColumn_left = {
    minWidth:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '-1px',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 42px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_third = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 84px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  function makeTable ()  {

    return (customers.length != 0 ?
      <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>

        <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
          <thead>
            <tr>
              <th style={{position: 'sticky', left: '0px', backgroundColor: '#f1f1f1'}}>Customer Name</th>
              <th>Phone Number</th>
              <th><i className='icon-chevron-down' style={{fontSize: '10px'}}></i> Date Created</th>
              <th>Date Modified</th>
              {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 84px)', backgroundColor: '#f1f1f1'}}></th> : null }
              {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 42px)', backgroundColor: '#f1f1f1'}}></th> : null }
              <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}} ></th>
            </tr>
          </thead>
          <tbody>

          {customers.map((customer) => (
            <tr key={customer.id}>
              <td style={frozenColumn_left}>{customer.customer_name}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{formatPhoneNumber(customer.phone_number)}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(customer.date_created).format('lll')}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(customer.date_modified).format('lll') != 'Invalid date' ? moment(customer.date_modified).format('lll') : ''  }</td>
              {userCredentials.role == 'administrator' ?
                <td style={frozenColumn_right_third}>
                  <Link to={`/admin/customer/${customer.id}`}>
                  <button className="ps-btn-delete">
                      <i className='icon-eye'></i>
                  </button>
                  </Link>
                </td> : null }
              {userCredentials.role == 'administrator' ?
                <td style={frozenColumn_right_second}>
                  <Link to={`/admin/customer/${customer.id}/edit`}>
                    <button className="ps-btn-delete">
                        <i className='icon-pencil'></i>
                    </button>
                  </Link>
                </td> : null}
              {userCredentials.role == 'administrator' ?
                <td style={frozenColumn_right}>
                  <button className="ps-btn-delete" onClick={e => handleDelete(e, customer.id)}>
                      <i className='icon-cross'></i>
                  </button>
                </td> : null }
              {userCredentials.role == 'csr' ?
                <td style={frozenColumn_right}>
                  <Link to={`/admin/customer/${customer.id}`}>
                    <button className="ps-btn-delete">
                        <i className='icon-eye'></i>
                    </button>
                  </Link>
                </td> : null }

            </tr>
          ))}
          </tbody>
        </table>
      </div>
      :
      <div className="col-lg-3">
        <input
            className='form-control-plaintext'
            style={{backgroundColor: 'white', fontSize: '16px'}}
            type="text"
            value={'No results found'}
            readOnly={true}
        />
      </div>
      )
  }


  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All Customers</h3>
                      {userCredentials.role == 'administrator' ?
                        <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                          <button className="ps-btn-delete" style={{marginRight: '15px'}}onClick={e => handleExport(e)}>Export</button>
                          <Link to="/admin/customers/new">
                            <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New Customer</button>
                          </Link>
                        </div> : null }
                    </div>
                </div>
                <div className="ps-form__content">

                  <div className="row">

                  {!loading ? makeTable() :
                  <div className="col-lg-3">
                    <input
                        className='form-control-plaintext'
                        style={{backgroundColor: 'white', fontSize: '16px'}}
                        type="text"
                        value={'Loading...'}
                        readOnly={true}
                    />

                  </div>}






                  </div>
                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default CustomersTable;
