import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio, message } from 'antd';
import { Prompt } from 'react-router-dom';
// import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined } from '@ant-design/icons'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link






const mapState = ({ user }) => ({
  user: user,
})

const FormNewCustomer = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)
  const date = new Date()
  const [date_created, setDate_created] = useState("");

  const [customer_name, setCustomer_name] = useState("");
  const [phone_number, setPhone_number] = useState("");

  const [loading, setLoading] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);




  let location = useLocation()



  useEffect(() => {
    const title = "Luxanda | Create New Customer"
    title && (document.title = title);

  }, []);

  // async function getBrands() {
  //   console.log ("loading...")
  //   setLoadingDropdowns(true)
  //   const res = await fetch('/b/all')
  //   const brandsArray = await res.json()
  //   setBrands(brandsArray);
  //   setLoadingDropdowns(false)
  // }
  //
  // async function getProductsByBrand(selected_brand_id) {
  //   console.log ("loading...")
  //   setLoadingDropdowns(true)
  //   const res = await fetch(`/p/b/${selected_brand_id}`)
  //   const productsByBrandArray = await res.json()
  //   setProductsByBrand(productsByBrandArray);
  //   setLoadingDropdowns(false)
  // }


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true)
      const created_by = user.credentials.email
      const body = { date_created, customer_name, phone_number, created_by };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}customer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        setLoading(false)
      })


      window.location = "/admin/customers";

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  };







  const fieldChanged = (type, value) => {
    if (type == 'customer_name'){
       setCustomer_name(value);
    } else if (type == 'phone_number'){
      setPhone_number(value);
    }

    setDate_created(new Date())


  };




  console.log ("savinngggg...")
  console.log ("customer_name: " + customer_name)
  console.log ("phone_number: " + phone_number)




    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Create New Customer</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Customer Information</h3></label>
                      </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Customer Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Customer Name"
                                value={customer_name}
                                onChange={e => fieldChanged('customer_name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Phone Number</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Phone Number"
                                value={phone_number}
                                onChange={e => fieldChanged('phone_number', e.target.value)}
                            />
                        </div>
                    </div>




                </div>

            </div>
            <div className="form-group submit" style={{marginTop:"5rem"}}>
                <button
                  className="ps-btn"
                  onClick={e => handleSubmit(e)}
                  disabled={loading}
                  >
                  {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Save Customer')}
                  </button>
            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormNewCustomer;
