import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'

import {makeCSVExport} from '../../../utilities/common-helpers'

import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const ProductsTable = ({userCredentials, title}) => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [brand_id, setBrand_id] = useState(0);

  function handleExport (e) {
    e.preventDefault()
    // console.log (orders)
    makeCSVExport(products, 'products_data')
  }



  async function getAllProducts() {
    // console.log (axios.defaults.headers.common['Authorization'].split("Bearer ")[1])
    const auth_token = userCredentials.auth_token

    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`, {
      headers: { "Content-Type": "application/json", "Authorization": axios.defaults.headers.common['Authorization'].split("Bearer ")[1]},
    });
    const productsArray = await res.json();
    setProducts(productsArray);
    setLoading(false)
  }

  async function getAllProductsByBrand() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/brands/${brand_id}`);
    const productsArray = await res.json();
    setProducts(productsArray);
    setLoading(false)
  }

  async function getBrands() {
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`);
    const brandsArray = await res.json();
    setBrands(brandsArray);
    setLoadingDropdowns(false)
  }

  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}
      const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json"},
          body: JSON.stringify(body),
      }).then((response) => {
        // setLoading(false)
        // console.log ("Record Deleted")
        window.location.reload(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAllProducts();
    getBrands()
    // console.log (userCredentials.auth_token)
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
    // getProducts(tab.slug);
  }

  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }

  function handleSubmit(e) {
    // e.preventDefault()
    // console.log ("navigating")
  }

  function handleSearch(e) {
    e.preventDefault()
    // console.log ("Searching by: " + brand_id)
    if (brand_id == 0){
      getAllProducts()
    } else {
      getAllProductsByBrand(brand_id)
      // getOrdersByStatus(order_status)
    }
  }

  const handleSelect = (item, type) => {
    if (type == 'brand_id'){
      setBrand_id(item.target.value)
    }
  }


  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    }
  }

  const dropdown_brands = setSelectedId_View('brands')

  const frozenColumn_left = {
    width:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '-1px',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 42px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_third = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 84px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_fourth = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 126px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const img = {
    display: 'block',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const mark_red = {
    fontSize: '20px',
    color: 'lightgrey'
  }
  const mark_green = {
    fontSize: '20px',
    color: 'green'
  }

  function makeTable ()  {

    return (products.length != 0 ?
      <div className="col-lg-12" style={{overflowX: 'overlay', whiteSpace: 'nowrap', paddingLeft: '0px', paddingRight: '0px'}}>

        <table className="table mt-5" style={{display: 'block',  tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
          <thead>
            <tr>
              <th style={{position: 'sticky', left: '0px', backgroundColor: '#f1f1f1'}}>Brand</th>
              <th>Name</th>
              <th>Description</th>
              <th>Options</th>
              <th>Serialized</th>
              <th><i className='icon-chevron-down' style={{fontSize: '10px'}}></i> Date Created</th>
              <th>Date Modified</th>
              {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 84px)', backgroundColor: '#f1f1f1'}}></th> : null}
              {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 42px)', backgroundColor: '#f1f1f1'}}></th> : null}
              <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}}></th>
            </tr>
          </thead>
          <tbody>

          {products.map((product) => (
            <tr key={product.id}>
              <td style={frozenColumn_left}>
                <Link to={`/admin/brand/${product.brand_id}`}>
                  <button className="ps-btn" style={{width: '100%'}}>{product.brand_name}</button>
                </Link>
              </td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{product.name}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{(product.description).substring(0, 45) + '...'}</td>
              <td style={{minWidth:'7rem', verticalAlign: 'middle'}}>{product.flag_options ? <i className='icon-checkmark-circle' style={mark_green} ></i> : <i className='icon-cross-circle' style={mark_red}></i>}</td>
              <td style={{minWidth:'7rem', verticalAlign: 'middle'}}>{product.flag_serialization ?<i className='icon-checkmark-circle' style={mark_green}></i> : <i className='icon-cross-circle' style={mark_red}></i>}</td>

              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(product.date_created).format('lll')}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(product.date_modified).format('lll') != 'Invalid date' ? moment(product.date_modified).format('lll') : ''  }</td>

              {userCredentials.role == 'administrator' ?
              <td style={frozenColumn_right_third}>
                <Link to={`/admin/p/${product.id}`}>
                <button className="ps-btn-delete">
                    <i className='icon-eye'></i>
                </button>
                </Link>
              </td> : null }
              {userCredentials.role == 'administrator' ?
              <td style={frozenColumn_right_second}>
                <Link to={`/admin/p/${product.id}/edit`}>
                  <button className="ps-btn-delete">
                      <i className='icon-pencil'></i>
                  </button>
                </Link>
              </td> : null}
              {userCredentials.role == 'administrator' ?
              <td style={frozenColumn_right}>
                <button className="ps-btn-delete" onClick={e => handleDelete(e, product.id)}>
                    <i className='icon-cross'></i>
                </button>
              </td> : null}
              {userCredentials.role == 'csr' ?
              <td style={frozenColumn_right}>
                <Link to={`/admin/p/${product.id}`}>
                  <button className="ps-btn-delete">
                      <i className='icon-eye'></i>
                  </button>
                </Link>
              </td> : null}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      :
      <div className="col-lg-3">
        <input
            className='form-control-plaintext'
            style={{backgroundColor: 'white', fontSize: '16px'}}
            type="text"
            value={'No results found'}
            readOnly={true}
        />
      </div>
      )
  }


  // console.log (brand_id)



  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All Products</h3>
                      {userCredentials.role == 'administrator' ?
                        <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                          <button className="ps-btn-delete" style={{marginRight: '15px'}}onClick={e => handleExport(e)}>Export</button>
                          <Link to="/admin/products/new">
                            <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New Product</button>
                          </Link>
                        </div> : null }
                    </div>
                </div>

                <div className="row" style={{marginBottom: '30px'}}>
                  <div className="col-lg-12">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                      <div style={{width: '40rem', display: 'flex', alignItems: 'center'}}>
                        <div className="form-group">
                          <label className="form">Search by Brand:</label>
                          {loadingDropdowns ? <div>Loading...</div> :
                            <select className="form-control" value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                                {brands.length === 0 ? null : <option value={0}>All</option>}
                              {dropdown_brands}
                            </select>}
                        </div>
                        <div style={{marginLeft: '30px'}}>
                          <button className="ps-btn" onClick={e => handleSearch(e)}>Go</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="ps-form__content">

                  <div className="row">

                    {!loading ? makeTable() :
                      <div className="col-lg-3">
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={'Loading...'}
                            readOnly={true}
                        />

                    </div>}

                  </div>
                </div>


            </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default ProductsTable;
