import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'
import moment from 'moment'

import { connect } from 'react-redux'
import { assignHashToUser } from '../../redux/actions/userActions'

import { useLocation } from 'react-router-dom'
import { saveDeviceActions,formatter, formatPhoneNumber } from '../../utilities/common-helpers'

import { VerifiedOutlined, CheckCircleOutlined, WarningOutlined} from '@ant-design/icons'

import { useIntl, FormattedMessage } from "react-intl"




// const device = require('device-ip-location');

// import ShopItems from '../../components/partials/shop/ShopItems';

// var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link

var sha256 = require('sha256')


const mapState = ({ user, lang }) => ({
  user: user,
  lang
})

const BrandDocumentCustomerView = ({title}) => {
  const intl = useIntl()
  const dateFormat = 'YYYY-MM-DD'
  const dispatch = useDispatch();
  const { user, lang } = useSelector(mapState)

  const [orderDetails, setOrderDetails] = useState([]);
  const [order, setOrder] = useState({})
  const [customer, setCustomer] = useState({})


  const [orderReferenceSnippet, setOrderReferenceSnippet] = useState(null)
  const [formState, setFormState] = useState("")

  const location = useLocation()
  const pathArray = location.pathname.split('/')
  const [dateChecked, setDateChecked] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [errors, setErrors] = useState("")


  const [urlDetails, setURLDetails] = useState({})
  // const [foundProduct, setFoundProduct] = useState(false)
  const [foundUrl, setFoundUrl] = useState(false)







   const ua = navigator.userAgent.toLowerCase()
    const [isWXWork, setIsWXWork] = useState(ua.match(/wxwork/i) == 'wxwork')

    const [isWeixin, setIsWeixin] = useState(!isWXWork && ua.match(/micromessenger/i) == 'micromessenger')
    const [isMobile, setIsMobile] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)





  async function setupURL() {
    setLoading(true)
    try{
      const urlArray = await getURLDetails()


      setURLDetails(urlArray);

      console.log (urlArray)

      let is_order_found = false



      if (urlArray.filepath){
        saveDeviceActions(location.pathname, "User Visit - Document - filepath: " + urlArray.filepath, null, null, null)
        is_order_found = true
        setFoundUrl(true)
      }



    } catch (err) {
      console.log (err)
    }
    setLoading(false)
  }

  async function getURLDetails() {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}u/ugh/${pathArray[2]}`)
      .then((res) => {
          resolve(res.json())
      })
    })
  }



  useEffect(() => {
    setupURL();
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
      setIsMobile(true)
    } else {
      setIsDesktop(true)
    }

    setOrderReferenceSnippet(user.orderRef)
    // saveDeviceActions(location.pathname, "User Visit - Brand - Pre-Validation Page: " + pathArray[2], null, null, null )

    const title = "Luxanda | Document"
    title && (document.title = title);

    document.querySelector('meta[name="og:image"]').setAttribute("content", "%PUBLIC_URL%/static/imgs/hero-preview.jpg")
    document.querySelector('meta[name="og:title"]').setAttribute("content", 'Luxanda | Order Page')
  }, []);












  function handleSubmit(e) {
    e.preventDefault();
    window.location.href = urlDetails.filepath
    // console.log ("navigating")
  }


  function submissionForm(){
    return(
      <div className="row" style={{placeContent: 'center', height: '10vh'}}>


        {foundUrl &&
        <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
            <button className="ps-btn" onClick={e => handleSubmit(e)}>
              <FormattedMessage
                id="action_view_file"
              />
            </button>
        </div>}
      </div>
    )
  }












  // <section className="ps-my-account ps-page--account">
  //
  //
  //     <div className="container">
  //         <div className="row">
  //
  //
  //
  //           <div className="col-lg-12">
  //
  //             <div className="ps-page__content">
  //               <form className="ps-form--product-form" >
  //
  //
  //
  //                 <div className="ps-form__content" style={{display: 'flex', flexDirection: 'column'}}>
  //
  //                   <object data={urlDetails.filepath} type="application/pdf" width="100%" height="100%">
  //                     <p style={{display: 'flex', placeContent: 'center'}}>
  //                       <FormattedMessage
  //                         id="message_pdf_cannot_be_displayed"
  //                       />
  //                     </p>
  //                   </object>
  //                   {submissionForm()}
  //                 </div>
  //               </form>
  //           </div>
  //
  //         </div>
  //
  //
  //       </div>
  //       <div style={{height: '5vh'}}/>
  //     </div>
  // </section>


  // <object data={urlDetails.filepath} type="application/pdf" width="100%" height="100%">
  //   <p style={{display: 'flex', placeContent: 'center'}}>
  //     <div style={{padding: '2rem'}}>
  //       <FormattedMessage
  //         id="message_pdf_cannot_be_displayed"
  //       />
  //     </div>
  //     {submissionForm()}
  //   </p>
  // </object>


  return (
    <div style={{width: '100vw', height: '100vh', position: 'fixed', zIndex: '1000', backgroundColor: '#fff'}}>
      <iframe  src={urlDetails.filepath} style={{width: '100%', height: '100%'}}/>
        <p style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10rem'}}>
          <div style={{padding: '2rem'}}>
            <FormattedMessage
              id="message_pdf_cannot_be_displayed"
            />
          </div>
          {submissionForm()}
        </p>
    </div>

  );

}


export default BrandDocumentCustomerView;
