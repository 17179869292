import axios from 'axios'
import {
  MULTI_SEARCH_FETCH_SUCCESS,
  SEARCH_QUERY_EMPTY,
  MULTI_SEARCH_FETCH_ERROR,
  SINGLE_SEARCH_FETCH_SUCCESS,
  SINGLE_SEARCH_FETCH_ERROR,
  ALL_PRODUCTS_FETCH_SUCCESS,
  ALL_PRODUCTS_FETCH_ERROR,

} from '../types';


// export const multiSearchAction = (query, page) => (dispatch) => {
//   if (query !== '') {
//     return fetchSearch('multi', query, page)
//       .then((searchResults) =>
//         dispatch({
//           type: MULTI_SEARCH_FETCH_SUCCESS,
//           payload: searchResults.data.results,
//         })
//       )
//       .catch((error) =>
//         dispatch({
//           type: MULTI_SEARCH_FETCH_ERROR,
//           error: error,
//         })
//       );
//   } else {
//     return dispatch({
//       type: SEARCH_QUERY_EMPTY,
//     });
//   }
// };
//
// export const singleSearchAction = (type, query, page) => (dispatch) => {
//   if (query !== '') {
//     return fetchSearch(type, query, page)
//       .then((searchResults) =>
//         dispatch({
//           type: SINGLE_SEARCH_FETCH_SUCCESS,
//           payload: searchResults.data,
//         })
//       )
//       .catch((error) =>
//         dispatch({
//           type: SINGLE_SEARCH_FETCH_ERROR,
//           error: error,
//         })
//       );
//   } else {
//     return dispatch({
//       type: SEARCH_QUERY_EMPTY,
//     });
//   }
// };


export const getAllProductsAction = () => (dispatch) => {

  axios.get('/products')
    .then(res => {
      dispatch({
        type: ALL_PRODUCTS_FETCH_SUCCESS,
        payload: res.data
      })
    })
    .catch((error) => {
      dispatch({
        type: ALL_PRODUCTS_FETCH_ERROR,
        error: error,
      })
    })
};



// export const signinUser = (userData, history) => (dispatch) => {
//   dispatch({ type: LOADING_UI})
//   axios.post('/signin', userData)
//     .then(res => {
//       setAuthorizationHeader(res.data.token)
//       dispatch(getUserData())
//       dispatch({type: CLEAR_ERRORS});
//       history.push('/')
//     })
//     .catch(e => {
//       dispatch({
//         type: SET_ERRORS,
//         payload: e.response.data
//       })
//     })
// }
//
// async function getProducts() {
//   setLoading(true);
//   const responseData = await axios.get('/products');
//   if (responseData) {
//       // setProductItems(productItems => [...productItems, responseData.data]);
//       setProductItems(responseData.data);
//       setTotal(responseData.data.length)
//       console.log ("retrieving... " + dataRetrievalCounter)
//       setDataRetrievalCounter(dataRetrievalCounter + 1)
//       console.log ("RESPONSE DATA:")
//       console.log (responseData.data)
//       console.log ("________________")
//       setLatestDoc_id(responseData.data[responseData.data.length-1])
//       setTimeout(
//           function () {
//               setLoading(false);
//           }.bind(this),
//           250
//       );
//
//   }
// }
