import React, { Component, useEffect, useState } from 'react'
import { Popconfirm, message } from 'antd'

import { useLocation } from 'react-router-dom'


import { saveDeviceActions } from '../../../../utilities/common-helpers'

import { useIntl, FormattedMessage } from "react-intl"


const Link = require("react-router-dom").Link

// <ul className="ps-list--social">
//     <li>
//         <a className="facebook" href="#">
//             <i className="fa fa-facebook"></i>
//         </a>
//     </li>
//     <li>
//         <a className="twitter" href="#">
//             <i className="fa fa-twitter"></i>
//         </a>
//     </li>
//     <li>
//         <a className="google-plus" href="#">
//             <i className="fa fa-google-plus"></i>
//         </a>
//     </li>
//     <li>
//         <a className="instagram" href="#">
//             <i className="fa fa-instagram"></i>
//         </a>
//     </li>
// </ul>
const FooterWidgets = () => {
  const intl = useIntl()


  const location = useLocation()
  const [isJumpPage, setIsJumpPage] = useState(false)
  const [loading_jumpPage, setLoading_jumpPage] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)


  useEffect(() => {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
      setIsMobile(true)
    } else {
      setIsDesktop(true)
    }
    // saveDeviceActions(location.pathname, "User Visit - Jump to MP from Footer", null, null, null )
  }, []);

  function handleJumpPage(e, wc_url) {
    // console.log (wc_url)
    e.preventDefault();
    setIsJumpPage(true)
    setLoading_jumpPage(true)
    generateWeChatLink(wc_url)
  }

  async function generateWeChatLink(wc_url){
    const jumpType = 'page'
    const res = await fetch(`${process.env.REACT_APP_API_URL}wc_jump/${jumpType}/${wc_url}`)
    const jump_data = await res.json();
    if (jump_data && jump_data.openlink){
      message.success (intl.formatMessage({ id: 'success_openlink_generated' }))
      window.location.href = jump_data.openlink
    } else {
      message.error (intl.formatMessage({ id: 'error_something_went_wrong' }))
    }
    setLoading_jumpPage(false)
  }

  const mp_link = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    right: '0px',
    cursor: 'pointer',
    paddingBottom: '1rem',
  }

  const mp_icon = {
    width: '3rem',
    display: 'relative'
  }

  return (
    <div className="ps-footer__widgets">
        <aside className="widget widget_footer widget_contact-us">
            <h4 className="widget-title">{intl.formatMessage({ id: 'label_contact_us'})}</h4>
            <div className="widget_content">
                {isMobile ? <div style={mp_link} onClick={e => handleJumpPage(e, "5fd7bebac392e80012a20248")}>
                  <div
                      className="form-control-plaintext"
                      style={{color: "#7a79b0", fontSize: '12px', fontWeight: '600', padding: '0px'}}
                      type="text"
                      placeholder={null}
                      readOnly={true}
                  >
                  <img src="/static/imgs/mini-program-icon-2.png" alt={`Luxanda | WeChat Mini-Program`} style={mp_icon}/>

                    <FormattedMessage
                      id="message_view_us_on_wechat"
                    />
                  </div>
                </div> : null}
                <p>{intl.formatMessage({ id: 'label_call_us_anytime'})}</p>
                <h3><a href="tel:+18334389633">1-833-GETZO-33</a></h3>


                <p>
                    Toronto, Ontario | Canada <br />
                    <a href="mailto:info@getzo.com">info@getzo.com</a>
                </p>

            </div>
        </aside>


    </div>
  )
}


export default FooterWidgets;
