import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/orderDetail/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'

// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const AdminOrderDetailView = ({title}) => {

  const [orderDetail, setOrderDetail] = useState({});
  const [product, setProduct] = useState({})
  const [order, setOrder] = useState({})
  const [promotion, setPromotion] = useState({})
  const [customer, setCustomer] = useState({})
  const [brand, setBrand] = useState({})

  const location = useLocation()
  const pathArray = location.pathname.split('/')


  async function getOrderDetail() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/id/${pathArray[3]}`)
    const orderDetailArray = await res.json();
    console.log (orderDetailArray)
    setOrderDetail(orderDetailArray);
    getOrder(orderDetailArray.order_id)
    getProduct(orderDetailArray.product_id)
    getPromotion(orderDetailArray.promotion_id)
  }

  async function getOrder(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/id/${id}`)
    const orderArray = await res.json();
    console.log (orderArray)
    setOrder(orderArray);
    getCustomer(orderArray.customer_id)
  }

  async function getProduct(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${id}`)
    const productArray = await res.json();
    console.log (productArray)
    setProduct(productArray);
    getBrand(productArray.brand_id)
  }

  async function getPromotion(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}promo/id/${id}`)
    const promotionArray = await res.json();
    console.log (promotionArray)
    setPromotion(promotionArray);
  }

  async function getCustomer(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${id}`)
    const customerArray = await res.json();
    console.log (customerArray)
    setCustomer(customerArray);
  }

  async function getBrand(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/id/${id}`)
    const brandArray = await res.json();
    console.log (brandArray)
    setBrand(brandArray);
  }

  useEffect(() => {
    getOrderDetail();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();

  }

  function handleSubmit(e) {
    console.log ("navigating")
  }

  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '500px',
    maxHeight: '500px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const row = {
    justifyContent: 'center',
  }

  let orderDetailView;
  if (orderDetail){
    orderDetailView = (
      <div className="row" style={row}>




        <div className="col-lg-6">
        <div className="form-group">
          <label className="form">Order Information</label>
        </div>
          <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"  id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2">
            <div class="table-responsive">
              <table class="table table-bordered ps-table ps-table--specification">
                <tbody>
                  <tr>
                    <td>Related Order</td>
                    <td>
                      <Link to={`/admin/o/${order.id}`}>
                        <button className="ps-btn">{order.id}</button>
                      </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>Order Number</td>
                    <td>{order.order_number}</td>
                  </tr>
                  <tr>
                    <td>Order Reference</td>
                    <td>{order.order_reference}</td>
                  </tr>
                  <tr>
                    <td>Order Date</td>
                    <td>{moment(order.order_date).format('lll')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    <div class="separator"></div>

        <div className="col-lg-6">
        <div className="form-group">
          <label className="form">Customer Information</label>
        </div>
          <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"  id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2">
            <div class="table-responsive">
              <table class="table table-bordered ps-table ps-table--specification">
                <tbody>
                  <tr>
                    <td>Related Customer</td>

                    <td>
                      <Link to={`/admin/customer/${customer.id}`}>
                        <button className="ps-btn">{customer.id}</button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{customer.customer_name}</td>
                  </tr>
                  <tr>
                    <td>Customer Phone Number</td>
                    <td>{customer.phone_number}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
    <div class="separator"></div>

        <div className="col-lg-12">
          <div class="text-center">
            <img src={product.pic} class="rounded" style={img} alt="CBR0" border="0" />
          </div>
        </div>

        <div className="col-lg-6">
        <div className="form-group">
          <label className="form">Product Information</label>
        </div>
          <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"  id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2">
            <div class="table-responsive">
              <table class="table table-bordered ps-table ps-table--specification">
                <tbody>
                  <tr>
                    <td>Related Product</td>
                    <td>
                      <Link to={`/admin/product/${product.id}`}>
                        <button className="ps-btn">{product.id}</button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Product Name</td>
                    <td>{product.name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{product.description}</td>
                  </tr>
                  <tr>
                    <td>Variant</td>
                    <td>{product.variant}</td>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <td>{product.sku}</td>
                  </tr>
                  <tr>
                    <td>Brand</td>
                    <td>
                      <Link to={`/admin/brand/${brand.id}`}>
                        <button className="ps-btn">{brand.name}</button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Brand Country</td>
                    <td>{brand.country}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
        </div>




<div class="separator"></div>
        <div className="col-lg-6">
        <div className="form-group">
          <label className="form">Promotion Information</label>
        </div>
          <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"  id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2">
            <div class="table-responsive">
              <table class="table table-bordered ps-table ps-table--specification">
                <tbody>
                  <tr>
                    <td>Related Promotion</td>
                    <td>
                      <Link to={`/admin/promotion/${promotion.id}`}>
                        <button className="ps-btn">{promotion.id}</button>
                      </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>Date Created</td>
                    <td>{moment(promotion.date_created).format('lll')}</td>
                  </tr>
                  <tr>
                    <td>Promotion Date</td>
                    <td>{moment(promotion.promotion_date).format('lll')}</td>
                  </tr>
                  <tr>
                    <td>Promotion Price</td>
                    <td>${promotion.price}</td>
                  </tr>
                  <tr>
                    <td>Promotion Currency</td>
                    <td>{promotion.currency}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>

    )
  } else {
    orderDetailView = (<div>No Order Detail Found</div>)
  }

  let modified_view

  if (orderDetail.modified_by) {
    modified_view = (<div>Last modified by {orderDetail.modified_by} on {moment(orderDetail.date_modified).format('lll')}</div>)
  }




  return (
    <section className="ps-my-account ps-page--account">
    <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">


                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>Order Detail ID: {orderDetail.id}</h3>
                      <h5><strong>Date Created: </strong>{moment(orderDetail.date_created).format('lll')} {modified_view}</h5>
                    </div>

                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`/admin/order-detail/${order.id}/edit`}>
                        <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit Order Detail</button>
                      </Link>

                    </div>
                  </div>




                </div>
                <div className="ps-form__content">



                  {orderDetailView}

                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




    </div>
</section>

  );

}


export default AdminOrderDetailView;
