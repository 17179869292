import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Popconfirm, message, Tooltip, Switch } from 'antd'
import moment from 'moment'
import {makeID, formatter} from '../../../utilities/common-helpers'

import { useLocation } from 'react-router-dom'

import { variant_list } from './data/variant_list'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormEditProduct = () => {

  const {user, selectedUnit } = useSelector(mapState)

  const [name, setName] = useState("");
  const [brand_id, setBrand_id] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [parent_variant_id, setParent_variant_id] = useState("")
  const [brands, setBrands] = useState([]);
  const [itemWarranty, setItemWarranty] = useState("")
  const [itemPrice, setItemPrice] = useState("")
  const [itemPrice_new, setItemPrice_new] = useState("")
  const [itemPrice_original_id, setItemPrice_original_id] = useState()
  const [itemPromotion_date, setItemPromotion_date] = useState('');
  const [itemQuantity, setItemQuantity] = useState("")
  const [itemSku, setItemSku] = useState("")
  const [itemBarcode, setItemBarcode] = useState("")
  const [itemSerialRunLimit, setItemSerialRunLimit] = useState("")
  const [itemPic, setItemPic] = useState("")
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [doesProductHaveOptions, setDoesProductHaveOptions] = useState(false)
  const [isThisProductSerialized, setIsThisProductSerialized] = useState(false)
  const [optionList, setOptionList] = useState([""])
  const [optionList_deleted, setOptionList_deleted] = useState([{}]) //this is the list of options that were once on db and active, but are now removed
  const [optionValueList, setOptionValueList] = useState([[{name: ''}]]);
  const [optionValueList_deleted, setOptionValueList_deleted] = useState([[{name: ''}]]); //this is the list of options that were once on db and active, but are now removed
  const [optionValueList_temp, setOptionValueList_temp] = useState([[{name: ''}]]);
  const [optionValueMatrix, setOptionValueMatrix] = useState([])
  const [serialSelectionList, setSerialSelectionList] = useState([])
  const [priceList, setPriceList] = useState({})
  const [priceList_new, setPriceList_new] = useState({})
  const [quantityList, setQuantityList] = useState({})
  const [skuList, setSkuList] = useState({})
  const [barcodeList, setBarcodeList] = useState({})
  const [promotionDateList, setPromotionDateList] = useState({})
  const [promotionDateList_old, setPromotionDateList_old] = useState({})
  const [picUrlList, setPicUrlList] = useState({})
  const [serialQuantityList, setSerialQuantityList] = useState({})
  const [loadingProperties, setLoadingProperties] = useState(false)

  const [wc_url, setWc_url] = useState('')



  const [product, setProduct] = useState({})


  let location = useLocation()
  const pathArray = location.pathname.split('/')

  const dateFormat = 'YYYY-MM-DD'

  useEffect(() => {
    getProduct()
    getSkus()
    getPromos()
    getProductVariantOptionValues()
    getBrands()
  }, []);

  async function getBrands() {
      setLoadingDropdowns(true)
      const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
      const brandsArray = await res.json()
      setBrands(brandsArray);
      setLoadingDropdowns(false)
    }



  async function getProduct() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${pathArray[3]}`)
    const product_data = await res.json();

    setProduct(product_data);
    setName(product_data.name)
    setDescription(product_data.description)
    setBrand_id(product_data.brand_id)
    setDoesProductHaveOptions(product_data.flag_options)
    setIsThisProductSerialized(product_data.flag_serialization)
    setItemWarranty(product_data.warranty_information)
    setWc_url(product_data.wc_url)
    // setItemPic(product_data.pic_url)
    // if (product_data.flag_options){
      //get product variants
      getProductVariants(product_data.id)

      const title = "Luxanda | Edit Product: " + pathArray[3]
      title && (document.title = title);
    // } else {
    //   // getSkuByProductID()
    //   getPromotionsByProductID()
    // }
  }

  // async function getSkuByProductID() {
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${pathArray[3]}`)
  //   const sku_data = await res.json();
  //   setItemSku(sku_data[0].sku)
  //   setItemBarcode(sku_data[0].barcode)
  //   setItemQuantity(sku_data[0].quantity)
  //   setItemSerialRunLimit(sku_data[0].serial_run_limit)
  //   console.log (sku_data)
  // }

  // async function getPromotionsByProductID() {
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}promos/pid/${pathArray[3]}`)
  //   const promotion_data = await res.json();
  //   console.log ('-------------------------')
  //   console.log (promotion_data)
  //   setItemPrice(promotion_data[0].price)
  //
  //   setItemPromotion_date(promotion_data[0].promotion_date)
  //
  // }

  async function getProductVariants() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}v/pid/${pathArray[3]}`)
    const variant_data = await res.json();

    getProductVariantOptions(variant_data)
  }

  async function getProductVariantOptions(variant_data) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}v_o/pid/${pathArray[3]}`)
    const variant_options_data = await res.json();

    // return variant_options_data
    //Must use clone inorder to have separate instances of the retrieved object
    const variant_options_data_clone = JSON.parse(JSON.stringify(variant_options_data))

    console.log (variant_data)
      //else skip
    if (variant_data.length != 0){
      // // populate the optionList array
      let list = []
      let list_temp = []

      for (var i = 0; i < variant_data.length; i++){
        list.push([])
        list_temp.push([])
        for (var x = 0; x < variant_options_data.length; x++){
          if (variant_data[i].id == variant_options_data[x].product_variant_id){
            list[i].push(variant_options_data[x])
            list_temp[i].push(variant_options_data_clone[x])
          }
        }
      }

      for (var i = 0; i < list.length; i++){
        if (list[i].length == 0){
          list[i].push({name: ''})
          list_temp[i].push({name: ''})
        }
      }



      setOptionValueList(list)
      setOptionValueList_temp(list_temp)
      setOptionList(variant_data)
      setOptionValueMatrix(makeVariantArray(list))
    } else {
      let list = []

      list.push({name: ''})

      setOptionValueList([list])
      setOptionValueList_temp([list])
      setOptionList(list)
      setOptionValueMatrix([])
    }

  }


  async function getProductVariantOptionValues() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}m/pid/${pathArray[3]}`)
    const variant_option_values_data = await res.json();
    // console.log (variant_option_values_data)
    if (variant_option_values_data.length != 0){
    //parse out by the value
      // const picUrlList_db = {}
      for (var i = 0; i < variant_option_values_data.length; i++){
        const combo_id = variant_option_values_data[i].value
        const record_id = variant_option_values_data[i].id
        // const value_pic_url = variant_option_values_data[i].pic_url
        const value_active = variant_option_values_data[i].active
        // picUrlList_db[combo_id] ={value: value_pic_url, active: value_active, record_id: record_id}
      }
      // setPicUrlList(picUrlList_db)
    }
  }

  async function getSkus() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${pathArray[3]}`)
    const sku_data = await res.json();

    const picUrlList_db = {}
    if (sku_data.length != 0){

      const skuList_db = {}
      const barcodeList_db = {}
      const quantityList_db = {}
      const serial_run_limit_db = {}

      for (var i = 0; i < sku_data.length; i++){

        const combo_id = sku_data[i].combo_identifier
        const record_id = sku_data[i].id
        const barcode = sku_data[i].barcode
        const sku = sku_data[i].sku
        const quantity = sku_data[i].quantity
        const value_active = sku_data[i].active
        const run_limit = sku_data[i].serial_run_limit
        const value_pic_url = sku_data[i].pic_url

        picUrlList_db[combo_id] ={value: value_pic_url, active: value_active, record_id: record_id}
        skuList_db[combo_id] ={value: sku, active: value_active, record_id: record_id}
        barcodeList_db[combo_id] = {value: barcode, active: value_active, record_id: record_id }
        quantityList_db[combo_id] = {value: quantity, active: value_active, record_id: record_id }
        serial_run_limit_db[combo_id] = {value: run_limit, active: value_active, record_id: record_id }

        if (combo_id == 'NO OPTIONS'){
          setItemSku(sku)
          setItemBarcode(barcode)
          setItemQuantity(quantity)
          setItemSerialRunLimit(run_limit)
          setItemPic(value_pic_url)
        }

      }
      setPicUrlList(picUrlList_db)
      setBarcodeList(barcodeList_db)
      setSkuList(skuList_db)
      setQuantityList(quantityList_db)
      setSerialQuantityList(serial_run_limit_db)
    }
  }

  async function getPromos() {
    setLoadingProperties(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}promos/pid/${pathArray[3]}`)
    const promo_data = await res.json();

    // console.log (promo_data)
    if (promo_data.length != 0){
      console.log (promo_data)


      const priceList_db = {}
      const promotionDateList_db = {}
      let found_item = {}
      let price
      let promotion_date
      let record_id
      let value_active
      let sku_id

      for (var i = 0; i < promo_data.length; i++){
        const combo_id = promo_data[i].combo_identifier

        // console.log (found_item[combo_id])
        // console.log (combo_id)
        if (found_item[combo_id] && found_item[combo_id].hasOwnProperty('record_id')){
          // console.log (found_item[combo_id])
          // console.log (found_item[combo_id].record_id  + " < " + promo_data[i].id)
          if (found_item[combo_id].record_id < promo_data[i].id) {
            // console.log ("TRUUEE")
            price = promo_data[i].price
            promotion_date = promo_data[i].promotion_date
            record_id = promo_data[i].id
            value_active = promo_data[i].active
            sku_id = promo_data[i].sku_id
          } else {
            price = found_item[combo_id].price
            promotion_date = found_item[combo_id].promotion_date
            record_id = found_item[combo_id].record_id
            value_active = found_item[combo_id].active
            sku_id = found_item[combo_id].sku_id
          }
        } else {
          price = promo_data[i].price
          promotion_date = promo_data[i].promotion_date
          record_id = promo_data[i].id
          value_active = promo_data[i].active
          sku_id = promo_data[i].sku_id
          // found_item[combo_id] = {price: price, promotion_date: promotion_date, record_id: record_id}
        }

        found_item[combo_id] = {price: price, promotion_date: promotion_date, record_id: record_id, active: value_active, sku_id: sku_id}
        // found_item[combo_id] = {record_id: record_id}

        // const value_active = promo_data[i].active
        // const sku_id = promo_data[i].sku_id


        priceList_db[combo_id] = {value: price, active: value_active, record_id: record_id, sku_id: sku_id}
        promotionDateList_db[combo_id] = {value: promotion_date, active: value_active, record_id: record_id}

        if (combo_id == 'NO OPTIONS'){
          setItemPrice(price)
          setItemPrice_new(price)
          setItemPrice_original_id(record_id)
          setItemPromotion_date(promotion_date)

        }
      }

      // console.log (promotionDateList_db)
      setPriceList(priceList_db)
      setPromotionDateList(promotionDateList_db)
    }
    setLoadingProperties(false)

  }


  const makeVariantArray = (list, n = 0, result = [], current = [], index) => {
    if (n === list.length){
      result.push(current)
    } else list[n].forEach((item, i) => {
     if (item && item.name != "DELETE" && item.name !== '') {
        makeVariantArray(list, n+1, result, [...current, item.name], i)
     }
    }
    )
    return result
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    //General Form Validation
    if(!brand_id || brand_id == '0'){
      message.error('Please select a Brand for this Product.')
    } else if (!name){
      message.error('Please enter a Product Name for this Product.')
    } else if (!name){
      message.error('Please enter a Product Name for this Product.')
    } else {

      //Form Validation if Product Has Options
      if (doesProductHaveOptions){
        if (optionValueMatrix.length == 0){
          message.error('Please enter all of the applicable Product Options and Option Values.')
        } else {

          //check to see that all of the options are populated
          var isOptionListValid = true
          for (var i = 0; i < optionList.length && isOptionListValid == true ; i++){

            if (optionList[i].name == ''){
              isOptionListValid = false
            }
          }
          if (!isOptionListValid){
            message.error('Please enter the missing Option Name.')
          } else {
            //save the product and related info
            updateProduct(pathArray[3])

          }

        }
        //Form Validation if Product does not have options
      } else {

        if (!itemPrice_new){
          message.error('Please enter a Product Price for this Product.')
        } else if (!itemPromotion_date){
          message.error('Please enter an Effective Date for this Product.')
        } else if (!itemSku){
          message.error('Please enter a Product SKU for this Product.')
        } else if (!itemQuantity){
          message.error('Please enter a Product Quantity for this Product.')
        } else if (isThisProductSerialized && !itemSerialRunLimit){
          message.error('Please enter a Run Limit for this Product.')
        } else {
          //save product and related info
          // saveToProductTable()

          updateProduct(pathArray[3])
        }


      }
    }
  };


  async function updateProduct(record_id){
    //Save the product info to the Product table and retrieve new PK ID
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()
      // const pic_url = itemPic ? itemPic : null
      const body = { date_modified, modified_by, name, description, brand_id, doesProductHaveOptions, isThisProductSerialized, itemWarranty, wc_url  };

      const response = await fetch(`${process.env.REACT_APP_API_URL}p/id/${record_id}/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        // console.log (data)
        if (data.code == 409){
          message.error(data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          message.success("Product Information Successfully Updated")

          // if product has options, save related info
          if (!doesProductHaveOptions){
            createNewSkuValues(record_id).then((data1) => {
              // console.log ('done 1')
              updateSkuValues(record_id).then((data2) => {
                // console.log ('done 2')
                updatePromoValues(record_id).then((data3) => {
                  // console.log ('done 3')
                  // window.location = "/admin/products"
                  setTimeout(function() {
                    window.location = "/admin/products"
                  }, 1000)
                })
              })
            })

          } else {
            createNewSkuValues(record_id).then((data1) => {
              // console.log ('done 1')
              updateSkuValues(record_id).then((data2) => {
                // console.log ('done 2')
                updatePromoValues(record_id).then((data3) => {
                  // console.log ('done 3')
                    updateVariants(record_id).then((data) => {
                      // console.log ('done 4')
                      createNewVariantOptionValues(record_id).then((data) => {
                        // console.log ('done 5')
                        updateVariantOptionValues(record_id).then((data) => {
                          // console.log ('done 6')
                          if (Object.keys(priceList_new).length > 0){
                            saveToPromoTable(record_id, 'new records').then((data) => {
                              // console.log ('done 7')
                              // window.location = "/admin/products"
                              setTimeout(function() {
                                window.location = "/admin/products"
                              }, 1000)
                            })
                          } else {
                            // window.location = "/admin/products"
                            setTimeout(function() {
                              window.location = "/admin/products"
                            }, 1000)
                          }
                      })
                    })
                  })
                })
              })
            })
          }

        }
        setLoading(false)
      })



    } catch (err) {
      message.error ('Network Issue, please refresh the page and try again')
      message.error (err.message)
      console.error(err.message);
    }
  }

  async function updateVariants(product_id) {
    //Save the variant info to the Variant table and retrieve set of new PK ID
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()
      let data_array_existing_records = []
      let data_array_existing_records_deleted = []
      let data_array_new_records = []

      for (var i = 0; i < optionList.length; i++){
        if (optionList[i] && optionList[i].id){
          data_array_existing_records.push({id: optionList[i].id, name: optionList[i].name, product_id: product_id, date_modified: date_modified, modified_by: modified_by}) //update existing
        } else {
          data_array_new_records.push({name: optionList[i].name, product_id: product_id, date_created: date_modified, created_by: modified_by})
        }
      }

      for (var i = 0; i < optionList_deleted.length; i++){
        if (optionList_deleted[i] && optionList_deleted[i].id){
          data_array_existing_records_deleted.push({id: optionList_deleted[i].id, name: optionList_deleted[i].name, product_id: product_id, deleted_at: date_modified, deleted_by: modified_by})
        }
      }

      const body_existing_records = {data: data_array_existing_records}
      const body_existing_records_deleted = {data: data_array_existing_records_deleted}
      const body_new_records = {data: data_array_new_records}


      const response = await fetch(`${process.env.REACT_APP_API_URL}v/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body_existing_records),
      }).then(response => response.json())
      .then(data =>{
        if (data.code == 409){
          message.error(data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          message.success("Variant Information Successfully Updated")
          createNewVariants(body_new_records, product_id)
          deleteExistingVariants(body_existing_records_deleted)
          // window.location = "/admin/products"
        }

        updateVariantOptions(product_id, data_array_existing_records)
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)

      console.error(err.message);
    }
  }

  async function deleteExistingVariants(body_existing_records_deleted) {
    //Save the variant info to the Variant table and retrieve set of new PK ID
    if (body_existing_records_deleted.data.length != 0){
      try {
        setLoading(true)

        const response = await fetch(`${process.env.REACT_APP_API_URL}v/a=delete`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body_existing_records_deleted),
        }).then(response => response.json())
        .then(data =>{
          if (data.data.code == 409){
            message.error(data.message)
          } else if (data.code == 1002) {
            message.error(data.message)
          } else {
            message.success("Variant Item(s) have been successfully deleted")
          }
          setLoading(false)
        })
      } catch (err) {
        message.error (err.message)
        console.error(err.message);
      }
    } else {
      message.warning('No Variant to delete')
    }
  }

  async function deleteExistingVariantOptions(body_existing_records_deleted) {
    //Save the variant info to the Variant table and retrieve set of new PK ID
    if (body_existing_records_deleted.data.length != 0){
      try {
        setLoading(true)

        const response = await fetch(`${process.env.REACT_APP_API_URL}v_o/a=delete`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body_existing_records_deleted),
        }).then(response => response.json())
        .then(data =>{
          if (data.data.code == 409){
            message.error(data.message)
          } else if (data.code == 1002) {
            message.error(data.message)
          } else {
            message.success("Variant Option Item(s) have been successfully deleted")
          }
          setLoading(false)
        })
      } catch (err) {
        message.error (err.message)
        console.error(err.message);
      }
    }else {
      message.warning('No Variant Option(s) to delete')
    }
  }

  async function createNewVariants(body_new_records, product_id) {
    //Save the variant info to the Variant table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()

      const response = await fetch(`${process.env.REACT_APP_API_URL}variant`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body_new_records),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          for (var i = 0; i < data.data.length; i++) {
            message.success("Variant successfully saved with returning ID: " + data.data[i].id)
          }

          //REPLACE THE OPTION VALUE LIST WITH THE RETURNED (NEWLY SAVED) RECORDS
          const new_variant_data = data.data
          const list_options = [...optionList];
          const list_option_values = [...optionValueList]
          // const list_temp = [...optionValueList_temp];

          // console.log (list_options)
          // console.log (list_option_values)
          // console.log (new_variant_data)
          let data_array_new_records = []


          for (var i = 0; i < list_options.length; i++){
            //these are all of the NEW variant values that ARE part of the NEW variant
              for (var y = 0; y < new_variant_data.length; y++){
                if (list_options[i].name == new_variant_data[y].name){ //this doesn't work: && !list[i][x].id
                  for (var x = 0; x < list_option_values[i].length; x++){
                    data_array_new_records.push({name: list_option_values[i][x].name, product_id: product_id, product_variant_id: new_variant_data[y].id, date_created: date_created, created_by: created_by})
                  }

                  // list[i][x] = {name: new_variant_data[y].name, product_id: product_id, product_variant_id: new_variant_data[y].id, date_created: date_created, created_by: created_by}
                  // list_temp[i][x] = {name: new_variant_data[y].name, product_id: product_id, product_variant_id: new_variant_data[y].id, date_created: date_created, created_by: created_by}

                }
              }
              // //these are all of the other NEW variant values that AREN'T apart of a NEW variant
              // for (var y = 0; y < list_option_values[i].length; y++){
              //   for (var r = 0; r < new_variant_data.length; r++){
              //     if (list_option_values[i][y] && !list_option_values[i][y].id && list_options[i].name != new_variant_data[r].name){
              //       data_array_new_records.push({name: list_option_values[i][y].name, product_id: product_id, product_variant_id: list_options[i].id, date_created: date_created, created_by: created_by})
              //     }
              //   }
              //
              // }
          }
          createNewVariantOptions(product_id, data_array_new_records)

          // window.location = "/admin/products"
        }
        setLoading(false)
        // return data
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }

  async function updateVariantOptions(product_id, existing_variant_data) {
    //Update exisitng Variant Options and create any new ones (if applicable)
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()

      let data_array_existing_records = []
      let data_array_existing_records_deleted = []
      let data_array_new_records = []
      //create the data_array_new_records from returning "variant_data"
      for (var i = 0; i < optionValueList.length; i++){
        for (var x = 0; x < optionValueList[i].length; x++){
          if (optionValueList[i][x] && optionValueList[i][x].id && optionList[i].id){
            data_array_existing_records.push({id: optionValueList[i][x].id, name: optionValueList[i][x].name, product_id: product_id, product_variant_id: optionList[i].id, date_modified: date_modified, modified_by: modified_by}) //update existing
          } else if (optionValueList[i][x] && optionList[i].id) {
            data_array_new_records.push({name: optionValueList[i][x].name, product_id: product_id, product_variant_id: optionList[i].id, date_created: date_modified, created_by: modified_by})
          }
        }
      }

      for (var i = 0; i < optionValueList_deleted.length; i++){
        if (optionValueList_deleted[i] && optionValueList_deleted[i].id){
          data_array_existing_records_deleted.push({id: optionValueList_deleted[i].id, name: optionValueList_deleted[i].name, product_id: product_id, deleted_at: date_modified, deleted_by: modified_by})
        }
      }

      // const body_existing_records = {data: data_array_existing_records}
      const body_existing_records = {data: data_array_existing_records}
      const body_existing_records_deleted = {data: data_array_existing_records_deleted}
      const body_new_records = {data: data_array_new_records}

      // console.log (body_existing_records)
      // console.log (body_existing_records_deleted)
      // console.log (body_new_records)


      const response = await fetch(`${process.env.REACT_APP_API_URL}v_o/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body_existing_records),
      }).then(response => response.json())
      .then(data =>{
        if (data.code == 409){
          message.error(data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          message.success("Variant Option Information Successfully Updated")
          deleteExistingVariantOptions(body_existing_records_deleted)
          createNewVariantOptions(product_id, data_array_new_records)
          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }

  async function createNewVariantOptions(product_id, variant_option_data) {
    //Save the variant option info to the Variant Options table and retrieve set of new PK ID
    try {
      setLoading(true)

      const body = {data: variant_option_data}

      const response = await fetch(`${process.env.REACT_APP_API_URL}variant_option`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          for (var i = 0; i < data.data.length; i++) {
            message.success("Variant Option Information Saved to Variant Table with returning ID: " + data.data[i].id)
          }
          //DO THIS TASK: SAVE MATRIX FOR NEW VALUES

          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }

  async function createNewVariantOptionValues(product_id) {
    //Save the variant option value info to the Variant Option Values table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()

      let data_array = []
      for (var i = 0; i < optionValueMatrix.length; i++){
        const combo_name = optionValueMatrix[i].join(" - ")

          if (picUrlList[combo_name] && !picUrlList[combo_name].hasOwnProperty('record_id') ){
            // const item_pic_url =  picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('value') ? picUrlList[combo_name].value : null
            //doesn't matter which object we use, they all have the same "active" status
            const item_active = quantityList[combo_name] && quantityList[combo_name].active == false ? false : true

            data_array.push({value: combo_name, product_id: product_id, active: item_active, date_created: date_created, created_by: created_by})
          }

      }
      const body = {data: data_array}

      const response = await fetch(`${process.env.REACT_APP_API_URL}matrix`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          for (var i = 0; i < data.data.length; i++) {
            message.success("Matrix Item Saved to Matrix with returning ID: " + data.data[i].id)
          }
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }

  async function updateVariantOptionValues(product_id) {
    //Save the variant option value info to the Variant Option Values table and retrieve set of new PK ID
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()

      let data_array = []
      for (var i = 0; i < optionValueMatrix.length; i++){
        const combo_name = optionValueMatrix[i].join(" - ")

          if (picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('record_id') ){
            // const item_pic_url =  picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('value') ? picUrlList[combo_name].value : null
            const item_id = picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('record_id') ? picUrlList[combo_name].record_id : null
            //doesn't matter which object we use, they all have the same "active" status
            const item_active = quantityList[combo_name] && quantityList[combo_name].active == false ? false : true

            data_array.push({id: item_id, value: combo_name, product_id: product_id, active: item_active, date_modified: date_modified, modified_by: modified_by})
          }

      }
      const body = {data: data_array}

      const response = await fetch(`${process.env.REACT_APP_API_URL}m/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          for (var i = 0; i < data.length; i++) {
            message.success("Matrix Item Updated to Matrix with returning ID: " + data.data[i].id)
          }
          // window.location = "/admin/products"
        }

        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }



  async function updateSkuValues(product_id) {
    //Save the variant option value info to the Variant Option Values table and retrieve set of new PK ID
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()

      let data_array = []
      // console.log ("HEERE IN THE UPDATED SKU")
      // console.log ('skuList')
      // console.log (skuList)
      // console.log (itemQuantity)

      if (doesProductHaveOptions){

        for (var i = 0; i < optionValueMatrix.length; i++){
          const combo_name = optionValueMatrix[i].join(" - ")

            if (skuList[combo_name] && skuList[combo_name].hasOwnProperty('record_id') ){
              //SKU DATA
              const item_id = quantityList[combo_name].record_id
              const item_quantity = quantityList[combo_name] && quantityList[combo_name].hasOwnProperty('value') ? quantityList[combo_name].value : null
              const item_sku =  skuList[combo_name] && skuList[combo_name].hasOwnProperty('value') ? skuList[combo_name].value : null
              const item_barcode = barcodeList[combo_name] && barcodeList[combo_name].hasOwnProperty('value') ? barcodeList[combo_name].value : null
              const item_serial_run_limit = serialQuantityList[combo_name] && serialQuantityList[combo_name].hasOwnProperty('value') ? serialQuantityList[combo_name].value : null
              const item_pic_url = picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('value') ? picUrlList[combo_name].value : null


              //doesn't matter which object we use, they all have the same "active" status
              const item_active = quantityList[combo_name] && quantityList[combo_name].active == false ? false : true

              data_array.push({id: item_id, combo_identifier: combo_name, quantity: item_quantity, sku: item_sku, barcode: item_barcode, serial_run_limit: item_serial_run_limit, product_id: product_id, active: item_active, pic_url: item_pic_url, date_modified: date_modified, modified_by: modified_by})
            }

        }
      } else {
        if (skuList['NO OPTIONS'].hasOwnProperty('record_id')){
          // console.log (itemQuantity)
          // console.log (itemSku)
          // console.log (itemBarcode)
          // console.log (itemSerialRunLimit)
          const item_id = skuList['NO OPTIONS'].record_id
          const item_quantity = itemQuantity
          const item_sku = itemSku
          const item_barcode = itemBarcode
          const item_serial_run_limit = itemSerialRunLimit
          const item_active = true
          const item_pic_url = itemPic

          data_array.push({id: item_id, combo_identifier: 'NO OPTIONS', quantity: item_quantity, sku: item_sku, barcode: item_barcode, serial_run_limit: item_serial_run_limit, product_id: product_id, active: item_active, pic_url: item_pic_url, date_modified: date_modified, modified_by: modified_by})
        }
      }
      const body = {data: data_array}
      // console.log ('---------------------------------------------')
      // console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}sku/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.code == 409){
          message.error(data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          // console.log (data)

            // message.success("SKU Item(s) successfully updated")
            if (!data.data){
              message.success("SKU Item Successfully Updated")
            } else {
              for (var i = 0; i < data.data.length; i++) {
                message.success("SKU Item Updated to Matrix with returning ID: " + data.data[i].id)
              }
            }

          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
      console.error(err);
    }
  }

  async function createNewSkuValues(product_id) {
    //Save the variant option value info to the Variant Option Values table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()

      let data_array = []
      // console.log ("HEERE IN THE NEW SKU")
      // console.log ('optionValueMatrix')
      // console.log (optionValueMatrix)
      // console.log (skuList)

      if (doesProductHaveOptions){
        for (var i = 0; i < optionValueMatrix.length; i++){
            const combo_name = optionValueMatrix[i].join(" - ")

            if (!skuList[combo_name] || !skuList[combo_name].hasOwnProperty('record_id') ) {
              const item_quantity = quantityList[combo_name] && quantityList[combo_name].hasOwnProperty('value') ? quantityList[combo_name].value : null
              const item_sku =  skuList[combo_name] && skuList[combo_name].hasOwnProperty('value') && skuList[combo_name].value != null ? skuList[combo_name].value : `TEMP${product_id}-${makeID(6)}`
              const item_barcode = barcodeList[combo_name] && barcodeList[combo_name].hasOwnProperty('value') ? barcodeList[combo_name].value : null
              const item_serial_run_limit = serialQuantityList[combo_name] && serialQuantityList[combo_name].hasOwnProperty('value') ? serialQuantityList[combo_name].value : null
              const item_pic_url = picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('value') ? picUrlList[combo_name].value : null
              // const item_matrix_id = matrix_data[i].id

              //doesn't matter which object we use, they all have the same "active" status
              const item_active = quantityList[combo_name] && quantityList[combo_name].active == false ? false : true

              data_array.push({combo_identifier: combo_name, quantity: item_quantity, sku: item_sku, barcode: item_barcode, serial_run_limit: item_serial_run_limit, product_id: product_id, active: item_active, pic_url: item_pic_url, date_created: date_created, created_by: created_by})


              // const handleSkuInputChange = (e, index) => {
              //   const { name, value } = e.target;


              // let  sku_constructor = { name: null, value: item_sku}
              // handleSkuInputChange(sku_constructor, combo_name)
            }
        }
      } else {
        if (!skuList['NO OPTIONS'] || !skuList['NO OPTIONS'].hasOwnProperty('record_id')){
          const item_quantity = itemQuantity
          const item_sku = itemSku ? itemSku : `TEMP${product_id}-${makeID(6)}`
          const item_barcode = itemBarcode
          const item_serial_run_limit = itemSerialRunLimit ? itemSerialRunLimit : null
          const item_active = true
          const item_pic_url = itemPic


          data_array.push({combo_identifier: 'NO OPTIONS', quantity: item_quantity, sku: item_sku, barcode: item_barcode, serial_run_limit: item_serial_run_limit, product_id: product_id, active: item_active, pic_url: item_pic_url, date_created: date_created, created_by: created_by})
        }
      }


      const body = {data: data_array}
      // console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}sku`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          //If SKUs haven't changed, there will be NO new skus.  However, new price & promo might be present
          if (!data.data){
            message.success("SKU Item Successfully Created")
          } else {
            for (var i = 0; i < data.data.length; i++) {
              message.success("SKU Item created to Matrix with returning ID: " + data.data[i].id)
            }
          }


          // for (var i = 0; i < data.data.length; i++) {
          //   message.success("SKU Item Updated to Matrix with returning ID: " + data.data[i].id)
          // }
          if ((itemPrice_new !== itemPrice && doesProductHaveOptions == false) || doesProductHaveOptions == true){
            saveToPromoTable(product_id, data.data)
          }

        }
          // window.location = "/admin/products"

        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
      console.error(err);
    }
  }





  async function updatePromoValues(product_id) {
    //Save the variant option value info to the Variant Option Values table and retrieve set of new PK ID
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()


      let data_array = []
      let data_array_new_records = [] //this is used to push new promos with the same sku, but marking

      if (doesProductHaveOptions){
        // console.log (optionValueMatrix)
        // console.log (priceList)

        for (var i = 0; i < optionValueMatrix.length; i++){
          const combo_name = optionValueMatrix[i].join(" - ")

            if (priceList[combo_name] && priceList[combo_name].hasOwnProperty('record_id') ){
              //SKU DATA
              const item_id = priceList[combo_name].record_id
              const item_sku_id =  priceList[combo_name] && priceList[combo_name].hasOwnProperty('sku_id') ? priceList[combo_name].sku_id : null
              const item_price = priceList[combo_name] && priceList[combo_name].hasOwnProperty('value') ? priceList[combo_name].value : null
              const item_price_effective_date = priceList_new[combo_name] && promotionDateList_old[combo_name] ?  promotionDateList_old[combo_name].value : promotionDateList[combo_name] && promotionDateList[combo_name].hasOwnProperty('value') ? promotionDateList[combo_name].value : null
              const currency = 'USD'



              const item_active = priceList[combo_name] && priceList[combo_name].active == false ? false : true

              data_array.push({id: item_id, combo_identifier: combo_name, sku_id: item_sku_id, product_id: product_id, active: item_active, price: item_price, currency: currency, promotion_date: item_price_effective_date, date_modified: date_modified, modified_by: modified_by})
            }

        }
      } else {

        if (priceList['NO OPTIONS'].hasOwnProperty('record_id')){

          const item_id = priceList['NO OPTIONS'].record_id
          const item_sku_id =  priceList['NO OPTIONS'] && priceList['NO OPTIONS'].hasOwnProperty('sku_id') ? priceList['NO OPTIONS'].sku_id : null
          const item_price = itemPrice
          const item_price_effective_date = itemPromotion_date
          const currency = 'USD'


          let item_active = false
          if (itemPrice == itemPrice_new) {
            item_active = true
          }

          data_array.push({id: item_id, combo_identifier: 'NO OPTIONS', sku_id: item_sku_id, product_id: product_id, active: item_active, price: item_price, currency: currency, promotion_date: item_price_effective_date, date_modified: date_modified, modified_by: modified_by})
        }
      }
      const body = {data: data_array}
      // console.log ('*****************************')
      // console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}promo/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.code == 409){
          message.error(data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {

          if (!data.data){
            message.success("Promo Item Successfully Updated")
          } else {
            for (var i = 0; i < data.data.length; i++) {
              message.success("Promo Item Updated with returning ID: " + data.data[i].id)
            }
          }
          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }
  }

  async function saveToPromoTable(product_id, sku_data) {
    //Save the variant option info to the Variant Options table and retrieve set of new PK ID

    // console.log ('HEEERRRE----------------')
    // console.log (sku_data)
    // console.log (priceList)
    // console.log (skuList)
    // console.log (sku_data ? sku_data[i].id : skuList[combo_name].sku_id)
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // let body = {}


      let data_array = []
      if (doesProductHaveOptions){


        for (var i = 0; i < optionValueMatrix.length; i++){
            const combo_name = optionValueMatrix[i].join(" - ")




            if (sku_data == 'new records'){
              if (priceList_new[combo_name] && !priceList_new[combo_name].hasOwnProperty('record_id') ){
                const item_price = priceList_new[combo_name].value
                const item_sku_id = priceList_new[combo_name].sku_id
                const item_active = priceList_new[combo_name].active
                const item_price_effective_date =  promotionDateList[combo_name] && promotionDateList[combo_name].hasOwnProperty('value') ? promotionDateList[combo_name].value : null
                data_array.push({combo_identifier: combo_name, price: item_price, currency: 'USD', promotion_date: item_price_effective_date, product_id: product_id, sku_id: item_sku_id, active: item_active, date_created: date_created, created_by: created_by})

              }
            } else {
              if (priceList[combo_name] && !priceList[combo_name].hasOwnProperty('record_id') ){
                const item_price = priceList[combo_name] && priceList[combo_name].hasOwnProperty('value') ? priceList[combo_name].value : null
                const item_price_effective_date =  promotionDateList[combo_name] && promotionDateList[combo_name].hasOwnProperty('value') ? promotionDateList[combo_name].value : null
                let sku_data_id = null
                if (sku_data){
                  for (var x = 0; x < sku_data.length; x++){
                    if (sku_data[x].combo_identifier == combo_name){
                      sku_data_id = sku_data[x].id
                    }
                  }
                }
                const item_sku_id = sku_data_id ? sku_data_id : skuList[combo_name].record_id

                //doesn't matter which object we use, they all have the same "active" status
                const item_active = quantityList[combo_name] && quantityList[combo_name].active == false ? false : true

                data_array.push({combo_identifier: combo_name, price: item_price, currency: 'USD', promotion_date: item_price_effective_date, product_id: product_id, sku_id: item_sku_id, active: item_active, date_created: date_created, created_by: created_by})
              }
          }

        }


      } else {
        if (priceList['NO OPTIONS'].hasOwnProperty('record_id')){
          const item_price = itemPrice_new
          const item_price_effective_date = itemPromotion_date
          const item_sku_id = sku_data.length > 0 ? sku_data[0].id : skuList['NO OPTIONS'].record_id
          const item_active = true

          data_array.push({combo_identifier: 'NO OPTIONS', price: item_price, currency: 'USD', promotion_date: item_price_effective_date, product_id: product_id, sku_id: item_sku_id, active: item_active, date_created: date_created, created_by: created_by})
        }





      }
      const body = {data: data_array}
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}promo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          // for (var i = 0; i < data.data.length; i++) {
          //   message.success("Promo Item created to Promos with returning ID: " + data.data[i].id)
          // }

          if (!data.data){
            message.success("Promo Item Successfully Created")
          } else {
            for (var i = 0; i < data.data.length; i++) {
              message.success("Promo Item created to Promos with returning ID: " + data.data[i].id)
            }
          }

          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
      console.error(err);
    }
  }









  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))


        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }
    }
  }

  const dropdown_brands = setSelectedId_View('brands')




  function setVariant_option_values_container(x, i_x) {
    return (

        x.map((y, i_y) => (
        <div key={i_y} className="box" style={{display: 'flex'}}>
          <input
              name="variant_value"
              className={optionValueList[i_x][i_y].name == 'DELETE' ? `form-control error` : (y == '' ? `form-control pending` : `form-control`)}
              type="text"
              placeholder={variant_list[i_x] && i_y == 0 ? variant_list[i_x].placeholder : "Add another value"}
              value={y.name}
              onChange={e => handleOptionValueInputChange(e, i_x, i_y)}
          />
          <div className="col-lg-6" style={{display:'flex'}}>
            {x.length !== 1 && <button className="ps-btn-delete" onClick={(e)=> handleRemoveOptionValueInput(e, i_x, i_y)}><i className='icon-cross'></i></button>}
            {x.length - 1 === i_y && <button className="ps-btn-delete" onClick={(e) => handleAddOptionValueInput(e, i_x)}><i className='icon-plus'></i> add another value</button>}
          </div>
        </div>)

      )

      )

  }







  const fieldChanged = (type, value) => {

    if (type == 'name'){
       setName(value);
    } else if (type == 'description'){
      setDescription(value);
    } else if (type == 'itemPrice'){
      // setItemPrice(value)
      setItemPrice_new(value)
    } else if (type == 'itemWarranty'){
      setItemWarranty(value)
    } else if (type == 'itemSku'){
      setItemSku(value)
    } else if (type == 'itemBarcode'){
      setItemBarcode(value)
    } else if (type == 'itemQuantity'){
      setItemQuantity(value)
    } else if (type == 'itemSerialRunLimit'){
      setItemSerialRunLimit(value)
    } else if (type == 'wc_url'){
      setWc_url(value)
    } else if (type == 'itemPic'){
      setItemPic(value)
    } else if (type == 'promotion_date'){
      const converted_date = value.toISOString()
      setItemPromotion_date(converted_date);
    } else if (type == 'doesProductHaveOptions'){
      console.log ("HERE")
      setDoesProductHaveOptions(value)
      setSerialSelectionList([false])
      setIsThisProductSerialized(false)
      // if (value == false){
        setOptionList([{name: ''}])
        setOptionValueList([[{name: ''}]]);
        setOptionValueList_temp([[{name: ''}]])
        setOptionValueMatrix([])
        setSerialSelectionList([])
      // }
    } else if (type == 'isThisProductSerialized'){
      console.log ("HERE")
      setIsThisProductSerialized(value)

      const serial_selection_list = []
      for (var i = 0; i < optionList.length; i++){
        serial_selection_list.push(false)
      }
      setSerialSelectionList(serial_selection_list)

      if (value == false) {
        setSerialSelectionList([])
        //reset option selection
      }
    }

  };

  const handleSelect = (item, type) => {
    if (type == 'brand_id'){
      setBrand_id(item.target.value)
    }
  }


  const handleMakeVariantActive = (e, index) => {
    e.preventDefault();
    setPriceList({...priceList, [index]: {...priceList[index], active: true}})
    setPriceList_new({...priceList_new, [index]: {...priceList_new[index], active: true}})
    setPromotionDateList_old({...promotionDateList_old, [index]: {...promotionDateList_old[index], active: true}})
    setQuantityList({...quantityList, [index]: {...quantityList[index], active: true}})
    setSkuList({...skuList, [index]: {...skuList[index], active: true}})
    setBarcodeList({...barcodeList, [index]: {...barcodeList[index], active: true}})
    setPromotionDateList({...promotionDateList, [index]: {...promotionDateList[index], active: true}})
    setPicUrlList({...picUrlList, [index]: {...picUrlList[index], active: true}})
    setSerialQuantityList({...serialQuantityList, [index]: {...serialQuantityList[index], active: true}})

  }
  const handleMakeVariantInactive = (e, index) => {
    e.preventDefault();
    setPriceList({...priceList, [index]: {...priceList[index], active: false}})
    setPriceList_new({...priceList_new, [index]: {...priceList_new[index], active: false}})
    setPromotionDateList_old({...promotionDateList_old, [index]: {...promotionDateList_old[index], active: false}})
    setQuantityList({...quantityList, [index]: {...quantityList[index], active: false}})
    setSkuList({...skuList, [index]: {...skuList[index], active: false}})
    setBarcodeList({...barcodeList, [index]: {...barcodeList[index], active: false}})
    setPromotionDateList({...promotionDateList, [index]: {...promotionDateList[index], active: false}})
    setPicUrlList({...picUrlList, [index]: {...picUrlList[index], active: false}})
    setSerialQuantityList({...serialQuantityList, [index]: {...serialQuantityList[index], active: false}})
  }

  const handlePriceInputChange = (e, index) => {
    const { name, value } = e.target;
    if (priceList[index] && priceList[index].record_id){
      const record_id = priceList[index].record_id
      const sku_id = priceList[index].sku_id
      if (priceList[index].value != value){
        setPriceList({...priceList, [index]: {value: priceList[index].value, active: false, record_id: record_id, sku_id: sku_id}});
        setPriceList_new({...priceList_new, [index]: {value: value, active: true, sku_id: sku_id, }})

      } else {
        setPriceList({...priceList, [index]: {value: value, active: true, record_id: record_id, sku_id: sku_id}});
        if (priceList_new[index]){
          delete priceList_new[index]
        }
      }
    } else {
      setPriceList({...priceList, [index]: {value: value, active: true}});
    }
  }

  const handleQuantityInputChange = (e, index) => {
    const { name, value } = e.target;

    if (quantityList[index] && quantityList[index].record_id){
      const record_id = quantityList[index].record_id
      setQuantityList({...quantityList, [index]: {value: value, active: true, record_id: record_id}});
    } else {
      setQuantityList({...quantityList, [index]: {value: value, active: true}});
    }
  }

  const handleSkuInputChange = (e, index) => {
    const { name, value } = e.target;

    if (skuList[index] && skuList[index].record_id){
      const record_id = skuList[index].record_id
      setSkuList({...skuList, [index]: {value: value, active: true, record_id: record_id}});
    } else {
      setSkuList({...skuList, [index]: {value: value, active: true}});
    }
  }
  const handleBarcodeInputChange = (e, index) => {
    const { name, value } = e.target;

    if (barcodeList[index] && barcodeList[index].record_id){
      const record_id = barcodeList[index].record_id
      setBarcodeList({...barcodeList, [index]: {value: value, active: true, record_id: record_id}});
    } else {
      setBarcodeList({...barcodeList, [index]: {value: value, active: true}});
    }
  }

  const handlePromotionDateInputChange = (e, index) => {
    const  value  = e;
    const converted_date = value.toISOString()

    if (promotionDateList[index] && promotionDateList[index].record_id){
      const record_id = promotionDateList[index].record_id
      if(!promotionDateList_old[index]){
        // setPriceList_new({...priceList_new, [index]: {...priceList_new[index], active: false}})
        setPromotionDateList_old({...promotionDateList_old, [index]: {value: promotionDateList[index].value, active: true, record_id: record_id}});
        setPromotionDateList({...promotionDateList, [index]: {value: value, active: true, record_id: record_id}});
        // setPriceList_new({...priceList_new, [index]: {...priceList_new[index], promotion_date: value}});

      } else {
        setPromotionDateList({...promotionDateList, [index]: {value: value, active: true, record_id: record_id}});
      }
    } else {
      setPromotionDateList({...promotionDateList, [index]: {value: converted_date, active: true}});
    }
  }


  // setPriceList_new({...priceList_new, [index]: {...priceList_new[index], active: false}})

  const handleUrlInputChange = (e, index) => {
    const { name, value } = e.target;

    if (picUrlList[index] && picUrlList[index].record_id){
      const record_id = picUrlList[index].record_id
      setPicUrlList({...picUrlList, [index]: {value: value, active: true, record_id: record_id}});
    } else {
      setPicUrlList({...picUrlList, [index]: {value: value, active: true}});
    }
  }

  const handleSerialQuantityInputChange = (e, index) => {
    const {name, value} = e.target;

    if (serialQuantityList[index] && serialQuantityList[index].record_id){
      const record_id = serialQuantityList[index].record_id
      setSerialQuantityList({...serialQuantityList, [index]: {value: value, active: true, record_id: record_id}});
    } else {
      setSerialQuantityList({...serialQuantityList, [index]: {value: value, active: true}})
    }
  }



  const handleOptionValueInputChange = (e, index_x, index_y) => {
    console.log (index_x)
    console.log (index_y)

    const { name, value } = e.target;
    const list = [...optionValueList];
    const list_temp = [...optionValueList_temp];

    var valueExists = false
    for (var y = 0; y < list[index_x].length; y++){
      if (list[index_x][y].name == value){
        valueExists = true
      }
    }

    list_temp[index_x][index_y].name = value

    if (!valueExists){
      list[index_x][index_y].name = value;
    } else {
      list[index_x][index_y].name = "DELETE";
    }

    setOptionValueList_temp(list_temp)
    setOptionValueList(list);
    setOptionValueMatrix(makeVariantArray(list))
  }


  const handleOptionInputChange = (e, index) => {
    console.log ('--------------')
    console.log (optionList)
    console.log (index)
    const { name, value } = e.target;
    const list = [...optionList];
    const list_deleted = [...optionList_deleted]

    let record_found = false
    for (var i = 0; i < optionList_deleted.length; i++){
      if (optionList_deleted[i].name == value){
        record_found = true
        list[index] = optionList_deleted[i]
        list_deleted.splice(i, 1)

      }
    }

    if (!record_found){
      list[index].name = value;
    } else {
      setOptionList_deleted(list_deleted)
    }
    setOptionList(list);
  }

  const handleRemoveOptionValueInput = (e, index_x, index_y) => {
    e.preventDefault();
    const list = [...optionValueList]
    const list_deleted = [...optionValueList_deleted]
    list_deleted.push(list[index_x][index_y])
    list[index_x].splice(index_y, 1)

    const list_temp = [...optionValueList_temp]
    list_temp[index_x].splice(index_y, 1)

    setOptionValueList(list)
    setOptionValueList_temp(list_temp)
    setOptionValueList_deleted(list_deleted)
    setOptionValueMatrix(makeVariantArray(list))
  }


  const handleRemoveOptionInput = (e, index) => {
    e.preventDefault();

    //set up an object of deleted option values (so their Variant ID can be recovered if they are re-entered in the form)
    const option_list = [...optionList]
    const option_list_deleted = [...optionList_deleted]

    if (optionList[index].id){
      let isRecordFound = false
      for (var i = 0; i < optionList_deleted.length; i++){
        if (optionList_deleted[i].id == optionList[index].id){
          return
        } else {
          isRecordFound = true
        }
      }

      if (isRecordFound){
        option_list_deleted.push(optionList[index])
      }
    }

    option_list.splice(index, 1)
    // const option_list = [...optionList]
    // option_list[index].deleted = true
    const option_value_list = [...optionValueList]
    option_value_list.splice(index, 1)

    const option_value_list_temp = [...optionValueList_temp]
    option_value_list_temp.splice(index, 1)

    const serial_selection_list = [...serialSelectionList]
    serial_selection_list.splice(index, 1)

    setOptionList(option_list)
    setOptionList_deleted(option_list_deleted)
    setOptionValueList(option_value_list)
    setOptionValueList_temp(option_value_list_temp)
    setSerialSelectionList(serial_selection_list)
    setOptionValueMatrix(makeVariantArray(option_value_list))
  }

  const handleAddOptionValueInput = (e, index_x) => {
    e.preventDefault();
    const list = [...optionValueList]

    list[index_x].push({name: ""})

    const list_temp = [...optionValueList_temp]

    list_temp[index_x].push({name: ""})

    setOptionValueList(list)
    setOptionValueList_temp(list_temp)

    // setOptionValueMatrix(makeVariantArray(list))


  }

  const handleAddOptionInput = (e) => {
    e.preventDefault();
    setOptionList([...optionList, {name: ""}])
    setOptionValueList([...optionValueList, [{name: ""}]])
    setOptionValueList_temp([...optionValueList_temp, [{name: ""}]])
    setSerialSelectionList([...serialSelectionList, false])
    setOptionValueMatrix(makeVariantArray([[{name: ''}]]))
  }

  const isListValid = (list) => {
    var foundValidValue = false
    for (var i = 0; i < list.length; i++){
      if (list[i].name != '' && list[i].name != 'DELETE'){
        foundValidValue = true

      }
    }
    return foundValidValue
  }



  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solid #dee2e6',
    minWidth: '500px',
  }



  const frozenColumn_left = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    minWidth:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(27px + 5rem)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };



  // console.log ("doesProductHaveOptions: " + doesProductHaveOptions)
  //
    // console.log ("----------------------------------- ")
  // console.log ("optionList: ")
  // console.log (optionList)
  // console.log ("optionList_deleted: ")
  // console.log (optionList_deleted)
  // console.log ("optionValueList_temp: ")
  // console.log (optionValueList_temp)
  // console.log ("optionValueList: ")
  // console.log (optionValueList)
  // console.log ("optionValueMatrix: " )
  // console.log(optionValueMatrix)
  // console.log ("price: ")
  // console.log (priceList)
  // console.log ("price NEW: ")
  // console.log (priceList_new)
  // console.log ("quantityList: ")
  // console.log (quantityList)
  console.log ("skuList: ")
  console.log (skuList)
  // console.log ("item pic")
  // console.log (itemPic)
  // console.log ("barcodeList: ")
  // console.log (barcodeList)
  // console.log ("serial selection list:")
  // console.log (serialSelectionList)
  // console.log ("serialQuantityList:")
  // console.log (serialQuantityList)
  // console.log ("picUrlList")
  // console.log (picUrlList)
  // console.log ("itemPromotion_date")
  // console.log (itemPromotion_date)
  // console.log (moment(itemPromotion_date, dateFormat))
  // console.log ("effective dates: ")
  // console.log (promotionDateList)
  // console.log ("effective dates NEW: ")
  // console.log (promotionDateList_old)
  // console.log ('itemPrice')
  // console.log (itemPrice)
  // console.log ('itemPrice_new')
  // console.log (itemPrice_new)
  // console.log ('itemPrice_original_id')
  // console.log (itemPrice_original_id)

  // //
  //








    return (
      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Edit Product</h3>
            </div>

            <div className="ps-form__content">
            {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING RECORDS</h5></div> : null}


                <div className="row">
                  <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Product Information</h3></label>
                        </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form">Brand</label>


                          {loadingDropdowns ? <div>Loading...</div> :

                            <select className="form-control" defaultValue={0} value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                                {brands.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                              {dropdown_brands}
                            </select>}

                      </div>
                  </div>

                  <div className="separator"></div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product Name</label>
                            <input
                                className={name ? `form-control` :  `form-control error`}
                                type="text"
                                placeholder="Product Title"
                                value={name}
                                onChange={e => fieldChanged('name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product Description</label>
                            <textarea
                                className={description ? `form-control` :  `form-control error`}
                                type="text"
                                rows="3"
                                placeholder="Product Description"
                                value={description}
                                onChange={e => fieldChanged('description', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="separator"></div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product Warranty</label>
                            <textarea
                                className={`form-control`}
                                style={{resize: 'vertical', transition: 'all 0s ease'}}
                                type="text"
                                rows="5"
                                placeholder="Product Warranty"
                                value={itemWarranty}
                                onChange={e => fieldChanged('itemWarranty', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="separator"></div>

                  </div>

                  <div className="row" style={{backgroundColor: '#f1f1f1', paddingTop: '2.5rem', borderRadius: '4px'}}>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3 style={{color: '#d3d3d3'}}>Mini-Program Information</h3></label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">
                            <Tooltip placement="topRight" title="This is the Query portion of the Mini-Program pagepath after the 'ID' marker: /pages/product/show/main.html?id=62aa4b9885eb4df50ec8cf71">
                              <InfoCircleOutlined />
                            </Tooltip> Mini-Program Scheme Query
                          </label>
                          <input
                              className="form-control"
                              style={{backgroundColor: 'white'}}
                              type="text"
                              placeholder="62aa4b9885eb4df50ec8cf71"
                              value={wc_url}
                              onChange={e => fieldChanged('wc_url', e.target.value)}
                          />
                      </div>
                    </div>

                  </div>
                </div>









                <div className="ps-form__content" style={{marginTop: '50px'}}>

                    <div className="row">
                      <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form"><h3>Options</h3></label>
                            <div className="ps-checkbox" style={{marginTop: '10px'}}>
                              <input
                                className="form-control"
                                type="checkbox"
                                id="product-options"
                                checked={doesProductHaveOptions}
                                onChange={e => fieldChanged('doesProductHaveOptions', e.target.checked)}
                                />
                              <label for="product-options">Does this product have options?</label>
                            </div>
                          </div>
                      </div>


                    </div>



                      {doesProductHaveOptions ? optionList.map((x, i_x) => {
                        return (
                          <div key={i_x} className="row">
                            <div className="separator"></div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form">Option Name {i_x + 1}</label>
                                    <div className="box" style={{display: 'flex'}}>
                                      <input
                                          name="variant_option"
                                          className={optionList[i_x] && isListValid(optionValueList[i_x]) ? `form-control` :  `form-control error`}
                                          type="text"
                                          placeholder={variant_list[i_x] ? variant_list[i_x].name : "Another Option Name"}
                                          value={x.name}
                                          onChange={e => handleOptionInputChange(e, i_x)}
                                      />
                                      <div className="col-lg-6" style={{display:'flex'}}>
                                        {optionList.length !== 1 && <button className="ps-btn-delete" onClick={(e)=> handleRemoveOptionInput(e, i_x)}><i className='icon-cross'></i></button>}
                                      </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form">Option Values</label>
                                    {setVariant_option_values_container(optionValueList_temp[i_x], i_x)}

                                </div>

                            </div>
                            {optionList.length - 1 === i_x &&
                              <div className="col-lg-6" >
                                 <button className="ps-btn-delete" onClick={(e) => handleAddOptionInput(e)}><i className='icon-plus'></i> add another option</button>
                              </div>}
                        </div>

                      )
                    }) : null}


            </div>










            {doesProductHaveOptions   ? <div className="ps-form__content" style={{marginTop: '50px'}}>





                <div className="row">
                  <div className="col-lg-6">
                      <div className="form-group" style={{margin:'0px'}}>
                        <label className="form"><h3>Variants</h3></label>
                        <div className="ps-checkbox" style={{marginTop: '10px', marginBottom: '2.5rem'}}>
                          <input
                            className="form-control"
                            type="checkbox"
                            id="serial-option"
                            name="serial-option"
                            checked={isThisProductSerialized}
                            onChange={e => fieldChanged('isThisProductSerialized', e.target.checked)}
                            />
                          <label for="serial-options" >Is this product serialized by SKU?</label>
                        </div>

                      </div>
                  </div>

                </div>







          <div className="row">




            <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>
            {optionValueMatrix.length != 0 ? <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
              <thead>
                <tr>
                  <th style={{position: 'sticky', left: '0', backgroundColor: '#f1f1f1'}}>Variant</th>
                  <th>Price</th>
                  <th>Effective Date</th>
                  <th>Quantity</th>
                  <th><Tooltip placement="topRight" title='If the brand is "King Mountain" and the SKU contains "MASK", the customer will be able to change the size of the product that they ordered before the Order has been Shipped'>
                    <InfoCircleOutlined />
                  </Tooltip> SKU</th>
                  <th>Barcode</th>
                  <th>Picture URL</th>
                  <th>Preview</th>
                  {isThisProductSerialized ? <th style={{position: 'sticky', right: 'calc(27px + 5rem)', backgroundColor: '#f1f1f1'}}>Serial Run Limit</th> : null}
                  <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}}></th>
                </tr>
              </thead>
              <tbody>
                {optionValueMatrix.map((comboSet, i) => (
                  <tr key={comboSet.join(' - ')}>
                    <td style={frozenColumn_left}><b>{comboSet.join(' - ')}</b></td>
                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                        <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <div className="input-group mb-2" >
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                                className="form-control"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder="0.00"
                                readOnly={priceList_new[comboSet.join(' - ')] && priceList_new[comboSet.join(' - ')].active == true ? false : (priceList[comboSet.join(' - ')] && priceList[comboSet.join(' - ')].active == false ? true : false)}
                                value={priceList_new[comboSet.join(' - ')] ? priceList_new[comboSet.join(' - ')].value : priceList[comboSet.join(' - ')] ? priceList[comboSet.join(' - ')].value : null}
                                onChange={e => handlePriceInputChange(e, comboSet.join(' - '))}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">USD</span>
                            </div>
                          </div>
                        </div>
                    </td>

                    <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        <div className="ant-calendar-picker">
                            <DatePicker allowClear={false}
                            disabled={promotionDateList[comboSet.join(' - ')] ? !promotionDateList[comboSet.join(' - ')].active : null}
                            value={(promotionDateList[comboSet.join(' - ')] && promotionDateList[comboSet.join(' - ')].value!= null)   ? moment(promotionDateList[comboSet.join(' - ')].value) : null}
                            onChange={e => handlePromotionDateInputChange(e, comboSet.join(' - '))}/>
                        </div>
                      </div>

                    </td>


                    <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id="input_table_quantity"
                              placeholder="0"
                              readOnly={quantityList[comboSet.join(' - ')] && quantityList[comboSet.join(' - ')].active == false ? true : false}
                              value={quantityList[comboSet.join(' - ')] ? quantityList[comboSet.join(' - ')].value : null}
                              onChange={e => handleQuantityInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="SKU012345"
                              readOnly={skuList[comboSet.join(' - ')] && skuList[comboSet.join(' - ')].active == false ? true : false}
                              value={skuList[comboSet.join(' - ')] ? skuList[comboSet.join(' - ')].value : null}
                              onChange={e => handleSkuInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="9102132512"
                              readOnly={barcodeList[comboSet.join(' - ')] && barcodeList[comboSet.join(' - ')].active == false ? true : false}
                              value={barcodeList[comboSet.join(' - ')] ? barcodeList[comboSet.join(' - ')].value : null}
                              onChange={e => handleBarcodeInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="www.tencent.com/..."
                              readOnly={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].active == false ? true : false}
                              value={picUrlList[comboSet.join(' - ')] ? picUrlList[comboSet.join(' - ')].value : null}
                              onChange={e => handleUrlInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'100px', verticalAlign: 'middle'}}>
                      <img src={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].value ? picUrlList[comboSet.join(' - ')].value : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                      style={{display: 'block',
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          filter: (picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].active == false) ? 'grayscale(1)' : 'none',
                        }} alt={i} border="0" />
                    </td>
                    {isThisProductSerialized ?
                      <td style={frozenColumn_right_second}>
                        <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                            <input
                                className="form-control"
                                style={{backgroundColor: serialQuantityList[comboSet.join(' - ')] && serialQuantityList[comboSet.join(' - ')].active == false ? '#e9ecef' : "white"}}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder="0"
                                readOnly={serialQuantityList[comboSet.join(' - ')] && serialQuantityList[comboSet.join(' - ')].active == false ? true : false}
                                value={serialQuantityList[comboSet.join(' - ')] ? serialQuantityList[comboSet.join(' - ')].value : null}
                                onChange={e => handleSerialQuantityInputChange(e, comboSet.join(' - '))}
                            />


                        </div>
                      </td> : null}

                    <td style={frozenColumn_right}>
                      {quantityList[comboSet.join(' - ')] && quantityList[comboSet.join(' - ')].active == false ?
                      <button className="ps-btn-table-control" onClick={e => handleMakeVariantActive(e, comboSet.join(' - '))}>
                          <i className='icon-undo'></i>
                      </button>
                      :
                      <button className="ps-btn-table-control" onClick={e => handleMakeVariantInactive(e, comboSet.join(' - '))}>
                          <i className='icon-cross'></i>
                      </button>}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table> :

              <div className="col-lg-12">
                <div className="form-group" style={{marginTop: '3rem', marginBottom: '5rem'}}>
                  <label className="form">Please enter all of the applicable product options in order to generate the variant matrix.</label>
                </div>
              </div>}





            </div>

          </div>

        </div> :null }

        {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form"><h3>Picture</h3></label>
              </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                  <div style={thumb} >
                    <div style={thumbInner}>
                      <img src={itemPic} style={img} alt="CBR0" border="0" />
                    </div>
                  </div>
                </div>
            </div>
            <div style={{height: '5vh'}}/>


            <div className="col-lg-12">
              <div className="form-group">
                <label className="form">Picture Address</label>
                <div className="input-group mb-2" >
                  <div className="input-group-prepend">
                    <span className="input-group-text">URL</span>
                  </div>
                  <input
                      className="form-control"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      placeholder="https://www.luxanda.cn/static/imgs/products/CBR1.jpg"
                      value={itemPic}
                      onChange={e => fieldChanged('itemPic', e.target.value)}
                  />

                </div>
              </div>
            </div>




          </div>
        </div> }

        {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form"><h3>Pricing</h3></label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form">Price</label>
                <div className="input-group mb-2" >
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                      className="form-control"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0.00"
                      value={itemPrice_new}
                      onChange={e => fieldChanged('itemPrice', e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">USD</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form">Effective Date</label>
                <div className="ant-calendar-picker">
                    <DatePicker
                    allowClear={false}
                    value={(itemPromotion_date != null) ? moment(itemPromotion_date) : null}
                    onChange={e => fieldChanged('promotion_date', e)}
                    />
                </div>
              </div>
            </div>



          </div>
        </div> }

        {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form"><h3>Inventory</h3></label>
              </div>
            </div>




            <div className="col-lg-6">
              <div className="form-group">
              <label className="form"><Tooltip placement="topRight" title='If the brand is "King Mountain" and the SKU contains "MASK", the customer will be able to change the size of the product that they ordered before the Order has been Shipped'>
                <InfoCircleOutlined />
              </Tooltip> Product SKU</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="SKU012345"
                    value={itemSku}
                    onChange={e => fieldChanged('itemSku', e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className="form">Product Barcode</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="9102132512"
                    value={itemBarcode}
                    onChange={e => fieldChanged('itemBarcode', e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className="form">Quantity</label>
                <input
                    className="form-control"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="0"
                    value={itemQuantity}
                    onChange={e => fieldChanged('itemQuantity', e.target.value)}
                />
              </div>
            </div>
            <div className="separator"></div>

            <div className="col-lg-6">
                <div className="form-group" style={{margin:'0px'}}>
                  <div className="ps-checkbox" style={{marginTop: '10px', marginBottom: '2.5rem'}}>
                    <input
                      className="form-control"
                      type="checkbox"
                      id="serial-option"
                      name="serial-option"
                      checked={isThisProductSerialized}
                      onChange={e => fieldChanged('isThisProductSerialized', e.target.checked)}
                      />
                    <label >Is this product serialized by SKU?</label>
                  </div>

                </div>
            </div>
            <div className="col-lg-6"></div>
            {isThisProductSerialized ?
            <div className="col-lg-6">
              <div className="form-group">
              <label className="form">Serial Run Limit</label>
                <input
                    className="form-control"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="0"
                    value={itemSerialRunLimit}
                    onChange={e => fieldChanged('itemSerialRunLimit', e.target.value)}
                />
              </div>
            </div> : null}

          </div>
        </div>}
        <div className="form-group submit" style={{marginTop:"5rem"}}>
            <button
              className="ps-btn"
              onClick={e => handleSubmit(e)}
              disabled={loading}
              >
              {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Update Product')}
            </button>
        </div>


        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormEditProduct;
