import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { Popconfirm, message, Tooltip, Switch } from 'antd'
import moment from 'moment'
import {makeID} from '../../../utilities/common-helpers'


import { useLocation } from 'react-router-dom'

import { variant_list } from './data/variant_list'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormNewProduct = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)
  // const date = new Date()
  // const [date_created, setDate_created] = useState("");
  const [name, setName] = useState("");
  // const [pic, setPic] = useState("");
  // const [variant, setVariant] = useState("");
  // const [price, setPrice] = useState("");
  const [brand_id, setBrand_id] = useState("");
  const [description, setDescription] = useState("");
  // const [sku, setSku] = useState("");
  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)

  // const [currentPage, setCurrentPage] = useState("")
  const [parent_variant_id, setParent_variant_id] = useState("")


  const [brands, setBrands] = useState([]);

  const [itemPrice, setItemPrice] = useState("")
  const [itemWarranty, setItemWarranty] = useState("")
  const [promotion_date, setPromotion_date] = useState("");

  const [itemQuantity, setItemQuantity] = useState("")
  const [itemSku, setItemSku] = useState("")
  const [itemBarcode, setItemBarcode] = useState("")
  const [itemSerialRunLimit, setItemSerialRunLimit] = useState("")
  const [itemPic, setItemPic] = useState("")




  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [doesProductHaveOptions, setDoesProductHaveOptions] = useState(false)
  const [isThisProductSerialized, setIsThisProductSerialized] = useState(false)


  const [optionList, setOptionList] = useState([""])
  const [optionValueList, setOptionValueList] = useState([[""]]);
  const [optionValueList_temp, setOptionValueList_temp] = useState([[""]]);
  const [optionValueMatrix, setOptionValueMatrix] = useState([])
  const [serialSelectionList, setSerialSelectionList] = useState([])
  const [priceList, setPriceList] = useState({})
  const [quantityList, setQuantityList] = useState({})
  const [skuList, setSkuList] = useState({})
  const [barcodeList, setBarcodeList] = useState({})
  const [promotionDateList, setPromotionDateList] = useState({})
  const [picUrlList, setPicUrlList] = useState({})
  const [serialQuantityList, setSerialQuantityList] = useState({})

  const [wc_url, setWc_url] = useState('')
  // const [serialSelectionOption, setSerialSelectionOption] = useState([{value: true, option: 'by Product'}, {value: false, option: 'by Option'}, {value: false, option: 'by Option Value'}])


  // let variantMatrix = []
  let location = useLocation()


  const makeVariantArray = (list, n = 0, result = [], current = [], index) => {
    if (n === list.length){
      result.push(current)
    } else list[n].forEach((item, i) => {
     if (item && item != "DELETE") {
        makeVariantArray(list, n+1, result, [...current, item], i)
     }
    }
    )
    setOptionValueMatrix(result)
    return result
  }




  const handleSubmit = async (e) => {
    e.preventDefault();


    //General Form Validation
    if(!brand_id || brand_id == '0'){
      message.error('Please select a Brand for this Product.')
    } else if (!name){
      message.error('Please enter a Product Name for this Product.')
    } else if (!name){
      message.error('Please enter a Product Name for this Product.')
    } else {

      //Form Validation if Product Has Options
      if (doesProductHaveOptions){
        if (optionValueMatrix.length == 0){
          message.error('Please enter all of the applicable product options and option values.')
        } else {

          //check to see that all of the options are populated
          var isOptionListValid = true
          for (var i = 0; i < optionValueMatrix.length && isOptionListValid == true ; i++){

            if (optionList[i] == ''){
              isOptionListValid = false
            }
            console.log (isOptionListValid)
          }
          if (!isOptionListValid){
            message.error('Please enter the missing Option Name.')
          } else {
            //save the product and related info
            saveToProductTable()

          }

        }
        //Form Validation if Product does not have options
      } else {

        if (!itemPrice){
          message.error('Please enter a Product Price for this Product.')
        } else if (!promotion_date){
          message.error('Please enter an Effective Date for this Product.')
        // } else if (!itemSku){
        //   message.error('Please enter a Product SKU for this Product.')
        } else if (!itemQuantity){
          message.error('Please enter a Product Quantity for this Product.')
        } else if (isThisProductSerialized && !itemSerialRunLimit){
          message.error('Please enter a Run Limit for this Product.')
        } else {
          //save product and related info
          saveToProductTable()

        }


      }
    }





  };

  async function saveToProductTable(){
    //Save the product info to the Product table and retrieve new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // const pic_url = itemPic ? itemPic : null
      const body = { date_created, created_by, name, description, brand_id, doesProductHaveOptions, isThisProductSerialized, itemWarranty, wc_url };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}p`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          console.log (data)
          message.success("Product Information Saved to Project Table with returning ID: " + data.data.id)
          console.log (data.data.id)

          //if product has options, save related info
          if (doesProductHaveOptions){
            saveToVariantsTable(data.data.id)
          } else {
            //if product does not have options, save just the price and inventory data
            saveToSkusTable(data.data.id)
          }
          // window.location = "/admin/products"
        }
        setLoading(false)
      })



    } catch (err) {
      message.error (err.message)
      message.error ("Something went wrong, it's best you edit the product.")
      console.error(err.message);
    }
  }


  async function saveToVariantsTable(product_id) {
    //Save the variant info to the Variant table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // let body = {}
      let data_array = []
      //const body = { date_created, created_by, name, description, brand_id, doesProductHaveOptions  };
      //console.log (body)
      for (var i = 0; i < optionList.length; i++){
        data_array[i] = {name: optionList[i], product_id: product_id, date_created: date_created, created_by: created_by}
        // data_array.push([optionList[i], product_id, date_created, created_by])

      }
      const body = {data: data_array}
      // console.log (JSON.parse(body.data))
      console.log ("____________________________")
      console.log (body)


      const response = await fetch(`${process.env.REACT_APP_API_URL}variant`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          console.log (data)
          for (var i = 0; i < data.data.length; i++) {
            message.success("Variant Information Saved to Variant Table with returning ID: " + data.data[i].id)
          }
          saveToVariantOptionsTable(product_id, data.data)

          // console.log (data.data.id)
          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      console.error(err.message);
    }
  }

  async function saveToVariantOptionsTable(product_id, variant_data) {
    //Save the variant option info to the Variant Options table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // let body = {}


      let data_array = []
      for (var i = 0; i < variant_data.length; i++){
        for (var inner_index = 0; inner_index < optionValueList[i].length; inner_index++){

          data_array.push({name: optionValueList[i][inner_index], product_id: product_id, product_variant_id: variant_data[i].id, date_created: date_created, created_by: created_by})
        }
        // data_array[i] = {name: optionList[i], product_id: product_id, date_created: date_created, created_by: created_by}
        // data_array.push([optionList[i], product_id, date_created, created_by])
      }
      const body = {data: data_array}
      // console.log (JSON.parse(body.data))
      console.log ("____________________________")
      console.log (body)


      const response = await fetch(`${process.env.REACT_APP_API_URL}variant_option`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          console.log (data)
          for (var i = 0; i < data.data.length; i++) {
            message.success("Variant Option Information Saved to Variant Table with returning ID: " + data.data[i].id)
          }
          saveToVariantOptionValuesTable(product_id)
          // console.log (data.data.id)
          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      console.error(err.message);
    }
  }


  async function saveToVariantOptionValuesTable(product_id) {
    //Save the variant option info to the Variant Options table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // let body = {}


      let data_array = []
      for (var i = 0; i < optionValueMatrix.length; i++){
        const combo_name = optionValueMatrix[i].join(" - ")
          // const item_pic_url =  picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('value') ? picUrlList[combo_name].value : null
          //doesn't matter which object we use, they all have the same "active" status
          const item_active = quantityList[combo_name] && quantityList[combo_name].active === false ? false : true

          data_array.push({value: combo_name, product_id: product_id, active: item_active, date_created: date_created, created_by: created_by})

        // data_array[i] = {name: optionList[i], product_id: product_id, date_created: date_created, created_by: created_by}
        // data_array.push([optionList[i], product_id, date_created, created_by])
      }
      const body = {data: data_array}
      // console.log (JSON.parse(body.data))
      console.log ("____________________________")
      console.log (body)


      const response = await fetch(`${process.env.REACT_APP_API_URL}matrix`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          console.log (data)
          for (var i = 0; i < data.data.length; i++) {
            message.success("Matrix Item Saved to Matrix with returning ID: " + data.data[i].id)
          }
          saveToSkusTable(product_id)

          // console.log (data.data.id)
          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      console.error(err.message);
    }
  }


  async function saveToSkusTable(product_id) {
    //Save the variant option info to the Variant Options table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // let body = {}


      let data_array = []

      if (doesProductHaveOptions){
        for (var i = 0; i < optionValueMatrix.length; i++){
            const combo_name = optionValueMatrix[i].join(" - ")
            const item_quantity = quantityList[combo_name] && quantityList[combo_name].hasOwnProperty('value') ? quantityList[combo_name].value : null
            const item_sku =  skuList[combo_name] && skuList[combo_name].hasOwnProperty('value') ? skuList[combo_name].value : `TEMP${product_id}-${makeID(6)}`
            const item_barcode = barcodeList[combo_name] && barcodeList[combo_name].hasOwnProperty('value') ? barcodeList[combo_name].value : null
            const item_serial_run_limit = serialQuantityList[combo_name] && serialQuantityList[combo_name].hasOwnProperty('value') ? serialQuantityList[combo_name].value : null
            const item_pic_url =  picUrlList[combo_name] && picUrlList[combo_name].hasOwnProperty('value') ? picUrlList[combo_name].value : null

            // const item_matrix_id = matrix_data[i].id

            //doesn't matter which object we use, they all have the same "active" status
            const item_active = quantityList[combo_name] && quantityList[combo_name].active === false ? false : true

            data_array.push({combo_identifier: combo_name, quantity: item_quantity, sku: item_sku, barcode: item_barcode, serial_run_limit: item_serial_run_limit, product_id: product_id, active: item_active, pic_url: item_pic_url, date_created: date_created, created_by: created_by})

        }
      } else {
        const item_quantity = itemQuantity
        const item_sku = itemSku ? itemSku : `TEMP${product_id}-${makeID(6)}`
        const item_barcode = itemBarcode
        const item_serial_run_limit = itemSerialRunLimit ? itemSerialRunLimit : null
        const item_active = true
        const item_pic_url = itemPic ? itemPic : null

        data_array.push({combo_identifier: 'NO OPTIONS', quantity: item_quantity, sku: item_sku, barcode: item_barcode, serial_run_limit: item_serial_run_limit, product_id: product_id, active: item_active, pic_url: item_pic_url, date_created: date_created, created_by: created_by})
      }
      const body = {data: data_array}
      // console.log (JSON.parse(body.data))
      console.log ("____________________________")
      console.log (body)


      const response = await fetch(`${process.env.REACT_APP_API_URL}sku`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          console.log (data)
          for (var i = 0; i < data.data.length; i++) {
            message.success("SKU Item saved to SKUs with returning ID: " + data.data[i].id)
          }


            saveToPromoTable(product_id, data.data)


           console.log (data.data.id)
          // window.location = "/admin/products"
        }
        setLoading(false)
      })

    } catch (err) {
      console.error(err.message);
    }
  }


  async function saveToPromoTable(product_id, sku_data) {
    console.log (sku_data)
    //Save the variant option info to the Variant Options table and retrieve set of new PK ID
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date()
      // let body = {}


      let data_array = []
      if (doesProductHaveOptions){
        for (var i = 0; i < optionValueMatrix.length; i++){
            const combo_name = optionValueMatrix[i].join(" - ")
            const item_price = priceList[combo_name] && priceList[combo_name].hasOwnProperty('value') ? priceList[combo_name].value : null
            const item_price_effective_date =  promotionDateList[combo_name] && promotionDateList[combo_name].hasOwnProperty('value') ? promotionDateList[combo_name].value : null
            // const item_matrix_id = matrix_data[i].id
            const item_sku_id = sku_data[i].id

            //doesn't matter which object we use, they all have the same "active" status
            const item_active = quantityList[combo_name] && quantityList[combo_name].active === false ? false : true

            data_array.push({combo_identifier: combo_name, price: item_price, currency: 'USD', promotion_date: item_price_effective_date, product_id: product_id, sku_id: item_sku_id, active: item_active, date_created: date_created, created_by: created_by})

          // data_array[i] = {name: optionList[i], product_id: product_id, date_created: date_created, created_by: created_by}
          // data_array.push([optionList[i], product_id, date_created, created_by])
        }
      } else {
        const item_price = itemPrice
        const item_price_effective_date = promotion_date
        const item_sku_id = sku_data[0].id
        const item_active = true

        data_array.push({combo_identifier: 'NO OPTIONS', price: item_price, currency: 'USD', promotion_date: item_price_effective_date, product_id: product_id, sku_id: item_sku_id, active: item_active, date_created: date_created, created_by: created_by})


      }
      const body = {data: data_array}
      // console.log (JSON.parse(body.data))
      console.log ("____________________________")
      console.log (body)


      const response = await fetch(`${process.env.REACT_APP_API_URL}promo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.message)
        } else if (data.code == 1002) {
          message.error(data.message)

        } else {
          console.log (data)
          for (var i = 0; i < data.data.length; i++) {
            message.success("Promo Item saved to Promos with returning ID: " + data.data[i].id)
          }

          // console.log (data.data.id)
          setTimeout(function() {
            window.location = "/admin/products"
          }, 1000)

        }
        setLoading(false)
      })

    } catch (err) {
      console.error(err.message);
    }
  }

  // const handleSubmit_delete = async (e) => {
  //   e.preventDefault();
  //
  //   try {
  //     setLoading(true)
  //     const created_by = user.credentials.email
  //     const body = { date_created, created_by, name, description, brand_id  };
  //     console.log (body)
  //
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}p`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(body),
  //     }).then(response => response.json())
  //     .then(data =>{
  //       if (data.data.code == 409){
  //         message.error(data.data.msg)
  //       } else {
  //         window.location = "/admin/products"
  //       }
  //       setLoading(false)
  //     })
  //
  //
  //
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // };

  useEffect(() => {
    const title = "Luxanda | Create New Product"
    title && (document.title = title);

    getBrands()
  }, []);




  async function getBrands() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
    const brandsArray = await res.json()
    setBrands(brandsArray);
    setLoadingDropdowns(false)
  }

  // //GET ACTIVE URL
  // useEffect(() => {
  //
  //   //Navigate to the correct Page # from URL
  //   let pathArray = location.pathname.split('/')
  //   setCurrentPage(pathArray[0] + '/p/' + sku)
  // },[sku])


  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))


        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }
    }
  }

  const dropdown_brands = setSelectedId_View('brands')




  function setVariant_option_values_container(x, i_x) {
    // console.log (x)
    return (

        x.map((y, i_y) => (
        <div className="box" style={{display: 'flex'}}>
          <input
              name="variant_value"
              className={optionValueList[i_x][i_y] == 'DELETE' ? `form-control error` : (y == '' ? `form-control pending` : `form-control`)}
              type="text"
              placeholder={variant_list[i_x] && i_y == 0 ? variant_list[i_x].placeholder : "Add another value"}
              value={y}
              onChange={e => handleOptionValueInputChange(e, i_x, i_y)}
          />
          <div className="col-lg-6" style={{display:'flex'}}>
            {x.length !== 1 && <button className="ps-btn-delete" onClick={(e)=> handleRemoveOptionValueInput(e, i_x, i_y)}><i className='icon-cross'></i></button>}
            {x.length - 1 === i_y && <button className="ps-btn-delete" onClick={(e) => handleAddOptionValueInput(e, i_x)}><i className='icon-plus'></i> add another value</button>}
          </div>
        </div>)

      )

      )

  }





  const fieldChanged = (type, value) => {

    if (type == 'name'){
       setName(value);
    } else if (type == 'description'){
      setDescription(value);
    } else if (type == 'itemPrice'){
      setItemPrice(value)
    } else if (type == 'itemWarranty'){
      setItemWarranty(value)
    } else if (type == 'itemSku'){
      setItemSku(value)
    } else if (type == 'itemBarcode'){
      setItemBarcode(value)
    } else if (type == 'itemQuantity'){
      setItemQuantity(value)
    } else if (type == 'itemSerialRunLimit'){
      setItemSerialRunLimit(value)
    } else if (type == 'wc_url'){
      setWc_url(value)
    } else if (type == 'itemPic'){
      setItemPic(value)
    } else if (type == 'promotion_date'){
      const converted_date = value.toISOString()
      setPromotion_date(converted_date);
    } else if (type === 'doesProductHaveOptions'){
      setDoesProductHaveOptions(value)
      setSerialSelectionList([false])
      setIsThisProductSerialized(false)
      if (value == false){
        setOptionList([""])
        setOptionValueList([[""]]);
        setOptionValueList_temp([[""]])
        setOptionValueMatrix([])
        setSerialSelectionList([])
      }
    } else if (type === 'isThisProductSerialized'){
      setIsThisProductSerialized(value)

      const serial_selection_list = []
      for (var i = 0; i < optionList.length; i++){
        serial_selection_list.push(false)
      }
      setSerialSelectionList(serial_selection_list)

      if (value == false) {
        setSerialSelectionList([])
        //reset option selection
      }
    }

  };

  const handleSelect = (item, type) => {
    if (type == 'brand_id'){
      setBrand_id(item.target.value)
    }
  }


  const handleMakeVariantActive = (e, index) => {
    e.preventDefault();
    setPriceList({...priceList, [index]: {...priceList[index], active: true}})
    setQuantityList({...quantityList, [index]: {...quantityList[index], active: true}})
    setSkuList({...skuList, [index]: {...skuList[index], active: true}})
    setBarcodeList({...barcodeList, [index]: {...barcodeList[index], active: true}})
    setPromotionDateList({...promotionDateList, [index]: {...promotionDateList[index], active: true}})
    setPicUrlList({...picUrlList, [index]: {...picUrlList[index], active: true}})
    setSerialQuantityList({...serialQuantityList, [index]: {...serialQuantityList[index], active: true}})

  }
  const handleMakeVariantInactive = (e, index) => {
    e.preventDefault();
    setPriceList({...priceList, [index]: {...priceList[index], active: false}})
    setQuantityList({...quantityList, [index]: {...quantityList[index], active: false}})
    setSkuList({...skuList, [index]: {...skuList[index], active: false}})
    setBarcodeList({...barcodeList, [index]: {...barcodeList[index], active: false}})
    setPromotionDateList({...promotionDateList, [index]: {...promotionDateList[index], active: false}})
    setPicUrlList({...picUrlList, [index]: {...picUrlList[index], active: false}})
    setSerialQuantityList({...serialQuantityList, [index]: {...serialQuantityList[index], active: false}})
  }

  const handlePriceInputChange = (e, index) => {
    const { name, value } = e.target;
    // const item = {[index]: {value: value, active: false}};
    setPriceList({...priceList, [index]: {value: value, active: true}});
  }

  const handleQuantityInputChange = (e, index) => {
    const { name, value } = e.target;
    // const item = {[index]: value};
    setQuantityList({...quantityList, [index]: {value: value, active: true}});
  }

  const handleSkuInputChange = (e, index) => {
    const { name, value } = e.target;
    // const item = {[index]: value};
    setSkuList({...skuList, [index]: {value: value, active: true}});
  }
  const handleBarcodeInputChange = (e, index) => {
    const { name, value } = e.target;
    // const item = {[index]: value};
    setBarcodeList({...barcodeList, [index]: {value: value, active: true}});
  }

  const handlePromotionDateInputChange = (e, index) => {
    const  value  = e;
    const converted_date = value.toISOString()
    // setPromotion_date(converted_date);
    // const item = {[index]: {value: value, active: false}};
    setPromotionDateList({...promotionDateList, [index]: {value: converted_date, active: true}});
  }

  const handleUrlInputChange = (e, index) => {
    const { name, value } = e.target;
    // const item = {[index]: value};
    setPicUrlList({...picUrlList, [index]: {value: value, active: true}});
  }

  const handleSerialQuantityInputChange = (e, index) => {
    const {name, value} = e.target;

    setSerialQuantityList({...serialQuantityList, [index]: {value: value, active: true}})
  }



  const handleOptionValueInputChange = (e, index_x, index_y) => {
    const { name, value } = e.target;


    const list = [...optionValueList];
    const list_temp = [...optionValueList_temp];



    var valueExists = false
    for (var y = 0; y < list[index_x].length; y++){
      if (list[index_x][y] == value){
        valueExists = true
      }
    }

    console.log ("FOUND: " + valueExists)

    list_temp[index_x][index_y] = value


    if (!valueExists){
      list[index_x][index_y] = value;
    } else {
      list[index_x][index_y] = "DELETE";
    }

    // console.log (list)
    // console.log (list_temp)

    setOptionValueList_temp(list_temp)
    setOptionValueList(list);
    makeVariantArray(list)
  }


  const handleOptionInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...optionList];
    list[index] = value;
    setOptionList(list);

  }

  const handleRemoveOptionValueInput = (e, index_x, index_y) => {
    e.preventDefault();
    const list = [...optionValueList]
    list[index_x].splice(index_y, 1)
    const list_temp = [...optionValueList_temp]
    list_temp[index_x].splice(index_y, 1)
    setOptionValueList(list)
    setOptionValueList_temp(list_temp)




    makeVariantArray(list)
  }

  const handleRemoveOptionInput = (e, index) => {
    e.preventDefault();
    const option_list = [...optionList]
    option_list.splice(index, 1)

    const option_value_list = [...optionValueList]
    option_value_list.splice(index, 1)

    const option_value_list_temp = [...optionValueList_temp]
    option_value_list_temp.splice(index, 1)

    const serial_selection_list = [...serialSelectionList]
    serial_selection_list.splice(index, 1)

    setOptionList(option_list)
    setOptionValueList(option_value_list)
    setOptionValueList_temp(option_value_list_temp)
    setSerialSelectionList(serial_selection_list)
    makeVariantArray(option_value_list)
  }

  const handleAddOptionValueInput = (e, index_x) => {
    e.preventDefault();
    const list = [...optionValueList]
    for (var i = 0; i < list.length; i++){
    }
    list[index_x].push("")

    const list_temp = [...optionValueList_temp]
    for (var i = 0; i < list_temp.length; i++){
    }
    list_temp[index_x].push("")

    setOptionValueList(list)
    setOptionValueList_temp(list_temp)


  }

  const handleAddOptionInput = (e) => {
    e.preventDefault();
    setOptionList([...optionList, ""])
    setOptionValueList([...optionValueList, [""]])
    setOptionValueList_temp([...optionValueList_temp, [""]])
    setSerialSelectionList([...serialSelectionList, false])
  }

  const isListValid = (list) => {
    var foundValidValue = false
    for (var i = 0; i < list.length; i++){
      if (list[i] != '' && list[i] != 'DELETE'){
        foundValidValue = true

      }
    }
    // console.log ('FOUND? ' + foundValidValue)
    return foundValidValue
  }

  // const handleSerialSelectionListChange = (e, index) => {
  //   console.log (e)
  //   console.log (index)
  //   const { id, checked } = e.target;
  //   const list = [...serialSelectionList];
  //   list[index] = checked;
  //   setSerialSelectionList(list);
  // }

  // const handleSerialOptionSelect = (e, selected_index) => {
  //
  //   const { id } = e.target
  //   const list = [...serialSelectionOption]
  //   for (var inner_index = 0; inner_index < serialSelectionOption.length; inner_index++){
  //     list[inner_index].value = false
  //   }
  //   list[selected_index].value = true
  //
  //   setSerialSelectionOption(list)
  //   //
  //   // document.getElementById("list-all").classList.remove("active")
  //   // document.getElementById("list-option").classList.remove("active")
  //   // document.getElementById(id).classList.add("active")
  // }
//   const adjustPriceList = (index_x, index_y, action, matrixArray) => {
//     console.log ("Action: " + action)
//     console.log ("Number of variants: " + optionValueMatrix.length)
//     console.log (matrixArray)
//     console.log (optionValueList)
//     console.log (optionValueList[index_x][index_y])
//     const value = optionValueList[index_x][index_y]
//     console.log(value)
//
//     console.log ("PRICE LIST PRE")
//     console.log (priceList)
//     console.log (index_x + ", " + index_y)
//
//     var positionFactor
//     var totalNumOfElements
//     var numOfElementsToAdd = 1
//     var indexToInsert
//
//
//     var depthOfAddition
//     if (index_x = 0){
//       depthOfAddition = 1 * index_y
//     } else if (index_y = 0){
//       depthOfAddition = 1 * index_x
//     } else {
//       depthOfAddition = 1
//     }
//     console.log ("depth: " + depthOfAddition)
//
//     var temp_priceList = [...priceList]
//     // if (matrixArray.length == 0){
//     //   for (var i = 0; i < matrixArray.length; i++){
//     //     temp_priceList.push([""])
//     //   }
//     // }
//
//     console.log (temp_priceList)
//
// console.log ("LENGTH AT I: " + optionValueList[0].length)
//     for (var i = 0; i <= index_x ; i++){
//       console.log ("LENGTH AT I: " + optionValueList[i].length)
//       if (index_x > 0){
//         numOfElementsToAdd = numOfElementsToAdd * optionValueList[i].length
//       } else {
//         numOfElementsToAdd = 0
//       }
//       // if (numOfElementsToAdd == 0){
//       //   numOfElementsToAdd = 1
//       // }
//       console.log (optionValueList)
//     }
//
//
// console.log (optionValueList)
//     console.log (optionValueList[index_x][index_y])
//
//     if (action == "add"){
//
//       for (var i = 0; i < numOfElementsToAdd; i++){
//         temp_priceList.splice(depthOfAddition * (i + 1), 0, value)
//         console.log ("adding: "+ value + " to index: " + depthOfAddition * (i + 1))
//       //  temp_priceList.splice((index_x + 1)*(index_y + 1), 0, "input: ")
//       }
//     } else if (action == "remove"){
//       for (var i = 0; i < numOfElementsToAdd; i++){
//         temp_priceList.splice(depthOfAddition * (i + 1), 1)
//         // temp_priceList.splice((index_x + 1)*(index_y + 1), 1)
//       }
//     }
//     setPriceList(temp_priceList)
//
//     console.log ("numOfElementsToAdd: " + numOfElementsToAdd)
//
//
//
//
//
//
//     // if (optionValueMatrix){
//     //   totalNumOfElements = optionValueMatrix.length
//     //   console.log ("num of elements: " + totalNumOfElements)
//     //
//     //   for (var i = 0; i < index_x ; i++){
//     //     numOfElementsToAdd = numOfElementsToAdd * optionValueMatrix[i].length
//     //   }
//     //   // //Lower limit (first element, only 1 in size)
//     //   // if (index_x == 0 && variantMatrix[index_x].length == 1){
//     //   //   positionFactor = 2
//     //   // }
//     //   // if (index_x)
//     // } else {
//     //   priceList.push("")
//     // }
//
//     console.log ("PRICE LIST POST")
//     console.log (temp_priceList)
//
//   }


  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solid #dee2e6',
    minWidth: '500px',
  }



  const frozenColumn_left = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    minWidth:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(27px + 5rem)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  const dateFormat = 'YYYY-MM-DD'

  // console.log ("savinngggg...")
  // console.log ("name: " + name)
  // console.log ("brand: " + brand_id)
  // console.log ("description: " + description)
  // console.log ("doesProductHaveOptions: " + doesProductHaveOptions)
  //
  // console.log ("optionList: ")
  // console.log (optionList)
  // console.log ("optionValueList: ")
  // console.log (optionValueList)
  // console.log ("combo: " )
  // console.log(optionValueMatrix)
  //
  // console.log ("price: ")
  // console.log (priceList)
  // console.log ("effective dates: ")
  // console.log (promotionDateList)
  // console.log ("quantityList: ")
  // console.log (quantityList)
  // console.log ("skuList: ")
  // console.log (skuList)
  // console.log ("barcodeList: ")
  // console.log (barcodeList)
  // console.log ("serial selection list:")
  // console.log (serialSelectionList)
  // console.log ("serialQuantityList:")
  // console.log (serialQuantityList)
  // console.log ("picUrlList")
  // console.log (picUrlList)


  console.log ("----------------------------------- ")
console.log ("optionList: ")
console.log (optionList)

console.log ("optionValueList_temp: ")
console.log (optionValueList_temp)
console.log ("optionValueList: ")
console.log (optionValueList)
console.log ("optionValueMatrix: " )
console.log(optionValueMatrix)
console.log ("price: ")
console.log (priceList)
console.log ("quantityList: ")
console.log (quantityList)
console.log ("skuList: ")
console.log (skuList)
console.log ("barcodeList: ")
console.log (barcodeList)
console.log ("serial selection list:")
console.log (serialSelectionList)
console.log ("serialQuantityList:")
console.log (serialQuantityList)
console.log ("picUrlList")
console.log (picUrlList)







    return (
      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Add New Product</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                  <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Product Information</h3></label>
                        </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form">Brand</label>


                          {loadingDropdowns ? <div>Loading...</div> :

                            <select className="form-control" defaultValue={0} value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                                {brands.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                              {dropdown_brands}
                            </select>}

                      </div>
                  </div>

                  <div className="separator"></div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product Name</label>
                            <input
                                className={name ? `form-control` :  `form-control error`}
                                type="text"
                                placeholder="Product Title"
                                value={name}
                                onChange={e => fieldChanged('name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product Description</label>
                            <textarea
                                className={description ? `form-control` :  `form-control error`}
                                type="text"
                                rows="3"
                                placeholder="Product Description"
                                value={description}
                                onChange={e => fieldChanged('description', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="separator"></div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product Warranty</label>
                            <textarea
                                className={`form-control`}
                                style={{resize: 'vertical', transition: 'all 0s ease'}}
                                type="text"
                                rows="5"
                                placeholder="Product Warranty"
                                value={itemWarranty}
                                onChange={e => fieldChanged('itemWarranty', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="separator"></div>

                  </div>

                  <div className="row" style={{backgroundColor: '#f1f1f1', paddingTop: '2.5rem', borderRadius: '4px'}}>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3 style={{color: '#d3d3d3'}}>Mini-Program Information</h3></label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">
                            <Tooltip placement="topRight" title="This is the Query portion of the Mini-Program pagepath after the 'ID' marker: /pages/product/show/main.html?id=62aa4b9885eb4df50ec8cf71">
                              <InfoCircleOutlined />
                            </Tooltip> Mini-Program Scheme Query
                          </label>
                          <input
                              className="form-control"
                              style={{backgroundColor: 'white'}}
                              type="text"
                              placeholder="62aa4b9885eb4df50ec8cf71"
                              value={wc_url}
                              onChange={e => fieldChanged('wc_url', e.target.value)}
                          />
                      </div>
                    </div>

                  </div>


                </div>









                <div className="ps-form__content" style={{marginTop: '50px'}}>

                    <div className="row">
                      <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form"><h3>Options</h3></label>
                            <div className="ps-checkbox" style={{marginTop: '10px'}}>
                              <input
                                className="form-control"
                                type="checkbox"
                                id="product-options"
                                checked={doesProductHaveOptions}
                                onChange={e => fieldChanged('doesProductHaveOptions', e.target.checked)}
                                />
                              <label for="product-options">Does this product have options?</label>
                            </div>
                          </div>
                      </div>


                    </div>



                      {doesProductHaveOptions ? optionList.map((x, i_x) => {
                        return (
                          <div className="row">
                            <div className="separator"></div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form">Option Name {i_x + 1}</label>
                                    <div className="box" style={{display: 'flex'}}>
                                      <input
                                          name="variant_option"
                                          className={optionList[i_x] && isListValid(optionValueList[i_x]) ? `form-control` :  `form-control error`}
                                          type="text"
                                          placeholder={variant_list[i_x] ? variant_list[i_x].name : "Another Option Name"}
                                          value={x}
                                          onChange={e => handleOptionInputChange(e, i_x)}
                                      />
                                      <div className="col-lg-6" style={{display:'flex'}}>
                                        {optionList.length !== 1 && <button className="ps-btn-delete" onClick={(e)=> handleRemoveOptionInput(e, i_x)}><i className='icon-cross'></i></button>}
                                      </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form">Option Values</label>
                                    {setVariant_option_values_container(optionValueList_temp[i_x], i_x)}

                                </div>

                            </div>
                            {optionList.length - 1 === i_x &&
                              <div className="col-lg-6" >
                                 <button className="ps-btn-delete" onClick={(e) => handleAddOptionInput(e)}><i className='icon-plus'></i> add another option</button>
                              </div>}
                        </div>

                      )
                    }) : null}


            </div>










            {doesProductHaveOptions ? <div className="ps-form__content" style={{marginTop: '50px'}}>





                <div className="row">
                  <div className="col-lg-6">
                      <div className="form-group" style={{margin:'0px'}}>
                        <label className="form"><h3>Variants</h3></label>
                        <div className="ps-checkbox" style={{marginTop: '10px', marginBottom: '2.5rem'}}>
                          <input
                            className="form-control"
                            type="checkbox"
                            id="serial-option"
                            name="serial-option"
                            checked={isThisProductSerialized}
                            onChange={e => fieldChanged('isThisProductSerialized', e.target.checked)}
                            />
                          <label for="serial-option">Is this product serialized by SKU?</label>
                        </div>

                      </div>
                  </div>

                </div>







          <div className="row">




            <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>
            {optionValueMatrix.length != 0 ? <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
              <thead>
                <tr>
                  <th style={{position: 'sticky', left: '0', backgroundColor: '#f1f1f1'}}>Variant</th>
                  <th>Price</th>
                  <th>Effective Date</th>
                  <th>Quantity</th>
                  <th><Tooltip placement="topRight" title='If the brand is "King Mountain" and the SKU contains "MASK", the customer will be able to change the size of the product that they ordered before the Order has been Shipped'>
                    <InfoCircleOutlined />
                  </Tooltip> SKU</th>
                  <th>Barcode</th>
                  <th>Picture URL</th>
                  <th>Preview</th>
                  {isThisProductSerialized ? <th style={{position: 'sticky', right: 'calc(27px + 5rem)', backgroundColor: '#f1f1f1'}}>Serial Run Limit</th> : null}
                  <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}}></th>
                </tr>
              </thead>
              <tbody>
                {optionValueMatrix.map((comboSet, i) => (
                  <tr key={comboSet.join(' - ')}>
                    <td style={frozenColumn_left}><b>{comboSet.join(' - ')}</b></td>
                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                        <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <div className="input-group mb-2" >
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                                className="form-control"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder="0.00"
                                readOnly={priceList[comboSet.join(' - ')] && priceList[comboSet.join(' - ')].active == false ? true : false}
                                value={priceList[comboSet.join(' - ')] ? priceList[comboSet.join(' - ')].value : null}
                                onChange={e => handlePriceInputChange(e, comboSet.join(' - '))}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">USD</span>
                            </div>
                          </div>
                        </div>
                    </td>

                    <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        <div className="ant-calendar-picker">
                            <DatePicker allowClear={false}
                            disabled={promotionDateList[comboSet.join(' - ')] ? !promotionDateList[comboSet.join(' - ')].active : null}
                            defaultValue={promotionDateList[comboSet.join(' - ')] ? moment(promotionDateList[comboSet.join(' - ')].value) : null}
                            onChange={e => handlePromotionDateInputChange(e, comboSet.join(' - '))}/>
                        </div>
                      </div>
                    </td>


                    <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id="input_table_quantity"
                              placeholder="0"
                              readOnly={quantityList[comboSet.join(' - ')] && quantityList[comboSet.join(' - ')].active == false ? true : false}
                              value={quantityList[comboSet.join(' - ')] ? quantityList[comboSet.join(' - ')].value : null}
                              onChange={e => handleQuantityInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="SKU012345"
                              readOnly={skuList[comboSet.join(' - ')] && skuList[comboSet.join(' - ')].active == false ? true : false}
                              value={skuList[comboSet.join(' - ')] ? skuList[comboSet.join(' - ')].value : null}
                              onChange={e => handleSkuInputChange(e, comboSet.join(' - '))}
                          />

                      </div>
                    </td>
                    <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="9102132512"
                              readOnly={barcodeList[comboSet.join(' - ')] && barcodeList[comboSet.join(' - ')].active == false ? true : false}
                              value={barcodeList[comboSet.join(' - ')] ? barcodeList[comboSet.join(' - ')].value : null}
                              onChange={e => handleBarcodeInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="www.tencent.com/..."
                              readOnly={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].active == false ? true : false}
                              value={picUrlList[comboSet.join(' - ')] ? picUrlList[comboSet.join(' - ')].value : null}
                              onChange={e => handleUrlInputChange(e, comboSet.join(' - '))}
                          />
                      </div>
                    </td>
                    <td style={{minWidth:'100px', verticalAlign: 'middle'}}>
                      <img src={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].value ? picUrlList[comboSet.join(' - ')].value : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                      style={{display: 'block',
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          filter: (picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].active == false) ? 'grayscale(1)' : 'none',
                        }} alt="CBR0" border="0" />
                    </td>
                    {isThisProductSerialized ?
                      <td style={frozenColumn_right_second}>
                        <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                            <input
                                className="form-control"
                                style={{backgroundColor: serialQuantityList[comboSet.join(' - ')] && serialQuantityList[comboSet.join(' - ')].active == false ? '#e9ecef' : "white"}}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder="0"
                                readOnly={serialQuantityList[comboSet.join(' - ')] && serialQuantityList[comboSet.join(' - ')].active == false ? true : false}
                                value={serialQuantityList[comboSet.join(' - ')] ? serialQuantityList[comboSet.join(' - ')].value : null}
                                onChange={e => handleSerialQuantityInputChange(e, comboSet.join(' - '))}
                            />


                        </div>
                      </td> : null}

                    <td style={frozenColumn_right}>
                      {priceList[comboSet.join(' - ')] && priceList[comboSet.join(' - ')].active == false ?
                      <button className="ps-btn-table-control" onClick={e => handleMakeVariantActive(e, comboSet.join(' - '))}>
                          <i className='icon-undo'></i>
                      </button>
                      :
                      <button className="ps-btn-table-control" onClick={e => handleMakeVariantInactive(e, comboSet.join(' - '))}>
                          <i className='icon-cross'></i>
                      </button>}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table> :

              <div className="col-lg-12">
                <div className="form-group" style={{marginTop: '3rem', marginBottom: '5rem'}}>
                  <label className="form">Please enter all of the applicable product options in order to generate the variant matrix.</label>
                </div>
              </div>}





            </div>

          </div>

        </div> :null }


        {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form"><h3>Picture</h3></label>
              </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                  <div style={thumb} >
                    <div style={thumbInner}>
                      <img src={itemPic} style={img} alt="CBR0" border="0" />
                    </div>
                  </div>
                </div>
            </div>
            <div style={{height: '5vh'}}/>


            <div className="col-lg-12">
              <div className="form-group">
                <label className="form">Picture Address</label>
                <div className="input-group mb-2" >
                  <div className="input-group-prepend">
                    <span className="input-group-text">URL</span>
                  </div>
                  <input
                      className="form-control"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      placeholder="https://www.luxanda.cn/static/imgs/products/CBR1.jpg"
                      value={itemPic}
                      onChange={e => fieldChanged('itemPic', e.target.value)}
                  />

                </div>
              </div>
            </div>




          </div>
        </div> }


        {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form"><h3>Pricing</h3></label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form">Price</label>
                <div className="input-group mb-2" >
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                      className="form-control"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0.00"
                      value={itemPrice}
                      onChange={e => fieldChanged('itemPrice', e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">USD</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form">Effective Date</label>
                <div className="ant-calendar-picker">
                    <DatePicker
                    allowClear={false}
                        onChange={e => fieldChanged('promotion_date', e)}
                    />
                </div>
              </div>
            </div>



          </div>
        </div> }



        {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form"><h3>Inventory</h3></label>
              </div>
            </div>




            <div className="col-lg-6">
              <div className="form-group">
              <label className="form"><Tooltip placement="topRight" title='If the brand is "King Mountain" and the SKU contains "MASK", the customer will be able to change the size of the product that they ordered before the Order has been Shipped'>
                <InfoCircleOutlined />
              </Tooltip> Product SKU</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="SKU012345"
                    value={itemSku}
                    onChange={e => fieldChanged('itemSku', e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className="form">Product Barcode</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="9102132512"
                    value={itemBarcode}
                    onChange={e => fieldChanged('itemBarcode', e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className="form">Quantity</label>
                <input
                    className="form-control"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="0"
                    value={itemQuantity}
                    onChange={e => fieldChanged('itemQuantity', e.target.value)}
                />
              </div>
            </div>
            <div className="separator"></div>

            <div className="col-lg-6">
                <div className="form-group" style={{margin:'0px'}}>
                  <div className="ps-checkbox" style={{marginTop: '10px', marginBottom: '2.5rem'}}>
                    <input
                      className="form-control"
                      type="checkbox"
                      id="serial-option"
                      name="serial-option"
                      checked={isThisProductSerialized}
                      onChange={e => fieldChanged('isThisProductSerialized', e.target.checked)}
                      />
                    <label for="serial-option">Is this product serialized by SKU?</label>
                  </div>

                </div>
            </div>
            <div className="col-lg-6"></div>
            {isThisProductSerialized ?
            <div className="col-lg-6">
              <div className="form-group">
              <label className="form">Serial Run Limit</label>
                <input
                    className="form-control"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="0"
                    value={itemSerialRunLimit}
                    onChange={e => fieldChanged('itemSerialRunLimit', e.target.value)}
                />
              </div>
            </div> : null}

          </div>
        </div>}
        <div className="form-group submit" style={{marginTop:"5rem"}}>
            <button
              className="ps-btn"
              onClick={e => handleSubmit(e)}
              disabled={loading}
              >
              {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Save Product')}
            </button>
        </div>


        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormNewProduct;
