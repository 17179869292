import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import { connect } from 'react-redux'
import { assignHashToUser } from '../../redux/actions/userActions'
import { saveDeviceActions } from '../../utilities/common-helpers'
import { VerifiedOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { countryFlags } from './data/country-flags'
import TextareaAutosize from 'react-textarea-autosize';


import { FormattedMessage } from "react-intl"

// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'
import { Popconfirm, message } from 'antd'



const Link = require("react-router-dom").Link

const mapState = ({ user }) => ({
  user: user,
})

const CustomerSkuView = ({title}) => {

  const dispatch = useDispatch();
  const { user } = useSelector(mapState)

  // const [url, setUrl] = useState({})
  const [urlDetails, setURLDetails] = useState({})
  const [product, setProduct] = useState({});
  const [sku_details , setSku_details] = useState([]);
  const [loading, setLoading] = useState(true)


  const location = useLocation()
  const pathArray = location.pathname.split('/')





  async function getURLDetails() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/ugh/${pathArray[2]}`)
    const urlArray = await res.json();
    setURLDetails(urlArray);

    if (urlArray.sku_id){
      getSku(urlArray.sku_id,  urlArray.url_item_type)
      // message.success("Product Found")
    } else {
      message.error("Sorry, no product information was found.")
    }
    setLoading(false)
  }

  async function getProduct(id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/pid/${id}`)
    const productArray = await res.json();

    const title = "Luxanda | " + productArray.brand_name + ": " + productArray.name + " (Warranty Page)"
    title && (document.title = title);

    
    setProduct(productArray);
    setLoading(false)


    // getBrand(productArray.brand_id)
    // getPromotion(productArray.promotion_id)
  }

  async function getSku(record_id, url_item_type) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/id/${record_id}`)
    const skuArray = await res.json();
    setSku_details(skuArray);
    getProduct(skuArray.product_id)
    setLoading(false)
    saveDeviceActions(location.pathname, "User Visit - Warranty Page - sku_id: " + record_id, url_item_type, null, null )



    // getBrand(productArray.brand_id)
    // getPromotion(productArray.promotion_id)
  }


  useEffect(() => {
    getURLDetails();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();

  }

  function handleSubmit(e) {
    // console.log ("navigating")
  }

  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '30%',
    minWidth: '150px',
    objectFit: 'cover',
  };

  // console.log ('loading ' + loading)






  return (
    <section className="ps-my-account ps-page--account">
    <div className="container">
        <div className="row">



          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">



                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>
                        <FormattedMessage
                          id="title_warranty_information"
                        />

                      </h3>
                    </div>


                  </div>
                </div>

                <div className="ps-form__content">


                  {!loading ?
                  <div className="row" style={{placeContent: 'center'}}>
                      <div className="col-lg-2">
                          <div className="form-group">
                            <img src={sku_details && sku_details.pic_url ? sku_details.pic_url : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                            style={{display: 'block',
                                maxWidth: '100px',
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover',
                                margin: 'auto',
                              }} alt={sku_details && sku_details.pic_url ? sku_details.pic_url : 'Not Found'} border="0" />
                          </div>
                      </div>

                      <div className="col-lg-10">
                          <div className="form-group" style={{marginBottom: '0px'}}>
                              <div style={{display: 'block'}}>
                                {sku_details && sku_details.combo_identifier != 'NO OPTIONS' ?
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <i className='icon-chevron-right' style={{fontSize: '16px', fontWeight: '600', color: '#ffb81c'}}></i>
                                    <input
                                        className="form-control-plaintext"
                                        style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginBottom: '0px', color: '#ffb81c'}}
                                        type="text"
                                        placeholder={null}
                                        readOnly={true}
                                        value={sku_details && sku_details.combo_identifier ? sku_details.combo_identifier: ''}
                                    />
                                </div> : null}
                                <input
                                    className="form-control-plaintext"
                                    style={{backgroundColor: "white", fontSize: '26px', fontWeight: '600', padding: '0px'}}
                                    type="text"
                                    placeholder={null}
                                    readOnly={true}
                                    value={product && product.name ? product.name : ''}
                                />
                                <div
                                    className="form-control-plaintext"
                                    style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginTop: '-7px'}}
                                    type="text"
                                    placeholder={null}
                                    readOnly={true}
                                >
                                  <FormattedMessage
                                    id="product_by"
                                  />
                                  {product && product.brand_name ? ' ' + product.brand_name : ''}
                                </div>
                                {product && product.flag_serialization ?
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <CheckCircleOutlined style={{ fontSize: '14px', color: 'lightgrey'}} />
                                  <input
                                      className="form-control-plaintext"
                                      style={{backgroundColor: "white", fontSize: '14px', padding: '0px', color: 'lightgrey', fontStyle: 'italic', fontWeight: '600'}}
                                      type="text"
                                      placeholder={null}
                                      readOnly={true}
                                      value={product && product.flag_serialization ? ' Limited Production of ' + sku_details.serial_run_limit : ''}
                                  />

                                </div> : null}
                                <div
                                    className="form-control-plaintext"
                                    style={{backgroundColor: "white", fontSize: '12px', padding: '0px', color: 'lightgrey', fontStyle: 'italic'}}
                                    type="text"
                                    placeholder={null}
                                    readOnly={true}
                                  >
                                  <FormattedMessage
                                    id="product_sku"
                                  />
                                  {sku_details && sku_details.sku ? ': ' + sku_details.sku : ''}
                                </div>
                              </div>
                          </div>
                      </div>

                      <div className="separator" style={{margin: '15px 15px 35px'}}></div>



                </div> : <input
                            className="form-control-plaintext"
                            style={{backgroundColor: "white", fontSize: '26px', fontWeight: '600', padding: '0px'}}
                            type="text"
                            placeholder="Loading..."
                            readOnly={true}
                            value=''
                        />}


                    {!loading ?
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                      {product && product.warranty_information ?
                      <div className="col-lg-8">
                          <div className="form-group">
                            <TextareaAutosize
                                className="form-control-plaintext"
                                readOnly={true}
                                style={{display: 'block', backgroundColor: "white", fontSize: '12px', padding: '0px', height: 'auto', resize: 'none'}}
                                value={product && product.warranty_information ? product.warranty_information : ''}

                            />
                          </div>
                      </div> : null}
                    </div> : null}

                    {!loading ?
                    <div className="col-lg-12">
                        <div className="form-group">
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img src={product && product.brand_country ? countryFlags[product.brand_country].mini : null} style={{height: '12px'}}></img>
                            <div className="form-control-plaintext" style={{backgroundColor: "white", fontSize: '12px', padding: '0px', fontWeight: '600', fontStyle: 'bold', marginTop: '5px', width: 'auto'}}>
                            {product && product.brand_country ? 'Product of ' + product.brand_country : null}
                            </div>
                          </div>
                        </div>
                    </div> : null}

                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




    </div>
</section>

  );

}


export default CustomerSkuView;
