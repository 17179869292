import React, { Component, useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Popconfirm, message } from 'antd'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/orderDetail/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'
import { saveDeviceActions } from '../../utilities/common-helpers'

// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const mapState = ({ user }) => ({
  user: user,
})

const CustomerOrderDetailView = ({title}) => {

  const {user } = useSelector(mapState)
  console.log (user)

  const [orderDetail, setOrderDetail] = useState({});
  const [product, setProduct] = useState({})
  const [order, setOrder] = useState({})
  const [promotion, setPromotion] = useState({})
  const [customer, setCustomer] = useState({})
  const [brand, setBrand] = useState({})
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const pathArray = location.pathname.split('/')

  function dialog_confirm (e) {
    console.log (e)
    // cancelOrderByID(order.id);
    message.success('Order Updated')
  }

  function dialog_cancel (e) {
    console.log (e)
    message.error('Cancelled')
  }


  async function getOrderDetail() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/id/${pathArray[4]}`)
    const orderDetailArray = await res.json();
    console.log (orderDetailArray)
    saveDeviceActions(location.pathname, "Navigation: Order Detail: " + orderDetail.id, pathArray[5])
    setOrderDetail(orderDetailArray);
    getOrder(orderDetailArray.order_id)
    getProduct(orderDetailArray.product_id)
    getPromotion(orderDetailArray.promotion_id)
  }

  async function getOrder(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/id/${id}`)
    const orderArray = await res.json();
    console.log (orderArray)
    setOrder(orderArray);
    getCustomer(orderArray.customer_id)

  }

  async function getProduct(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${id}`)
    const productArray = await res.json();
    console.log (productArray)
    setProduct(productArray);
    getBrand(productArray.brand_id)
  }

  async function getPromotion(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}promo/id/${id}`)
    const promotionArray = await res.json();
    console.log (promotionArray)
    setPromotion(promotionArray);
  }

  async function getCustomer(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${id}`)
    const customerArray = await res.json();
    console.log (customerArray)
    setCustomer(customerArray);
  }

  async function getBrand(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/id/${id}`)
    const brandArray = await res.json();
    console.log (brandArray)
    setBrand(brandArray);
  }

  useEffect(() => {
    // console.log(user)
    if (user.orderHash !== pathArray[2]){
      window.location = `/o/${pathArray[2]}`
    } else {
      getOrderDetail();
    }
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();

  }

  function handleSubmit(e) {
    console.log ("navigating")
  }

  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '500px',
    maxHeight: '500px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const row = {
    justifyContent: 'center',
  }

  let orderDetailView;
  if (orderDetail){
    orderDetailView = (
      <div className="row" style={row}>








        <div className="col-lg-12">
          <div class="text-center">
            <img src={product.pic} class="rounded" style={img} alt="CBR0" border="0" />
          </div>
        </div>

        <div className="col-lg-6">
        <div className="form-group">
          <label className="form">Product Information</label>
        </div>
          <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"  id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2">
            <div class="table-responsive">
              <table class="table table-bordered ps-table ps-table--specification">
                <tbody>

                  <tr>
                    <td>Product Name</td>
                    <td>{product.name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{product.description}</td>
                  </tr>
                  <tr>
                    <td>Variant</td>
                    <td>{product.variant}</td>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <td>{product.sku}</td>
                  </tr>
                  <tr>
                    <td>Brand</td>
                    <td>{brand.name}
                    </td>
                  </tr>
                  <tr>
                    <td>Brand Country</td>
                    <td>{brand.country}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
        </div>




<div class="separator"></div>
        <div className="col-lg-12">
          {order.order_status == 'Awaiting Fulfillment' ?
          <Popconfirm
            title="Are you sure you want to cancel this order?"
            onConfirm={dialog_confirm}
            onCancel={dialog_cancel}
            okText="Yes"
            cancelText="No"
          >
            <div style={{display: 'flex', width: '100%', placeContent: 'center'}}>
              <button
                className="ps-btn ps-btn--gray"

                disabled={loading}
                >
                {loading ? (<>Processing... <LoadingOutlined size="large"/></>) : ('Cancel Order')}
              </button>
            </div>
          </Popconfirm> : null
          }

        </div>


      </div>

    )
  } else {
    orderDetailView = (<div>No Order Detail Found</div>)
  }






  return (
    <section className="ps-my-account ps-page--account">
    <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >

                <div className="ps-form__content">



                  {orderDetailView}

                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




    </div>
</section>

  );

}


export default CustomerOrderDetailView;
