import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import jwtDecode from 'jwt-decode'
import axios from 'axios'


import {SET_AUTHENTICATED} from './redux/types'
import { signoutUser, getUserData } from './redux/actions/userActions'

// const cors = require("cors");
//
// const corsOptions ={
//    origin:['https://wwwcie.ups.com/ship/v1/shipments'],
//    credentials:true,            //access-control-allow-credentials:true
//    optionSuccessStatus:200,
// }
// //middleware
// App.use(cors(corsOptions));


// require('../env.js')
// import thunk from "redux-thunk"
// import { addLocaleData } from "react-intl"
// import language_cn from "./locale/cn/translation"
// import language_en from "./locale/en/translation"

// const store = createStore(rootReducer, applyMiddleware(thunk))
// addLocaleData([...language_en, ...language_cn])
// let authenticated
const token = localStorage.FBIdToken;
if(token){
  const decodedToken = jwtDecode(token);
  // console.log(decodedToken)
  if(decodedToken.exp * 1000 < Date.now()){
    store.dispatch(signoutUser())
    window.location.href = '/signin'

  } else {
    store.dispatch({type: SET_AUTHENTICATED})
    axios.defaults.headers.common['Authorization'] = token
    store.dispatch(getUserData())
    // authenticated = true;
  }
} else {
  // console.log ('no token')
}

require('dotenv').config()

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Route component={App} />
    </Provider>


  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
