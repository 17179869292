let accountLinks = [
    {
        text: 'Account Information',
        url: '/account/account-information',
        icon: 'icon-user',
    },
    {
        text: 'Notifications',
        url: '/account/account-notifications',
        icon: 'icon-alarm-ringing',
    },
    {
        text: 'Invoices',
        url: '/account/invoices',
        icon: 'icon-papers',
    },
    {
        text: 'Address',
        url: '/account/addresses',
        icon: 'icon-map-marker',
    },
    {
        text: 'Recent Viewed Product',
        url: '/account/recent-viewed-product',
        icon: 'icon-store',
    },
    {
        text: 'Wishlist',
        url: '/account/wishlist',
        icon: 'icon-heart',
    },
];

exports.accountLinks = accountLinks
