import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import FormNewProduct from '../../shared/forms/FormNewProduct';
import { productSections } from './data/product-navigation'



const Link = require("react-router-dom").Link





const mapState = ({ user }) => ({
  user: user
})




const NewProductSectional = ({}) => {

  const {user } = useSelector(mapState)

  const [currentSection, setCurrentSection] = useState(1)
  const [activeForm, setActiveForm] = useState(<FormNewProduct userCredentials={user.credentials}/>)







  //
  // console.log ("PRODUCT SECTIONS")
  // console.log (productSections)

  useEffect(() => {
    productSections[0].active = true
    productSections[1].active = false
  },[])



  function handlePageSelection(selectedSection) {

    setCurrentSection(selectedSection)
    setSelectedSection ()
    setSelectedForm(selectedSection)
  }


    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         breadCrumb: [
    //             {
    //                 text: 'Home',
    //                 url: '/',
    //             },
    //             {
    //                 text: 'My Account',
    //             },
    //         ],
    //     };
    // }

    function handleSave(e) {
      console.log ("Saving...")
    }

    function setSelectedSection () {
      return productSections.map((item) => (
          <li key={item.order} className={currentSection == item.order ? 'active-custom' : '' } onClick={() => handlePageSelection(item.order)}>
              <a className="a-custom">
                  <div >
                      <i className={item.icon}></i>
                      {item.text}
                  </div>
                  </a>

          </li>
      ));
    }

    function setSelectedForm(selectedSection){
      // console.log (currentSection)
      switch (selectedSection) {
        case 1:
          setActiveForm(<FormNewProduct userCredentials={user.credentials}/>)
          // return 1;
          break;
        case 2:
          setActiveForm(null)
          // return 2;
          break;
        default:
          setActiveForm(<FormNewProduct userCredentials={user.credentials}/>)

      }
    }


    //Views
    const productSectionsView = setSelectedSection ()
    // const formView =  setSelectedForm()



    // console.log (productSectionsView)

        // let profileMarkup = !loading ? (authenticated ? (
        //   <div>AUTHENTICATED</div>
        // ) : (
        //   <div>Not authenticated, please signup/login</div>
        // )) : (<p>Loading...</p>)
  return (
          <section className="ps-my-account ps-page--account">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-3">
                          <div className="ps-section__left">
                              <aside className="ps-widget--account-dashboard">
                                  <div className="ps-widget__header">
                                      <img src={user.credentials.imageUrl} />
                                      <figure>
                                          <figcaption>Hello,</figcaption>
                                          <p>{user.credentials.email}</p>
                                      </figure>
                                  </div>
                                  <div className="ps-widget__content">
                                      <ul>
                                        {productSectionsView}


                                          <li>
                                              <Link className="a-custom" to="/" onClick={(e) => handleSave(e)}>
                                                  <div>
                                                      <i className="icon-power-switch"></i>
                                                      Save
                                                  </div>
                                              </Link>
                                          </li>
                                      </ul>
                                  </div>
                              </aside>
                          </div>
                      </div>
                      <div className="col-lg-9">
                          <div className="ps-page__content">
                          {activeForm}
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      );

}


export default NewProductSectional;
