import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'
import moment from 'moment'

import { connect } from 'react-redux'
import { assignHashToUser } from '../../redux/actions/userActions'

import { useLocation } from 'react-router-dom'
import { saveDeviceActions,formatter, formatPhoneNumber } from '../../utilities/common-helpers'

import { VerifiedOutlined, CheckCircleOutlined, WarningOutlined} from '@ant-design/icons'

import { useIntl, FormattedMessage } from "react-intl"




// const device = require('device-ip-location');

// import ShopItems from '../../components/partials/shop/ShopItems';

// var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link



const mapState = ({ user, lang }) => ({
  user: user,
  lang
})

const CustomerOrderView = ({title}) => {
  const intl = useIntl()
  const dateFormat = 'YYYY-MM-DD'
  const dispatch = useDispatch();
  const { user, lang } = useSelector(mapState)

  const [orderDetails, setOrderDetails] = useState([]);
  const [order, setOrder] = useState({})
  const [customer, setCustomer] = useState({})


  const [orderReferenceSnippet, setOrderReferenceSnippet] = useState(null)
  const [formState, setFormState] = useState("")

  const location = useLocation()
  const pathArray = location.pathname.split('/')
  const [dateChecked, setDateChecked] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [loadingSizeChange, setLoadingSizeChange] = useState(false)

  const [variantSelections, setVariantSelections] = useState([])
  const [errors, setErrors] = useState("")

   const [selected_sizes, setSelected_sizes] = useState([])
   const [submittedRequestFlags, setSubmittedRequestFlags] = useState([])

   const [deadline_date, setDeadline_date] = useState('')
   const [deadline_days_left, setDeadline_days_left] = useState('')
   const [isJumpPage, setIsJumpPage] = useState(false)
   const [loading_jumpPage, setLoading_jumpPage] = useState(false)

   const ua = navigator.userAgent.toLowerCase()
    const [isWXWork, setIsWXWork] = useState(ua.match(/wxwork/i) == 'wxwork')

    const [isWeixin, setIsWeixin] = useState(!isWXWork && ua.match(/micromessenger/i) == 'micromessenger')
    const [isMobile, setIsMobile] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)

    const [isCancelSelected, setIsCancelSelected] = useState(false)




  function dialog_confirm (e) {
    e.preventDefault(e)
    setIsCancelSelected(false)
    cancelOrderByID(order.id);

  }

  function dialog_cancel (e) {
    e.preventDefault(e)
    setIsCancelSelected(false)
    // console.log (e)
    // message.error(intl.formatMessage({ id: 'message_cancel_success' }))
  }

  async function getOrderByID(orderReferenceSnippet_value) {
    // console.log (orderReferenceSnippet_value)
    if (orderReferenceSnippet_value){
      // console.log (orderReferenceSnippet_value)
      setLoading(true)
      const res = await fetch(`${process.env.REACT_APP_API_URL}o/id_hash/${pathArray[2]}/${orderReferenceSnippet_value}`)
      const order_object = await res.json();
      // console.log ("order_object")
      // console.log (order_object)

      setLoading(false)




      if (order_object.length != 0 && res.ok){
        setFormState('found')
        setOrder(order_object[0])
        const deadline_date_data = moment(moment(order_object[0].tyl_date_delivered, "YYYY-MM-DD").add('days', 7).format(dateFormat))
        setDeadline_date(deadline_date_data)
        setDeadline_days_left(moment(deadline_date_data, dateFormat).diff( moment(), 'days'))

        // console.log (deadline_date_data)
        // console.log (moment(deadline_date_data, dateFormat).diff( moment(), 'days'))
        getCustomer(order_object[0].customer_id);
        getOrderDetailsByOrderID(order_object[0].id)
      } else {
         setFormState('')
      }

      if (!res.ok || order_object.length == 0){

        setErrors(intl.formatMessage({ id: 'message_order_not_found_try_again' }))
        // saveDeviceActions(location.pathname, "Search Order: Order Not Found", pathArray[4])
        saveDeviceActions(location.pathname, "User Visit - Order - Order Not Found - hash: " + pathArray[2], null, null, null )

      } else {
        // saveDeviceActions(location.pathname, "Navigation: Order Information Page (successful validation)", pathArray[4])
        saveDeviceActions(location.pathname, "User Visit - Order - Found - order_id: " + order_object[0].id + ' customer_id: ' + order_object[0].customer_id, null, null, null )
        // console.log ("Saving Hash to user prop: " + pathArray[3])
        const userOrderHash = pathArray[2]
        if (!user.orderHash){
          const title = "Luxanda | Order: " + order_object[0].order_number
          title && (document.title = title);

          dispatch({type:"SET_USER_HASH", payload:{orderHash: userOrderHash}})
          dispatch({type: "SET_USER_ORDER_REF", payload:{orderRef: orderReferenceSnippet}})
        }
        // assignHashToUser({orderHash: userOrderHash})
      }
    }

  }

  async function cancelOrderByID(order_id) {
    try {
      setLoading(true)
      const order_status = "Cancelled by Customer"
      const date_cancelled = new Date()


      const body = { date_cancelled, order_status };
      // console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}o/id/${order_id}/a=cancel`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        setLoading(false)
      })

      // window.location = "/admin/orders";
      saveDeviceActions(location.pathname, "User Cancelled order_id: " + order_id, null, null, null )
      message.success(intl.formatMessage({ id: 'message_cancel_success' }))
      getOrderByID(user.orderRef);
    } catch (err) {
      console.error(err.message);
    }
  };

  async function getOrderDetailsByOrderID(order_id) {
    setLoadingProperties(true)
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/oid/${order_id}/v=c`)
    const orderDetailsArray = await res.json()

    let variant_selection = []

    // console.log (orderDetailsArray)
    setOrderDetails(orderDetailsArray)
    // console.log (variant_selection)

    try {
      for (var i = 0; i < orderDetailsArray.length; i++){
        variant_selection.push([])
        const combo_identifier_parse = orderDetailsArray[i].combo_identifier.split(" - ")

        const variant_option_data = await getVariantByProductIDandName(orderDetailsArray[i].product_id, 'Size', combo_identifier_parse[1])

        // console.log (variant_option_data)


        for (var x = 0; x < variant_option_data.length; x++){

          if (!(orderDetailsArray[i].combo_identifier).includes(variant_option_data[x].product_variant_option_name)){
            // console.log (variant_option_data[x])
            variant_selection[i].push(variant_option_data[x])
          }
        }

      }
      setVariantSelections(variant_selection)
    } catch (err) {
      // console.log (err)
    }
    setSelected_sizes(new Array(orderDetailsArray.length))
    setSubmittedRequestFlags(new Array(orderDetailsArray.length))



    setLoadingDropdowns(false)
    setLoadingProperties(false)


  }

  async function getVariantByProductIDandName(product_id, name, item) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}v/pid/${product_id}/name/${name}/item/${item}`)
      .then((res) => {
        // console.log (res.json())
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  const handleSizeSelectionChange = (item, index) => {
    const list = [...selected_sizes];
    list[index] = item.target.value
    setSelected_sizes(list)

  }

  const getOrderTotal = () => {
    let total = 0
    for (var i = 0; i < orderDetails.length; i++){
      total += orderDetails[i].quantity * parseFloat(orderDetails[i].price)
    }

    return (formatter.format(total))
  }



  async function getCustomer(customer_id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${customer_id}`)
    const customerArray = await res.json();
    // console.log ("customerArray")
    // console.log (customerArray)
    setCustomer(customerArray);
    // getOrderByID();
  }


  useEffect(() => {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
      setIsMobile(true)
    } else {
      setIsDesktop(true)
    }
    setOrderReferenceSnippet(user.orderRef)
    saveDeviceActions(location.pathname, "User Visit - Order - Pre-Validation Page: " + pathArray[2], null, null, null )

    if (user.orderHash !== pathArray[2] && (!user.orderRef || user.orderRed != [])){
      // console.log ('First Load')
      //window.location = `/o/${pathArray[2]}`
    } else {

      getOrderByID(user.orderRef);
    }


    const title = "Luxanda | Order Verification"
    title && (document.title = title);

    document.querySelector('meta[name="og:image"]').setAttribute("content", "%PUBLIC_URL%/static/imgs/hero-preview.jpg")
    document.querySelector('meta[name="og:title"]').setAttribute("content", 'Luxanda | Order Page')
    // assignHashToUser


  }, []);





  function handleChangeTab(e, tab) {
    e.preventDefault();
  }

  function handleJumpPage(e, wc_url) {
    // console.log (wc_url)
    e.preventDefault();
    setIsJumpPage(true)
    setLoading_jumpPage(true)
    generateWeChatLink(wc_url)
  }

  async function generateWeChatLink(wc_url){
    const jumpType = 'product'
    const res = await fetch(`${process.env.REACT_APP_API_URL}wc_jump/${jumpType}/${wc_url}`)
    const jump_data = await res.json();
    if (jump_data && jump_data.openlink){
      message.success (intl.formatMessage({ id: 'success_openlink_generated' }))
      window.location.href = jump_data.openlink
    } else {
      message.error (intl.formatMessage({ id: 'error_something_went_wrong' }))
    }
    setLoading_jumpPage(false)
  }

  // async function generateWeChatLink(){
  //   const jumpType = 'product'
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}wc_jump/${jumpType}/${product.wc_url}`)
  //   const jump_data = await res.json();
  //   if (jump_data && jump_data.openlink){
  //     message.success (intl.formatMessage({ id: 'success_openlink_generated' }))
  //     window.location.href = jump_data.openlink
  //   } else {
  //     message.error (intl.formatMessage({ id: 'error_something_went_wrong' }))
  //   }
  //   setLoading_jumpPage(false)
  // }

  const handleApplySizeChange = async (e, index) => {
    e.preventDefault()
    try {
      setLoadingSizeChange(true)
      const status_change = 'Change size to ' + selected_sizes[index]
      const id = orderDetails[index].id
      const customer_change_date = new Date()

      //setSubmissionFlagtoTrue
      const flag_list = submittedRequestFlags
      flag_list[index] = true
      setSubmittedRequestFlags(flag_list)
      // console.log  (orderDetails[index].id)
      const body = {id, status_change, customer_change_date}

      const response = await fetch(`${process.env.REACT_APP_API_URL}od/a=status_change`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {
        if (data.code == 409){
          console.log(data.message)
        } else if (data.code == 1002) {
          console.log(data.message)
        }
        message.success(intl.formatMessage({ id: 'message_change_size_submitted_1' }))
        saveDeviceActions(location.pathname, "User Requested Size Change - order_id: " + order.id + ' order_detail_id: ' + id, null, null, null )
        setLoadingSizeChange(false)
      })
      // window.location = "/admin/orders";
    } catch (err) {
    console.error(err);
    setLoadingSizeChange(false)
    }


  }

  // function handleDelete(e, record_id) {
  //   e.preventDefault()
  //
  //   if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
  //     deleteRecord(record_id)
  //   }
  // }

  const handleSubmit_search = async (e) => {
    e.preventDefault();
    if (orderReferenceSnippet.length === 0){
      message.error (intl.formatMessage({ id: 'message_enter_valid_reference' }))
    } else {
      getOrderByID(orderReferenceSnippet);
    }
  };

  const handleCancelConfirmation = (e) => {
    e.preventDefault()
    setIsCancelSelected(true)
  }

  // console.log (variantSelections)
  // console.log (selected_sizes)
  // console.log (order)


  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '30%',
    minWidth: '150px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #ffffff',
    backgroundColor: '#ffffff',
  }

  const product_container = {
    display: 'flex',
    flexDirection: 'column',

    // flexWrap: 'wrap',
    // placeContent: 'center',
    // alignItems: 'center',

  }


  const tableRow_product = {
    padding: '0.75rem',
    verticalAlign: 'middle',

    backgroundColor: 'white'
  }

  const tableRow_titles = {
    borderTop: '1px solid #ffffff',
    borderLeft: '1px solid #ffffff',
    borderRight: '1px solid #ffffff',
    textAlign: 'center'
  }

  const tableRow_totals = {
    borderBottom: '1px solid #ffffff',
    borderLeft: '1px solid #ffffff',
    borderRight: '1px solid #ffffff',
    textAlign: 'center',
    padding: '0.75rem',
    verticalAlign: 'middle',
  }


  const tableRow_FixedWidth = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    textAlign: 'center',

    width: '50px',
  }

  const product_information = {
    width: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    verticalAlign: 'middle',
    textAlign: 'center',
    alignSelf: 'center'
  }

  const row = {
    justifyContent: 'center',
  }

  const mp_link = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: '0px',
    cursor: 'pointer'


  }

  const mp_icon = {
    width: '3rem',
  }

  const fieldChanged = (type, value) => {
    if (type == 'orderReferenceSnippet'){
       setOrderReferenceSnippet(value);
    }
    setDateChecked(new Date())
  };

  const translateOrderStatus = (status) => {
    console.log (status)
    if (status == 'delivered'){
      return(intl.formatMessage({ id: 'order_status_delivered' }))
    } else if (status == 'shipped'){
      return(intl.formatMessage({ id: 'order_status_shipped' }))
    } else if (status == 'awaiting_fulfillment'){
      return(intl.formatMessage({ id: 'order_status_awaiting_fulfillment' }))
    } else if (status == 'cancelled_by_admin'){
      return(intl.formatMessage({ id: 'order_status_cancelled_by_admin' }))
    } else if (status == 'cancelled_by_customer'){
      return(intl.formatMessage({ id: 'order_status_cancelled_by_customer' }))
    } else if (status == 'refunded'){
      return(intl.formatMessage({ id: 'order_status_refunded' }))
    } else if (status == 'waiting_for_payment'){
      return(intl.formatMessage({ id: 'order_status_waiting_for_payment' }))
    } else if (status == 'waiting_for_fulfillment'){
      return(intl.formatMessage({ id: 'order_status_waiting_for_fulfillment' }))
    }
  }




  // console.log ("reference:" + orderReferenceSnippet)



  // <button
  //   className="ps-btn"
  //   onClick={e => handleSubmit(e)}
  //   disabled={loading}
  //   >
  //   {loading ? (<>Searching... <LoadingOutlined size="large"/></>) : ('Search for Order')}
  //   </button>


  // console.log (submittedRequestFlags)

  function order_details_view(){
    if (orderDetails && orderDetails.length != 0){
        return orderDetails.map((orderDetail, index) => (
        <div className="row" style={{placeContent: 'center'}}>
        {index != 0 ? <div className="separator" style={{margin: '15px 15px 15px 15px'}}></div> : null}
          <div className="col-lg-2">
            <div className="form-group">
              {isMobile ? <div style={mp_link} onClick={e => handleJumpPage(e, orderDetail.wc_url)}>
                <div
                    className="form-control-plaintext"
                    style={{color: "#7a79b0", fontSize: '12px', fontWeight: '600', padding: '0px'}}
                    type="text"
                    placeholder={null}
                    readOnly={true}
                >
                  <FormattedMessage
                    id="message_view_on_wechat"
                  />
                </div>
                <img src="/static/imgs/mini-program-icon-2.png" alt={`Luxanda | WeChat Mini-Program | ${orderDetail.sku}`} style={mp_icon}/>
              </div> : null}


              <img src={orderDetail && orderDetail.pic_url ? orderDetail.pic_url : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
              style={{display: 'block',
                  maxWidth: '100px',
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',

                }} alt={orderDetail && orderDetail.pic_url ? orderDetail.pic_url : 'Not Found'} border="0" />
            </div>

          </div>

          <div className="col-lg-6">
            <div className="form-group" style={{marginBottom: '0px'}}>
              <div style={{display: 'block'}}>
                {orderDetail && orderDetail.combo_identifier != 'NO OPTIONS' ?
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <i className='icon-chevron-right' style={{fontSize: '16px', fontWeight: '600', color: '#ffb81c'}}></i>
                    <input
                        className="form-control-plaintext"
                        style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginBottom: '0px', color: '#ffb81c'}}
                        type="text"
                        placeholder={null}
                        readOnly={true}
                        value={orderDetail && orderDetail.combo_identifier ? orderDetail.combo_identifier: ''}
                    />
                </div> : null}
                <input
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '18px', fontWeight: '600', padding: '0px'}}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'label_loading' })}
                    readOnly={true}
                    value={orderDetail && orderDetail.product_name ? orderDetail.product_name : ''}
                />

                <div
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '16px', fontWeight: '600', padding: '0px', marginTop: '-7px'}}
                    type="text"
                    placeholder={null}
                    readOnly={true}
                >
                  <FormattedMessage
                    id="product_by"
                  />
                  {orderDetail && orderDetail.brand_name ? ' ' + orderDetail.brand_name : ''}
                </div>

                {orderDetail && orderDetail.flag_serialization ?
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <CheckCircleOutlined style={{ fontSize: '14px', color: 'lightgrey'}} />

                  <div
                      className="form-control-plaintext"
                      style={{backgroundColor: "white", fontSize: '14px', padding: '0px', color: 'lightgrey', fontStyle: 'italic', fontWeight: '600'}}
                      type="text"
                      placeholder={null}
                      readOnly={true}
                  >
                  <FormattedMessage
                    id="product_limited_production"
                  />
                  {orderDetail && orderDetail.flag_serialization ? ' ' + orderDetail.serial_run_limit : ''}
                  </div>

                </div> : null}

                <div
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '12px', padding: '0px', color: 'lightgrey', fontStyle: 'italic'}}
                    type="text"
                    placeholder={null}
                    readOnly={true}
                  >
                  <FormattedMessage
                    id="product_sku"
                  />
                  {orderDetail && orderDetail.sku ? ': ' + orderDetail.sku : ''}
                </div>

              </div>

            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <input
                  className="form-control-plaintext"
                  style={{fontSize: '18px', padding: '0px', fontWeight: '600', textAlign: 'end', marginTop: '1rem'}}
                  type="text"
                  placeholder={null}
                  readOnly={true}
                  value={orderDetail && orderDetail.price ? formatter.format(orderDetail.price) + ' ' + orderDetail.currency + ' × ' +orderDetail.quantity : ''}
              />
              {order.order_status == 'Awaiting Fulfillment' && (orderDetail.sku).includes('MASK') && orderDetail.brand_name == 'King Mountain' &&  (!deadline_days_left || deadline_days_left > -1) ?
              <div className='form-control-plaintext' style={{backgroundColor: '#f1f1f1', fontSize: '14px', fontWeight: '600', textAlign: 'center', marginBottom: '2rem', marginTop: '2rem', padding: '10px', borderRadius: '4px'}}>


              {order.tyl_tracking_id  && deadline_days_left > -1?
                <div className='form-control-plaintext' style={{ textAlign: 'center', marginBottom: '1rem'}}>
                  <WarningOutlined style={{fontSize: '16px'}}/>
                  <div className='form-control-plaintext' style={{backgroundColor: '', color: '', fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.formatMessage({ id: 'message_cancel_order_count_down_disclaimer'})}
                  </div>
                  <div style={{display:'flex'}}>
                    <div className='form-control-plaintext' style={{backgroundColor: '', color: '', fontSize: '14px', textAlign: 'right'}}>
                    {moment(deadline_date).format(dateFormat)}

                    </div>
                    <div className='form-control-plaintext' style={{backgroundColor: '', color: '#e2252b', fontSize: '14px', fontWeight: '600', textAlign: 'left', marginLeft: '0.5rem'}}>
                    {`${intl.formatMessage({ id: 'message_days_left_1'})}${deadline_days_left} ${intl.formatMessage({ id: 'message_days_left_2'})})`}

                    </div>

                  </div>
                </div>

                : orderDetail.status_change ?
                  <div className='form-control-plaintext' style={{fontSize: '14px', fontWeight: '600'}}>
                    <FormattedMessage
                      id="message_change_size_disclaimer_requested_by_customer_1"
                    />
                    <div>{moment(orderDetail.customer_change_date).format(dateFormat)}</div>
                    <FormattedMessage
                      id="message_change_size_disclaimer_requested_by_customer_2"
                    />

                    {orderDetail.quantity > 1 ?
                    <div style={{marginTop: '1rem'}}>
                      <FormattedMessage
                        id="message_change_size_disclaimer_requested_by_customer_3"
                      />
                      ({orderDetail.quantity})
                    </div> : null}
                  </div>
                : <FormattedMessage
                  id="message_change_size_disclaimer"
                />
              }




              </div> : null }


                {loadingDropdowns ? <div>{intl.formatMessage({ id: 'label_loading' })}</div> : order.order_status == 'Awaiting Fulfillment' && (orderDetail.sku).includes('MASK') && orderDetail.brand_name == 'King Mountain' && !submittedRequestFlags[index] &&  (!deadline_days_left || deadline_days_left > -1) ?
                  <select className="form-control" defaultValue={0} value={selected_sizes[index]} onChange={ e=> handleSizeSelectionChange(e, index)} >
                      {variantSelections[index] && variantSelections[index].length === 0 ? null : <option value={0}>{intl.formatMessage({ id: 'message_please_select' })}</option>}

                      {variantSelections[index] && variantSelections[index].length > 0 ? variantSelections[index].map((item) => (
                        <option value={item.product_variant_option_name} key={item.product_variant_option_name}  >
                                {item.product_variant_option_name} {intl.formatMessage({ id: 'translate_' + item.product_variant_option_name.toLowerCase() })}
                        </option>
                      )) : <option value={0}>{intl.formatMessage({ id: 'message_no_selection_available' })}</option>}
                  </select>:
                  (order.order_status == 'Shipped' || order.order_status == 'Awaiting Fulfillment') && (orderDetail.sku).includes('MASK') && orderDetail.brand_name == 'King Mountain' && !submittedRequestFlags[index]?
                    <div style={{width: '100%'}}>
                      <div className='form-control-plaintext' style={{ textAlign: 'center', marginBottom: '3rem'}}>
                        <div className='form-control-plaintext' style={{backgroundColor: '', color: '#e2252b', fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                          {intl.formatMessage({ id: 'message_missed_deadline_to_change_size'})}
                        </div>
                      </div>
                    </div>
                  : null

                }


                  {selected_sizes[index] != null && selected_sizes[index] != '0'   ?
                  <div className="form-group submit" style={{textAlign: 'center', marginTop: '1rem'}} >

                    {!submittedRequestFlags[index]  ?
                      <button
                        className="ps-btn"
                        onClick={e => handleApplySizeChange(e, index)}
                        disabled={loadingSizeChange}

                        >
                        {loadingSizeChange ? (<>Applying... <LoadingOutlined size="large"/></>) : intl.formatMessage({ id: 'message_request_change' })}
                      </button> :
                      <div className='form-control-plaintext' style={{backgroundColor: 'white', fontSize: '14px', fontWeight: '600', textAlign: 'center', marginBottom: '3rem', color: "#ffb81c"}}>
                        <WarningOutlined style={{fontSize: '16px'}} />
                        <span> </span>
                        <div></div>
                        <FormattedMessage
                          id="message_change_size_submitted_2"
                        />
                        <div></div>
                        <FormattedMessage
                          id="message_change_size_submitted_3"
                        />
                      </div>}
                  </div>
                    : null }
            </div>
          </div>
        </div>
        ))
    } else {
      return <div>
        <FormattedMessage
          id="message_not_found_order_detail"
        />
      </div>
    }
  }




  function set_order_view () {
    if (Object.keys(order).length !== 0){
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div className="col-lg-8">
            <div className="form-group">
              {order_details_view()}
              <div className="col-lg-12" style={{marginTop: '2.5rem'}}>
                  <div className="form-group" style={{textAlign: 'end'}}>
                      <label className="form" style={{color: 'lightgrey'}}>
                        <FormattedMessage
                          id="message_order_total"
                        />
                      </label>
                      <div className="form-control-plaintext" style={{fontSize: '18px', fontWeight: '600'}}>
                        {getOrderTotal() +' USD'}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

      )
    } else {
      return <div>
        <FormattedMessage
          id="message_order_not_found"
        />
      </div>
    }
  }











  let customerView;
  let tracking_url = "https://www.ups.com/mobile/track?trackingNumber=" + order.tracking_id

  if (customer && order && formState === "found"){
    customerView = (
      <div>
      <div className="row" >

        <div className="col-lg-12">
          <div className="form-group">
            <label className="form">
              <h3>
                <FormattedMessage
                  id="title_order_information"
                />
              </h3>
            </label>
          </div>
        </div>
      </div>

          <div className="row" style={{backgroundColor: '#f1f1f1', paddingTop: '2.5rem', borderRadius: '4px'}}>
            <div className="col-lg-2">
                <div className="form-group" >
                    <div
                        className='form-control-plaintext'
                        style={{fontSize: '12px', fontWeight: '600'}}
                        type="text"
                        readOnly={true}
                    >
                      <FormattedMessage
                        id="title_order_date"
                      />
                    </div>
                    <input
                        className='form-control-plaintext'
                        style={{fontSize: '16px'}}
                        type="text"
                        placeholder={intl.formatMessage({ id: 'label_loading' })}
                        value={moment(order.order_date).format(dateFormat)}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="col-lg-3">
                <div className="form-group" >
                    <div
                        className='form-control-plaintext'
                        style={{fontSize: '12px', fontWeight: '600'}}
                        type="text"
                        readOnly={true}
                    >
                      <FormattedMessage
                        id="title_order_number"
                      />
                    </div>
                    <input
                        className='form-control-plaintext'
                        style={{fontSize: '16px'}}
                        type="text"
                        placeholder={intl.formatMessage({ id: 'label_loading' })}
                        value={order.order_number}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="col-lg-2">
                <div className="form-group" >
                    <div
                        className='form-control-plaintext'
                        style={{fontSize: '12px', fontWeight: '600'}}
                        type="text"
                        readOnly={true}
                    >
                      <FormattedMessage
                        id="title_order_reference_number"
                      />
                    </div>
                    <input
                        className='form-control-plaintext'
                        style={{fontSize: '16px'}}
                        type="text"
                        placeholder={intl.formatMessage({ id: 'label_loading' })}
                        value={order.order_reference}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="col-lg-4">
                <div className="form-group" >

                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div>
                      <div
                          className='form-control-plaintext'
                          style={{fontSize: '12px', fontWeight: '600'}}
                          type="text"
                          readOnly={true}
                      >
                        <FormattedMessage
                          id="title_order_status"
                        />
                      </div>
                      <input
                          className='form-control-plaintext'
                          style={{fontSize: '16px', fontWeight: '600'}}
                          type="text"
                          placeholder={intl.formatMessage({ id: 'label_loading' })}
                          value={translateOrderStatus(order.order_status)}
                          readOnly={true}
                      />
                      </div>
                      {order.tracking_id && order.order_status == 'Shipped' ?

                        <div className="form-group" style={{textAlign: 'center', margin: '0px'}}>
                            <a className="ps-btn" target="_blank" href={tracking_url}>
                              <FormattedMessage
                                id="action_track_shipment"
                              />

                            </a>
                        </div>
                   : null}
                   </div>

                </div>
              </div>
            </div>

          </div>


    )
  } else {
    customerView = (<div>No Customer Found</div>)
  }

  let submissionForm

  if (formState === ""){
    submissionForm = (<div className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>
                  <FormattedMessage
                    id="title_order_verification"
                  />
                </h3>
            </div>
            <div className="ps-form__content" style={{textAlign: 'center', boxShadow:'none'}}>
                <div className="row" style={{justifyContent: 'center'}}>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="form">
                              <FormattedMessage
                                id="label_last_4_digits"
                              />
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="example: '1001'"
                                maxLength="4"
                                value={orderReferenceSnippet}
                                onChange={e => fieldChanged('orderReferenceSnippet', e.target.value)}
                            />
                        </div>
                    </div>


                </div>
                <div className="form-group submit" >

                {(errors) && (
                <div>
                  <p className="error-message">{errors}</p>
                </div>
              )}
                    <button
                      className="ps-btn"
                      onClick={e => handleSubmit_search(e)}
                      disabled={loading}
                      >
                      {loading ? (<>{intl.formatMessage({ id: 'message_searching' })} <LoadingOutlined size="large"/></>) :
                      <FormattedMessage
                        id="action_search_for_order"
                      />}
                      </button>
                </div>
            </div>
        </div>)
  } else if (formState === "found") {
    submissionForm =
    (<div>
        {customerView}
        <div className="row">

          <div className="col-lg-12" style={{marginTop: '5rem'}}>


                  {set_order_view()}




          </div>


          <div className="separator" style={{margin: '30px 15px 30px 15px'}}></div>

          {order.order_status == 'Awaiting Fulfillment' &&  (!deadline_days_left || deadline_days_left > -1) ?
          <div style={{width: '100%'}}>

              {order.tyl_tracking_id  && deadline_days_left > -1?

                <div className='form-control-plaintext' style={{ textAlign: 'center', marginBottom: '3rem'}}>
                  <WarningOutlined style={{fontSize: '16px'}}/>
                  <div className='form-control-plaintext' style={{backgroundColor: 'white', color: '', fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.formatMessage({ id: 'message_cancel_order_count_down_disclaimer'})}
                  </div>
                  <div style={{display:'flex'}}>
                    <div className='form-control-plaintext' style={{backgroundColor: 'white', color: '', fontSize: '14px', textAlign: 'right'}}>
                    {moment(deadline_date).format(dateFormat)}

                    </div>
                    <div className='form-control-plaintext' style={{backgroundColor: 'white', color: '#e2252b', fontSize: '14px', fontWeight: '600', textAlign: 'left', marginLeft: '0.5rem'}}>
                    {`${intl.formatMessage({ id: 'message_days_left_1'})}${deadline_days_left} ${intl.formatMessage({ id: 'message_days_left_2'})})`}

                    </div>

                  </div>
                </div>

                : <div className='form-control-plaintext' style={{backgroundColor: 'white', fontSize: '14px', fontWeight: '600', textAlign: 'center', marginBottom: '3rem'}}>
                  {intl.formatMessage({ id: 'message_cancel_order_disclaimer' })}
                </div>
              }


              <div style={{display: 'flex', width: '100%', placeContent: 'center'}}>
                <button
                  className="ps-btn ps-btn--gray"
                  onClick={e => handleCancelConfirmation(e)}
                  disabled={loading}
                  >
                  {loading ? (<>{intl.formatMessage({ id: 'message_processing' })} <LoadingOutlined size="large"/></>) : intl.formatMessage({ id: 'message_cancel_order' })}
                </button>
              </div>

            {isCancelSelected ? <div style={{position:'fixed', height: '100vh', width: '100vw', padding: '3rem', top: '0px', left: '0px', backgroundColor: 'white', zIndex: '9999', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>



            <WarningOutlined style={{fontSize: '20px'}}/>
            <div className='form-control-plaintext' style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600', textAlign: 'center'}}>
              {intl.formatMessage({ id: 'message_cancel_order_confirm' })}
            </div>

            <div className='form-control-plaintext' style={{backgroundColor: 'white', fontSize: '14px', fontWeight: '400', textAlign: 'center', marginBottom: '3rem'}}>
              {intl.formatMessage({ id: 'message_cancel_details' })}
            </div>

            <div style={{display: 'flex', width: '100%', placeContent: 'center', marginBottom: '2rem'}}>
              <button
                className="ps-btn "
                disabled={loading}
                onClick={e => dialog_confirm(e)}
                >
                {loading ? (<>{intl.formatMessage({ id: 'message_processing' })} <LoadingOutlined size="large"/></>) : intl.formatMessage({ id: 'message_yes' })}
              </button>
            </div>
            <div style={{display: 'flex', width: '100%', placeContent: 'center'}}>
              <button
                className="ps-btn ps-btn--gray"
                disabled={loading}
                onClick={e => dialog_cancel(e)}
                >
                {loading ? (<>{intl.formatMessage({ id: 'message_processing' })} <LoadingOutlined size="large"/></>) : intl.formatMessage({ id: 'message_no' })}
              </button>
            </div>


            </div> : null}




            </div> :

            order.order_status == 'Shipped' || order.order_status == 'Awaiting Fulfillment' ?
              <div style={{width: '100%'}}>
                <div className='form-control-plaintext' style={{ textAlign: 'center', marginBottom: '3rem'}}>
                  <div className='form-control-plaintext' style={{backgroundColor: 'white', color: '#e2252b', fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.formatMessage({ id: 'message_missed_deadline_to_cancel'})}
                  </div>
                  <div style={{display:'flex'}}>
                    <div className='form-control-plaintext' style={{backgroundColor: 'white', color: '', fontSize: '14px', textAlign: 'right'}}>
                    {moment(deadline_date).format(dateFormat)}

                    </div>
                    <div className='form-control-plaintext' style={{backgroundColor: 'white', color: '#e2252b', fontSize: '14px', fontWeight: '600', textAlign: 'left', marginLeft: '0.5rem'}}>
                    {`${intl.formatMessage({ id: 'message_days_ago_1'})}${Math.abs(deadline_days_left)} ${intl.formatMessage({ id: 'message_days_ago_2'})})`}

                    </div>

                  </div>
                </div>
              </div>
            : null
          }

        </div>
      </div>
      )
  } else if (formState === "not-found"){
    submissionForm = (
      <div>PRESS TO RESTART</div>
    )
  }




  return (
    <section className="ps-my-account ps-page--account">
        {loading_jumpPage ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'white', zIndex: '9999', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <LoadingOutlined style={{fontSize: '25px', marginBottom: '2rem'}}/>
        <FormattedMessage
          id="message_openning_wechat"
        />
        </div> : null}

        <div className="container">
            <div className="row">



              <div className="col-lg-12">

                <div className="ps-page__content">
                  <form className="ps-form--product-form" >
                    <div className="ps-form__header">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <h3>{user.orderHash !== pathArray[2] ? intl.formatMessage({ id: 'label_order_id'}) + pathArray[2].substring(0,10) + "..." : 'Hello ' + customer.customer_name}</h3>
                            </div>
                          </div>
                    </div>
                    <div className="ps-form__content">
                    {submissionForm}
                    </div>
                  </form>
              </div>

            </div>


          </div>
          <div style={{height: '5vh'}}/>
        </div>
    </section>

  );

}

// CustomerOrderView.propTypes = {
//   // classes: PropTypes.object.isRequired
//   signinUser: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
//   ui: PropTypes.object.isRequired
// }
//
// const mapStateToProps = (state) => ({
//   user: state.user,
//   ui: state.ui
// })
//
// const mapActionsToProps = {
//   assignHashToUser
// }


// export default connect(mapStateToProps, mapActionsToProps)(CustomerOrderView);
export default CustomerOrderView;
