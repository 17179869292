import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { OrderDetailGroupWithCarousel } from '../../components/partials/product/OrderDetailGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const OrderDetailsTable = ({userCredentials, title}) => {
  // const [productItems, setOrderDetailItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [orderDetails, setOrderDetails] = useState([]);

  let isMounted = false;


  async function getOrderDetails() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/all`);
    const orderDetailsArray = await res.json();


    setOrderDetails(orderDetailsArray);
  }


  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}
      const res = await fetch(`${process.env.REACT_APP_API_URL}od/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      }).then((response) => {
        // setLoading(false)
        console.log ("Record Deleted")
        window.location.reload(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {

    getOrderDetails();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
    // getOrderDetails(tab.slug);
  }

  function handleSubmit(e) {
    // e.preventDefault()
    console.log ("navigating")
  }

  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }


  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }


  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All Order Details</h3>

                      <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                        <Link to="/admin/order-details/new">
                          <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New Order Detail</button>
                        </Link>
                      </div>
                    </div>
                </div>
                <div className="ps-form__content">

                  <div className="row">

                        <table class="table mt-5">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Product ID</th>
                              <th>Order ID</th>
                              <th>Promotion ID</th>
                              <th>Date Created</th>
                              <th>Date Modified</th>
                              <th>View</th>
                              <th>Edit</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          {/*<tr>
                            <td>John</td>
                            <td>Doe</td>
                            <td>john@example.com</td>
                          </tr> */}

                          {orderDetails.map((orderDetail) => (
                            <tr key={orderDetail.id}>
                              <td style={tableRow}>{orderDetail.id}</td>
                              <td style={tableRow}>
                                <Link to={`/admin/p/${orderDetail.product_id}`}>
                                  <button className="ps-btn">{orderDetail.product_id}</button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <Link to={`/admin/o/${orderDetail.order_id}`}>
                                  <button className="ps-btn">{orderDetail.order_id}</button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <Link to={`/admin/promotion/${orderDetail.promotion_id}`}>
                                  <button className="ps-btn">{orderDetail.promotion_id}</button>
                                </Link>
                              </td>
                              <td style={tableRow}>{moment(orderDetail.date_created).format('lll')}</td>
                              <td style={tableRow}>{moment(orderDetail.date_modified).format('lll') != 'Invalid date' ? moment(orderDetail.date_modified).format('lll') : ''  }</td>

                              <td style={tableRow}>
                                <Link to={`/admin/order-detail/${orderDetail.id}`}>
                                <button className="ps-btn-delete">
                                    <i className='icon-eye'></i>
                                </button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <Link to={`/admin/order-detail/${orderDetail.id}/edit`}>
                                  <button className="ps-btn-delete">
                                      <i className='icon-pencil'></i>
                                  </button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <button className="ps-btn-delete" onClick={e => handleDelete(e, orderDetail.id)}>
                                    <i className='icon-cross'></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>

                      </div>
                    </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default OrderDetailsTable;
