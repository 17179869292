import React, { Component, useEffect, useState } from 'react'
// import axios from 'axios'
import TemplateProduct from '../../components/elements/templates/TemplateProduct'
import {generateTempArray} from '../../utilities/common-helpers'
import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import axios from 'axios'


import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const HomeJSX = ({ collectionSlug, title}) => {
  const [productItems, setProductItems] = useState([]);
  const [currentCollection, setCurrentCollection] = useState('new-arrivals');
  const [loading, setLoading] = useState(true);





  const sectionLinks = [
      {
          title: 'New Arrivals',
          name: 'new-arrivals',
          slug: collectionSlug,
      },
      {
          title: 'Best seller',
          name: 'best-seller',
          slug: 'fullwidth-clothing-best-sellers',
      },
      {
          title: 'Most Popular',
          name: 'most-popular',
          slug: 'fullwidth-clothing-most-popular',
      },
  ];

  useEffect(() => {
    getAllProducts();


  }, []);

  async function getAllProducts() {
    // console.log (axios.defaults.headers.common['Authorization'].split("Bearer ")[1])
    // const auth_token = userCredentials.auth_token

    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`)
    const productsArray = await res.json();
    setProductItems(productsArray);
    console.log (productsArray)
    setLoading(false)
  }



  function handleChangeTab(e, tab) {
    e.preventDefault();
    setCurrentCollection(tab.name);
    // getProducts(tab.slug);
  }




  const sectionLinksView = sectionLinks.map((link) => (
    <li
        className={currentCollection === link.pid ? 'active' : ''}
        key={link.pid}>
        <a href="#" onClick={(e) => handleChangeTab(e, link)}>
            {link.pid}
        </a>
    </li>
  ));

  // views
    let productItemsView;
    if (!loading) {
        if (productItems && productItems.length > 0) {
            productItemsView = (
                <ProductGroupWithCarousel
                    products={productItems}
                    type="fullwidth"
                />
            );
        } else {
            productItemsView = <p>No product(s) found.</p>;
        }
    } else {
        const skeletons = generateTempArray(8).map((item) => (
            <div className="col-xl-2 col-lg-3 col-sm-3 col-6" key={item}>
                <TemplateProduct />
            </div>
        ));
        productItemsView = <div className="row">{skeletons}</div>;
    }
  // IN RETURN:
  // <div className="ps-section__content">{productItemsView}</div>
  // <ShopItems columns={6} productsPerPage={10} searchType={null} searchQuery={null} page={1}/>

  return (
    <div className="ps-product-list">
        <div className="ps-container">
            <div className="ps-section__header">
                <h3>{title}</h3>
                <ul className="ps-section__links">
                    <li>
                        <Link to={`/shop`}>
                            <a>View All</a>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="ps-section__content">{productItemsView}</div>

        </div>
    </div>

  );

}


export default HomeJSX;
