import { SET_USER, SET_ERRORS, CLEAR_ERRORS, LOADING_UI, SET_UNAUTHENTICATED, LOADING_USER, SET_USER_HASH } from '../types'
import axios from 'axios'


export const signinUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI})
  axios.post('https://us-central1-glmp-fcb39.cloudfunctions.net/api/signin', userData)
    .then(res => {
      // console.log ("response:")
      // console.log (res)
      setAuthorizationHeader(res.data.token)
      dispatch(getUserData())
      dispatch({type: CLEAR_ERRORS});
      // res.redirect('/admin/orders')
      // history.push('/admin/orders')
      window.location = "/admin/orders"
    })
    .catch(e => {
      // console.log (e.response)
      dispatch({
        type: SET_ERRORS,
        payload: e.response.data
      })
    })
}

export const signupUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI})
  axios.post('https://us-central1-glmp-fcb39.cloudfunctions.net/api/signup', newUserData)
    .then(res => {
      // console.log (res)
      setAuthorizationHeader(res.data.access_token)
      dispatch(getUserData())
      dispatch({type: CLEAR_ERRORS});
      history.push('/')
    })
    .catch(e => {
      dispatch({
        type: SET_ERRORS,
        payload: e.response.data
      })
    })
}

export const signoutUser = () => (dispatch) => {
  localStorage.removeItem('FBIdToken');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: SET_UNAUTHENTICATED })
  // history.push('/')
}

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER})
  axios.get('https://us-central1-glmp-fcb39.cloudfunctions.net/api/user')
    .then(res => {
      // console.log ('---------------')
      // console.log (res)
      dispatch({
        type: SET_USER,
        payload: res.data
      })
    })
    .catch(e => console.log(e))
}

export const assignHashToUser = (userHash) => (dispatch) => {
  dispatch({ type: LOADING_UI})

  dispatch({
    type: SET_USER_HASH,
    payload: userHash
  })

}


const setAuthorizationHeader = (token) => {
  // console.log ('token:' + token)
  const FBIdToken = `Bearer ${token}`
  localStorage.setItem('FBIdToken', FBIdToken)
  axios.defaults.headers.common['Authorization'] = FBIdToken
}
