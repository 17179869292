import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import BreadCrumb from '../../../components/elements/common/BreadCrumb';
import NewProductSectional from '../../../components/partials/store/NewProductSectional';
import ContainerPage from '../../../components/layouts/ContainerPage';


const mapState = ({ user }) => ({
  user: user
})

const breadCrumb = [{text:'Products', url: '/store/products'},{text: 'New Product'}]

const NewProductPage = ({}) => {

  const {user } = useSelector(mapState)
  console.log (user)

  useEffect(() => {
  },[])


//THIS LISTENS TO A RELOAD ON THE WINDOW! WILL PROMPT USER TO MAKE SURE CHANGES ARE SAVED
  window.onbeforeunload = (event) => {
  const e = event || window.event;
  // Cancel the event
  e.preventDefault();
  if (e) {
    e.returnValue = ''; // Legacy method for cross browser support
  }
  return ''; // Legacy method for cross browser support
};







    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         breadCrumb: [
    //             {
    //                 text: 'Home',
    //                 url: '/',
    //             },
    //             {
    //                 text: 'My Account',
    //             },
    //         ],
    //     };
    // }



        // let profileMarkup = !loading ? (authenticated ? (
        //   <div>AUTHENTICATED</div>
        // ) : (
        //   <div>Not authenticated, please signup/login</div>
        // )) : (<p>Loading...</p>)
  return (
      <ContainerPage title="New Product">
          <div className="ps-page--my-account">
              <BreadCrumb breacrumb={breadCrumb} />
              <NewProductSectional />


          </div>
      </ContainerPage>
    )

}


export default NewProductPage;
