import React, { Component, useEffect, useState } from 'react'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';
import FormEditBrand from '../../components/shared/forms/form-edit-brand';


const Link = require("react-router-dom").Link

const AdminBrandEditPage = () => {


  useEffect(() => {
    // getProducts();
  }, []);


  return (

        <section className="ps-my-account ps-page--account">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ps-page__content">
                      <FormEditBrand/>
                    </div>
                  </div>
                </div>



                <div style={{height: '10vh'}}/>


            </div>
        </section>

  );

}


export default AdminBrandEditPage;
