let productSections = [
    {
        order: 1,
        text: 'Product Details',
        // url: '/store/product/new/details',
        icon: 'icon-pencil',
    },
    {
        order: 2,
        text: 'Product Description',
        // url: '/store/product/new/information',
        icon: 'icon-pencil',
    },

];

exports.productSections = productSections
