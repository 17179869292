import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
// import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined } from '@ant-design/icons'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormNewOrderDetail = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)
  // const date = new Date()
  const [date_created, setDate_created] = useState("");
  const [orderDetail_date, setOrderDetail_date] = useState("");

  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");

  const [order_id, setOrder_id] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [promotion_id, setPromotion_id] = useState("");
  const [customer_id, setCustomer_id] = useState("");

  const [orders, setOrders] = useState([])
  const [products, setProducts] = useState([])
  const [promotionsByProduct, setPromotionsByProduct] = useState([])
  const [customer, setCustomer] = useState([])







  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [ordersDropdown, setOrdersDropdown] = useState([])
  const [productsDropdown, setProductsDropdown] = useState([])
  const [promotionsDropdown, setPromotionsDropdown] = useState([])




  let location = useLocation()



  useEffect(() => {
    getOrders();
    getProducts()

  }, []);

  async function getOrders() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`)
    const ordersArray = await res.json()
    setOrders(ordersArray);
    setLoadingDropdowns(false)
  }

  async function getProducts() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`)
    const productsArray = await res.json()
    setProducts(productsArray);
    setLoadingDropdowns(false)
  }

  async function getCustomerByID(selected_customer_id) {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/id/${selected_customer_id}`)
    const customerArray = await res.json()
    setCustomer(customerArray);
    setLoadingDropdowns(false)
  }

  async function getPromotionsByProduct(selected_product_id) {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}promos/p/${selected_product_id}`)
    const promotionsArray = await res.json()
    setPromotionsByProduct(promotionsArray);
    setLoadingDropdowns(false)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();



    try {
      setLoading(true)
      const created_by = user.credentials.email
      const body = { date_created, order_id, product_id, promotion_id, created_by };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}od`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        setLoading(false)
      })


      window.location = "/admin/order-details";

    } catch (err) {
      console.error(err.message);
    }
  };










  const handleSelect = (item, type) => {
    setDate_created(new Date())
    if (type == 'order_id'){
      setOrder_id(item.target.value)

      let selected_order_data = getValueFromDataArray(orders, item.target.value)
      if (item.target.value == 0){
        setCustomer([])
      } else {
        getCustomerByID(selected_order_data.customer_id)
      }

    } else if (type == 'product_id'){
      setProduct_id(item.target.value)
      getPromotionsByProduct(item.target.value)
      setPromotion_id("")
    } else if (type == 'promotion_id'){
      setPromotion_id(item.target.value)
    }
  }

  console.log ("savinngggg...")
  console.log ("order_id: " + order_id)
  console.log ("product_id: " + product_id)
  console.log ("promotion_id: " + promotion_id)

  console.log ("Orders: ")
  console.log (orders)
  console.log ("products by brand: ")
  console.log (products)
  console.log ("promotions by product: ")
  console.log (promotionsByProduct)



  function getValueFromDataArray (data,id) {

    // let data =[]
    // if (type === 'orders'){
    //   data = orders
    // }

    for (let i = 0; i < data.length; i++){

      if (data[i].id == id){

        return data[i]
      }
    }
  }




  function setSelectedId_View (type) {
    if (type == 'orders'){
       if (orders.length > 0){


          return (
            orders.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.id} - {item.order_number} - {item.order_reference} - ({item.order_date})
            </option>
          )))


        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }
    } else if (type == 'products'){
      if (products.length > 0){
         return (
           products.map((item) => (
            <option value={item.id} key={item.id} >
                    {item.name} - {item.variant} - {item.sku}
            </option>
          )))
        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }

    } else if (type == 'promotions'){
      if (promotionsByProduct.length > 0){
         return (
           promotionsByProduct.map((item) => (
            <option value={item.id} key={item.id}  >
                    ${item.price}{item.currency} - {item.date_created}
            </option>
          )))
        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }

    }

  }

  const dropdown_orders = setSelectedId_View('orders')
  const dropdown_products = setSelectedId_View('products')
  const dropdown_promotions = setSelectedId_View('promotions')

  let order_data = getValueFromDataArray(orders, order_id)
  let product_data = getValueFromDataArray(products, product_id)
  let promotion_data = getValueFromDataArray(promotionsByProduct, promotion_id)


  console.log (order_data)

  const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 'calc(25% - 8px)',
  height: 'calc(25% - 8px)',
  maxWidth: '200.13px',
  aspectRatio: '1',
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  objectFit: 'cover',
};

    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Create New Order Detail</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">




                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order ID</label>

                            {loadingDropdowns ? <div>Loading...</div> :
                            <select className="form-control" defaultValue={0} value={order_id} onChange={ e=> handleSelect(e, 'order_id')} >
                                    {orders.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                      {dropdown_orders}
                                </select>}
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order Number</label>
                              <div>
                                {order_data ? order_data.order_number : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order Reference</label>
                              <div>
                                {order_data ? order_data.order_reference : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Order Date</label>
                              <div>
                                {order_data ? order_data.order_date : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div class="separator"></div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Customer Name</label>
                              <div>
                                {customer ? customer.customer_name : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Customer Phone</label>
                              <div>
                                {customer ? customer.phone_number : 'Not Available'}
                              </div>
                        </div>
                    </div>





                    <div class="separator"></div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product ID</label>
                            {loadingDropdowns ? <div>Loading...</div> :


                            <select className="form-control" defaultValue={0} value={product_id} onChange={ e=> handleSelect(e, 'product_id')} >

                                    {products.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                  {dropdown_products}
                                </select>}


                        </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div style={thumb} >
                          <div style={thumbInner}>
                            <img src={product_data ? product_data.pic : null} style={img} alt="" border="0" />
                          </div>
                        </div>
                      </div>
                  </div>



                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Product Name</label>
                              <div>
                                {product_data ? product_data.name : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Product Variant</label>
                              <div>
                                {product_data ? product_data.variant : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Product SKU</label>
                              <div>
                                {product_data ? product_data.sku : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div class="separator"></div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Promotion ID</label>
                            {loadingDropdowns ? <div>Loading...</div> :


                            <select className="form-control" defaultValue={0} value={promotion_id} onChange={ e=> handleSelect(e, 'promotion_id')} >

                                    {promotionsByProduct.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                  {dropdown_promotions}
                                </select>}


                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Promotion Date Created</label>
                              <div>
                                {promotion_data ? promotion_data.date_created : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Promotion Date</label>
                              <div>
                                {promotion_data ? promotion_data.promotion_date : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Promotion Price</label>
                              <div>
                                {promotion_data ? promotion_data.price : 'Not Available'}
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Promotion Currency</label>
                              <div>
                                {promotion_data ? promotion_data.currency : 'Not Available'}
                              </div>
                        </div>
                    </div>
                </div>
                <div style={{height: '5vh'}}/>
                <div className="form-group submit">
                    <button
                      className="ps-btn"
                      onClick={e => handleSubmit(e)}
                      disabled={loading}
                      >
                      {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Save Order Detail')}
                      </button>
                </div>

            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormNewOrderDetail;
