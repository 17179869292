import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'

import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const AdminPromotionView = ({title}) => {

  const [product, setProduct] = useState({});
  const [brand, setBrand] = useState({})
  const [promotion, setPromotion] = useState({})


  const location = useLocation()
  const pathArray = location.pathname.split('/')


  async function getProduct(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${id}`)
    const productArray = await res.json();
    console.log (productArray)
    setProduct(productArray);
  }


  async function getBrand(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/id/${id}`)
    const brandArray = await res.json();
    console.log (brandArray)
    setBrand(brandArray);
  }

  async function getPromotion() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}promo/id/${pathArray[3]}`)
    const promotionArray = await res.json();
    console.log (promotionArray)
    setPromotion(promotionArray);
    getBrand(promotionArray.brand_id)
    getProduct(promotionArray.product_id)
  }


  useEffect(() => {
    getPromotion();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();

  }

  function handleSubmit(e) {
    console.log ("navigating")
  }

  const img = {
    width: '100%',
    height: '100%',
    maxWidth: '500px',
    maxHeight: '500px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const row = {
    justifyContent: 'center',
  }

  let productView;
  if (product){
    productView = (
      <div className="row" style={row}>
        <div className="col-lg-12">
          <div class="text-center">
            <img src={product.pic} class="rounded" style={img} alt="CBR0" border="0" />
          </div>
        </div>
        <div className="col-lg-6">
          <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"  id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2">
            <div class="table-responsive">
              <table class="table table-bordered ps-table ps-table--specification">
                <tbody>
                  <tr>
                    <td>Brand</td>
                    <td>{brand.name}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{brand.country}</td>
                  </tr>
                  <tr>
                    <td>Product Name</td>
                    <td>{product.name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{product.description}</td>
                  </tr>
                  <tr>
                    <td>Variant</td>
                    <td>{product.variant}</td>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <td>{product.sku}</td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td style={tableRow}>
                      <Link to={`/admin/p/${product.id}`}>
                        <button className="ps-btn">View Product</button>
                      </Link>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
          </div>


        </div>

      </div>

    )
  } else {
    productView = (<div>No Product Found</div>)
  }


  let modified_view

  if (promotion.modified_by) {
    modified_view = (<div>Last modified by {promotion.modified_by} on {moment(promotion.date_modified).format('lll')}</div>)
  }

  return (
    <section className="ps-my-account ps-page--account">
    <div className="container">
        <div className="row">



          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">



                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>Promotion ID: {promotion.id}</h3>
                      <h5><strong>Date Created: </strong>{moment(promotion.date_created).format('lll')} {modified_view}</h5>
                    </div>

                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`/admin/promotion/${promotion.id}/edit`}>
                        <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit Promotion</button>
                      </Link>

                    </div>
                  </div>



                </div>
                <div className="ps-form__content">


                  <div className="row">
                      <div className="col-lg-6">
                          <div className="form-group">
                              <label className="form">Promotion Date</label>
                                <div>{moment(promotion.promotion_date).format('lll')}</div>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="form-group">
                              <label className="form">Date Created</label>
                                <div>{moment(promotion.date_created).format('lll')}</div>
                          </div>
                      </div>
                    </div>

                    <div class="separator"></div>



                  {productView}

                  <div class="separator"></div>
                  <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form">Promotion Price</label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>

                            <input className="form-control" value={promotion.price}/>
                            <div class="input-group-append">
                              <span class="input-group-text">CAD</span>
                            </div>
                          </div>
                      </div>
                  </div>

                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




    </div>
</section>

  );

}


export default AdminPromotionView;
