// export default function lang(state = {}, action = {}) {
//   switch (action.type) {
//     case "CHANGE_LANG":
//       return action.lang;
//
//     default:
//       return "cn";
//   }
// }


import { CHANGE_LANG } from '../types'


let lang

const domain = window.location.hostname
const tld = domain.match(/\.[^.]+$/);


if (tld) {
  if (tld[0] === '.ca'){
    lang = "en"
  } else if (tld[0] === '.fr') {
    lang = "fr"
  } else if (tld[0] === '.cn') {
    lang = "cn"
  } else {
    lang = "en"
  }
  console.log(tld[0]); // This will log ".ca" to the console.
} else {
  lang = "en"
}

console.log (lang)



const initialState = {
  default: lang
}

export default function(state = initialState, action){
  switch(action.type){
    case CHANGE_LANG:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state;

  }
}
