import DeviceDetector from "device-detector-js"
import moment from 'moment'

var device_ip_source = require("ip")

export const stickyHeader = () => {
  let number =
    window.pageXOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;

  const header = document.getElementById('headerSticky')
  if (header !== null) {
    if (number >= 96) {
      // if (number >= 300) {
      header.classList.add('header--sticky')
    } else {
      header.classList.remove('header--sticky')
    }
  }
}


function convertToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr)

  return array.map(it => {

    // console.log (Object.values(it))
    const line = Object.values(it)

    for (var i = 0; i < line.length; i++){
      if (line[i] != null){
        const entry = line[i].toString()

        line[i] = entry.replaceAll('#', '').replaceAll(',', '').replace(/(\r\n|\n|\r)/gm, "");
      }

    }
    return line
  }).join('\n')
}

export const makeCSVExport = (array, title) => {
  console.log (array)

  const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(array)
  console.log (csvContent)
  const dateFormat = 'YYYY-MM-DD'
  const date = moment(new Date()).format(dateFormat)
  // console.log (csvContent)

  const encodedUri = encodeURI(csvContent)

  console.log (encodedUri)
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${title}-${date}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click()

}

export const copyToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

export const stickyHeaderPlaceholder = () => {
  let number =
    window.pageXOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;

  const header_placeholder = document.getElementById('headerSticky_placeholder')
  if (header_placeholder !== null) {
    if (number >= 96) {
      // if (number >= 300) {
      header_placeholder.classList.add('header--placeholder--open')
    } else {
      header_placeholder.classList.remove('header--placeholder--open')
    }
  }
}

export const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
        result.push(i);
    }
    return result;
};


export const makeID = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export const getDeviceDetails = () => {
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  return deviceDetector.parse(userAgent);
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export const saveDeviceActions = async (url, action, access_method, modified_by, note) => {
  try {

    const date_created = new Date()

    const device_ip = device_ip_source.address()
    const device_details = getDeviceDetails()

    const os_name = device_details.os.name
    const os_version = device_details.os.version
    const os_platform = device_details.os.platform
    const device_type = device_details.device.type
    const device_brand = device_details.device.brand
    const device_model = device_details.device.model
    const client_type = device_details.client.type
    const client_name = device_details.client.name
    const client_version = device_details.client.version
    const client_engine = device_details.client.engine
    const client_engine_version = device_details.client.engineVersion



    const body = { date_created, device_ip, os_name, os_version, os_platform,
      device_type, device_brand, device_model, client_type, client_name, client_version, client_engine, client_engine_version, url, action, access_method, modified_by, note };
    // console.log (body)

    const response = await fetch(`${process.env.REACT_APP_API_URL}log`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }).then((response) => {
      return
    })

  } catch (err) {
    console.error(err.message);
  }
};


export const saveFeedback = async (feedback, ugh) => {
  try {
    const date_created = new Date()

    const body = {date_created, feedback, ugh}

    const response = await fetch(`${process.env.REACT_APP_API_URL}feedback`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }).then((response) => {
      return response
    })
  } catch (err) {
    console.error(err.message)
  }
}
