import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Form, Input, Checkbox, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
// import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Popconfirm, message, Tooltip, Switch, Button, Modal } from 'antd'
import moment from 'moment'
import {formatter, formatPhoneNumber} from '../../../utilities/common-helpers'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')


const mapState = ({ user }) => ({
  user: user,
})



const FormEditOrder = () => {

  const {user, selectedUnit } = useSelector(mapState)
  const dateFormat = 'YYYY-MM-DD'


  // console.log (user)
  // const [date_created, setDate_created] = useState("");

  const [order_number, setOrder_number] = useState("");
  const [order_date, setOrder_date] = useState("");
  const [order_reference, setOrder_reference] = useState("");
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const [isNewCustomerSaved, setIsNewCustomerSaved] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(true)

  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [loadingDropdowns_brands, setLoadingDropdowns_brands] = useState(false)
  const [loadingDropdowns_products, setLoadingDropdowns_products] = useState([false])
  const [loadingDropdowns_skus, setLoadingDropdowns_skus] = useState([false])
  const [loadingDropdowns_promos, setLoadingDropdowns_promos] = useState([false])

  const [showingPreview, setShowingPreview] = useState(false)

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [customerList, setCustomerList] = useState([])
  const [customerList_object, setCustomerList_object] = useState({})
  const [customer_record_id, setCustomer_record_id] = useState();
  const [customer_name, setCustomer_name] = useState("")
  const [customer_phone, setCustomer_phone] = useState("")


  const [order_record_id, setOrder_record_id] = useState() //DELETE THIS AS WE DO NOT NEED TO CREATE A NEW ORDER

  const [tracking_id, setTracking_id] = useState("")


  const [tyl_tracking_id, setTyl_tracking_id] = useState("")
  const [tyl_date_delivered, setTyl_date_delivered] = useState("");


  const [order_status, setOrder_status] = useState("")

  const [selectionList, setSelectionList] = useState([''])


  const [brandList, setBrandList] = useState([]);
  const [productList, setProductList] = useState([])
  const [skuList, setSkuList] = useState([])
  const [promoList, setPromoList] = useState([])
  const [quantityList, setQuantityList] = useState([''])
  const [selected_brands, setSelected_brands] = useState([''])
  const [selected_products, setSelected_products] = useState([''])
  const [selected_skus, setSelected_skus] = useState([''])
  const [selected_promos, setSelected_promos] = useState([''])
  const [selected_promos_price, setSelected_promos_price] = useState([''])
  const [selected_skus_view, setSelected_skus_view] = useState([''])
  const [selected_pics_view, setSelected_pics_view] = useState([''])

  const [orderDetailList, setOrderDetailList] = useState([])


  //Customer Data
  const [cdata_addressLine, setCdata_addressLine] = useState('')
  const [cdata_addressLine_pinyin, setCdata_addressLine_pinyin] = useState('')
  // const [cdata_address_2, setCdata_address_2] = useState([''])
  // const [cdata_address_3, setCdata_address_3] = useState([''])
  const [cdata_address_zip, setCdata_address_zip] = useState('')
  const [cdata_address_city, setCdata_address_city] = useState('')
  const [cdata_address_city_pinyin, setCdata_address_city_pinyin] = useState('')
  const [cdata_attention_name, setCdata_attention_name] = useState('')
  const [cdata_attention_name_pinyin, setCdata_attention_name_pinyin] = useState('')
  const [cdata_address_country, setCdata_address_country] = useState('')
  const [cdata_address_country_pinyin, setCdata_address_country_pinyin] = useState('')
  const [cdata_address_state, setCdata_address_state] = useState('')
  const [cdata_address_state_pinyin, setCdata_address_state_pinyin] = useState('')
  const [cdata_name, setCdata_name] = useState('')
  const [cdata_name_pinyin, setCdata_name_pinyin] = useState('')
  const [cdata_phone_number, setCdata_phone_number] = useState('')

  //Package Data
  const [pdata_dimWeight, setPdata_dimWeight] = useState('')
  const [pdata_dimensions_height, setPdata_dimensions_height] = useState(8)
  const [pdata_dimensions_length, setPdata_dimensions_length] = useState(25)
  const [pdata_dimensions_width, setPdata_dimensions_width] = useState(20)
  const [pdata_packageWeight, setPdata_packageWeight] = useState('')
  const [pdata_package_shipping_label, setPdata_package_shipping_label] = useState('')
  const [package_size_type, setPackage_size_type] = useState('S1')
//THIS IS THE DELETED SET
  // const [brandList_deleted, setBrandList_deleted] = useState([]);
  // const [productList_deleted, setProductList_deleted] = useState([])
  // const [skuList_deleted, setSkuList_deleted] = useState([])
  // const [promoList_deleted, setPromoList_deleted] = useState([])
  // const [quantityList_deleted, setQuantityList_deleted] = useState([''])
  // const [selected_brands_deleted, setSelected_brands_deleted] = useState([''])
  // const [selected_products_deleted, setSelected_products_deleted] = useState([''])
  // const [selected_skus_deleted, setSelected_skus_deleted] = useState([''])
  // const [selected_promos_deleted, setSelected_promos_deleted] = useState([''])
  // const [selected_promos_price_deleted, setSelected_promos_price_deleted] = useState([''])
  // const [selected_skus_view_deleted, setSelected_skus_view_deleted] = useState([''])
  // const [selected_pics_view_deleted, setSelected_pics_view_deleted] = useState([''])

  let location = useLocation()
  const pathArray = location.pathname.split('/')

  useEffect(() => {
    getCustomers();
    getOrder()


  }, []);

  function downloadShippingLabel (e){
    e.preventDefault()
    console.log ('Saving image...')

    var aDownloadLink = document.createElement('a')
    aDownloadLink.download = 'UPS_ShippingLabel_oid_' + pathArray[3] + '.png'
    aDownloadLink.href = 'data:image/jpeg;base64,' + pdata_package_shipping_label
    aDownloadLink.click()
  }

  function showShippingLabelPreview (e){
    e.preventDefault()
    console.log ('Showing Image...')
    setShowingPreview(true)
  }

  function hideShippingLabelPreview (e){
    e.preventDefault()
    setShowingPreview(false)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();


    //FORM VALIDATION: Missing prices, means the form isn't complete
    let formCompleted = true

    //CUSTOMER VALIDATION
    if ((!customer_record_id || customer_record_id == 0) && isNewCustomer == false){
      message.warning  ('Please select a Customer')
      formCompleted = false
    } else if (isNewCustomer){
      if (!customer_name){
        message.warning ('Please enter a Customer Name')
        formCompleted = false
      } else if (!customer_phone){
        message.warning ('Please enter a Phone Number')
        formCompleted = false
      }

    }

    //ORDER INFORMATION VALIDATION
    if (formCompleted){
      if (!order_date){
        message.warning ('Please enter an Order Date')
        formCompleted = false
      } else if (!order_number){
        message.warning ('Please enter an Order Number')
        formCompleted = false
      } else if (!order_reference){
        message.warning ('Please enter an Order Reference Number')
        formCompleted = false
      }
    }

    //LINE ITEM VALIDATION
    if (formCompleted){
      for (var i = 0; i < selected_promos.length; i++){
        //CHECKS FOR LOWEST NEST IN THE DEPENDENCY OF DROPDOWN SELECTIONS (PROMOS SELECTION)
        if (selected_promos[i] == '' || selected_promos[i] == null || selected_promos[i] == 0){
          message.warning ('Please either remove or complete the selections for Item: ' + (i + 1))
          formCompleted = false
        }
        //CHECKS FOR MISSING QUANTITIES
        if (quantityList[i] == '' || quantityList[i] == null || quantityList[i] == 0){
          message.warning ('Please enter Quantity for Item: ' + (i + 1))
          formCompleted = false
        }

      }
    }

    //DUPLICATE VALIDATION
    //check for duplicates of the Promo IDs selected, to ensure no issues with determining new, existing, and to be deleted records
    const toFindDuplicates = selected_promos => selected_promos.filter((item, index) => selected_promos.indexOf(item) !== index)
    const duplicateElements = toFindDuplicates(selected_promos);

    if (duplicateElements.length != 0){
      message.warning ("You've entered duplicate line items. Please remove one and adjust the quantities accordingly.")
      formCompleted = false
    }

    if (formCompleted){
      if (isNewCustomer){
        if (isNewCustomerSaved){
          updateOrder(customer_record_id)
        } else {
          createNewCustomer()
        }
      } else {
        updateOrder(customer_record_id)
      }
    }
  };

  async function createNewCustomer() {
    try {
      setLoading(true)
      const created_by = user.credentials.email
      const date_created = new Date ()
      const phone_number = customer_phone
      const body = { date_created, customer_name, phone_number, created_by };
      // console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}customer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {
        if (data.data.code == 1000 && data.code !== 0 ){
          message.warning('Customer with this phone number already exists!')
        } else {
          message.success('Customer created with returning ID: ' + data.data[0].id)
          const customer_id = data.data[0].id
          setIsNewCustomerSaved(true)
          setCustomer_record_id(customer_id)
          updateOrder(customer_id)
        }

        setLoading(false)
      })


      // window.location = "/admin/customers";

    } catch (err) {
      message.warning ('Something went wrong with creating a New Customer!')
      message.error (err.message)
      console.error(err.message);
      setLoading(false)
    }
  }













  async function updateOrder(customer_id){
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()

      const body = {date_modified, modified_by, order_number, order_date, order_reference, customer_id, order_status, tracking_id, tyl_tracking_id, tyl_date_delivered, cdata_name, cdata_name_pinyin, cdata_attention_name, cdata_attention_name_pinyin, cdata_phone_number, cdata_addressLine, cdata_addressLine_pinyin, cdata_address_city, cdata_address_city_pinyin, cdata_address_state, cdata_address_state_pinyin, cdata_address_country, cdata_address_country_pinyin, cdata_address_zip, pdata_dimensions_width, pdata_dimensions_height, pdata_dimensions_length, pdata_packageWeight, package_size_type}
      console.log (body)
      const response = await fetch(`${process.env.REACT_APP_API_URL}o/id/${pathArray[3]}/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {

        if (data.code == 1002 && data.data.code == 1000 ){
          message.warning('Order with this Reference Number already exists for a different Order!')
        } else if (data.code == 0){
          message.success('Order successfully updated')

          //UPDATE THE ORDER DETAILS
          updateOrderDetails()

        } else {
          console.log (data)
          message.warning ('Something went wrong while updating the Order!')
        }

        setLoading(false)
      })
      // window.location = "/admin/orders";
    } catch (err) {
      message.warning ('Something went wrong while updating the Order!')
      message.error (err.message)
      console.error(err.message);
      console.error(err);
      setLoading(false)
    }
  }

  async function updateOrderDetails(){
    try {
      setLoading(true)
      const modified_by = user.credentials.email
      const date_modified = new Date()

      let data_array_new_records = []
      let data_array_existing_records = []
      let data_array_existing_records_deleted = []


      //checking for selected items in existing
      for (var i = 0; i < selected_promos.length; i++){
        let foundSelectedInExisting = false
        let existing_order_detail_id
        for (var existingRecordsIndex = 0; existingRecordsIndex < orderDetailList.length; existingRecordsIndex++){
          //determine if any selected skus have been deleted and must now be flagged as "inactive"
          if (orderDetailList[existingRecordsIndex].promotion_id == selected_promos[i]){
            existing_order_detail_id = orderDetailList[existingRecordsIndex].id
            foundSelectedInExisting = true
            data_array_existing_records.push({id: existing_order_detail_id, quantity: quantityList[i], order_id: pathArray[3], brand_id: selected_brands[i], product_id: selected_products[i], promotion_id: selected_promos[i], sku_id: selected_skus[i], active: true, date_modified: date_modified, modified_by: modified_by})
            break
          }
        }
        //if item selected is not found in the existing, it must be new
        if (foundSelectedInExisting == false){
          data_array_new_records.push({quantity: quantityList[i], order_id: pathArray[3], brand_id: selected_brands[i], product_id: selected_products[i], promotion_id: selected_promos[i], sku_id: selected_skus[i], active: true, date_modified: date_modified, modified_by: modified_by})
        }
      }


      //checking existing items in selected
      for (var existingRecordsIndex = 0; existingRecordsIndex < orderDetailList.length; existingRecordsIndex++){
        let foundExistingInSelected = false
        // let existing_order_detail_id
        for (var i = 0; i < selected_promos.length; i++){
          if (orderDetailList[existingRecordsIndex].promotion_id == selected_promos[i]){
            // existing_order_detail_id = orderDetailList[existingRecordsIndex].id
            foundExistingInSelected = true

            break
          }
        }
        //if item in existing is not found in the selected, it must be deleted
        if (foundExistingInSelected == false){
          data_array_existing_records_deleted.push({id: orderDetailList[existingRecordsIndex].id, active: false, deleted_at: date_modified, deleted_by: modified_by})
        }
      }

      const body_new_records = {data: data_array_new_records}
      const body_existing_records = {data: data_array_existing_records}
      const body_existing_records_deleted = {data: data_array_existing_records_deleted}


      console.log (body_new_records)
      console.log (body_existing_records)
      console.log (body_existing_records_deleted)


      const response = await fetch(`${process.env.REACT_APP_API_URL}od/a=edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body_existing_records),
      }).then(response => response.json())
      .then(data => {
        let isCreateNewOrderDetailDone = false
        let isDeleteExistingOrderDetailsDone = false

        if (data.code == 409){
          message.error(data.message)
        } else if (data.code == 1002) {
          message.error(data.message)
        } else {
          message.success('Order Detail(s) Successfully updated')
          if (body_new_records.data.length != 0){
            isCreateNewOrderDetailDone = createNewOrderDetails(body_new_records)
          } else {
            message.warning('No new Order Details to create')
            isCreateNewOrderDetailDone = true
          }
          if (body_existing_records_deleted.data.length != 0){
            isDeleteExistingOrderDetailsDone = deleteExistingOrderDetails(body_existing_records_deleted)
          } else {
            message.warning('No existing Order Details to delete')
            isDeleteExistingOrderDetailsDone = true
          }
          setLoading(false)
          if (data && isCreateNewOrderDetailDone && isDeleteExistingOrderDetailsDone){
            // console.log ('RELOADING')
            setTimeout(function() {
              window.location = "/admin/orders"
            }, 1000)
          } else {
            message.warning ('In order to minimize conflict due to a partial save, please refresh the page and try editing again.')
          }
          // window.location = "/admin/orders"
        }
      })

    } catch (err) {
      message.warning ('Something went wrong with updating existing Order Details!')
      message.error (err.message)
      console.error(err.message);
      console.error(err);
      setLoading(false)
    }

  }

  async function createNewOrderDetails(body_new_records){
    try {
      setLoading(true)


      const body = body_new_records

      const response = await fetch(`${process.env.REACT_APP_API_URL}od`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {
        if (data.code == 409){
          message.error(data.message)
          return false
        } else if (data.code == 1002) {
          message.error(data.message)
          return false
        } else {
          for (var i = 0; i < data.data.length; i++){
              message.success('Order Detail(s) Successfully created with returning ID: ' + data.data[i].id)
          }
          return true
          // window.location = "/admin/orders"
        }
        setLoading(false)
      })
      // window.location = "/admin/orders";
    } catch (err) {
      message.warning ('Something went wrong with creating a New Order Detail!')
      message.error (err.message)
      console.error(err.message);
      console.error(err);
      setLoading(false)
    }

  }

  async function deleteExistingOrderDetails(body_existing_records_deleted){
    try {
      setLoading(true)


      const body = body_existing_records_deleted

      const response = await fetch(`${process.env.REACT_APP_API_URL}od/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data => {
        if (data.code == 409){
          message.error(data.message)
          return false
        } else if (data.code == 1002) {
          message.error(data.message)
          return false
        } else {
          message.success('Order Detail(s) Successfully deleted!')

          return true
          // window.location = "/admin/orders"
        }
        setLoading(false)
      })
      // window.location = "/admin/orders";
    } catch (err) {
      message.warning ('Something went wrong with deleting an existing Order Detail!')
      message.error (err.message)
      console.error(err.message);
      console.error(err);
      setLoading(false)
    }

  }




  async function getCustomers() {
    // console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/all`)
    const customersArray = await res.json()
    let list = {}
    for (var i = 0; i < customersArray.length; i++){
      list[customersArray[i].id] = {customer_name: customersArray[i].customer_name, phone_number: formatPhoneNumber(customersArray[i].phone_number)}
    }
    setCustomerList_object(list)
    setCustomerList(customersArray);
    setLoadingDropdowns(false)
  }

  async function getBrands_onload() {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}b/all`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  // async function getBrands() {
  //   console.log ("loading...")
  //   setLoadingDropdowns_brands(true)
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
  //   const brandsArray = await res.json().then(data => {
  //     console.log (data)
  //     // const brand_list = [...brandList, data]
  //     // setBrandList(brand_list);
  //     setLoadingDropdowns_brands(false)
  //     // getOrder(data)
  //   })
  // }

  async function getOrder() {
    // console.log ("loading...")
    // setLoadingDropdowns_brands(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/id/${pathArray[3]}`)
    const orderArray = await res.json()
    // console.log (orderArray)

    setOrder_status(orderArray.order_status)
    setTracking_id(orderArray.tracking_id)
    setOrder_date(orderArray.order_date)
    setOrder_number(orderArray.order_number)
    setOrder_reference(orderArray.order_reference)
    setIsNewCustomer(false)
    setCustomer_record_id(orderArray.customer_id)

    setTyl_tracking_id(orderArray.tyl_tracking_id)
    setTyl_date_delivered(orderArray.tyl_date_delivered)

    setCdata_name(orderArray.customer_ship_to_name)
    setCdata_attention_name(orderArray.customer_ship_to_attention_name)
    setCdata_addressLine(orderArray.customer_ship_to_address_line)
    setCdata_address_country(orderArray.customer_ship_to_address_country_code)
    setCdata_address_state(orderArray.customer_ship_to_address_state_province_code)
    setCdata_address_city(orderArray.customer_ship_to_address_city)
    setCdata_address_zip(orderArray.customer_ship_to_address_postal_code)
    setCdata_phone_number(orderArray.customer_ship_to_phone_number)

    setCdata_name_pinyin(orderArray.customer_ship_to_name_pinyin)
    setCdata_attention_name_pinyin(orderArray.customer_ship_to_attention_name_pinyin)
    setCdata_addressLine_pinyin(orderArray.customer_ship_to_address_line_pinyin)
    setCdata_address_country_pinyin(orderArray.customer_ship_to_address_country_code_pinyin)
    setCdata_address_state_pinyin(orderArray.customer_ship_to_address_state_province_code_pinyin)
    setCdata_address_city_pinyin(orderArray.customer_ship_to_address_city_pinyin)


    setPdata_dimWeight(orderArray.package_dim_weight)
    setPdata_dimensions_height(orderArray.package_dimensions_height)
    setPdata_dimensions_length(orderArray.package_dimensions_length)
    setPdata_dimensions_width(orderArray.package_dimensions_width)
    setPdata_packageWeight(orderArray.package_package_weight)

    setPdata_package_shipping_label(orderArray.package_shipping_label)
    setPackage_size_type(orderArray.package_size_type)

    //getOrderDetails
    getOrderDetailsByOrderID(pathArray[3] )

    const title = "Luxanda | Edit Order: " + pathArray[3]
    title && (document.title = title);

    //GET SKUS
    //GET PROMOS

    // setBrandList([...brandList, brandsArray]);
    // setLoadingDropdowns_brands(false)
  }

  async function getOrderDetailsByOrderID(order_id) {
    // console.log ("loading...")

    // console.log (brand_list)
    // setLoadingDropdowns_brands(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/oid/${order_id}`)
    const orderDetailsArray = await res.json()
      setLoadingProperties(true)
      setOrderDetailList(orderDetailsArray)



      console.log (orderDetailsArray)
      // console.log (brand_list)
      // console.log (brandList)
      let selection_array = []
      let brand_selected_array = []
      let product_selected_array = []
      let sku_selected_array = []
      let promo_selected_array = []
      let price_selected_array = []
      let sku_view_selected_array = []
      let pics_view_selected_array = []
      let quantityArray = []


      // const [selected_promos_price, setSelected_promos_price] = useState([''])
      // const [selected_skus_view, setSelected_skus_view] = useState([''])
      // const [selected_pics_view, setSelected_pics_view] = useState([''])
      // let brandsArray = []
      let productsArray = []
      let skuArray = []
      let promoArray = []
      let promoSelectionArray = []

      const brandsArray = [await getBrands_onload()]
      // console.log (orderDetailsArray)

      try{

      for (var i = 0; i < orderDetailsArray.length; i++){

        //this is templating, the Brands do not change during edit/creation, so we can replicate it for as many line items as we have
        selection_array.push('')

        //the is to populat the onload dropdown items
        brandsArray.push(brandsArray[0])



        const data_skus = await getSkusByProduct_onload(orderDetailsArray[i].product_id)
        const data_promos = await getPromotionsByActiveSku_onload(orderDetailsArray[i].id, orderDetailsArray[i].order_id)
        const data_promos_dropdown = await getPromotionsByActiveSku(orderDetailsArray[i].sku_id, i)


        productsArray.push(await getProductsByBrand_onload(orderDetailsArray[i].brand_id))
        skuArray.push(data_skus)
        promoArray.push(data_promos)
        promoSelectionArray.push(data_promos_dropdown)
        quantityArray.push(orderDetailsArray[i].quantity)

        console.log (data_promos)

        for (var x = 0; x < data_promos.length; x++){
          //META DATA
          if (data_promos[x].id === orderDetailsArray[i].promotion_id){
            price_selected_array.push(data_promos[x].price)
          }
        }

        for (var x = 0; x < data_skus.length; x++){
          //META DATA
          if (data_skus[x].id === orderDetailsArray[i].sku_id){
            sku_view_selected_array.push(data_skus[x].sku)
            pics_view_selected_array.push(data_skus[x].pic_url)
          }
        }

        //this is to populate the selection arrays
        brand_selected_array.push(orderDetailsArray[i].brand_id)
        product_selected_array.push(orderDetailsArray[i].product_id)
        sku_selected_array.push(orderDetailsArray[i].sku_id)
        promo_selected_array.push(orderDetailsArray[i].promotion_id)
      }

      } catch (err) {
        console.log (err)
        message.error('The Network Request has Timed-Out, the page will now refresh.')
        message.error (err.message)
        window.location.reload()
      }


      // console.log ('brandsArray')
      // console.log (brandsArray)
      // console.log ('productsArray')
      // console.log (productsArray)
      // console.log ('skuArray')
      // console.log (skuArray)
      // console.log ('promoArray')
      // console.log (promoArray)
      //
      // console.log ('price_selected_array')
      // console.log (price_selected_array)
      // console.log ('sku_view_selected_array')
      // console.log (sku_view_selected_array)
      // console.log ('pics_view_selected_array')
      // console.log (pics_view_selected_array)
      // console.log ('quantityArray')
      // console.log (quantityArray)
      //
      //
      // console.log ('sku_selected_array')
      // console.log (sku_selected_array)
      // console.log ('promo_selected_array')
      // console.log (promo_selected_array)
      console.log (promoSelectionArray)


      // console.log (brand_selection_array)
      setSelectionList(selection_array)
      setBrandList(brandsArray)
      setProductList(productsArray)
      setSkuList(skuArray)
      setPromoList(promoSelectionArray)


      setSelected_brands(brand_selected_array)
      setSelected_products(product_selected_array)
      setSelected_skus(sku_selected_array)
      setSelected_promos(promo_selected_array)

      //META DATA
      setSelected_pics_view(pics_view_selected_array)
      setSelected_skus_view(sku_view_selected_array)
      setSelected_promos_price(price_selected_array)
      setQuantityList(quantityArray)

      getOrderTotal()
      getOrderQuantityTotal()

      setLoadingProperties(false)


    // setSelected_brands(list_brands)
    // setOrder_status(orderArray.order_status)
    // setTracking_id(orderArray.tracking_id)
    // setOrder_date(orderArray.order_date)
    // setOrder_number(orderArray.order_number)
    // setOrder_reference(orderArray.order_reference)
    // setIsNewCustomer(false)
    // setCustomer_record_id(orderArray.customer_id)

    //GET SKUS
    //GET PROMOS

    // setBrandList([...brandList, brandsArray]);
    // setLoadingDropdowns_brands(false)
  }

  async function getProductsByBrand_onload(record_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}p/b/${record_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  async function getProductsByBrand(record_id, index) {
    // console.log ("loading...")
    const loading_list = [...loadingDropdowns_products]
    loading_list[index] = true
    setLoadingDropdowns_products(loading_list)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/b/${record_id}`)
    const data = await res.json().then(productsArray => {
      let list = [...productList];
      list[index] = productsArray

      // setQuantityList(list)
      // setProductList(list);

      loading_list[index] = false
      setLoadingDropdowns_products(loading_list)

      setProductList(list);
    })


    // setLoadingDropdowns_products(false)
  }

  async function getSkusByProduct_onload(record_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}sku/pid/${record_id}/s=active`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  async function getSkusByProduct(record_id, index) {
    // console.log ("loading...")
    const loading_list = [...loadingDropdowns_skus]
    loading_list[index] = true
    setLoadingDropdowns_skus(loading_list)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${record_id}/s=active`)
    const skusArray = await res.json()
    // console.log (skusArray)

    const list = [...skuList];
    list[index] = skusArray

    loading_list[index] = false
    setLoadingDropdowns_skus(loading_list)
    // console.log (list)
    setSkuList(list);
    // setLoadingDropdowns_products(false)
  }

  async function getPromotionsByActiveSku_onload(order_detail_d, order_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}promos/o_d/${order_detail_d}/order_id/${order_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  async function getPromotionsByActiveSku(record_id, index) {
    // console.log ("loading...")
    // console.log ("index: " + index)
    // console.log ("record id: " + record_id)
    const loading_list = [...loadingDropdowns_promos]
    loading_list[index] = true
    setLoadingDropdowns_promos(loading_list)

    const res = await fetch(`${process.env.REACT_APP_API_URL}promos/sku/${record_id}`)
    const promosArray = await res.json()
    // console.log ('promosArray')
    // console.log (promosArray)

    const list = [...promoList];
    list[index] = promosArray

    loading_list[index] = false
    setLoadingDropdowns_promos(loading_list)

    // console.log (list)
    setPromoList(list);

    return promosArray
    // setLoadingDropdowns_products(false)
  }



  const handleSelect = (item, type) => {

    if (type == 'customer_record_id'){
      setCustomer_record_id(item.target.value)
    } else if (type == 'order_status'){
      setOrder_status(item.target.value)
    } else if (type == 'isNewCustomer'){
      setCustomer_record_id(0)
      setIsNewCustomer(item.target.checked)
      if (item.target.checked == false){
        setIsNewCustomerSaved(false)
      }
    } else if (type == 'package_size'){
      setPdata_dimensions_width(20)
      setPdata_dimensions_height(8)
      setPdata_dimensions_length(25)
      setPackage_size_type(item.target.value)
      // setPdata_packageWeight(item.target.value)

    }
  }

  const fieldChanged = (type, value) => {

    if (type == 'order_number'){
       setOrder_number(value);
    } else if (type == 'order_date'){
      setOrder_date(value);
    } else if (type == 'order_reference'){
      setOrder_reference(value);
    } else if (type == 'tracking_id'){
      setTracking_id(value)
    } else if (type == 'customer_name'){
      setCustomer_name(value)
    } else if (type == 'customer_phone'){
      setCustomer_phone(value)
    } else if (type == 'tyl_date_delivered'){
      setTyl_date_delivered(value)
    } else if (type == 'tyl_tracking_id'){
      setTyl_tracking_id(value)
    } else if (type == 'cdata_name'){
      setCdata_name(value)
    } else if (type == 'cdata_name_pinyin'){
      setCdata_name_pinyin(value)
    } else if (type == 'cdata_attention_name'){
      setCdata_attention_name(value)
    } else if (type == 'cdata_attention_name_pinyin'){
      setCdata_attention_name_pinyin(value)
    } else if (type == 'cdata_phone_number'){
      setCdata_phone_number(value)
    } else if (type == 'cdata_addressLine'){
      setCdata_addressLine(value)
    } else if (type == 'cdata_addressLine_pinyin'){
      setCdata_addressLine_pinyin(value)
    } else if (type == 'cdata_address_city'){
      setCdata_address_city(value)
    } else if (type == 'cdata_address_city_pinyin'){
      setCdata_address_city_pinyin(value)
    } else if (type == 'cdata_address_state'){
      setCdata_address_state(value)
    } else if (type == 'cdata_address_state_pinyin'){
      setCdata_address_state_pinyin(value)
    } else if (type == 'cdata_address_country'){
      setCdata_address_country(value)
    } else if (type == 'cdata_address_country_pinyin'){
      setCdata_address_country_pinyin(value)
    } else if (type == 'cdata_address_zip'){
      setCdata_address_zip(value)
    } else if (type == 'pdata_packageWeight'){
      setPdata_packageWeight(value)
    }

  };

  // const handleSelect_brand = (item, type, index) => {
  //
  //   if (type == 'brand_id'){
  //
  //     setBrand_id(item.target.value)
  //
  //     getProductsByBrand(item.target.value, index)
  //   }
  // }


  const resetSelections = (item, index, type) => {
    // THESE ARE TO RESET PRESELECTED VALUES
    const selected_products_list = [...selected_products]
    const product_list = [...productList]

    const skus_list = [...skuList]
    skus_list[index] = null

    const promo_list = [...promoList]
    promo_list[index] = null

    const selected_skus_list = [...selected_skus]
    selected_skus_list[index] = null

    const selected_skus_view_list = [...selected_skus_view]
    selected_skus_view_list[index] = null

    const selected_pics_view_list = [...selected_pics_view]
    selected_pics_view_list[index] = null

    const selected_promos_list = [...selected_promos]
    selected_promos_list[index] = null

    const selected_promos_price_list = [...selected_promos_price]
    selected_promos_price_list[index] = null

    if (type == 'brand_change'){
      selected_products_list[index] = null
      setSelected_products(selected_products_list)
      product_list[index] = null
      setProductList(product_list)
    }



    setSkuList(skus_list)
    setPromoList(promo_list)
    setSelected_skus(selected_skus_list)
    setSelected_skus_view(selected_skus_view_list)
    setSelected_pics_view(selected_pics_view_list)
    setSelected_promos(selected_promos_list)
    setSelected_promos_price(selected_promos_price_list)

  }

  const handleBrandSelectionChange = (item, index) => {
    const list = [...selected_brands];
    list[index] = item.target.value
    setSelected_brands(list)
    resetSelections(item, index, 'brand_change')

    getProductsByBrand(item.target.value, index)
  }

  const handleProductSelectionChange = (item, index) => {
    const list = [...selected_products];
    list[index] = item.target.value

    setSelected_products(list)
    resetSelections(item, index, 'product_change')
    getSkusByProduct(item.target.value, index)
  }

  const handleSkuSelectionChange = (item, index) => {

    const list_skus = [...selected_skus_view]
    list_skus[index] = item.target.selectedOptions[0].dataset.sku

    const list_pics = [...selected_pics_view]
    list_pics[index] = item.target.selectedOptions[0].dataset.pic

    const list = [...selected_skus];
    list[index] = item.target.value

    const selected_promos_list = [...selected_promos]
    selected_promos_list[index] = null

    const selected_promos_price_list = [...selected_promos_price]
    selected_promos_price_list[index] = null

    setSelected_skus_view(list_skus)
    setSelected_pics_view(list_pics)
    setSelected_skus(list)
    setSelected_promos(selected_promos_list)
    setSelected_promos_price(selected_promos_price_list)
    getPromotionsByActiveSku(item.target.value, index)
  }

  const handlePromotionSelectionChange = (item, index) => {
    const list = [...selected_promos];
    list[index] = item.target.value

    const list_prices = [...selected_promos_price]
    list_prices[index] = item.target.selectedOptions[0].dataset.price

    setSelected_promos(list)
    setSelected_promos_price(list_prices)
    // resetSelections(item, index, 'product_change')
    // getSkusByProduct(item.target.value, index)
  }


  const handleAddSelection = (e) => {
    e.preventDefault();
    setSelectionList([...selectionList, ""])
    setBrandList([...brandList, brandList[0]])
    setProductList([...productList, ""])
    setQuantityList([...quantityList, ""])
    setSkuList([...skuList, ""])
    setPromoList([...promoList, ""])
    setSelected_brands([...selected_brands, ""])
    setSelected_products([...selected_products, ""])
    setSelected_skus([...selected_skus, ""])
    setSelected_skus_view([...selected_skus_view, ""])
    setSelected_pics_view([...selected_pics_view, ""])
    setSelected_promos([...selected_promos, ""])
    setSelected_promos_price([...selected_promos_price, ""])
    setLoadingDropdowns_products([...loadingDropdowns_products, false])
    // setOptionValueList([...optionValueList, [""]])
    // setOptionValueList_temp([...optionValueList_temp, [""]])
    // setSerialSelectionList([...serialSelectionList, false])
  }

  const handleRemoveSelection = (e, index) => {
    e.preventDefault();
    const selection_list = [...selectionList]
    selection_list.splice(index, 1)

    const brand_list = [...brandList]
    brand_list.splice(index, 1)

    const product_list = [...productList]
    product_list.splice(index, 1)

    const quantity_list = [...quantityList]
    quantity_list.splice(index, 1)

    const sku_list = [...skuList]
    sku_list.splice(index, 1)

    const promo_list = [...promoList]
    promo_list.splice(index, 1)


    const selected_brand_list = [...selected_brands]
    selected_brand_list.splice(index, 1)

    const selected_products_list = [...selected_products]
    selected_products_list.splice(index, 1)

    const selected_skus_list = [...selected_skus]
    selected_skus_list.splice(index, 1)

    const selected_promos_list = [...selected_promos]
    selected_promos_list.splice(index, 1)

    const selected_promos_price_list = [...selected_promos_price]
    selected_promos_price_list.splice(index, 1)

    const loading_list_products = [...loadingDropdowns_products]
    loading_list_products.splice(index, 1)

    const loading_list_promos = [...loadingDropdowns_promos]
    loading_list_promos.splice(index, 1)



    const selected_skus_view_list = [...selected_skus_view]
    selected_skus_view_list.splice(index, 1)
    const selected_pics_view_list = [...selected_pics_view]
    selected_pics_view_list.splice(index, 1)

    // const option_value_list = [...optionValueList]
    // option_value_list.splice(index, 1)
    //
    // const option_value_list_temp = [...optionValueList_temp]
    // option_value_list_temp.splice(index, 1)
    //
    // const serial_selection_list = [...serialSelectionList]
    // serial_selection_list.splice(index, 1)



    setSelectionList(selection_list)
    setBrandList(brand_list)
    setProductList(product_list)
    setQuantityList(quantity_list)
    setSkuList(sku_list)
    setPromoList(promo_list)
    setSelected_brands(selected_brand_list)
    setSelected_products(selected_products_list)
    setSelected_skus(selected_skus_list)
    setSelected_promos(selected_promos_list)
    setSelected_promos_price(selected_promos_price_list)

    setSelected_skus_view(selected_skus_view_list)
    setSelected_pics_view(selected_pics_view_list)


    setLoadingDropdowns_products(loading_list_products)
    setLoadingDropdowns_products(loading_list_promos)
    // setOptionValueList(option_value_list)
    // setOptionValueList_temp(option_value_list_temp)
    // setSerialSelectionList(serial_selection_list)
    // makeVariantArray(option_value_list)
  }

  const handleQuantityInputChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...quantityList];
    list[index] = value

    setQuantityList(list)
  }





  // function handleSubmit(e){
  //
  // }
  const getOrderTotal = () => {
    let total = 0
    for (var i = 0; i < quantityList.length; i++){
      total += quantityList[i] * selected_promos_price[i]
    }
    return (formatter.format(total))
  }

  const getOrderLineItemTotal = (index) => {
    const total = quantityList[index] * selected_promos_price[index]
    return (formatter.format(total))
  }

  const getOrderQuantityTotal = () => {
    let total = 0

    for (var i = 0; i < quantityList.length; i++){
      if (quantityList[i] != ''){
        total += parseInt(quantityList[i])
      }
    }
    return (total)
  }





  function setSelectedId_View (type) {
    if (type == 'customerList'){
       if (customerList.length > 0){
          return (
            customerList.map((item) => (
            <option value={item.id} key={item.id}  >
              {item.phone_number} - {item.customer_name}
            </option>
          )))
        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }
    }
  }

  // const isListValid = (list, index) => {
  //   var isValid = false
  //   // for (var i = 0; i < list.length; i++){
  //   //   if (list[i] != '' && list[i] != 'DELETE'){
  //   //     foundValidValue = true
  //   //   }
  //   // }
  //   if list[i]
  //   // console.log ('FOUND? ' + foundValidValue)
  //   return isValid
  // }

  const dropdown_customers = setSelectedId_View('customerList')

  const frozenColumn_left = {
  minWidth:'5rem',
  verticalAlign: 'middle',
  position: 'sticky',
  left: '0',
  backgroundColor: '#f1f1f1',
  whiteSpace: 'nowrap',
  paddingLeft: '25px',
  paddingRight: '25px',
  // borderRight: '1px solid #dee2e6',
  zIndex:'999'
}
const frozenColumn_right = {
  minWidth:'6rem',
  verticalAlign: 'middle',
  position: 'sticky',
  right: '0',
  backgroundColor: '#f1f1f1',
  whiteSpace: 'nowrap',
  paddingLeft: '0px',
  paddingRight: '0px',
  // borderRight: '1px solid #dee2e6',
  zIndex:'999'
}
const frozenColumn_right_second = {
  minWidth:'100px',
  verticalAlign: 'middle',
  position: 'sticky',
  right: 'calc(-1px + 6rem)',
  backgroundColor: '#f1f1f1',
  whiteSpace: 'nowrap',
  paddingLeft: '7px',
  paddingRight: '7px',
  // borderRight: '1px solid #dee2e6',
  zIndex:'999'
}
  // const dropdown_brands = setSelectedId_View('brands')
  // const dropdown_products = setSelectedId_View('products')



  // console.log (customerList)
  // console.log (customer_id)
  // // console.log (customerList_object[customer_id].customer_name)
  // console.log ("------------")
  // console.log("LOADING PROPERTIES: " + loadingProperties)
  // // console.log (customer_name)
  // // console.log (customer_phone)
  //
  // // console.log ('selected_skus_view')
  // // console.log (selected_skus_view)
  // // console.log ('selected_pics_view')
  // // console.log (selected_pics_view)
  // // console.log ('brandList')
  // // console.log (brandList)
  // // console.log ('productList')
  // // console.log (productList)
  // selected_products
  // console.log ('selected_brands')
  // console.log (selected_brands)
  // console.log ('selected_products')
  // console.log (selected_products)
  // console.log ('selected_skus')
  // console.log (selected_skus)
  // console.log ('selected_promos')
  // console.log (selected_promos)
  // console.log ('quantityList')
  // console.log (quantityList)
  //

  // console.log ('orderDetailList')
  // console.log (orderDetailList)

  // console.log ('skuList')
  // console.log (skuList)

  // // console.log (isNewCustomer)
  // // console.log ('loadingDropdowns_products')
  // // console.log (loadingDropdowns_products)
  // console.log ('promoList')
  // console.log (promoList)




    return (
      <div>

      {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING PROPERTIES</h5></div> : null}
      {showingPreview ? <div onClick={e => hideShippingLabelPreview(e)} style={{display: 'flex', flexDirection: 'column', position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)',  zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img style={{transform: 'rotate(90deg)', maxHeight: '50vh'}}src={'data:image/jpeg;base64,' + pdata_package_shipping_label}/>
            <div style={{position: 'absolute', textAlign: 'center', bottom: '1.5rem', width: '100%' }}>
              <h5>PRESS ANYWHERE TO DISMISS</h5>
            </div>
          </div> : null}
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Edit Order</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                  <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Customer Information</h3></label>
                        </div>
                  </div>

                  {!isNewCustomer ? <div className="col-lg-3">
                      <div className="form-group">
                          <label className="form">Customer</label>
                          {loadingDropdowns ? <div>Loading...</div> :
                          <select className="form-control" defaultValue={0} value={customer_record_id} onChange={ e=> handleSelect(e, 'customer_record_id')} >
                              {customerList.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {dropdown_customers}
                          </select>}
                      </div>
                  </div> : null}


                  <div className="col-lg-12" style={{alignSelf: 'center'}}>
                      <Form
                        className="form-group"
                        initialValues={{ newCustomer: isNewCustomer }}>


                          <Form.Item name="newCustomer" valuePropName="checked" style={{marginTop:'1rem'}}>
                            <Checkbox onChange={e => handleSelect(e, 'isNewCustomer')}>New Customer?</Checkbox>
                          </Form.Item>

                      </Form>
                  </div>
                  {isNewCustomer ?
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Name</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Customer Name"
                                value={customer_name}
                                onChange={e => fieldChanged('customer_name', e.target.value)}
                              />
                        </div>
                    </div>

                  : null}
                  {isNewCustomer ?
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Phone Number</label>
                            <input
                                className='form-control'
                                type="number"
                                placeholder="Phone Number"
                                value={customer_phone}
                                onChange={e => fieldChanged('customer_phone', e.target.value)}
                              />
                        </div>
                    </div>

                  : null}





                    {customer_record_id != 0 && !isNewCustomer?
                      <div className="col-lg-3">
                          <div className="form-group">
                              <label className="form">Name</label>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  placeholder="Customer Name"
                                  value={customerList_object[customer_record_id] && customerList_object[customer_record_id].hasOwnProperty('customer_name') ? customerList_object[customer_record_id].customer_name : ''}
                                  readOnly={true}
                              />
                          </div>
                      </div>: null}
                      {customer_record_id != 0 && !isNewCustomer ?
                        <div className="col-lg-3">
                          <div className="form-group">
                              <label className="form">Phone Number</label>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  placeholder="Customer Phone Number"
                                  value={customerList_object[customer_record_id] && customerList_object[customer_record_id].hasOwnProperty('phone_number') ? customerList_object[customer_record_id].phone_number : ''}
                                  readOnly={true}
                              />
                          </div>
                      </div>: null}
                    </div>
                  </div>




            <div className="ps-form__content" style={{marginTop: '50px'}}>
              <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Order Information</h3></label>
                      </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label className="form">Order Status</label>

                    {loadingDropdowns ? <div>Loading...</div> :
                    <select className="form-control" defaultValue={0} value={order_status} onChange={ e=> handleSelect(e, 'order_status')} >
                      <option value="waiting_for_payment">Waiting for Payment</option>
                      <option value="awaiting_fulfillment">Awaiting Fulfillment</option>
                      <option value="cancelled_by_admin">Cancelled by Admin</option>
                      <option value="cancelled_by_customer">Cancelled by Customer</option>
                      <option value="refunded">Refunded</option>
                      <option value="shipped">Shipped</option>
                      <option value="delivered">Delivered</option>
                      <option value="shipping_label_created">Shipping Label Created</option>
                    </select>}
                  </div>
                </div>
                <div className="col-lg-6">
                {order_status === "awaiting_fulfillment" || order_status === "waiting_for_payment" ? null :
                    <div className="form-group">
                        <label className="form">Tracking ID</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="1Z9999999999999999"
                            value={tracking_id}
                            onChange={e => fieldChanged('tracking_id', e.target.value)}
                        />
                    </div>
                  }
                </div>

                <div className="separator"></div>

                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">Order Date</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Example: 2021-09-21 01:02:54"
                            value={order_date}
                            onChange={e => fieldChanged('order_date', e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">Order Number</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Example: 6148bebefa01bf00129d3bea"
                            value={order_number}
                            onChange={e => fieldChanged('order_number', e.target.value)}
                        />
                    </div>
                </div>

                <div className="col-lg-6">
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="form">Order Reference</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Example: D#1000"
                            value={order_reference}
                            onChange={e => fieldChanged('order_reference', e.target.value)}
                        />
                    </div>
                </div>

                <div className="separator"></div>

                <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Package Information</h3></label>
                      </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form">Package Dimensions</label>

                    {loadingDropdowns ? <div>Loading...</div> :
                    <select className="form-control" defaultValue={0} value={package_size_type} onChange={ e=> handleSelect(e, 'package_size')} >
                          <option value="S1">25 x 20 x 8 cm (10 x 8 x 3")</option>
                    </select>}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label className="form">Package Weight</label>
                    <div className="input-group mb-2" >

                      <input
                          className="form-control"
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          placeholder="0.0"
                          value={pdata_packageWeight}
                          onChange={e => fieldChanged('pdata_packageWeight', e.target.value)}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">KGS</span>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="separator"></div>
                <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Shipping Information</h3></label>
                      </div>
                </div>


                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="form" style={{color: '#ffb81c'}}>
                              <Tooltip placement="topLeft" title="This order has an active Shipping Label.  You will need to issue a new Shipping Label if any changes are made to the mailing details.">
                                {tracking_id ?<InfoCircleOutlined /> : null }
                              </Tooltip> <b>{tracking_id ? "Warning: this order has an active Shipping Label!" : null}</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Name</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_name}
                                onChange={e => fieldChanged('cdata_name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Name (Pinyin)</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_name_pinyin}
                                onChange={e => fieldChanged('cdata_name_pinyin', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Attention Name</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_attention_name}
                                onChange={e => fieldChanged('cdata_attention_name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Attention Name (Pinyin)</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_attention_name_pinyin}
                                onChange={e => fieldChanged('cdata_attention_name_pinyin', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Phone Number</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_phone_number}
                                onChange={e => fieldChanged('cdata_phone_number', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Address Line</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_addressLine}
                                onChange={e => fieldChanged('cdata_addressLine', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Address Line (Pinyin)</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_addressLine_pinyin}
                                onChange={e => fieldChanged('cdata_addressLine_pinyin', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">State/Province</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_state}
                                onChange={e => fieldChanged('cdata_address_state', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">State/Province (Pinyin)</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_state_pinyin}
                                onChange={e => fieldChanged('cdata_address_state_pinyin', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">City</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_city}
                                onChange={e => fieldChanged('cdata_address_city', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">City (Pinyin)</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_city_pinyin}
                                onChange={e => fieldChanged('cdata_address_city_pinyin', e.target.value)}
                            />
                        </div>
                    </div>



                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Country Code</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_country}
                                onChange={e => fieldChanged('cdata_address_country', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Country Code (Pinyin)</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_country_pinyin}
                                onChange={e => fieldChanged('cdata_address_country_pinyin', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">ZIP</label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_zip}
                                onChange={e => fieldChanged('cdata_address_zip', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    {pdata_package_shipping_label && tracking_id?
                      <div className="col-lg-6" style={{display: 'flex', justifyContent:'center', alignItems: 'center'}} >

                        <div className="form-group" style={{textAlign: 'center', marginRight: '15px', marginBottom: '2.5rem'}}>
                          <div className="ps-btn--link" onClick={e => showShippingLabelPreview(e)}>Preview Label</div>
                        </div>
                        <div className="form-group" style={{textAlign: 'center', margin: '0px', marginBottom: '2.5rem'}}>
                            <button className="ps-btn--download" onClick={e => downloadShippingLabel(e)}>Download Shipping Label</button>
                        </div>

                      </div>
                      : null}




              </div>

              <div className="row" style={{backgroundColor: '#f1f1f1', paddingTop: '2.5rem', borderRadius: '4px'}}>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form"><h3 style={{color: '#d3d3d3'}}>Thank You Letter Information</h3></label>
                    </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                      <label className="form">Tracking ID (Thank-You-Letter)</label>
                      <input
                          className="form-control"
                          style={{backgroundColor: 'white'}}
                          type="text"
                          placeholder="TYL1Z9999999999999"
                          value={tyl_tracking_id}
                          onChange={e => fieldChanged('tyl_tracking_id', e.target.value)}
                      />
                  </div>
                </div>
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                      <label className="form">Date Delivered (Thank-You-Letter)</label>
                      <input
                          className="form-control"
                          style={{backgroundColor: 'white'}}
                          type="text"
                          placeholder="Example: 2022-09-21 01:02:54"
                          value={tyl_date_delivered}
                          onChange={e => fieldChanged('tyl_date_delivered', e.target.value)}
                      />
                  </div>
                </div>
              </div>


            </div>












            <div className="ps-form__content" style={{marginTop: '50px'}}>
              <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Order Details</h3></label>
                      </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>
                  <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
                    <thead>
                      <tr>
                        <th style={{position: 'sticky', left: '0', backgroundColor: '#f1f1f1'}}></th>
                        <th>Brand</th>
                        <th>Product</th>
                        <th>Variant <span>(only active)</span></th>
                        <th>Quantity</th>
                        <th>Promotion</th>
                        <th>SKU</th>
                        <th style={{position: 'sticky', right: 'calc(-1px + 6rem)', backgroundColor: '#f1f1f1'}}>Preview</th>

                        <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}}></th>
                      </tr>
                    </thead>
                    <tbody>

                {selectionList.map((x, i_x) => (
                  <tr key={i_x}>
                    <td style={frozenColumn_left}><b>Item {i_x + 1}</b></td>


                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        {loadingDropdowns_brands ? <div>Loading...</div> :
                        <select className={selected_brands[i_x] !== null && selected_brands[i_x] !== '' && selected_brands[i_x] !== '0' ? `form-control` :  `form-control error`} defaultValue={0} value={selected_brands[i_x]} onChange={ e=> handleBrandSelectionChange(e, [i_x])} >
                            {brandList[i_x] && brandList[i_x].length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}

                            {brandList[i_x] && brandList[i_x].length > 0 ? brandList[i_x].map((item) => (
                              <option value={item.id} key={item.id}  >
                                      {item.name} - {item.country}
                              </option>
                            )) : <option value={0}>- - - No Selection Available - - -</option>}
                        </select>}
                      </div>
                    </td>

                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        {loadingDropdowns_products[i_x] === true ? <div>Loading...</div> :
                            <select className={selected_products[i_x] !== null && selected_products[i_x] !== '' && selected_products[i_x] !== '0' ? `form-control` :  `form-control error`} defaultValue={0} value={selected_products[i_x]} onChange={ e=> handleProductSelectionChange(e, [i_x])} >

                                {productList[i_x] && productList[i_x].length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {productList[i_x] && productList[i_x].length > 0 ? productList[i_x].map((item) => (
                                  <option value={item.id} key={item.id}  >
                                          {item.name} - {(item.description).substring(0, 45) + '...'}
                                  </option>
                                )) : <option value={0}>- - - No Selection Available - - -</option>}
                            </select>
                          }
                      </div>
                    </td>
                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        {loadingDropdowns_skus[i_x] === true ? <div>Loading...</div> :
                            <select className={selected_skus[i_x] !== null && selected_skus[i_x] !== '' && selected_skus[i_x] !== '0' ? `form-control` :  `form-control error`} defaultValue={0} value={selected_skus[i_x]} onChange={ e=> handleSkuSelectionChange(e, [i_x])} >

                                {skuList[i_x] && skuList[i_x].length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {skuList[i_x] && skuList[i_x].length > 0 ? skuList[i_x].map((item) => (
                                  <option value={item.id} data-sku={item.sku} data-pic={item.pic_url} key={item.id}  >
                                          {item.combo_identifier}  (Quantity: {item.quantity})
                                  </option>
                                )) : <option value={0}>- - - No Selection Available - - -</option>}
                            </select>

                          }
                          {orderDetailList[i_x] && orderDetailList[i_x].status_change ?
                          <div className='form-control-plaintext' style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600', color: 'red'}}>
                              <ExclamationCircleOutlined/>
                              {orderDetailList[i_x] ? ' '+ orderDetailList[i_x].status_change : ''}
                          </div> : null }

                      </div>

                    </td>
                    <td style={{minWidth:'10rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        <div className="box" style={{display: 'flex'}}>
                          <input
                              name="variant_option"
                              className={quantityList[i_x] ? `form-control` :  `form-control error`}
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder={0}
                              value={quantityList[i_x]}
                              onChange={e => handleQuantityInputChange(e, i_x)}
                          />
                        </div>
                      </div>
                    </td>
                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        {loadingDropdowns_promos[i_x] === true ? <div>Loading...</div> :
                            <select className={selected_promos[i_x] !== null && selected_promos[i_x] !== '' && selected_promos[i_x] !== '0' ? `form-control` :  `form-control error`} defaultValue={0} value={selected_promos[i_x]} onChange={ e=> handlePromotionSelectionChange(e, [i_x])} >

                                {promoList[i_x] && promoList[i_x].length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {promoList[i_x] && promoList[i_x].length > 0 ? promoList[i_x].map((item) => (
                                  <option value={item.id} data-price={item.price} data-currency={item.currency} data-date={item.promotion_date} key={item.id}  >
                                          {formatter.format(item.price)} {item.currency} {item.promotion_date && item.promotion_date !== null ? "(Effective: " + moment(item.promotion_date).format(dateFormat) + ")": null}
                                  </option>
                                )) : <option value={0}>- - - No Selection Available - - -</option>}
                            </select>

                          }

                      </div>
                    </td>
                    <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                      <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                        <div className="box" style={{display: 'flex'}}>
                          <input
                              className='form-control-plaintext'
                              style={{backgroundColor: 'white', fontSize: '16px'}}
                              type="text"
                              placeholder="Product SKU"
                              value={selected_skus_view[i_x] ? selected_skus_view[i_x] : ''}
                              readOnly={true}
                          />
                        </div>
                      </div>
                    </td>
                    <td style={frozenColumn_right_second}>
                      <img src={selected_pics_view[i_x] ? selected_pics_view[i_x] : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                      style={{display: 'block',
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                        }} alt={selected_pics_view[i_x]} border="0" />
                    </td>
                    <td style={frozenColumn_right}>
                      <div className="col-lg-6" style={{display:'flex'}}>
                        {selectionList.length !== 1 && <button className="ps-btn-delete" onClick={(e)=> handleRemoveSelection(e, i_x)}><i className='icon-cross'></i></button>}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>














                </div>
                <div className="col-lg-9" style={{marginTop: '2.5rem'}}>
                  <button className="ps-btn-delete" onClick={(e) => handleAddSelection(e)}><i className='icon-plus'></i> add another product</button>
                </div>
                <div className="col-lg-1" style={{marginTop: '2.5rem'}}>
                    <div className="form-group">
                        <label className="form">QUANTITY</label>
                        <input
                            className="form-control-plaintext"
                            type="text"
                            style={{fontSize: '16px', textAlign: 'right'}}
                            value={getOrderQuantityTotal()}
                        />
                    </div>
                </div>
                <div className="col-lg-2" style={{marginTop: '2.5rem'}}>
                    <div className="form-group">
                        <label className="form">ORDER TOTAL</label>
                        <input
                            className="form-control-plaintext"
                            type="text"
                            style={{fontSize: '16px'}}
                            value={getOrderTotal() +' USD'}
                        />
                    </div>
                </div>

              </div>


            </div>





              <div className="form-group submit" style={{marginTop:"5rem"}}>
                  <button
                    className="ps-btn"
                    onClick={e => handleSubmit(e)}
                    disabled={loading}
                    >
                    {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Update Order')}
                    </button>
              </div>



        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormEditOrder;
