import React from 'react';
// import Link from 'next/link';
import { StrapiProductThumbnail } from '../../../utilities/product-helpers';
// import { removeItem } from '../../../store/cart/action';
// import { useDispatch } from 'react-redux';
const Link = require("react-router-dom").Link

const ProductOnCart = ({ product }) => {
    // const dispatch = useDispatch();

    const handleRemoveCartItem = (e) => {
        e.preventDefault();
        // dispatch(removeItem(product));
    };

    return (
        <div className="ps-product--cart-mobile">
            <div className="ps-product__thumbnail">
                {StrapiProductThumbnail(product)}
            </div>
            <div className="ps-product__content">
                <div
                    className="ps-product__remove"
                    onClick={(e) => handleRemoveCartItem(e)}>
                    <i className="icon-cross"></i>
                </div>
                <Link className="ps-product__title" to="/product/[pid]" as={`/product/${product.id}`}>
                    {product.title}
                </Link>
                <p>
                    <small>
                        ${product.price} x {product.quantity}
                    </small>
                </p>
            </div>
        </div>
    );
};

export default ProductOnCart;
