export const weightUnits_metric = [
    {
        order: 1,
        text: 'g',
    },
    {
        order: 2,
        text: 'kg',
    },

];

export const weightUnits_imperial = [
    {
        order: 1,
        text: 'oz',
    },
    {
        order: 2,
        text: 'lbs',
    },

];


export const dimensionUnits_metric = [
    {
        order: 1,
        text: 'cm',
    },
    {
        order: 2,
        text: 'm',
    },
  

];

export const dimensionUnits_imperial = [
    {
        order: 1,
        text: 'in',
    },
    {
        order: 2,
        text: 'ft',
    },
    {
        order: 3,
        text: 'yd',
    },

];

// exports.weightUnits = weightUnits
// exports.dimensionUnits = dimensionUnits
// export default {weightUnits, dimensionUnits}
