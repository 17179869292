import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const PromotionsTable = ({userCredentials, title}) => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [promotions, setPromotions] = useState([]);




  async function getPromotions() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}promos/all`);
    const promotionsArray = await res.json();
    setPromotions(promotionsArray);
  }

  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}
      const res = await fetch(`${process.env.REACT_APP_API_URL}promo/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      }).then((response) => {
        // setLoading(false)
        // console.log ("Record Deleted")
        window.location.reload(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getPromotions();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
    // getPromotions(tab.slug);
  }

  function handleSubmit(e) {
    // e.preventDefault()
    // console.log ("navigating")
  }

  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }


  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }


  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All Promotions</h3>

                      <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                        <Link to="/admin/promotions/new">
                          <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New Promotion</button>
                        </Link>
                      </div>
                    </div>
                </div>
                <div className="ps-form__content">

                  <div className="row">

                        <table class="table mt-5">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Promotion Date</th>
                              <th>Price</th>

                              <th>Product ID</th>
                              <th>Brand ID</th>
                              <th>Variant ID</th>
                              <th>Option ID</th>

                              <th>Date Created</th>
                              <th>Date Modified</th>
                              <th>View</th>
                              <th>Edit</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          {/*<tr>
                            <td>John</td>
                            <td>Doe</td>
                            <td>john@example.com</td>
                          </tr> */}

                          {promotions.map((promotion) => (
                            <tr key={promotion.id}>
                              <td style={tableRow}>{promotion.id}</td>


                              <td style={tableRow}>{moment(promotion.promotion_date).format('lll')}</td>
                              <td style={tableRow}>${promotion.price} {promotion.currency}</td>



                              <td style={tableRow}>
                                <Link to={`/admin/product/${promotion.product_id}`}>
                                  <button className="ps-btn" style={{padding: '10px'}}>{promotion.product_id}</button>
                                </Link>
                              </td>

                              <td style={tableRow}>
                                <Link to={`/admin/brand/${promotion.brand_id}`}>
                                  <button className="ps-btn" style={{padding: '10px'}}>{promotion.brand_id}</button>
                                </Link>
                              </td>
                              {promotion.variant_id ?
                              <td style={tableRow}>
                                <Link to={`/admin/variant/${promotion.variant_id}`}>
                                  <button className="ps-btn" style={{padding: '10px'}}>{promotion.variant_id}</button>
                                </Link>
                              </td> : <td></td>}
                              {promotion.variant_option_id ?
                              <td style={tableRow}>
                                <Link to={`/admin/variant_option/${promotion.variant_option_id}`}>
                                  <button className="ps-btn" style={{padding: '10px'}}>{promotion.variant_option_id}</button>
                                </Link>
                              </td> : <td></td>}

                              <td style={tableRow}>{moment(promotion.date_created).format('lll')}</td>
                              <td style={tableRow}>{moment(promotion.date_modified).format('lll') != 'Invalid date' ? moment(promotion.date_modified).format('lll') : ''  }</td>

                              <td style={tableRow}>
                                <Link to={`/admin/promotion/${promotion.id}`}>
                                <button className="ps-btn-delete">
                                    <i className='icon-eye'></i>
                                </button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <Link to={`/admin/promotion/${promotion.id}/edit`}>
                                  <button className="ps-btn-delete">
                                      <i className='icon-pencil'></i>
                                  </button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <button className="ps-btn-delete" onClick={e => handleDelete(e, promotion.id)}>
                                    <i className='icon-cross'></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>

                      </div>
                    </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default PromotionsTable;
