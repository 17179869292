import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
import { Popconfirm, message, Tooltip } from 'antd'
import { copyToClipboard, makeCSVExport } from '../../../utilities/common-helpers'
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'



var QRCode = require('qrcode.react')

// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';

const Link = require("react-router-dom").Link

const UrlsTable = ({userCredentials, title}) => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const dateFormat = 'YYYY-MM-DD'

  const [urls, setUrls] = useState([]);
  const [loadingDropdowns_brands, setLoadingDropdowns_brands] = useState(false)
  const [loadingDropdowns_products, setLoadingDropdowns_products] = useState(false)
  const [loadingDropdowns_skus, setLoadingDropdowns_skus] = useState(false)
  const [loadingDropdowns_orders, setLoadingDropdowns_orders] = useState(false)

  const [loading, setLoading] = useState(false)
  const [brand_id, setBrand_id] = useState(0);
  const [product_id, setProduct_id] = useState(0);
  const [sku_id, setSku_id] = useState(0)
  const [order_id, setOrder_id] = useState(0)
  const [brands, setBrands] = useState([])
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [skus, setSkus] = useState([])
  const [filterSelection, setFilterSelection] = useState([{value: true, option: 'by Products'}, {value: false, option: 'by Order'}, {value: false, option: 'by Status'}])
  const [selectedFilter, setSelectedFilter] = useState(0)

  // console.log (userCredentials)




  async function getBrands() {
    setLoadingDropdowns_brands(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`);
    const brandsArray = await res.json();
    setBrands(brandsArray);
    setLoadingDropdowns_brands(false)
  }

  async function getOrders() {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`)
    const ordersArray = await res.json()
    // console.log (ordersArray)

    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getAllProductsByBrandID(selected_brand_id) {
    setLoadingDropdowns_products(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/brands/${selected_brand_id}`);
    const productsArray = await res.json();
    setProducts(productsArray);
    setLoadingDropdowns_products(false)
  }

  async function getAllActiveSkusByProductID(selected_product_id) {
    setLoadingDropdowns_skus(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${selected_product_id}`);
    const skusArray = await res.json();
    setSkus(skusArray);
    setLoadingDropdowns_skus(false)
  }

  async function getAllUrls() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/all`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    console.log (urlsArray)
    setLoading(false)
  }

  async function getAllUrlsThatHaveProduct() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/all/f=hasProduct`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    setLoading(false)
  }

  async function getAllUrlsThatHaveOrder() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/all/f=hasOrder`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    setLoading(false)
  }

  async function getAllUrlsByOrderID(selected_order_id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/s/${selected_order_id}`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    setLoading(false)
  }

  async function getAllUrlsByBrandID(selected_brand_id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/bid/${selected_brand_id}`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    setLoading(false)
  }



  //DO THIS SLS
  async function getAllUrlsByProductID(selected_product_id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/pid/${selected_product_id}`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    setLoading(false)
  }

  //DO THIS SLS
  async function getAllUrlsBySkuID(selected_sku_id) {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/sid/${selected_sku_id}`);
    const urlsArray = await res.json();
    setUrls(urlsArray);
    setLoading(false)
  }



  useEffect(() => {
    getAllUrls();
    getBrands()
    getOrders()
  }, []);

  const handleSelect = (item, type) => {
    if (type == 'brand_id'){
      setBrand_id(item.target.value)
      setSku_id(0)
      setProduct_id(0)
      setSkus([])
      setProducts([])
      getAllProductsByBrandID(item.target.value)

    } else if (type == 'product_id'){
      setProduct_id(item.target.value)
      setSku_id(0)
      setSkus([])
      getAllActiveSkusByProductID(item.target.value)

    } else if (type == 'sku_id'){
      setSku_id(item.target.value)
    } else if (type == 'order_id'){
      setOrder_id(item.target.value)
    }
  }

  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'products'){
       if (products.length > 0){
          return (
            products.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.description}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'skus'){
       if (products.length > 0){
          return (
            products.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.sku} - {item.barcode} - {item.quantity}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    }
  }

  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);

  }

  function handleSubmit(e) {
    // e.preventDefault()
    // console.log ("navigating")

  }


  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}
      const res = await fetch(`${process.env.REACT_APP_API_URL}u/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      }).then((response) => {
        // setLoading(false)
        // console.log ("Record Deleted")
        window.location.reload(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  }






  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }

  function handleSearch(e) {
    e.preventDefault()

    // console.log ("b: " + brand_id)
    // console.log ("p: " + product_id)
    // console.log ("s: " + sku_id)
    if (selectedFilter == 0){
      if (brand_id == 0){
        // console.log ("Getting all URLS")
        getAllUrlsThatHaveProduct()
      } else if (brand_id != 0 && product_id == 0 && sku_id == 0){
        // console.log ("Searching by Brand: " + brand_id)
        getAllUrlsByBrandID(brand_id)
      } else if (product_id != 0 && sku_id == 0) {
        // console.log ("Searching by Product: " + brand_id)
        getAllUrlsByProductID(product_id)
        // getAllProductsByBrand(brand_id)
      } else if (product_id != 0 && sku_id != 0) {
        // console.log ("Searching by SKU: " + sku_id)
        getAllUrlsBySkuID(sku_id)
        // getAllProductsByBrand(brand_id)
      }
    } else if (selectedFilter == 1){
      if (order_id == 0){
        getAllUrlsThatHaveOrder()
      } else if (order_id != 0){
        getAllUrlsByOrderID(order_id)
      }
    }
  }

  function copyItem (item, index) {
    copyToClipboard(item)
    const url_list = [...urls]
    //Reset previously copied elements
    for (var i = 0; i< url_list.length; i++){
      url_list[i].copied = false
    }
    url_list[index].copied = true

    setUrls(url_list)

    message.success('URL copied to Clipboard')

  }

  function handleExport (e) {
    e.preventDefault()
    makeCSVExport(urls, 'urls_data')
  }

  const handleFilterSelection = (e, selected_index) => {

    const { id } = e.target
    const list = [...filterSelection]
    for (var inner_index = 0; inner_index < list.length; inner_index++){
      list[inner_index].value = false
    }
    list[selected_index].value = true

    setFilterSelection(list)
    setSelectedFilter(selected_index)
    //
    // document.getElementById("list-all").classList.remove("active")
    // document.getElementById("list-option").classList.remove("active")
    // document.getElementById(id).classList.add("active")
  }



  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const tableRow_column_default = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    textAlign: 'center',
    minWidth: '20rem',
  }

  const tableRow_cancelled = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'red',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow_assigned = {
    padding: '0.5rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: '#3ebb3e',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const frozenColumn_left = {
    width:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7.5px',
    paddingRight: '7.5px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '-1px',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 42px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_third = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 84px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  const mark_red = {
    fontSize: '20px',
    color: 'lightgrey'
  }
  const mark_green = {
    fontSize: '20px',
    color: 'green'
  }

  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'products'){
       if (products.length > 0){
          return (
            products.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.description}
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'skus'){
       if (skus.length > 0){
          return (
            skus.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.combo_identifier} (Quantity: {item.quantity})
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'orders'){
       if (orders.length > 0){
          return (
            orders.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.order_number}, REF: {item.order_reference} - ({moment(item.order_date).format(dateFormat)})
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }
    }
  }



  const dropdown_brands = setSelectedId_View('brands')
  const dropdown_products = setSelectedId_View('products')
  const dropdown_skus = setSelectedId_View('skus')
  const dropdown_orders = setSelectedId_View('orders')

  function getItemText(url, type){
    //ITEM
    if (type == 'item'){
      if (url.url_item_type == 'tyl'){
        return ("Thank You Letter")
      } else if (url.url_item_type == 'pnfc'){
        return ("Product: NFC")
      } else if (url.url_item_type == 'pqr') {
        return ("Product: QR")
      } else if (url.url_item_type == 'ptnfc') {
        return ("Tamper: NFC")
      } else if (url.url_item_type == 'cnfc'){
        return ("Card: NFC")
      } else if (url.url_item_type == 'cqr'){
        return ("Card: QR")
      }
    }

    //DESTINATION
    if (type == 'destination'){
      if (url.url_destination_type == 'o'){
        return ("Order")
      } else if (url.url_destination_type =='p'){
        return ("Product")
      }
    }
  }

  function getItemUrl(url){
    //DESTINATION

    if (url.url_destination_type == 'o' && url.order_id ){
      return (`/admin/o/${url.order_id}`)
    } else if (url.url_destination_type =='p' && url.product_id){
      return (`/admin/p/${url.product_id}`)
    } else {
      return null
    }

  }

  function makeFilters() {
    return (selectedFilter == 0 ?
      <div className="col-lg-12">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

          <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <div className="form-group" style={{width: '25rem'}}>
              <label className="form">Search by Brand:</label>
              {loadingDropdowns_brands ? <div>Loading...</div> :
                <select className="form-control" value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                    {brands.length === 0 ? null : <option value={0}>All</option>}
                  {dropdown_brands}
                </select>}
            </div>
            <div className="form-group" style={{width: '25rem'}}>
              <label className="form">by Product:</label>
              {loadingDropdowns_products ? <div>Loading...</div> :
                <select className="form-control" value={product_id} onChange={ e=> handleSelect(e, 'product_id')} >
                    {products.length === 0 ? null : <option value={0}>All</option>}
                  {dropdown_products}
                </select>}
            </div>
            <div className="form-group" style={{width: '25rem'}}>
              <label className="form">by SKU:</label>
              {loadingDropdowns_skus ? <div>Loading...</div> :
                <select className="form-control" value={sku_id} onChange={ e=> handleSelect(e, 'sku_id')} >
                    {skus.length === 0 ? null : <option value={0}>All</option>}
                  {dropdown_skus}
                </select>}
            </div>
            <div style={{marginLeft: '30px'}}>
              <button className="ps-btn" onClick={e => handleSearch(e)}>Go</button>
            </div>
          </div>

        </div>
      </div> : (selectedFilter == 1 ?
        <div className="col-lg-12">
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

            <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
              <div className="form-group" style={{width: '25rem'}}>
                <label className="form">Search by Order:</label>
                {loadingDropdowns_orders ? <div>Loading...</div> :
                  <select className="form-control" value={order_id} onChange={ e=> handleSelect(e, 'order_id')} >
                      {orders.length === 0 ? null : <option value={0}>All</option>}
                    {dropdown_orders}
                  </select>}
              </div>
              <div style={{marginLeft: '30px'}}>
                <button className="ps-btn" onClick={e => handleSearch(e)}>Go</button>
              </div>
            </div>

          </div>
        </div>
      : null))
  }
  function makeTable ()  {

  return (urls.length != 0 ?
    <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>

      <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
        <thead>
          <tr>
            <th style={{position: 'sticky', left: '0px', backgroundColor: '#f1f1f1'}}>QR Code</th>
            <th>URL</th>
            <th>Status</th>
            <th>Item</th>
            <th>Destination</th>
            <th>
              <Tooltip placement="topRight" title="This is an indicator to show if a URL is assigned to an Order">
                <InfoCircleOutlined />
              </Tooltip>
            </th>
            <th>Preview</th>
            <th>SKU</th>
            <th>Order</th>
            <th>Product</th>
            <th>UG Hash</th>
            <th>SG Hash</th>
            <th><i className='icon-chevron-down' style={{fontSize: '10px'}}></i> Date Created</th>
            <th>Date Modified</th>
            {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 84px)', backgroundColor: '#f1f1f1'}}></th> : null }
            {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 42px)', backgroundColor: '#f1f1f1'}}></th> : null }
            <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}} ></th>

          </tr>
        </thead>
        <tbody>

        {urls.map((url, index) => (
          <tr key={url.id}>
            <td style={frozenColumn_left}>
              <a target="_blank" href={url.url}>
                <QRCode value={url.url} size={100}/>
              </a>
            </td>
            <td style={{minWidth:'8rem', verticalAlign: 'middle'}}>
              <div className="ps-btn--link" onClick={e => copyItem(url.url, index)}>
                {urls[index].copied && urls[index].copied == true ?
                  <span>Copied!</span>
                  : 'Copy'}
              </div>
            </td>
            <td style={tableRow_column_default}>
              <div className="ps-btn" style={url.status == 'Unassigned' ? tableRow_cancelled : tableRow_assigned}>
                {url.status}
              </div>
            </td>
            <td style={{minWidth:'15rem', verticalAlign: 'middle'}}><b>{getItemText(url, 'item')}</b></td>

            <td style={{minWidth:'10rem', verticalAlign: 'middle'}}>

              {getItemUrl(url) != null ?
                <Link to={getItemUrl(url)}>
                  <b>{getItemText(url, 'destination')}</b>
                </Link> : <b>{getItemText(url, 'destination')}</b>
              }
            </td>

            <td style={{minWidth:'7rem', verticalAlign: 'middle'}}>{url.order_id != 0 ?<i className='icon-checkmark-circle' style={mark_green}></i> : <i className='icon-cross-circle' style={mark_red}></i>}</td>
            <td>
            <img src={url.pic_url ? url.pic_url : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                style={{display: 'block',
                    width: '100px',
                    height: '100px',
                    objectFit: 'cover',
                  }} alt={url.pic_url} border="0" />
            </td>
            <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>{url.sku}</td>
            <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
            {url.order_id ?
              <Link to={`/admin/o/${url.order_id}`}>
                <button className="ps-btn" style={{width: '100%'}}>{url.order_number}</button>
              </Link> : null}
            </td>
            <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
            {url.product_id ?
              <Link to={`/admin/p/${url.product_id}`}>
                <button className="ps-btn" style={{width: '100%'}}>{url.brand_name}: {url.product_name}</button>
              </Link> : null}
            </td>
            <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>{url.ug_hash}</td>
            <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>{url.sg_hash}</td>


            <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(url.date_created).format('lll')}</td>
            <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(url.date_modified).format('lll') != 'Invalid date' ? moment(url.date_modified).format('lll') : ''  }</td>

            {userCredentials.role == 'administrator' ?
            <td style={frozenColumn_right_third}>
              <Link to={`/admin/url/${url.id}`}>
              <button className="ps-btn-delete">
                  <i className='icon-eye'></i>
              </button>
              </Link>
            </td> : null }
            {userCredentials.role == 'administrator' ?
            <td style={frozenColumn_right_second}>
              <Link to={`/admin/url/${url.id}/edit`}>
                <button className="ps-btn-delete">
                    <i className='icon-pencil'></i>
                </button>
              </Link>
            </td> : null }
            {userCredentials.role == 'administrator' ?
            <td style={frozenColumn_right}>
              <button className="ps-btn-delete" onClick={e => handleDelete(e, url.id)}>
                  <i className='icon-cross'></i>
              </button>
            </td> : null }
           {userCredentials.role == 'csr' ?
            <td style={frozenColumn_right}>
              <Link to={`/admin/url/${url.id}`}>
                <button className="ps-btn-delete">
                    <i className='icon-eye'></i>
                </button>
              </Link>
            </td> : null }


          </tr>
        ))}
        </tbody>
      </table>
    </div>
    :
    <div className="col-lg-3">
      <input
          className='form-control-plaintext'
          style={{backgroundColor: 'white', fontSize: '16px'}}
          type="text"
          value={'No results found'}
          readOnly={true}
      />
    </div>
    )
  }



  // console.log (urls)

  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All URLs</h3>

                      <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>

                        <button className="ps-btn-delete" style={{marginRight: '15px'}}onClick={e => handleExport(e)}>Export</button>

                        <Link to="/admin/urls/new">
                          <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New URL</button>
                        </Link>


                      </div>
                    </div>
                </div>


                <div className="row" style={{marginBottom: '30px'}}>

                <div className="col-lg-12">
                  <div className="form-group" style={{marginBottom:'15px'}}>
                    <div className="list-group list-group-horizontal" style={{alignText: 'center', cursor: 'pointer'}} >
                      {filterSelection.map((item, i) => (
                        <li className={item.value ? "list-group-item list-group-item-light active" : "list-group-item list-group-item-light"} id="list-all" data-toggle="list" role="tab" key={i} onClick={e => handleFilterSelection(e, i)}>{item.option}</li>
                      ))}
                    </div>
                  </div>
                </div>


                {makeFilters()}

                </div>




                <div className="ps-form__content">

                  <div className="row">
                  {!loading ? makeTable() :
                    <div className="col-lg-3">
                      <input
                          className='form-control-plaintext'
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="text"
                          value={'Loading...'}
                          readOnly={true}
                      />

                  </div>}


                  </div>
                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default UrlsTable;
