import React from 'react';

const FooterCopyright = () => {

  const currentYear = new Date().getFullYear()

  return (
    <div className="ps-footer__copyright">
        <p>&copy; {`${currentYear} Getzo Corp. All Rights Reserved.`}</p>


    </div>
  )
}

// <p>
//     <span>We Use Safe Payment For:</span>
//     <a className="ps-logo" href="#">
//         <img src="/static/imgs/payment/americanexpress.png" alt="Luxanda" />
//     </a>
//     <a className="ps-logo" href="#">
//         <img src="/static/imgs/payment/mastercard.png" alt="Luxanda" />
//     </a>
//     <a className="ps-logo" href="#">
//         <img src="/static/imgs/payment/visa.png" alt="Luxanda" />
//     </a>
//
// </p>

export default FooterCopyright;
