import React, { Component, useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'

import {formatPhoneNumber} from '../../../utilities/common-helpers'
import { Popconfirm, message, Tooltip } from 'antd'
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const mapState = ({ user }) => ({
  user: user,
})


const OrdersTable = ({userCredentials, title}) => {

  const {user, selectedUnit } = useSelector(mapState)
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [orderDetails, setOrderDetails] = useState([]);
  // const [order_status, setOrder_status] = useState("All")
  const [loading, setLoading] = useState(false)
  const [loading_clearing, setLoading_clearing] = useState(false)




  async function getAllActiveChangeRequests() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/all/v=change_requests`);
    const changeRequests = await res.json();
    console.log (changeRequests)
    setOrderDetails(changeRequests);
    setLoading(false)
  }







  useEffect(() => {
    getAllActiveChangeRequests();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
  }

  function handleClear(e, record_id) {
    e.preventDefault()
    if(window.confirm('Are you sure you wish to clear record ID: ' + record_id + '?')){
      clearOrderDetailChangeRequest(record_id)
    }

    // console.log ('clearing OD: ' + record_id)

    // if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
    //   deleteRecord(record_id)
    // }
  }




  async function clearOrderDetailChangeRequest(id) {
    try {
      setLoading_clearing(true)
      const cleared_by = user.credentials.email
      const admin_clear_date = new Date()
      const status_change = null
      const body = { id, cleared_by, admin_clear_date, status_change };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}od/a=status_clear`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        getAllActiveChangeRequests()
        setLoading_clearing(false)
        message.success(`Order Detail ${id} has been successfully cleared`)
      })


      // window.location = "/admin/brands";

    } catch (err) {
      message.error (err.message)
      console.error(err.message);
    }



  }




  function handleSubmit(e) {
    // console.log ("navigating")
  }



  // const handleSelect = (item, type) => {
  //
  //   if (type == 'order_status'){
  //     setOrder_status(item.target.value)
  //   }
  // }


  // console.log (orders)


  const tableRow_column_default = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    textAlign: 'center',
  }

  const tableRow_cancelled = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'red',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow_awaiting_fulfillment = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'green',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow_default = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: '#dddddd',
    backgroundColor: '#efefef',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solid #dee2e6',
    minWidth: '500px',
  }

  const frozenColumn_left = {
    minWidth:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '-1px',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }


  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  function makeTable()  {

    return (orderDetails.length != 0 ?
      <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>

        <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
          <thead>
            <tr>
              <th style={{position: 'sticky', left: '0px', backgroundColor: '#f1f1f1'}}>Order Number</th>

              <th>Request</th>
              <th>Current Item</th>
              <th><i className='icon-chevron-down' style={{fontSize: '10px'}}></i> Request Date</th>
              <th>Order Reference</th>
              <th>Order Date</th>
              <th>Product</th>
              <th>Date Modified</th>

              <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}} ></th>
            </tr>
          </thead>
          <tbody>

          {orderDetails.map((orderDetail) => (
            <tr key={orderDetails.id}>
              <td style={frozenColumn_left}>
                <Link to={`/admin/o/${orderDetail.order_id}/edit`}>
                  <button className="ps-btn" style={{width: '100%'}}>
                    <div style={{display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label className="form"><b>{orderDetail.order_number}</b></label>
                    </div>
                  </button>
                </Link>

              </td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}><b>{orderDetail.status_change}</b></td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{orderDetail.combo_identifier}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(orderDetail.customer_change_date).format('lll')}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{orderDetail.order_reference}</td>

              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(orderDetail.order_date).format('lll')}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                <Link to={`/admin/p/${orderDetail.product_id}`}>
                  <button className="ps-btn" style={{width: '100%'}}>
                    <div style={{display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label className="form"><b>{orderDetail.product_name}</b></label>
                    </div>
                  </button>
                </Link>
              </td>





              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(orderDetail.admin_clear_date).format('lll')}</td>

              <td style={frozenColumn_right}>
                <button className="ps-btn-delete" onClick={e => handleClear(e, orderDetail.id)}>
                    Clear Request
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      :
      <div className="col-lg-3">
        <input
            className='form-control-plaintext'
            style={{backgroundColor: 'white', fontSize: '16px'}}
            type="text"
            value={'No results found'}
            readOnly={true}
        />
      </div>
      )
  }





  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{display: 'flex', alignItems: 'center'}}>
                      <Tooltip placement="topLeft" title="This is a list of Order Details for Unfulfilled Orders that customers have requested a change in size">
                        <InfoCircleOutlined style={{fontSize: '16px'}}/>
                      </Tooltip> Active Change Requests
                    </h3>


                  </div>
                </div>





                <div className="ps-form__content">


                  <div className="row">

                  {!loading ? makeTable() :
                    <div className="col-lg-3">
                      <input
                          className='form-control-plaintext'
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="text"
                          value={'Loading...'}
                          readOnly={true}
                      />

                    </div>}


                  </div>
                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default OrdersTable;
