export const urlDestinationTypeList = [
  {name: 'Product Page', code: 'p'},
{name: 'Order Page', code: 'o'},
{name: 'Tamper Validation Page', code: 'tp'}
];


// exports.weightUnits = weightUnits
// exports.dimensionUnits = dimensionUnits
// export default {weightUnits, dimensionUnits}
