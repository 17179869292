import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {signoutUser} from '../../../redux/actions/userActions'

import { accountLinks } from './data/profile-navigation'
const Link = require("react-router-dom").Link


class MyAccountDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user: '',
          ui: ''
        };

        accountLinks[0].active = true
    }




    render() {
      const { user: {user} } = this.props

      const handleLogout = (e) => {
          this.props.signoutUser()
      };



      //Views
      const accountLinkView = accountLinks.map((item) => (
          <li key={item.text} className={item.active ? 'active' : ''}>
              <Link to={item.url}>
                  <div>
                      <i className={item.icon}></i>
                      {item.text}
                  </div>
              </Link>
          </li>
      ));

      return (
          <section className="ps-my-account ps-page--account">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-3 col-md-12">
                          <div className="ps-section__left">
                              <aside className="ps-widget--account-dashboard">
                                  <div className="ps-widget__header">
                                      <img src={this.props.user.credentials.imageUrl} />
                                      <figure>
                                          <figcaption>Hello,</figcaption>
                                          <p>{this.props.user.credentials.email}</p>
                                      </figure>
                                  </div>
                                  <div className="ps-widget__content">
                                    <ul>
                                        {accountLinks.map((link) => (
                                            <li
                                                key={link.text}
                                                className={
                                                    link.active ? 'active' : ''
                                                }>
                                                <Link to={link.url}>
                                                    <div>
                                                        <i
                                                            className={
                                                                link.icon
                                                            }></i>
                                                        {link.text}
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                        <li>
                                            <Link to="/" onClick={(e) => handleLogout(e)}>
                                                <div>
                                                    <i className="icon-power-switch"></i>
                                                    Logout
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                  </div>
                              </aside>
                          </div>
                      </div>
                      <div className="col-lg-9">
                          <div className="ps-page__content">
                              <div className="ps-page__dashboard">
                                  <p>
                                      Hello <strong>{this.props.user.credentials.email}</strong>!
                                  </p>
                                  <p>
                                      From your account dashboard you can view
                                      your{' '}
                                      <Link to="/account/orders">
                                          recent orders
                                      </Link>
                                      , manage your{' '}
                                      <Link to="/account/user-information">

                                              shipping and billing addresses

                                      </Link>
                                      , and{' '}
                                      <Link to="/account/user-information">

                                              edit your password and account
                                              details

                                      </Link>
                                      .
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      );
  }
}

MyAccountDashboard.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

const mapActionsToProps = {
  signoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(MyAccountDashboard);
