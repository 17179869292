import React, { Component, useEffect, useState } from 'react'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';
import FormEditProduct from '../../components/shared/forms/form-edit-product';


const Link = require("react-router-dom").Link

const AdminProductEditPage = () => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);


  async function getProducts() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`);
    const productsArray = await res.json();
    setProducts(productsArray);
  }

  useEffect(() => {
    getProducts();
  }, []);





  // async function getProducts() {
  //   const res = await fetch('/p/all');
  //   const productsArray = await res.json();
  //   setProducts(productsArray);
  // }

  // useEffect(() => {
  //   getProducts();
  // }, []);

  //
  // function handleChangeTab(e, tab) {
  //   e.preventDefault();
  //   // setCurrentCollection(tab.name);
  //   // getProducts(tab.slug);
  // }

  const img = {
    display: 'block',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  return (

        <section className="ps-my-account ps-page--account">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ps-page__content">
                      <FormEditProduct/>
                    </div>
                  </div>
                </div>



                <div style={{height: '10vh'}}/>


            </div>
        </section>

  );

}


export default AdminProductEditPage;
