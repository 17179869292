import React from 'react'
// import Link from 'next/link'
import MenuDropdown from './MenuDropdown'
import MainMenu from './MainMenu'
const Link = require("react-router-dom").Link


const Menu = ({ source, className }) => {
  let menuView
  if (source) {
    menuView = source.map((item) => {
      if (item.subMenu) {
        return <MenuDropdown source={item} key={item.text} />
      } else if (item.megaContent) {
        return <MainMenu source={item} key={item.text} />
      } else {
        return (
          <li key={item.text}>
            <Link to={item.url}>
                {item.icon && <i className={item.icon}></i>}
                {item.text}
            </Link>
          </li>
        )
      }
    })
  } else {
    menuView = (
      <li>
        <a href="#" onClick={(e) => e.preventDefault()}>
          No Menu Items
        </a>
      </li>
    )
  }
  return <ul className={className}>{menuView}</ul>
}


export default Menu
