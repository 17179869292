import React from 'react';
import PropTypes from 'prop-types'

// import { connect, useDispatch } from 'react-redux';
// import Link from 'next/link';
// import { useTranslation } from "react-i18next";
// import { Dropdown, Menu } from 'antd';
import { connect } from 'react-redux'
import {signoutUser} from '../../../../redux/actions/userActions'


const Link = require("react-router-dom").Link

// import { logOut } from '~/store/auth/action';

const AccountQuickLinksMobile = (props) => {
    // const dispatch = useDispatch();
    // const { t } = useTranslation()
    const { isLoggedIn, profileImageUrl } = props

    const handleLogout = (e) => {
        // e.preventDefault();
        props.signoutUser()
        // dispatch(logOut());
    };
    const accountLinks = [
        // {
        //     text: 'My Account',
        //     url: '/account/account-information',
        // },
        // {
        //     text: 'Notifications',
        //     url: '/account/notifications',
        // },
        // {
        //     text: 'Invoices',
        //     url: '/account/invoices',
        // },
        // {
        //     text: 'Address',
        //     url: '/account/addresses',
        // },
        // {
        //     text: 'Recent Viewed Product',
        //     url: '/account/recent-viewed-product',
        // },
        // {
        //     text: 'Wishlist',
        //     url: '/account/wishlist',
        // },
    ];
     //props;
// <img className="profile" src={profileImageUrl} alt="profile-picture"/>
    // View
    const linksView = accountLinks.map((item) => (
        <li key={item.text}>
            <Link to={item.url}>
                {item.text}
            </Link>
        </li>
    ));

    if (isLoggedIn === true) {
        return (
            <div className="ps-block--user-account">

                <div className="ps-widget__header">
                  <Link to="/account/account-information">
                    <img src={profileImageUrl} alt="profile-picture" />
                  </Link>
                </div>

                <div className="ps-block__content">
                    <ul className="ps-list--arrow">
                        {linksView}
                        <li className="ps-block__footer">
                            <a href="#" onClick={(e) => handleLogout(e)}>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    } else {
        return (
            <div className="ps-block--user-header">

                <div className="ps-block__right">
                    <Link to="/signin">
                        Login
                    </Link>
                    <Link to="/signup">
                        Register
                    </Link>
                </div>
            </div>
        );
    }
};

AccountQuickLinksMobile.propTypes = {
  // classes: PropTypes.object.isRequired
  signoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,

}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

const mapActionsToProps = {
  signoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(AccountQuickLinksMobile)
// export default connect((state) => state)(AccountQuickLinks);
