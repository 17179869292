import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'


import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Popconfirm, message, Tooltip, Switch  } from 'antd'


import { urlItemTypeList } from './data/url_item_types'
import { urlDestinationTypeList } from './data/url_destination_types'
import moment from 'moment'
import {makeID, formatter, formatPhoneNumber} from '../../utilities/common-helpers'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link
var sha256 = require('sha256')



const mapState = ({ user }) => ({
  user: user,
})



const FormEditURL = () => {

  const current_date = new Date()
  const url_seed_prefix = "Getzo" + current_date.getFullYear() + ":"

  const {user, selectedUnit } = useSelector(mapState)

  const dateFormat = 'YYYY-MM-DD'
  // console.log (user)


  // const [pic, setPic] = useState("")
  // const [name, setName] = useState("");

  // const [product_name, setProduct_name] = useState("")





  const [urlRecord_data, setUrlRecord_data] = useState("")
  const [brand_id, setBrand_id] = useState(0)
  const [product_id, setProduct_id] = useState(0);
  const [sku_id, setSku_id] = useState(0)
  const [customer_id, setCustomer_id] = useState(0)
  const [order_id, setOrder_id] = useState(0);
  const [isAssigned, setIsAssigned] = useState(false)
  // const [existingUrl, setExistingUrl] = useState

  const [url, set_url] = useState("")
  const [url_seed, setUrl_seed] = useState("");
  const [ug_hash, setUg_hash] = useState("");
  const [url_item_type, setUrl_item_type] = useState("");
  const [url_destination_type, setUrl_destination_type] = useState("")
  const [url_item_selected, setUrl_item_selected] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [loadingDropdowns_customers, setLoadingDropdowns_customers] = useState(false)
  const [loadingDropdowns_orders, setLoadingDropdowns_orders] = useState(false)
  const [loadingDropdowns_brands, setLoadingDropdowns_brands] = useState(false)
  const [loadingDropdowns_products, setLoadingDropdowns_products] = useState(false)
  const [loadingDropdowns_skus, setLoadingDropdowns_skus] = useState(false)
  const [selected_skus_view, setSelected_skus_view] = useState([''])
  const [selected_pics_view, setSelected_pics_view] = useState([''])
  const [loadingProperties, setLoadingProperties] = useState(false)



  const [brands, setBrands] = useState([])
  const [customers, setCustomers] = useState([])
  const [orders, setOrders] = useState([])
  const [products, setProducts] = useState([])
  const [skus, setSkus] = useState([])

  const [isProductAttachedToOrder, setIsProductAttachedToOrder] = useState(false)

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [orderDetail, setOrderDetail] = useState([])
  const [foundOrderDetailMatch, setFoundOrderDetailMatch] = useState(false)



  let location = useLocation()
  const pathArray = location.pathname.split('/')



  useEffect(() => {
    // setUrl_item_type(urlItemTypeList[0].code)
    // setUrl_destination_type(urlItemTypeList[0].destination)
    getURL()
    getOrders()
    getCustomers()
    getProducts()
    getBrands()
  }, []);

  async function getURL() {
    setLoadingProperties(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}u/id/${pathArray[3]}`)
    const url_data = await res.json();
    setUrlRecord_data(url_data)
    setUrl_item_type(url_data.url_item_type)
    setUrl_destination_type(url_data.url_destination_type)
    setUrl_seed(url_data.url_seed)
    setUg_hash(url_data.ug_hash)
    setProduct_id(url_data.product_id)
    setSku_id(url_data.sku_id)
    setOrder_id(url_data.order_id)
    setIsProductAttachedToOrder(url_data.flag_assigned_to_order)
    set_url(`https://www.luxanda.cn/${url_data.url_destination_type}/${url_data.url_seed}`)
    if (url_data.sku_id != 0){
      getOrdersWhereOrderDetailsContainSKU(url_data.sku_id)
    }

    if (url_data.product_id || url_data.order_id ){
      setIsAssigned(true)
    }

    getSkusByProductID(url_data.product_id).then(data => {
      console.log (data)
      for (var i = 0; i < data.length; i++){
        if (url_data.sku_id == data[i].id){
          setSelected_skus_view(data[i].sku)
          setSelected_pics_view(data[i].pic_url)
          break
        }
      }
      setLoadingProperties(false)
    })

    const title = "Luxanda | URL " + pathArray[3]
    title && (document.title = title);




    // setProduct(url_data);
    // setName(url_data.name)
    // setDescription(url_data.description)


  }


  async function getOrders() {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`)
    const ordersArray = await res.json()
    // console.log (ordersArray)

    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getOrdersByCustomerID(customer_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/cid/${customer_record_id}`)
    const ordersArray = await res.json()
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getOrdersWhereOrderDetailsContainSKU(sku_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/od_sid/${sku_record_id}`)
    const ordersArray = await res.json()
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }

  async function getOrdersByCustomerIDWhereOrderDetailsContainSKU(sku_record_id, customer_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_orders(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/c/${customer_record_id}/od_sid/${sku_record_id}`)
    const ordersArray = await res.json()
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoadingDropdowns_orders(false)
  }


  async function getCustomers() {
    // console.log ("loading...")
    setLoadingDropdowns_customers(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/all`)
    const customersArray = await res.json()
    // console.log (customersArray)
    setCustomers(customersArray);
    setLoadingDropdowns_customers(false)
  }

  async function getSkusByProductID(product_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_skus(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${product_record_id}`)
    const skuArray = await res.json()
    // console.log (skuArray)
    setSkus(skuArray);
    setLoadingDropdowns_skus(false)
    return skuArray
  }

  async function getBrands() {
    // console.log ("loading...")
    setLoadingDropdowns_brands(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
    const brandsArray = await res.json()
    setBrands(brandsArray);
    setLoadingDropdowns_brands(false)
  }

  async function getProducts() {
    // console.log ("loading...")
    setLoadingDropdowns_products(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/all`)
    const productsArray = await res.json()
    setProducts(productsArray);
    setLoadingDropdowns_products(false)
  }

  async function getProductsByBrandID(brand_record_id) {
    // console.log ("loading...")
    setLoadingDropdowns_products(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/brands/${brand_record_id}`)
    const productsArray = await res.json()
    setProducts(productsArray);
    setLoadingDropdowns_products(false)
  }


  const handleSubmit = async (e) => {
    console.log ("navigating")
  };



















  function getValueFromDataArray (data,id) {
    for (let i = 0; i < data.length; i++){
      if (data[i].id == id){
        return data[i]
      }
    }
  }

  function setSelectedId_View (type) {
    if (type == 'orders'){
       if (orders.length > 0){
          return (
            orders.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.order_number}, REF: {item.order_reference} - ({moment(item.order_date).format(dateFormat)})
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'products'){
      if (products.length > 0){
         return (
           products.map((item) => (
            <option value={item.id} key={item.id} >
                    {item.name} - {item.description}
            </option>
          )))
        } else {
          return <option value={0}>No Selection Available</option>
        }

    } else if (type == 'customers'){
       if (customers.length > 0){
          return (
            customers.map((item) => (
            <option value={item.id} key={item.id}  >
                    {formatPhoneNumber(item.phone_number)} - {item.customer_name}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'brands'){
       if (brands.length > 0){
          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.country}
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    } else if (type == 'skus'){
       if (skus.length > 0){
          return (
            skus.map((item) => (
            <option value={item.id} data-sku={item.sku} data-pic={item.pic_url} key={item.id}  >
                    {item.combo_identifier} (Quantity: {item.quantity})
            </option>
          )))


        } else {
          return <option value={0}>No Selection Available</option>
        }
    }
  }



  const dropdown_orders = setSelectedId_View('orders')
  const dropdown_products = setSelectedId_View('products')
  const dropdown_brands = setSelectedId_View('brands')
  const dropdown_customers = setSelectedId_View('customers')
  const dropdown_skus = setSelectedId_View('skus')

  let order_data = getValueFromDataArray(orders, order_id)
  // let product_data = getValueFromDataArray(products, product_id)


  function setURLTypeDropdown (type) {
    let data
    if (type == 'url_item_types'){
      data = urlItemTypeList
    } else if (type == 'url_destination_types'){
      data = urlDestinationTypeList
    }
    return data.map((item) => (
        <option value={item.code} key={item.code}  >
                {item.name}
          </option>
    ));
  }



  const url_item_typesDropdown = setURLTypeDropdown ('url_item_types')
  const url_destination_typesDropdown = setURLTypeDropdown ('url_destination_types')

  const getProperty = (array, selected_item_id, type) => {

  console.log (type)
  console.log (array)
  console.log (selected_item_id)

  for (var i = 0; i < array.length; i++){
    if (array[i].id == selected_item_id){
      if (type == 'product'){
        return(`${array[i].name} - ${array[i].description}`)
      } else if (type == 'sku'){

        return(`${array[i].combo_identifier} (Quantity: ${array[i].quantity})`)
      } else if (type === 'order'){
        return(`${array[i].order_number}, REF: ${array[i].order_reference} - (${moment(array[i].order_date).format(dateFormat)})`)
      }
    }
  }
}



  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(50% - 8px)',
    height: 'calc(50% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  let modified_view

  if (urlRecord_data.modified_by) {
    modified_view = (<div>Last modified by {urlRecord_data.modified_by} on {moment(urlRecord_data.date_modified).format('lll')}</div>)
  }



    return (
      <section className="ps-my-account ps-page--account">
      <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ps-page__content">
              {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING RECORDS</h5></div> : null}




                <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>URL ID: {urlRecord_data.id}</h3>
                      <h5><strong>Date Created: </strong>{moment(urlRecord_data.date_created).format('lll')} {modified_view}</h5>
                    </div>
                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`/admin/url/${urlRecord_data.id}/edit`}>
                        <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit URL</button>
                      </Link>
                    </div>
                  </div>
                </div>

                    <div className="ps-form__content">

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              <label className="form"><h3>URL Setup</h3></label>
                              <div style={{display:'flex', alignItems: 'center'}}>
                                <label className="form" style={{marginBottom: '0px', paddingRight: '10px'}}>
                                  <Tooltip placement="topRight" title="This toggle indicates if a URL is assigned to a Product or an Order">
                                    <InfoCircleOutlined />
                                  </Tooltip> {isAssigned ? 'Assigned' : 'Unassigned'}
                                </label>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  checked={isAssigned}
                                />
                              </div>
                            </div>
                          </div>
                      </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="form">
                                  <Tooltip placement="topLeft" title="This is used to create a unique ID for the URL.">
                                    <InfoCircleOutlined />
                                  </Tooltip> Getzo ID
                                </label>
                                  <div className="input-group mb-2" >
                                    <input
                                        className="form-control-plaintext"
                                        style={{backgroundColor: 'white', fontSize: '16px'}}
                                        type="text"
                                        placeholder="Loading..."
                                        value={url_seed}
                                        readOnly={true}
                                    />

                                  </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form">
                                  <Tooltip placement="topLeft" title="This is the item on which the QR Code or NFC will be encoded/embedded on">
                                    <InfoCircleOutlined />
                                  </Tooltip> Item
                                </label>
                                <select className="form-control" value={url_item_type} readOnly={true} >
                                  {url_item_typesDropdown}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form">
                                  <Tooltip placement="topLeft" title="This is the destination for the URL">
                                    <InfoCircleOutlined />
                                  </Tooltip> URL Destination
                                </label>
                                <select className="form-control" value={url_destination_type} readOnly={true} >
                                  {url_destination_typesDropdown}
                                </select>

                                <div style={{color: '#ffb81c'}}><b>{url_item_selected ? <span><WarningOutlined /> This is automatically selected for you. Change at your discretion.</span> : null}</b></div>
                            </div>
                        </div>
                      </div>
                    </div>





                    {url_destination_type == 'o' && isAssigned == true ?
                    <div className="ps-form__content" style={{marginTop: '50px'}}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form"><h3>Order Information</h3></label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form">Order ID</label>


                                <div>
                                  {orders && orders.length > 0 ?
                                  <Link to={`/admin/o/${order_id}`}>
                                    <button className="ps-btn" style={{width: '100%'}}>{getProperty(orders, order_id, 'order')}</button>
                                  </Link> : 'Loading...'}
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-6">
                        </div>

                        <div className="col-lg-3">
                            <div className="form-group">
                                <label className="form">Order Number</label>
                                <input
                                    className='form-control-plaintext'
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    value={order_data ? order_data.order_number : 'Not Available'}
                                    readOnly={true}
                                  />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="form-group">
                                <label className="form">Customer Name</label>
                                <div>
                                  {order_data && order_data.customer_name ?
                                  <Link to={`/admin/customer/${order_data.customer_id}`}>
                                    <button className="ps-btn" >{order_data.customer_name}</button>
                                  </Link> : 'Not Available'}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="form-group">
                                <label className="form">Order Reference</label>
                                <input
                                    className='form-control-plaintext'
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    value={order_data ? order_data.order_reference : 'Not Available'}
                                    readOnly={true}
                                  />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="form-group">
                                <label className="form">Customer Phone Number</label>
                                <input
                                    className='form-control-plaintext'
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    value={order_data ? formatPhoneNumber(order_data.customer_phone_number) : 'Not Available'}
                                    readOnly={true}
                                  />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label className="form">Order Date</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      value={order_data ? order_data.order_date : 'Not Available'}
                                      readOnly={true}
                                    />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="form-group">
                                <label className="form">Order Status</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      value={order_data ? order_data.order_status : 'Not Available'}
                                      readOnly={true}
                                    />
                            </div>
                        </div>
                      </div>
                    </div> : null}


                      {url_destination_type == 'p' && isAssigned == true ?
                      <div className="ps-form__content" style={{marginTop: '50px'}}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form"><h3>Product Information</h3></label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div style={thumb} >
                                <div style={thumbInner}>
                                  <img src={selected_pics_view ? selected_pics_view : null} style={img} alt="" border="0" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                              <div className="form-group">
                                  <label className="form">Product</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={products && products.length > 0 ? getProperty(products, product_id, 'product') : 'Loading...'}
                                      readOnly={true}
                                  />
                              </div>
                          </div>

                          <div className="col-lg-6">
                              <div className="form-group">
                                  <label className="form">SKU</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={skus && skus.length > 0 ? getProperty(skus, sku_id, 'sku') : 'Loading...'}
                                      readOnly={true}
                                  />
                              </div>
                          </div>
                          {sku_id != 0 ?
                          <div className="separator"></div> : null}
                          {sku_id != 0 ?
                          <div className="col-lg-12">
                            <div className="ps-checkbox" style={{marginTop: '0px', marginBottom: '30px'}}>
                              <input
                                className="form-control"
                                type="checkbox"
                                id="product-options"
                                checked={isProductAttachedToOrder}
                                />
                              <label for="product-options">Is this product in an existing order?</label>
                            </div>
                          </div> : null}
                          {isProductAttachedToOrder &&  sku_id != 0?
                          <div className="col-lg-6">
                              <div className="form-group">
                                  <label className="form">Order ID</label>
                                  <div>
                                    {orders && orders.length > 0 ?
                                    <Link to={`/admin/o/${order_id}`}>
                                      <button className="ps-btn" style={{width: '100%'}}>{getProperty(orders, order_id, 'order')}</button>
                                    </Link> : 'Loading...'}
                                  </div>
                              </div>
                          </div> : null}


                          {order_id && order_id != 0 ?
                          <div style={{display: 'contents'}}>
                            <div className="separator"></div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label className="form">Order Number</label>
                                    <input
                                        className='form-control-plaintext'
                                        style={{backgroundColor: 'white', fontSize: '16px'}}
                                        type="text"
                                        value={order_data ? order_data.order_number : 'Not Available'}
                                        readOnly={true}
                                      />
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label className="form">Customer Name</label>
                                    <div>
                                      {order_data && order_data.customer_name ?
                                      <Link to={`/admin/customer/${order_data.customer_id}`}>
                                        <button className="ps-btn" >{order_data.customer_name}</button>
                                      </Link> : 'Not Available'}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label className="form">Order Reference</label>
                                    <input
                                        className='form-control-plaintext'
                                        style={{backgroundColor: 'white', fontSize: '16px'}}
                                        type="text"
                                        value={order_data ? order_data.order_reference : 'Not Available'}
                                        readOnly={true}
                                      />
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label className="form">Customer Phone Number</label>
                                    <input
                                        className='form-control-plaintext'
                                        style={{backgroundColor: 'white', fontSize: '16px'}}
                                        type="text"
                                        value={order_data ? formatPhoneNumber(order_data.customer_phone_number) : 'Not Available'}
                                        readOnly={true}
                                      />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label className="form">Order Date</label>
                                      <input
                                          className='form-control-plaintext'
                                          style={{backgroundColor: 'white', fontSize: '16px'}}
                                          type="text"
                                          value={order_data ? order_data.order_date : 'Not Available'}
                                          readOnly={true}
                                        />
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label className="form">Order Status</label>
                                      <input
                                          className='form-control-plaintext'
                                          style={{backgroundColor: 'white', fontSize: '16px'}}
                                          type="text"
                                          value={order_data ? order_data.order_status : 'Not Available'}
                                          readOnly={true}
                                        />
                                </div>
                            </div>
                          </div> : null}




                      </div>
                    </div> : null}



                      {url_destination_type == 'tp' && isAssigned == true ?
                      <div className="ps-form__content" style={{marginTop: '50px'}}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form"><h3>Tamper Information</h3></label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                              <div className="form-group">
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      value={"This function hasn't been developed yet.  Please select a different URL Destination."}
                                      readOnly={true}
                                  />
                              </div>
                          </div>

                      </div>
                    </div> : null}






                    <div className="ps-form__content" style={{marginTop: '50px'}}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form"><h3>URL Information</h3></label>
                            </div>
                        </div>

                        <div className="col-sm-auto">
                            <div className="form-group">
                              {url_seed ?
                              <div className="input-group mb-12">
                                <QRCode value={url} size='256'/>
                              </div> : null}
                              <label className="form" style={{paddingTop: '25px'}}>QR Code</label>
                              <div><b>{url_item_type}</b> to <b>{url_destination_type}</b> URL:</div>
                              <div ><a href={url_seed ? url : null} target="_blank" style={{fontSize: '16px'}}>{url_seed ? url : 'Generate a seed first'}</a></div>
                            </div>
                        </div>
                        <div className="separator"></div>

                      
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form">Item:</label>
                                <input
                                    className='form-control-plaintext'
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    value={url_item_type}
                                    readOnly={true}
                                  />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form">URL Destination:</label>
                                <input
                                    className='form-control-plaintext'
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    value={url_destination_type}
                                    readOnly={true}
                                  />
                            </div>
                        </div>

                        </div>
                    </div>


                </form>
              </div>
            </div>
          </div>
          <div style={{height: '5vh'}}/>
      </div>
    </section>
    );
};

export default FormEditURL;
