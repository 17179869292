import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Checkbox } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
// import axios from 'axios'


// Redux
import { connect } from 'react-redux'
import { signinUser } from '../../redux/actions/userActions'
const Link = require("react-router-dom").Link


class signin extends Component {

  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      loading: false,
      errors: {}
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.ui.errors){
      this.setState({errors: nextProps.ui.errors})
    }
  }


  handleSubmit = (event) => {
    // event.preventDefault();
    // console.log(this.state)
    this.setState({
      loading: true
    })

    const userData = {
      email: this.state.email,
      password: this.state.password
    }
    this.props.signinUser(userData, this.props.history)
  }


  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })

  }

  render () {
    // const { classes } = this.props;
    const { ui: { loading }} = this.props
    const { errors } = this.state;
    console.log (errors)

    const title = "Luxanda | Signin "
    title && (document.title = title);

    const validateMessages = {
      types: {
        email: errors.email,
        password: errors.password,
      }
    };

    return (
      <div className="ps-my-account">
        <div className="container">
          <Form
            requiredMark={'optional'}
            className="ps-form--account"
            layout="vertical"
            initialValues={{ remember: true }}
            validateMessages={validateMessages}
            onFinish={this.handleSubmit}>
            <ul className="ps-tab-list">
                <li className="active">
                    <Link to="/signin">
                        Login
                    </Link>
                </li>
                <li>
                    <Link to="/signup">
                        Register
                    </Link>
                </li>
            </ul>
            <div className="ps-tab active" id="sign-in">
                <div className="ps-form__content">
                    <h5>Welcome Back!</h5>
                    <div className="form-group">
                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter your e-mail',
                                },
                            ]}>
                            <Input
                                className="form-control"
                                type="email"
                                placeholder="Your E-mail Address"
                                name="email"
                                label="E-mail"
                                value={this.state.email}
                                onChange={this.handleChange}
                                prefix={<MailOutlined className="site-form-item-icon" />}
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <Form.Item
                            name="password"
                            label="Password"

                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter your password',
                                },
                            ]}>
                            <Input.Password
                                className="form-control"
                                type="password"
                                placeholder="Your Password"
                                name="password"
                                label="Password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group form-forgot">
                      <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                          <Checkbox>Remember Me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="/forgotpassword">
                          Forgot Password?
                        </a>
                      </Form.Item>
                    </div>
                    <div className="form-group submit">
                      {(errors.general || errors.error) && (
                        <div>
                          <p className="error-message">{errors.general}{errors.error}</p>
                        </div>
                      )}
                        <button
                            type="submit"
                            className="ps-btn ps-btn--fullwidth"
                            disabled={loading}
                            >
                            {loading ? (<>Checking credentials <LoadingOutlined size="large"/></>
                          ) : ('Login')}
                        </button>
                    </div>

                </div>

                <div className="ps-form__footer">

                </div>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

signin.propTypes = {
  // classes: PropTypes.object.isRequired
  signinUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

const mapActionsToProps = {
  signinUser
}
export default connect(mapStateToProps, mapActionsToProps)(signin)
