import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'

import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const AdminBrandView = ({title}) => {

  const [brand, setBrand] = useState({})
  const [products, setProducts] = useState([])
  const [loading_brand, setLoading_brand] = useState(false)
  const [loading_products, setLoading_products] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(false)


  const location = useLocation()
  const pathArray = location.pathname.split('/')





  async function getBrand() {
    setLoading_brand(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/id/${pathArray[3]}`)
    const brandArray = await res.json();
    setBrand(brandArray);
    setLoading_brand(false)

    const title = "Luxanda | Brand " + pathArray[3]
    title && (document.title = title);
  }

  async function getAllProductsByBrand() {
    setLoadingProperties(true)
    setLoading_products(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/brands/${pathArray[3]}`);
    const productsArray = await res.json();
    setProducts(productsArray);
    setLoading_products(false)
    setLoadingProperties(false)
  }




  useEffect(() => {
    getBrand();
    getAllProductsByBrand()
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();

  }

  function handleSubmit(e) {
    console.log ("navigating")
  }

  let modified_view

  if (brand.modified_by) {
    modified_view = (<div>Last modified by {brand.modified_by} on {moment(brand.date_modified).format('lll')}</div>)
  }

  const frozenColumn_left = {
    width:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_third = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_fourth = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 42px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const img = {
    display: 'block',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }

  const mark_red = {
    fontSize: '20px',
    color: 'lightgrey'
  }
  const mark_green = {
    fontSize: '20px',
    color: 'green'
  }

  function makeTable ()  {
    return (products.length != 0 ?
      <div className="col-lg-12" style={{overflowX: 'scroll', paddingLeft: '0px', paddingRight: '0px'}}>

        <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '0px'}}>
          <thead>
            <tr>
              <th style={{position: 'sticky', left: '0px', backgroundColor: '#f1f1f1'}}>Name</th>
              <th>Description</th>
              <th>Options</th>
              <th>Serialized</th>
              <th><i className='icon-chevron-down' style={{fontSize: '10px'}}></i> Date Created</th>
              <th>Date Modified</th>
              <th style={{position: 'sticky', right: 'calc(-1px)', backgroundColor: '#f1f1f1'}}></th>
            </tr>
          </thead>
          <tbody>

          {products.map((product) => (
            <tr key={product.id}>
              <td style={frozenColumn_left}>{product.name}</td>
              <td style={{minWidth:'45rem', verticalAlign: 'middle'}}>{product.description}</td>
              <td style={{minWidth:'7rem', verticalAlign: 'middle'}}>{product.flag_options ? <i className='icon-checkmark-circle' style={mark_green} ></i> : <i className='icon-cross-circle' style={mark_red}></i>}</td>
              <td style={{minWidth:'7rem', verticalAlign: 'middle'}}>{product.flag_serialization ?<i className='icon-checkmark-circle' style={mark_green}></i> : <i className='icon-cross-circle' style={mark_red}></i>}</td>

              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(product.date_created).format('lll')}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(product.date_modified).format('lll') != 'Invalid date' ? moment(product.date_modified).format('lll') : ''  }</td>

              <td style={frozenColumn_right_third}>
                <Link to={`/admin/p/${product.id}`}>
                <button className="ps-btn-delete">
                    <i className='icon-eye'></i>
                </button>
                </Link>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      :
      <div className="col-lg-3">
        <input
            className='form-control-plaintext'
            style={{backgroundColor: 'white', fontSize: '16px'}}
            type="text"
            value={'No results found'}
            readOnly={true}
        />
      </div>
      )
  }




  return (
    <section className="ps-my-account ps-page--account">
    <div className="container">
        <div className="row">



          <div className="col-lg-12">
            <div className="ps-page__content">
            {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING RECORDS</h5></div> : null}


              <form className="ps-form--product-form" >
                <div className="ps-form__header">



                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <h3>Brand ID: {brand.id}</h3>
                      <h5><strong>Date Created: </strong>{moment(brand.date_created).format('lll')} {modified_view}</h5>
                    </div>

                    <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                      <Link to={`/admin/brand/${brand.id}/edit`}>
                        <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit Brand</button>
                      </Link>

                    </div>
                  </div>
                </div>

                <div className="ps-form__content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Brand Information</h3></label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Brand Name</label>
                              <input
                                  className="form-control-plaintext"
                                  type="text"
                                  style={{fontSize: '16px'}}
                                  placeHolder="Loading..."
                                  value={brand.name}
                              />
                        </div>
                    </div>
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Brand Country</label>
                              <input
                                  className="form-control-plaintext"
                                  type="text"
                                  style={{fontSize: '16px'}}
                                  placeHolder="Loading..."
                                  value={brand.country}
                              />
                        </div>
                    </div>
                  </div>
                </div>
                <div className="ps-form__content" style={{marginTop: '50px'}}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3>Available Products</h3></label>
                      </div>
                    </div>
                    {!loading_products ? makeTable() :
                      <div className="col-lg-3">
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            value={'Loading...'}
                            readOnly={true}
                        />
                    </div>}
                  </div>
                </div>

              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




    </div>
</section>

  );

}


export default AdminBrandView;
