export const urlItemTypeList = [
  {name: 'Thank You Letter', code: 'tyl', destination: 'o'},
  {name: 'Product: NFC', code: 'pnfc', destination: 'p'},
  {name: 'Product: QR Code', code: 'pqr', destination: 'p'},
  {name: 'Product: Tamper NFC', code: 'ptnfc', destination: 'tp'},
  {name: 'NFC Card: NFC', code: 'cnfc', destination: 'p'},
  {name: 'NFC Card: QR Code', code: 'cqr', destination: 'p'},
  {name: 'Marketing', code: 'm', destination: 'p'},
];


// exports.weightUnits = weightUnits
// exports.dimensionUnits = dimensionUnits
// export default {weightUnits, dimensionUnits}
