import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import { connect } from 'react-redux'
// import { assignHashToUser } from '../../redux/actions/userActions'
import { saveDeviceActions } from '../../utilities/common-helpers'
import { LoadingOutlined, VerifiedOutlined, CheckCircleOutlined, WechatOutlined} from '@ant-design/icons'
// import { countryFlags } from './data/country-flags'
// import TextareaAutosize from 'react-textarea-autosize';



// import './customer-url-view.css';
// import '../../utilities/weixin/weui.min.css';



// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';

import { useLocation } from 'react-router-dom'
// import { Popconfirm, message } from 'antd'

import { useIntl, FormattedMessage } from "react-intl"


var sha256 = require('sha256')



const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})

const FeedbackJump = ({title}) => {
  const intl = useIntl()
  const location = useLocation()
  const pathArray = location.pathname.split('/')

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const title = "Luxanda | Feedback"
    title && (document.title = title);

    setupURL();
    // console.log (pathArray[2])
  }, []);


  async function setupURL() {
    try{


      saveDeviceActions(location.pathname, "User Visit - Feedback - ugh: " + pathArray[2], pathArray[3], null, null)
      localStorage.setItem('ugh', pathArray[2])
      console.log (pathArray[0] + `/feedback`)
      window.location.href = pathArray[0] + `/feedback`


    } catch (err) {
      console.log (err)
    }
  }



  return (
    <section className="ps-my-account ps-page--account">


    <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">
              <form className="ps-form--product-form" >

                <div className="ps-form__content">


                {loading &&
                  <input
                    className="form-control-plaintext"
                    style={{backgroundColor: "white", fontSize: '26px', fontWeight: '600', padding: '0px'}}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'label_loading' })}
                    readOnly={true}
                    value=''
                    />}




                </div>
              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>

    </div>
  </section>

  );
}

export default FeedbackJump
