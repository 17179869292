import React, { useEffect, useState, useMemo } from 'react'



import { useSelector } from 'react-redux'
import { useIntl } from "react-intl"



const Modal = ({title, content, redirectUserOnModalDismiss}) => {

  const intl = useIntl()
  // const { user } = useSelector((state) => state)

  const body = document.getElementById('body')



  // if(user.loading){
  //   body.classList.add('hidden')
  // } else {
  //   body.classList.remove('hidden')
  // }

  const closeModal = () => {
    document.getElementById("modal-backdrop").style.display = "none"
    document.getElementById("modal").style.display = "none"
    document.getElementById("modal").classList.remove("show");
    redirectUserOnModalDismiss()
  }

  const renderModal_content = () => {
    return content.map((item, i) => (
      <div className="modal-section" key={`content-${i}`}>
        {intl.formatMessage({ id: item })}
      </div>
    ))
  }


  const valid_title = title && intl.formatMessage({ id: title })
  const valid_content = useMemo(() => {
    if (content[0]) {
      return renderModal_content()
    }
  }, [content])


  return (


    <>
      <div className="modal-container" id="modal" >
        <div className="modal-backdrop fade show" id="modal-backdrop" style={{display: 'none'}} onClick={e => {closeModal()}}></div>
        <div className="modal-dialog" >
          <div className="modal-content">
            <div className="modal-header">
              <h5>{valid_title}</h5>
              <button type="button" className="btn-close" onClick={e => {closeModal()}}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {valid_content}
            </div>
            <div className="modal-footer">
              <button className="ps-btn" onClick={e => {closeModal()}}>{intl.formatMessage({ id: 'action_back_to_product' })}</button>
            </div>
          </div>
        </div>
      </div>

    </>




  )
}

export default Modal
