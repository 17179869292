import React from 'react';
import { DatePicker, Form, Input, Radio } from 'antd';

const FormChangeUserInformation = (userCredentials) => {
  //this is the passed data
  console.log (userCredentials.userCredentials)
    return (
        <form className="ps-form--account-setting">
            <div className="ps-form__header">
                <h3>Account Information</h3>
            </div>
            <div className="ps-form__content">
                <div className="form-group">
                    <label className="text">
                        {userCredentials.userCredentials.email}
                    </label>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form">First Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={userCredentials.userCredentials.firstName ?  userCredentials.userCredentials.firstName : "First Name"}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">Last Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={userCredentials.userCredentials.lastName ?  userCredentials.userCredentials.firstName : "Last Name"}
                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">Phone Number</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={userCredentials.userCredentials.phoneNumber ?  userCredentials.userCredentials.phoneNumber : "Phone Number"}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form">E-mail</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={userCredentials.userCredentials.email ?  userCredentials.userCredentials.email : "E-mail Address"}
                            />
                        </div>
                    </div>
                    <div class="separator"></div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form">Address</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Address"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form">City</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="City"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form">Country</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group submit">
                    <button className="ps-btn">Update profile</button>
                </div>
            </div>
        </form>
    );
};

export default FormChangeUserInformation;
