import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'

import {formatPhoneNumber, makeCSVExport} from '../../../utilities/common-helpers'
import OrdersTableExtension from './OrdersTableExtension'

// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';


const Link = require("react-router-dom").Link

const OrdersTable = ({userCredentials, title}) => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [orders, setOrders] = useState([]);
  const [order_status, setOrder_status] = useState("All")
  const [loading, setLoading] = useState(false)

  function handleExport (e) {
    e.preventDefault()
    // console.log (orders)
    makeCSVExport(orders, 'orders_data')
  }


  async function getAllOrders() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`);
    const ordersArray = await res.json();
    // console.log (ordersArray)
    setOrders(ordersArray);
    setLoading(false)
  }

  async function getOrdersByStatus() {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/status/${order_status}`);
    const ordersArray = await res.json();
    setOrders(ordersArray);
    setLoading(false)
  }

  // async function getDeleteOrders() {
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}o/all`);
  //   const ordersArray = await res.json();
  //   setOrders(ordersArray);
  // }


  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}
      const res = await fetch(`${process.env.REACT_APP_API_URL}o/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      }).then((response) => {
        // console.log ("Record Deleted")
        // window.location.reload(false)
        window.location.reload()
      })
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAllOrders();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);
  }

  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }


  function handleSearch(e) {
    e.preventDefault()
    // console.log ("Searching by: " + order_status)
    if (order_status == 'All'){
      getAllOrders()
    } else {
      getOrdersByStatus(order_status)
    }
  }

  function handleSubmit(e) {
    // console.log ("navigating")
  }



  const handleSelect = (item, type) => {

    if (type == 'order_status'){
      setOrder_status(item.target.value)
    }
  }


  // console.log (orders)


  const tableRow_column_default = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    textAlign: 'center',
  }

  const tableRow_cancelled = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'red',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow_awaiting_fulfillment = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: 'white',
    backgroundColor: 'green',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow_default = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
    color: '#dddddd',
    backgroundColor: '#efefef',
    vertialAlign: 'middle',
    textAlign: 'center',
    width: '100%',
    cursor: 'default'
  }

  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solid #dee2e6',
    minWidth: '500px',
  }

  const frozenColumn_left = {
    minWidth:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '-1px',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 42px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const frozenColumn_right_third = {
    width:'42px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px + 84px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  const mark_red = {
    fontSize: '20px',
    color: 'lightgrey'
  }
  const mark_green = {
    fontSize: '20px',
    color: 'green'
  }

  function makeTable()  {

    return (orders.length != 0 ?
      <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>

        <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '1.5rem'}}>
          <thead>
            <tr>
              <th style={{position: 'sticky', left: '0px', backgroundColor: '#f1f1f1'}}>Order Number</th>
              <th>Order Reference</th>

              <th><i className='icon-chevron-down' style={{fontSize: '10px'}}></i> Order Date</th>

              <th>Status

              </th>
              <th>Customer</th>
              <th>TYL Shipped?</th>
              <th>TYL Delivery Date</th>
              <th>Track Order</th>
              <th>Track TYL</th>

              <th>Date Created</th>
              <th>Date Modified</th>
              {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 84px)', backgroundColor: '#f1f1f1'}}></th> : null }
              {userCredentials.role == 'administrator' ? <th style={{position: 'sticky', right: 'calc(-1px + 42px)', backgroundColor: '#f1f1f1'}}></th> : null }
              <th style={{position: 'sticky', right: '-1px', backgroundColor: '#f1f1f1'}} ></th>
            </tr>
          </thead>
          <tbody>

          {orders.map((order) => (
            <tr key={order.id}>
              <td style={frozenColumn_left}>{order.order_number}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{order.order_reference}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(order.order_date).format('lll')}</td>

              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                <div className="ps-btn" style={order.order_status == 'cancelled_by_customer' || order.order_status == 'cancelled_by_admin' ? tableRow_cancelled : (order.order_status == 'awaiting_fulfillment' ? tableRow_awaiting_fulfillment : tableRow_default)}>
                  {order.order_status}
                </div>
              </td>
              <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                <Link to={`/admin/customer/${order.customer_id}`}>
                  <button className="ps-btn" style={{width: '100%'}}>
                  <div style={{display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label className="form"><b>{order.customer_name}</b></label>
                    <label className="form">{formatPhoneNumber(order.customer_phone_number)}</label>
                  </div>

                  </button>

                </Link>
              </td>
              <td style={{minWidth:'10rem', verticalAlign: 'middle', textAlign: 'center'}}>{order.tyl_tracking_id ?<i className='icon-checkmark-circle' style={mark_green}></i> : <i className='icon-cross-circle' style={mark_red}></i>}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(order.tyl_date_delivered).format('lll') != 'Invalid date' ? moment(order.tyl_date_delivered).format('lll') : ''  }</td>
              <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                {order.tracking_id && order.order_status == 'Shipped' ?
                  <div className="form-group" style={{textAlign: 'center', margin: '0px'}}>
                      <a className="ps-btn" style={{width: '100%', padding: '15px'}} target="_blank" href={"https://www.ups.com/mobile/track?trackingNumber=" + order.tracking_id}>
                        Track
                      </a>
                  </div> : null}
              </td>
              <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                {order.tyl_tracking_id  ?
                  <div className="form-group" style={{textAlign: 'center', margin: '0px'}}>
                      <a className="ps-btn" style={{width: '100%', padding: '15px'}} target="_blank" href={"https://www.ups.com/mobile/track?trackingNumber=" + order.tyl_tracking_id}>
                        Track
                      </a>
                  </div> : null}
              </td>




              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(order.date_created).format('lll')}</td>
              <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>{moment(order.date_modified).format('lll') != 'Invalid date' ? moment(order.date_modified).format('lll') : ''  }</td>
              {userCredentials.role == 'administrator' ?
              <td style={frozenColumn_right_third}>
                <Link to={`/admin/o/${order.id}`}>
                <button className="ps-btn-delete">
                    <i className='icon-eye'></i>
                </button>
                </Link>
              </td> : null}
              {userCredentials.role == 'administrator' ?
              <td style={frozenColumn_right_second}>
                <Link to={`/admin/o/${order.id}/edit`}>
                  <button className="ps-btn-delete">
                      <i className='icon-pencil'></i>
                  </button>
                </Link>
              </td> : null}
              {userCredentials.role == 'administrator' ?
              <td style={frozenColumn_right}>
                <button className="ps-btn-delete" onClick={e => handleDelete(e, order.id)}>
                    <i className='icon-cross'></i>
                </button>
              </td> : null }
              {userCredentials.role == 'csr' ?
              <td style={frozenColumn_right}>
                <Link to={`/admin/o/${order.id}`}>
                  <button className="ps-btn-delete">
                      <i className='icon-eye'></i>
                  </button>
                </Link>
              </td> : null }
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      :
      <div className="col-lg-3">
        <input
            className='form-control-plaintext'
            style={{backgroundColor: 'white', fontSize: '16px'}}
            type="text"
            value={'No results found'}
            readOnly={true}
        />
      </div>
      )
  }





  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All Orders</h3>
                      {userCredentials.role == 'administrator' ?
                      <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                        <button className="ps-btn-delete" style={{marginRight: '15px'}}onClick={e => handleExport(e)}>Export</button>
                        <Link to="/admin/orders/new">
                          <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New Order</button>
                        </Link>
                      </div> : null }
                    </div>
                </div>


                <div className="row" style={{marginBottom: '30px'}}>
                  <div className="col-lg-12">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                      <div style={{width: '40rem', display: 'flex', alignItems: 'center'}}>
                        <div className="form-group">
                          <label className="form">Search by Order Status:</label>
                          <select className="form-control" defaultValue={0} value={order_status} onChange={ e=> handleSelect(e, 'order_status')} >
                            <option value="All">All</option>
                            <option value="Awaiting Fulfillment">Awaiting Fulfillment</option>
                            <option value="Cancelled by Admin">Cancelled by Admin</option>
                            <option value="Cancelled by Customer">Cancelled by Customer</option>
                            <option value="Refunded">Refunded</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </div>
                        <div style={{marginLeft: '30px'}}>
                          <button className="ps-btn" onClick={e => handleSearch(e)}>Go</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="ps-form__content">


                  <div className="row">

                  {!loading ? makeTable() :
                    <div className="col-lg-3">
                      <input
                          className='form-control-plaintext'
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="text"
                          value={'Loading...'}
                          readOnly={true}
                      />

                    </div>}


                  </div>
                </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>
    <OrdersTableExtension/>



</section>

  );

}


export default OrdersTable;
