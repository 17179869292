import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
// import { connect } from 'react-redux';
// import AccountQuickLinks from './AccountQuickLinks';
// import Link from 'next/link';
import AccountQuickLinksMobile from './AccountQuickLinksMobile';
import { signoutUser } from '../../../../redux/actions/userActions'

import LanguageSelect from "../helpers/languageSelect";

const Link = require("react-router-dom").Link



/*import { Drawer } from 'antd';
import PanelCartMobile from '../../panel/PanelCartMobile';*/
class MobileHeaderActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuDrawer: false,
            cartDrawer: false,
            searchDrawer: false,
            categoriesDrawer: false,
        };
    }

    handleDrawerClose = () => {
        this.setState({
            menuDrawer: false,
            cartDrawer: false,
            searchDrawer: false,
            categoriesDrawer: false,
        });
    };


    render() {
        // const { auth } = true// this.props;
        let {compare, wishlist, auth} = this.props;
        const {ui: {loading}, user: {user}} = this.props

        // const  auth  =  {isLoggedIn: false}
        const { cartTotal } = 0//this.props.cart;
        // <Link to="/account/shopping-cart">
        //     <div className="header__extra" href="#">
        //         <i className="icon-bag2"></i>
        //         <span className={cartTotal ? "" : "active"}>
        //             <i>{cartTotal ? cartTotal : 0}</i>
        //         </span>
        //     </div>
        // </Link>
        return (
            <div className="navigation__right" style={{alignItems: 'center'}}>


                {this.props.user.authenticated && Boolean(this.props.user.authenticated) === true ? (
                    <AccountQuickLinksMobile isLoggedIn={true} profileImageUrl={this.props.user.credentials.imageUrl}/>
                ) : (
                    <div className="header__extra">
                


                    </div>
                )}
                <LanguageSelect/>
            </div>
        );
    }
}

// const mapStateToProps = state => {
//     return state;
// };

MobileHeaderActions.propTypes = {
  // classes: PropTypes.object.isRequired
  signoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
})

const mapActionsToProps = {
  signoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(MobileHeaderActions)
// export default connect(mapStateToProps)(MobileHeaderActions);
