import React, { useEffect } from 'react'
// import Link from 'react-router-dom/Link';
import Logo from '../../elements/common/Logo'
// import MenuCategoriesDropdown from '../menus/MenuCategoriesDropdown'
import CurrencyDropdown from './modules/CurrencyDropdown';
// import LanguageSwitcher from './modules/LanguageSwitcher';
import SearchHeader from './modules/SearchHeader';
import HeaderActions from './modules/HeaderActions';
import Menu from '../../elements/menu/Menu'
import menuData from './data/menu.json';
// import { useTranslation } from "react-i18next";

import { stickyHeader, stickyHeaderPlaceholder } from '../../../utilities/common-helpers'
const Link = require("react-router-dom").Link


const HeaderDefault = () => {
  // const { t } = useTranslation()
  useEffect(() => {
    if (process.browser) {
      window.addEventListener('scroll', stickyHeader)
      window.addEventListener('scroll', stickyHeaderPlaceholder)
    }
  }, [])

  return (
    <div>
    <header className="header header--1" id="headerSticky">
          <div className="header__top">
              <div className="ps-container">
                  <div className="header__left">
                    <Logo/>

                  </div>
                  <div className="header__center">
                    <SearchHeader />
                  </div>
                  <div className="header__right">
                    <HeaderActions />
                  </div>
              </div>
          </div>

      </header>
      <header className="header_placeholder" id="headerSticky_placeholder">
      </header>
      </div>


  )
}

export default HeaderDefault


// <header className="header header--1" id="headerSticky">
//         <div className="header__top">
//             <div className="ps-container">
//                 <div className="header__left">
//                   <Logo/>
//                   <div className="menu--product-categories">
//                       <div className="menu__toggle_dark">
//                           <i className="icon-menu"></i>
//                           <span>Shop by Department</span>
//                       </div>
//                       <div className="menu__content_short">
//                         <Menu
//                             source={menuData.product_categories}
//                             className="menu--dropdown"
//                         />
//                       </div>
//                   </div>
//                 </div>
//                 <div className="header__center">
//                   <SearchHeader />
//                 </div>
//                 <div className="header__right">
//                   <HeaderActions />
//                 </div>
//             </div>
//         </div>
//         <nav className="navigation">
//             <div className="ps-container">
//                 <div className="navigation__left">
//                     <div className="menu--product-categories">
//                         <div className="menu__toggle">
//                             <i className="icon-menu"></i>
//                             <span>Shop by Department</span>
//                         </div>
//                         <div className="menu__content">
//                           <Menu
//                               source={menuData.product_categories}
//                               className="menu--dropdown"
//                           />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="navigation__right">
//                     <ul className="menu">
//                         <li className="menu-item-has-children dropdown">
//                           <div component={Link} to="/page/blank">
//                               <a>Your Recently Viewed</a>
//                           </div>
//                             <div className="sub-menu">
//                                 <p>
//                                   <Link to="/page/blank">
//                                           See all your recently viewed
//                                           items
//                                   </Link>
//                                 </p>
//                             </div>
//                         </li>
//                     </ul>
//                     <ul className="navigation__extra">
//                         <li>
//                           <div className="menu__toggle">
//                             <Link to="/vendor/become-a-vendor">
//                                 Sell on Luxanda
//                             </Link>
//                           </div>
//                         </li>
//                         <li>
//                           <Link to="/account/order-tracking">
//                               Track your order
//                           </Link>
//                         </li>
//                         <li>
//                           <CurrencyDropdown />
//                         </li>
//                         <li>
//
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//         </nav>
//     </header>
