import React from 'react';
// import { StrapiProductThumbnail } from '~/utilities/product-helper';
import ModuleProductWideActions from './modules/ModuleProductWideActions';
const Link = require("react-router-dom").Link

const ProductWide = ({ product }) => {

  const priceView = product.price //StrapiProductPrice(product);
  const thumbnailImage = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.fashionphile.com%2Fp%2Fprada-saffiano-lux-promenade-tote-cameo-73097&psig=AOvVaw2QPgZlPABBBfGTvDVAUUVl&ust=1633303020493000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCPicmYXurPMCFQAAAAAdAAAAABAM" //StrapiProductThumbnail(product);
  const badgeView = "10" //StrapiProductBadge(product);
  const bid = product.bid

    return (
        <div className="ps-product ps-product--wide">
            <Link className="ps-product__thumbnail" href="/product/[pid]" as={`/product/${product.pid}`}>

            </Link>
            <div className="ps-product__container">
                <div className="ps-product__content">
                <div className="ps-product__title_container">
                  <Link href="/product/[pid]" as={`/product/${product.pid}`} className="ps-brand__thumbnail" >Logo
                  </Link>
                    <div>
                      <Link href="/product/[pid]" as={`/product/${product.pid}`}>
                          <a className="ps-product__title">{product.bid}</a>
                      </Link>
                      <p className="ps-product__vendor">
                          Sold by:
                          <Link href="/shop">
                              <a> <strong> {product.bid}</strong></a>
                          </Link>
                      </p>
                    </div>
                    </div>
                    <ul className="ps-product__desc">
                        <li>Unrestrained and portable active stereo speaker</li>
                        <li> Free from the confines of wires and chords</li>
                        <li> 20 hours of portable capabilities</li>
                        <li>
                            Double-ended Coil Cord with 3.5mm Stereo Plugs
                            Included
                        </li>
                        <li> 3/4″ Dome Tweeters: 2X and 4″ Woofer: 1X</li>
                    </ul>
                </div>
                <ModuleProductWideActions product={product} />
            </div>
        </div>
    );
};

export default ProductWide;
