import { SET_USER, SET_AUTHENTICATED, SET_UNAUTHENTICATED, LOADING_USER, SET_USER_HASH, SET_USER_ORDER_REF } from '../types'

const initialState = {
  authenticated: false,
  loading: false,
  credentials: {},
  likes: [],
  cart: [],
  wishlist: [],
  review_votes: [],
  reviews: [],
  notifications: [],
  orderHash: [],
  orderRef: [],

}

export default function(state = initialState, action){
  switch(action.type){
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        ...action.payload
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case SET_USER_HASH:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case SET_USER_ORDER_REF:
      return {
          ...state,
          loading: false,
          ...action.payload
      }
    default:
      return state;
  }
}
