import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { LoadingOutlined } from '@ant-design/icons'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormNewPromotion = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)
  const date = new Date()
  const [date_created, setDate_created] = useState("");
  const [promotion_date, setPromotion_date] = useState("");

  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [brand_id, setBrand_id] = useState("");

  const [variant_id, setVariant_id] = useState("")
  const [variant_option_id, setVariant_option_id] = useState("")

  const [brands, setBrands] = useState([])
  const [productsByBrand, setProductsByBrand] = useState([])
  const [variantsByProducts, setVariantsByProducts] = useState([])
  const [variantOptionsByVariant, setVariantOptionsByVariant] = useState([])


  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [brandsDropdown, setBrandsDropdown] = useState([])
  const [productsDropdown, setProductsDropdown] = useState([])




  let location = useLocation()



  useEffect(() => {
    getBrands();

  }, []);

  async function getBrands() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
    const brandsArray = await res.json()
    setBrands(brandsArray);
    setLoadingDropdowns(false)
  }

  async function getProductsByBrand(selected_brand_id) {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/b/${selected_brand_id}`)
    const productsByBrandArray = await res.json()
    setProductsByBrand(productsByBrandArray);
    setLoadingDropdowns(false)
  }

  async function getVariantsByProductID(selected_product_id) {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}v/pid/${selected_product_id}`)
    const variantsByProductsArray = await res.json()
    console.log (variantsByProductsArray)
    setVariantsByProducts(variantsByProductsArray);
    setLoadingDropdowns(false)
  }

  async function getVariantOptionsByVariantID(selected_variant_id) {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}v_o/vid/${selected_variant_id}`)
    const variantOptionsByVariantArray = await res.json()
    setVariantOptionsByVariant(variantOptionsByVariantArray);
    setLoadingDropdowns(false)
  }



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true)
      const created_by = user.credentials.email
      const body = { date_created, promotion_date, price, currency, product_id, brand_id, created_by, variant_id, variant_option_id };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}promo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then((response) => {
        setLoading(false)
      })


      window.location = "/admin/promotions";

    } catch (err) {
      console.error(err.message);
    }
  };







  const fieldChanged = (type, value) => {
    if (type == 'promotion_date'){
      const converted_date = value.toISOString()
      setPromotion_date(converted_date);
    } else if (type == 'price'){
      setPrice(value);
    }

    setDate_created(new Date())
    setCurrency('USD')


  };


  const handleSelect = (item, type) => {
    if (type == 'brand_id'){
      setBrand_id(item.target.value)
      getProductsByBrand(item.target.value)
      setProduct_id("")
    } else if (type == 'product_id'){
      setProduct_id(item.target.value)
      getVariantsByProductID(item.target.value)
    } else if (type == 'variant_id'){
      setVariant_id(item.target.value)
      getVariantOptionsByVariantID(item.target.value)
    } else if (type == 'variant_option_id'){
      setVariant_option_id(item.target.value)
    }
  }

  // console.log ("savinngggg...")
  // console.log ("promotion_date: " + promotion_date)
  // console.log ("price: " + price)
  // console.log ("brand_id: " + brand_id)
  // console.log ("product_id: " + product_id)
  // console.log ("date_created: " + date_created)
  // console.log ("currency: " + currency)
  // console.log ("brands: ")
  // console.log (brands)
  // console.log ("products by brands: ")
  // console.log (productsByBrand)
  // console.log ('variants')
  // console.log (variantsByProducts)
  // console.log ('variant options')
  // console.log (variantOptionsByVariant)




  function setSelectedId_View (type) {
    if (type == 'brands'){
       if (brands.length > 0){


          return (
            brands.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.id}
            </option>
          )))


        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }
    } else if (type == 'products'){
      if (productsByBrand.length > 0){
         return (
           productsByBrand.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name} - {item.variant} - {item.sku}
            </option>
          )))
      } else {
        return <option value={0}>- - - No Selection Available - - -</option>
      }
    } else if (type == 'variants'){
      if (variantsByProducts.length > 0){
         return (
           variantsByProducts.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name}
            </option>
          )))
      } else {
        return <option value={0}>- - - No Selection Available - - -</option>
      }
    } else if (type == 'variant_options'){
      if (variantOptionsByVariant.length > 0){
         return (
           variantOptionsByVariant.map((item) => (
            <option value={item.id} key={item.id}  >
                    {item.name}
            </option>
          )))
      } else {
        return <option value={0}>- - - No Selection Available - - -</option>
      }
    }

  }

  const dropdown_brands = setSelectedId_View('brands')
  const dropdown_products = setSelectedId_View('products')
  const dropdown_variants = setSelectedId_View('variants')
  const dropdown_variant_options = setSelectedId_View('variant_options')

    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Create New Promotion</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">
                  <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form">Promotion Date</label>
                        <div className="ant-calendar-picker">
                            <DatePicker
                            allowClear={false}
                                onChange={e => fieldChanged('promotion_date', e)}
                            />
                        </div>
                    </div>
                  </div>
                    <div className="separator"></div>


                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Brand ID</label>

                            {loadingDropdowns ? <div>Loading...</div> :
                            <select className="form-control" defaultValue={0} value={brand_id} onChange={ e=> handleSelect(e, 'brand_id')} >
                                    {brands.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                      {dropdown_brands}
                                </select>}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Product ID</label>
                            {loadingDropdowns ? <div>Loading...</div> :


                            <select className="form-control" defaultValue={0} value={product_id} onChange={ e=> handleSelect(e, 'product_id')} >

                                    {productsByBrand.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                  {dropdown_products}
                                </select>}


                        </div>
                    </div>



                    <div className="separator"></div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Variant ID</label>
                            {loadingDropdowns ? <div>Loading...</div> :


                            <select className="form-control" defaultValue={0} value={variant_id} onChange={ e=> handleSelect(e, 'variant_id')} >

                                    {variantsByProducts.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                  {dropdown_variants}
                                </select>}


                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Variant Option ID</label>
                            {loadingDropdowns ? <div>Loading...</div> :


                            <select className="form-control" defaultValue={0} value={variant_option_id} onChange={ e=> handleSelect(e, 'variant_option_id')} >

                                    {variantOptionsByVariant.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                  {dropdown_variant_options}
                                </select>}


                        </div>
                    </div>



                    <div className="separator"></div>


                    <div className="col-sm-auto">
                        <div className="form-group">
                          <label className="form">Price</label>
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>

                              <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Price"
                                  value={price}
                                  onChange={e => fieldChanged('price', e.target.value)}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">USD</span>
                              </div>
                            </div>
                        </div>
                    </div>



                    <div className="separator"></div>

                </div>

                <div className="form-group submit">
                    <button
                      className="ps-btn"
                      onClick={e => handleSubmit(e)}
                      disabled={loading}
                      >
                      {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Save Promotion')}
                      </button>
                </div>

            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormNewPromotion;
