import React from 'react'
// import Link from 'next/link'
const Link = require("react-router-dom").Link

const MainMenu = ({ source }) => {
  let menuContentView
  if (source) {
    menuContentView = source.megaContent.map((item) => (
      <div className="mega-menu__column" key={item.heading}>
        <h4>{item.heading}</h4>
        <ul className="mega-menu__list">
          {item.megaItems.map((subItem) => (
            <li key={subItem.text}>
              <Link to={subItem.url} as={subItem.url}>
                {subItem.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ))
  }
  return (
    <li className="menu-item-has-children has-mega-menu">
      <Link to={source.url !== '' ? source.url : '/'}>

          {source.icon && <i className={source.icon}></i>}
          {source.text}

      </Link>
      <div className="mega-menu">{menuContentView}</div>
    </li>
  )
}

export default MainMenu
