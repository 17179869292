import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
// import axios from 'axios'
// import {generateTempArray} from '../../utilities/common-helpers'
// import { ProductGroupWithCarousel } from '../../components/partials/product/ProductGroupWithCarousel';


// import ShopItems from '../../components/partials/shop/ShopItems';

const Link = require("react-router-dom").Link

const ProductVariantsTable = ({userCredentials, title}) => {
  // const [productItems, setProductItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const [variants, setVariants] = useState([]);




  async function getVariants() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}v/all/true`);
    const variantsArray = await res.json();


    // console.log (variantsArray)
    //
    // for (var i = 0; i < variantsArray.length; i++){
    //   variantsArray[i].product_name = await getProductByProductID(variantsArray[i].product_id)
    //   console.log (variantsArray[i].product_name)
    //
    // }
    console.log (variantsArray)

    setVariants(variantsArray);


  }

  // async function getProductByProductID(product_id){
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${product_id}`)
  //
  //   const product = await res.json()
  //   // console.log (product.name)
  //   return (product.name)
  // }


  useEffect(() => {
    getVariants();
  }, []);


  function handleChangeTab(e, tab) {
    e.preventDefault();
    // setCurrentCollection(tab.name);

  }

  function handleSubmit(e) {
    // e.preventDefault()
    console.log ("navigating")

  }


  async function deleteRecord(record_id) {
    try {
      // setLoading(true)
      const deleted_at = new Date()
      const deleted_by = userCredentials.email
      const body = {deleted_at, deleted_by}
      const res = await fetch(`${process.env.REACT_APP_API_URL}v/id/${record_id}/a=del`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      }).then((response) => {
        // setLoading(false)
        console.log ("Record Deleted")
        window.location.reload(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  }




  function handleDelete(e, record_id) {
    e.preventDefault()

    if(window.confirm('Are you sure you wish to delete record ID: ' + record_id + '?')){
      deleteRecord(record_id)
    }
  }



  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solix #dee2e6',
  }


  return (
    <section className="ps-my-account ps-page--account">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">

              <form className="ps-form--product-form" >
                <div className="ps-form__header">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>All Variants</h3>

                      <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                        <Link to="/admin/variants/new">
                          <button className="ps-btn" onClick={e => handleSubmit(e)}>Add New Variant</button>
                        </Link>
                      </div>
                    </div>
                </div>
                <div className="ps-form__content">

                  <div className="row">

                        <table class="table mt-5">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Product ID</th>
                              <th>Variant Type</th>
                              <th>Date Modified</th>
                              <th>View</th>
                              <th>Edit</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>

                          {variants.map((variant) => (
                            <tr key={variant.id}>
                              <td style={tableRow}>{variant.id}</td>
                              <td style={tableRow}>
                                <Link to={`/admin/p/${variant.product_id}`}>
                                  <button className="ps-btn">{variant.product_name}</button>
                                </Link>
                              </td>
                              <td style={tableRow}>{variant.name}</td>

                              <td style={tableRow}>{moment(variant.date_modified).format('lll') != 'Invalid date' ? moment(variant.date_modified).format('lll') : ''  }</td>
                              <td style={tableRow}>
                                <Link to={`/admin/variant/${variant.id}`}>
                                  <button className="ps-btn-delete">
                                      <i className='icon-eye'></i>
                                  </button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <Link to={`/admin/variant/${variant.id}/edit`}>
                                  <button className="ps-btn-delete">
                                      <i className='icon-pencil'></i>
                                  </button>
                                </Link>
                              </td>
                              <td style={tableRow}>
                                <button className="ps-btn-delete" onClick={e => handleDelete(e, variant.id)}>
                                    <i className='icon-cross'></i>
                                </button>
                              </td>



                            </tr>
                          ))}
                          </tbody>
                        </table>

                      </div>
                    </div>


              </form>


          </div>
        </div>
      </div>
    <div style={{height: '5vh'}}/>




</section>

  );

}


export default ProductVariantsTable;
