import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Popconfirm, message, Tooltip } from 'antd'
import moment from 'moment'
import {makeID, formatter} from '../../utilities/common-helpers'
import TextareaAutosize from 'react-textarea-autosize';
import { useLocation } from 'react-router-dom'


var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormEditProduct = () => {

  const {user, selectedUnit } = useSelector(mapState)

  const [name, setName] = useState("");
  const [brand_id, setBrand_id] = useState("");
  const [description, setDescription] = useState("Not Entered");
  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [parent_variant_id, setParent_variant_id] = useState("")
  const [brands, setBrands] = useState([]);
  const [itemPrice, setItemPrice] = useState("")
  const [itemPromotion_date, setItemPromotion_date] = useState('');
  const [itemWarranty, setItemWarranty] = useState("Not Entered")
  const [itemQuantity, setItemQuantity] = useState("")
  const [itemSku, setItemSku] = useState("")
  const [itemBarcode, setItemBarcode] = useState("")
  const [itemSerialRunLimit, setItemSerialRunLimit] = useState("")
  const [itemPic, setItemPic] = useState("")
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [doesProductHaveOptions, setDoesProductHaveOptions] = useState(false)
  const [isThisProductSerialized, setIsThisProductSerialized] = useState(false)
  const [optionList, setOptionList] = useState([""])
  const [optionList_deleted, setOptionList_deleted] = useState([{}]) //this is the list of options that were once on db and active, but are now removed
  const [optionValueList, setOptionValueList] = useState([[{name: ''}]]);
  const [optionValueList_deleted, setOptionValueList_deleted] = useState([[{name: ''}]]); //this is the list of options that were once on db and active, but are now removed
  const [optionValueList_temp, setOptionValueList_temp] = useState([[{name: ''}]]);
  const [optionValueMatrix, setOptionValueMatrix] = useState([])
  const [serialSelectionList, setSerialSelectionList] = useState([])
  const [priceList, setPriceList] = useState({})
  const [priceList_new, setPriceList_new] = useState({})
  const [quantityList, setQuantityList] = useState({})
  const [skuList, setSkuList] = useState({})
  const [barcodeList, setBarcodeList] = useState({})
  const [promotionDateList, setPromotionDateList] = useState({})
  const [promotionDateList_old, setPromotionDateList_old] = useState({})
  const [picUrlList, setPicUrlList] = useState({})
  const [serialQuantityList, setSerialQuantityList] = useState({})
  const [loadingProperties, setLoadingProperties] = useState(false)

  const [wc_url, setWc_url] = useState('Not Entered')


  const [product, setProduct] = useState({})


  let location = useLocation()
  const pathArray = location.pathname.split('/')

  const dateFormat = 'YYYY-MM-DD'

  useEffect(() => {
    getProduct()
    getSkus()
    getPromos()
    getProductVariantOptionValues()
    getBrands()
  }, []);

  async function getBrands() {
      setLoadingDropdowns(true)
      const res = await fetch(`${process.env.REACT_APP_API_URL}b/all`)
      const brandsArray = await res.json()
      setBrands(brandsArray);
      setLoadingDropdowns(false)
    }



  async function getProduct() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${pathArray[3]}`)
    const product_data = await res.json();

    setProduct(product_data);
    setName(product_data.name)
    setDescription(product_data.description)
    setBrand_id(product_data.brand_id)
    setDoesProductHaveOptions(product_data.flag_options)
    setIsThisProductSerialized(product_data.flag_serialization)
    getProductVariants(product_data.id)
    setWc_url(product_data.wc_url)
    setItemWarranty(product_data.warranty_information)

    const title = "Luxanda | Product " + pathArray[3]
    title && (document.title = title);

  }



  async function getProductVariants() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}v/pid/${pathArray[3]}`)
    const variant_data = await res.json();

    getProductVariantOptions(variant_data)
  }

  async function getProductVariantOptions(variant_data) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}v_o/pid/${pathArray[3]}`)
    const variant_options_data = await res.json();

    // return variant_options_data
    //Must use clone inorder to have separate instances of the retrieved object
    const variant_options_data_clone = JSON.parse(JSON.stringify(variant_options_data))

    console.log (variant_data)
      //else skip
    if (variant_data.length != 0){
      // // populate the optionList array
      let list = []
      let list_temp = []

      for (var i = 0; i < variant_data.length; i++){
        list.push([])
        list_temp.push([])
        for (var x = 0; x < variant_options_data.length; x++){
          if (variant_data[i].id == variant_options_data[x].product_variant_id){
            list[i].push(variant_options_data[x])
            list_temp[i].push(variant_options_data_clone[x])
          }
        }
      }

      for (var i = 0; i < list.length; i++){
        if (list[i].length == 0){
          list[i].push({name: ''})
          list_temp[i].push({name: ''})
        }
      }



      setOptionValueList(list)
      setOptionValueList_temp(list_temp)
      setOptionList(variant_data)
      setOptionValueMatrix(makeVariantArray(list))
    } else {
      let list = []

      list.push({name: ''})

      setOptionValueList([list])
      setOptionValueList_temp([list])
      setOptionList(list)
      setOptionValueMatrix([])
    }

  }


  async function getProductVariantOptionValues() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}m/pid/${pathArray[3]}`)
    const variant_option_values_data = await res.json();
    // console.log (variant_option_values_data)
    if (variant_option_values_data.length != 0){
    //parse out by the value
      // const picUrlList_db = {}
      for (var i = 0; i < variant_option_values_data.length; i++){
        const combo_id = variant_option_values_data[i].value
        const record_id = variant_option_values_data[i].id
        // const value_pic_url = variant_option_values_data[i].pic_url
        const value_active = variant_option_values_data[i].active
        // picUrlList_db[combo_id] ={value: value_pic_url, active: value_active, record_id: record_id}
      }
      // setPicUrlList(picUrlList_db)
    }
  }

  async function getSkus() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${pathArray[3]}`)
    const sku_data = await res.json();

    const picUrlList_db = {}
    if (sku_data.length != 0){

      const skuList_db = {}
      const barcodeList_db = {}
      const quantityList_db = {}
      const serial_run_limit_db = {}

      for (var i = 0; i < sku_data.length; i++){

        const combo_id = sku_data[i].combo_identifier
        const record_id = sku_data[i].id
        const barcode = sku_data[i].barcode
        const sku = sku_data[i].sku
        const quantity = sku_data[i].quantity
        const value_active = sku_data[i].active
        const run_limit = sku_data[i].serial_run_limit
        const value_pic_url = sku_data[i].pic_url

        picUrlList_db[combo_id] ={value: value_pic_url, active: value_active, record_id: record_id}
        skuList_db[combo_id] ={value: sku, active: value_active, record_id: record_id}
        barcodeList_db[combo_id] = {value: barcode, active: value_active, record_id: record_id }
        quantityList_db[combo_id] = {value: quantity, active: value_active, record_id: record_id }
        serial_run_limit_db[combo_id] = {value: run_limit, active: value_active, record_id: record_id }

        if (combo_id == 'NO OPTIONS'){
          setItemSku(sku)
          setItemBarcode(barcode)
          setItemQuantity(quantity)
          setItemSerialRunLimit(run_limit)
          setItemPic(value_pic_url)
        }

      }
      setPicUrlList(picUrlList_db)
      setBarcodeList(barcodeList_db)
      setSkuList(skuList_db)
      setQuantityList(quantityList_db)
      setSerialQuantityList(serial_run_limit_db)
    }
  }

  async function getPromos() {
    setLoadingProperties(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}promos/pid/${pathArray[3]}`)
    const promo_data = await res.json();

    // console.log (promo_data)
    if (promo_data.length != 0){
      console.log (promo_data)


      const priceList_db = {}
      const promotionDateList_db = {}
      let found_item = {}
      let price
      let promotion_date
      let record_id
      let value_active
      let sku_id

      for (var i = 0; i < promo_data.length; i++){
        const combo_id = promo_data[i].combo_identifier

        // console.log (found_item[combo_id])
        // console.log (combo_id)
        if (found_item[combo_id] && found_item[combo_id].hasOwnProperty('record_id')){
          // console.log (found_item[combo_id])
          // console.log (found_item[combo_id].record_id  + " < " + promo_data[i].id)
          if (found_item[combo_id].record_id < promo_data[i].id) {
            // console.log ("TRUUEE")
            price = promo_data[i].price
            promotion_date = promo_data[i].promotion_date
            record_id = promo_data[i].id
            value_active = promo_data[i].active
            sku_id = promo_data[i].sku_id
          } else {
            price = found_item[combo_id].price
            promotion_date = found_item[combo_id].promotion_date
            record_id = found_item[combo_id].record_id
            value_active = found_item[combo_id].active
            sku_id = found_item[combo_id].sku_id
          }
        } else {
          price = promo_data[i].price
          promotion_date = promo_data[i].promotion_date
          record_id = promo_data[i].id
          value_active = promo_data[i].active
          sku_id = promo_data[i].sku_id
          // found_item[combo_id] = {price: price, promotion_date: promotion_date, record_id: record_id}
        }

        found_item[combo_id] = {price: price, promotion_date: promotion_date, record_id: record_id, active: value_active, sku_id: sku_id}
        // found_item[combo_id] = {record_id: record_id}

        // const value_active = promo_data[i].active
        // const sku_id = promo_data[i].sku_id


        priceList_db[combo_id] = {value: price, active: value_active, record_id: record_id, sku_id: sku_id}
        promotionDateList_db[combo_id] = {value: promotion_date, active: value_active, record_id: record_id}

        if (combo_id == 'NO OPTIONS'){
          setItemPrice(price)
          setItemPromotion_date(promotion_date)

        }
      }

      // console.log (promotionDateList_db)
      setPriceList(priceList_db)
      setPromotionDateList(promotionDateList_db)
    }
    setLoadingProperties(false)

  }


  const makeVariantArray = (list, n = 0, result = [], current = [], index) => {
    if (n === list.length){
      result.push(current)
    } else list[n].forEach((item, i) => {
     if (item && item.name != "DELETE" && item.name !== '') {
        makeVariantArray(list, n+1, result, [...current, item.name], i)
     }
    }
    )
    return result
  }












  // function setSelectedId_View (type) {
  //   if (type == 'brands'){
  //      if (brands.length > 0){
  //         return (
  //           brands.map((item) => (
  //           <option value={item.id} key={item.id}  >
  //                   {item.name} - {item.country}
  //           </option>
  //         )))
  //
  //
  //       } else {
  //         return <option value={0}>- - - No Selection Available - - -</option>
  //       }
  //   }
  // }

  // const dropdown_brands = setSelectedId_View('brands')




  function setVariant_option_values_container(x, i_x) {
    return (

        x.map((y, i_y) => (
        <div key={i_y} className="box" style={{display: 'flex'}}>
          <input
              className='form-control-plaintext'
              style={{backgroundColor: 'white', fontSize: '16px'}}
              type="text"
              placeholder={"Loading..."}
              value={y.name}
              readOnly={true}
          />
        </div>)

      )

      )

  }








  const getProperty = (array, selected_item_id, type) => {

    console.log (type)
    console.log (array)
    console.log (selected_item_id)

    for (var i = 0; i < array.length; i++){
      if (array[i].id == selected_item_id){
        if (type == 'brand'){
          return(`${array[i].name}`)
        } else if (type == 'country'){
          return(`Country of Origin: ${array[i].country}`)
        } else if (type == 'product'){
          return(`${array[i].name}`)
        } else if (type == 'sku'){
          return(`${array[i].sku}`)
        } else if (type === 'promotion'){
          return(`${formatter.format(array.value)} USD`)
        } else if (type == 'effective_date'){
          return(`${array[i].effective_date}`)
        } else if (type == 'quantity'){
          return(`${array[i].quantity}`)
        }


      }
    }


  }

  const handleAddOptionInput = (e) => {
    e.preventDefault();
    setOptionList([...optionList, {name: ""}])
    setOptionValueList([...optionValueList, [{name: ""}]])
    setOptionValueList_temp([...optionValueList_temp, [{name: ""}]])
    setSerialSelectionList([...serialSelectionList, false])
    setOptionValueMatrix(makeVariantArray([[{name: ''}]]))
  }

  const isListValid = (list) => {
    var foundValidValue = false
    for (var i = 0; i < list.length; i++){
      if (list[i].name != '' && list[i].name != 'DELETE'){
        foundValidValue = true

      }
    }
    return foundValidValue
  }



  const tableRow = {
    padding: '0.75rem',
    verticalAlign: 'middle',
    borderTop: '1px solid #dee2e6',
    minWidth: '500px',
  }



  const frozenColumn_left = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    minWidth:'20rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(27px + 5rem)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };

  let modified_view

  if (product.date_modified) {
    modified_view = (<div>Last modified by {product.modified_by} on {moment(product.date_modified).format('lll')}</div>)
  }

  function handleSubmit(e) {
    console.log ("navigating")
  }



    return (
    <section className="ps-my-account ps-page--account">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ps-page__content">
              <form className="ps-form--product-form" >


              <div className="ps-form__header">
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <h3>Product ID: {product.id}</h3>
                    <h5><strong>Date Created: </strong>{moment(product.date_created).format('lll')} {modified_view}</h5>
                  </div>
                  {user.credentials.role == 'administrator' ?
                  <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                    <Link to={`/admin/p/${product.id}/edit`}>
                      <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit Product</button>
                    </Link>
                  </div> : null }
                </div>
              </div>

                  <div className="ps-form__content">
                  {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING RECORDS</h5></div> : null}


                      <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form"><h3>Product Information</h3></label>
                              </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form">Brand</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={brands && brands.length > 0 ? getProperty(brands, brand_id, 'brand') : 'Loading...'}
                                      readOnly={true}
                                  />
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '12px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={brands && brands.length > 0 ? getProperty(brands, brand_id, 'country') : 'Loading...'}
                                      readOnly={true}
                                  />
                            </div>
                        </div>

                        <div className="separator"></div>
                          <div className="col-lg-6">
                              <div className="form-group">
                                  <label className="form">Product Name</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={name}
                                      readOnly={true}
                                  />
                              </div>
                          </div>
                          <div className="col-lg-6">
                          </div>
                          <div className="col-lg-6">
                              <div className="form-group">
                                  <label className="form">Product Description</label>
                                  <TextareaAutosize
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px', height: 'auto', resize: 'none',}}
                                      placeholder="Loading..."
                                      value={description}
                                      readOnly={true}
                                  />
                              </div>
                          </div>
                          <div className="separator"></div>
                          <div className="col-lg-6">
                              <div className="form-group">
                                  <label className="form">Product Warranty</label>
                                  <TextareaAutosize
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px', height: 'auto', resize: 'none',}}
                                      placeholder="Loading..."
                                      value={itemWarranty}
                                      readOnly={true}
                                  />
                              </div>
                          </div>
                          <div className="separator"></div>

                        </div>

                        <div className="row" style={{backgroundColor: '#f1f1f1', paddingTop: '2.5rem', borderRadius: '4px'}}>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form"><h3 style={{color: '#d3d3d3'}}>Mini-Program Information</h3></label>
                              </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form">
                                  <Tooltip placement="topRight" title="This is the Query portion of the Mini-Program pagepath after the 'ID' marker: /pages/product/show/main.html?id=62aa4b9885eb4df50ec8cf71">
                                    <InfoCircleOutlined />
                                  </Tooltip> Mini-Program Scheme Query
                                </label>
                                <input
                                    className="form-control-plaintext"
                                    style={{ fontSize: '16px'}}
                                    type="text"
                                    placeholder="Loading..."
                                    value={wc_url}
                                    readOnly={true}
                                />
                            </div>
                          </div>
                        </div>


                      </div>









                      <div className="ps-form__content" style={{marginTop: '50px'}}>

                          <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="form"><h3>Options</h3></label>
                                  <div className="ps-checkbox" style={{marginTop: '10px'}}>
                                    <input
                                      className="form-control"
                                      type="checkbox"
                                      id="product-options"
                                      checked={doesProductHaveOptions}
                                      readOnly={true}
                                      />
                                    <label for="product-options">{doesProductHaveOptions ? 'This Product has Options' : 'This Product does not have Options'}</label>
                                  </div>
                                </div>
                            </div>


                          </div>



                            {doesProductHaveOptions ? optionList.map((x, i_x) => {
                              return (
                                <div key={i_x}className="row">
                                  <div className="separator"></div>
                                  <div className="col-lg-12">
                                      <div className="form-group">
                                          <label className="form">Option Name {i_x + 1}</label>
                                          <div className="box" style={{display: 'flex'}}>
                                            <input
                                                className='form-control-plaintext'
                                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                                type="text"
                                                placeholder={"Loading"}
                                                value={x.name}
                                                readOnly={true}
                                            />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-lg-12">
                                      <div className="form-group">
                                          <label className="form">Option Values</label>
                                          {setVariant_option_values_container(optionValueList_temp[i_x], i_x)}

                                      </div>

                                  </div>
                                </div>

                            )
                          }) : null}


                  </div>










                  {doesProductHaveOptions   ? <div className="ps-form__content" style={{marginTop: '50px'}}>





                      <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group" style={{margin:'0px'}}>
                              <label className="form"><h3>Variants</h3></label>
                              <div className="ps-checkbox" style={{marginTop: '10px', marginBottom: '2.5rem'}}>
                                <input
                                  className="form-control"
                                  type="checkbox"
                                  id="serial-option"
                                  name="serial-option"
                                  checked={isThisProductSerialized}
                                  readOnly={true}
                                  />
                                <label for="serial-option">{isThisProductSerialized ? 'This Product is Serialized by SKU' : 'This Product is not Serialized'}</label>
                              </div>

                            </div>
                        </div>

                      </div>





                <div className="row">


                  <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>
                  {optionValueMatrix.length != 0 ? <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '0px'}}>
                    <thead>
                      <tr>
                        <th style={{position: 'sticky', left: '0', backgroundColor: '#f1f1f1'}}>Variant</th>
                        <th>Picture URL</th>
                        <th>Price</th>
                        <th>Effective Date</th>
                        <th>Quantity</th>
                        <th>SKU</th>
                        <th>Barcode</th>

                        <th>Preview</th>
                        {isThisProductSerialized ? <th style={{position: 'sticky', right: 'calc(27px + 5rem)', backgroundColor: '#f1f1f1'}}>Serial Run Limit</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {optionValueMatrix.map((comboSet, i) => (
                        <tr key={comboSet.join(' - ')}>
                          <td style={frozenColumn_left}><b>{comboSet.join(' - ')}</b></td>
                          <td style={{minWidth:'100px', verticalAlign: 'middle'}}>
                            <img src={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].value ? picUrlList[comboSet.join(' - ')].value : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                            style={{display: 'block',
                                width: '100px',
                                height: '100px',
                                objectFit: 'cover',
                                filter: (picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].active == false) ? 'grayscale(1)' : 'none',
                              }} alt={i} border="0" />
                          </td>
                          <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                    <input
                                        className={priceList[comboSet.join(' - ')] && priceList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                        style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                        type="text"
                                        placeholder="Loading..."
                                        value={priceList[comboSet.join(' - ')] && priceList[comboSet.join(' - ')].value ? formatter.format(priceList[comboSet.join(' - ')].value) + ' USD' : 'Not Entered'}
                                        readOnly={true}

                                    />

                              </div>
                          </td>

                          <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                            <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                              <input
                                  className={promotionDateList[comboSet.join(' - ')] && promotionDateList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  placeholder="Loading..."
                                  value={promotionDateList[comboSet.join(' - ')] && promotionDateList[comboSet.join(' - ')].value ? moment(promotionDateList[comboSet.join(' - ')].value).format(dateFormat) : 'Not Entered'}
                                  readOnly={true}
                              />
                            </div>

                          </td>


                          <td style={{minWidth:'15rem', verticalAlign: 'middle'}}>
                            <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <input
                                    className={quantityList[comboSet.join(' - ')] && quantityList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                    style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                    type="text"
                                    placeholder="Loading..."
                                    value={quantityList[comboSet.join(' - ')] && quantityList[comboSet.join(' - ')].value ? quantityList[comboSet.join(' - ')].value : 'Not Entered'}
                                    readOnly={true}
                                />
                            </div>
                          </td>
                          <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                            <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <input
                                    className={skuList[comboSet.join(' - ')] && skuList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    placeholder="Loading..."
                                    value={skuList[comboSet.join(' - ')] && skuList[comboSet.join(' - ')].value ? skuList[comboSet.join(' - ')].value : 'Not Entered'}
                                    readOnly={true}
                                />
                            </div>
                          </td>
                          <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                            <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                <input
                                    className={barcodeList[comboSet.join(' - ')] && barcodeList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    placeholder="Loading..."
                                    value={barcodeList[comboSet.join(' - ')] && barcodeList[comboSet.join(' - ')].value ? barcodeList[comboSet.join(' - ')].value : 'Not Entered'}
                                    readOnly={true}
                                />
                            </div>
                          </td>
                          <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                            <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>

                                <input
                                    className={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                    style={{backgroundColor: 'white', fontSize: '16px'}}
                                    type="text"
                                    placeholder="Loading..."
                                    value={picUrlList[comboSet.join(' - ')] && picUrlList[comboSet.join(' - ')].value ? picUrlList[comboSet.join(' - ')].value : 'Not Entered'}
                                    readOnly={true}
                                />
                            </div>
                          </td>

                          {isThisProductSerialized ?
                            <td style={frozenColumn_right}>
                              <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                                  <input
                                    className={serialQuantityList[comboSet.join(' - ')] && serialQuantityList[comboSet.join(' - ')].active ? 'form-control-plaintext' : 'form-control-plaintext-inactive'}
                                      style={{backgroundColor: serialQuantityList[comboSet.join(' - ')] && serialQuantityList[comboSet.join(' - ')].active == false ? '#e9ecef' : "white", fontSize: '16px', paddingLeft: '1rem'}}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      placeholder="0"
                                      readOnly={true}
                                      value={serialQuantityList[comboSet.join(' - ')] ? serialQuantityList[comboSet.join(' - ')].value : null}
                                  />


                              </div>
                            </td> : null}



                        </tr>
                      ))}
                    </tbody>
                  </table> :

                    <div className="col-lg-12">
                      <div className="form-group" style={{marginTop: '3rem', marginBottom: '5rem'}}>
                        <label className="form">Please enter all of the applicable product options in order to generate the variant matrix.</label>
                      </div>
                    </div>}





                  </div>

                </div>

              </div> :null }

              {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Picture</h3></label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group">
                        <div style={thumb} >
                          <div style={thumbInner}>
                            <img src={itemPic} style={img} alt="CBR0" border="0" />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div style={{height: '5vh'}}/>


                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form">Picture Address</label>
                      <div className="input-group mb-2" >
                        <div className="input-group-prepend">
                          <span className="input-group-text">URL</span>
                        </div>
                        <input
                            className="form-control"
                            type="text"
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            onWheel={(e) => e.target.blur()}
                            value={itemPic}
                            readOnly={true}
                        />

                      </div>
                    </div>
                  </div>




                </div>
              </div> }

              {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Pricing</h3></label>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="form">Price</label>
                      <div className="input-group mb-2" >

                        <input
                            className="form-control-plaintext"
                            type="text"
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            onWheel={(e) => e.target.blur()}
                            placeholder="0.00"
                            value={formatter.format(itemPrice) + ' USD'}
                            readOnly={true}
                        />

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form">Effective Date</label>
                      <input
                          className="form-control-plaintext"
                          type="text"
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          onWheel={(e) => e.target.blur()}
                          value={(itemPromotion_date != null) ? moment(itemPromotion_date).format(dateFormat) : null}
                          readOnly={true}
                      />

                    </div>
                  </div>



                </div>
              </div> }

              {doesProductHaveOptions ? null : <div className="ps-form__content" style={{marginTop: '50px'}}>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form"><h3>Inventory</h3></label>
                    </div>
                  </div>




                  <div className="col-lg-6">
                    <div className="form-group">
                    <label className="form">Product SKU</label>
                      <input
                          className="form-control-plaintext"
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="text"
                          placeholder="SKU012345"
                          value={itemSku}
                          readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                    <label className="form">Product Barcode</label>
                      <input
                          className="form-control-plaintext"
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="text"
                          placeholder="9102132512"
                          value={itemBarcode}
                          readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                    <label className="form">Quantity</label>
                      <input
                          className="form-control-plaintext"
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="0"
                          value={itemQuantity}
                          readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="separator"></div>

                  <div className="col-lg-6">
                      <div className="form-group" style={{margin:'0px'}}>
                        <div className="ps-checkbox" style={{marginTop: '10px', marginBottom: '2.5rem'}}>
                          <input
                            Name="form-control"
                            type="checkbox"
                            id="serial-option"
                            name="serial-option"
                            checked={isThisProductSerialized}
                            readOnly={true}
                            />
                          <label for="serial-option">Is this product serialized by SKU?</label>
                        </div>

                      </div>
                  </div>
                  <div className="col-lg-6"></div>
                  {isThisProductSerialized ?
                  <div className="col-lg-6">
                    <div className="form-group">
                    <label className="form">Serial Run Limit</label>
                      <input
                          className="form-control-plaintext"
                          style={{backgroundColor: 'white', fontSize: '16px'}}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="0"
                          value={itemSerialRunLimit}
                          readOnly={true}
                      />
                    </div>
                  </div> : null}

                </div>
              </div>}


              </form>
            </div>
          </div>
        </div>
        <div style={{height: '15vh'}}/>
      </div>
    </section>
    );
};

export default FormEditProduct;
