import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Form, Input, Checkbox, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
// import { weightUnits_metric, dimensionUnits_metric, weightUnits_imperial, dimensionUnits_imperial } from './data/dimension-units'
import { copyToClipboard, makeCSVExport } from '../../utilities/common-helpers'
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'
import moment from 'moment'
import {formatter, formatPhoneNumber} from '../../utilities/common-helpers'

import TextareaAutosize from 'react-textarea-autosize';

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link


const shipConfirm = require('../../utilities/ups-lib/shipConfirm')
//var confirmShipment = new ShipConfirm(<API licenseId>, <API userId>, <API password>);
const confirmShipment = new shipConfirm("4DC2E2CFACE6B442", "GqFUTAcGUrkLZ6mkpaXiR91HWIBV7AHs2GtlSJx37Wpwe8Ze", "WNVZcut3GjtXtBaB87K2WuGii798AlC8GqJHIqGCZrR4QvJmdGAWJ9j7ounv5D1F")
confirmShipment.useSandbox(true)

const mapState = ({ user }) => ({
  user: user,
})



const FormNewOrder = ({ title}) => {

  const {user, selectedUnit } = useSelector(mapState)
  const dateFormat = 'YYYY-MM-DD'


  // console.log (user)
  // const [date_created, setDate_created] = useState("");

  const [order, setOrder] = useState([])
  const [order_number, setOrder_number] = useState("");
  const [order_date, setOrder_date] = useState("");
  const [order_reference, setOrder_reference] = useState("");
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const [isNewCustomerSaved, setIsNewCustomerSaved] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(true)

  const [loadingDropdowns, setLoadingDropdowns] = useState(false)
  const [loadingDropdowns_brands, setLoadingDropdowns_brands] = useState(false)
  const [loadingDropdowns_products, setLoadingDropdowns_products] = useState([false])
  const [loadingDropdowns_skus, setLoadingDropdowns_skus] = useState([false])
  const [loadingDropdowns_promos, setLoadingDropdowns_promos] = useState([false])

  const [showingPreview, setShowingPreview] = useState(false)

  const [copying, setCopying] = useState(false)

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [customerList, setCustomerList] = useState([])
  const [customerList_object, setCustomerList_object] = useState({})
  const [customer_record_id, setCustomer_record_id] = useState();
  const [customer_name, setCustomer_name] = useState("")
  const [customer_phone, setCustomer_phone] = useState("")


  const [order_record_id, setOrder_record_id] = useState() //DELETE THIS AS WE DO NOT NEED TO CREATE A NEW ORDER

  const [tracking_id, setTracking_id] = useState("")

  const [tyl_tracking_id, setTyl_tracking_id] = useState("")
  const [tyl_date_delivered, setTyl_date_delivered] = useState("");

  const [order_status, setOrder_status] = useState("")

  const [selectionList, setSelectionList] = useState([''])


  const [brandList, setBrandList] = useState([]);
  const [productList, setProductList] = useState([])
  const [skuList, setSkuList] = useState([])
  const [promoList, setPromoList] = useState([])
  const [quantityList, setQuantityList] = useState([''])
  const [selected_brands, setSelected_brands] = useState([''])
  const [selected_products, setSelected_products] = useState([''])
  const [selected_skus, setSelected_skus] = useState([''])
  const [selected_promos, setSelected_promos] = useState([''])
  const [selected_promos_price, setSelected_promos_price] = useState([''])
  const [selected_skus_view, setSelected_skus_view] = useState([''])
  const [selected_pics_view, setSelected_pics_view] = useState([''])
  const [orderDetailList, setOrderDetailList] = useState([])

  //Customer Data
  const [cdata_addressLine, setCdata_addressLine] = useState([''])
  const [cdata_addressLine_pinyin, setCdata_addressLine_pinyin] = useState([''])
  // const [cdata_address_2, setCdata_address_2] = useState([''])
  // const [cdata_address_3, setCdata_address_3] = useState([''])
  const [cdata_address_zip, setCdata_address_zip] = useState([''])
  const [cdata_address_city, setCdata_address_city] = useState([''])
  const [cdata_address_city_pinyin, setCdata_address_city_pinyin] = useState([''])
  const [cdata_attention_name, setCdata_attention_name] = useState([''])
  const [cdata_attention_name_pinyin, setCdata_attention_name_pinyin] = useState([''])
  const [cdata_address_country, setCdata_address_country] = useState([''])
  const [cdata_address_country_pinyin, setCdata_address_country_pinyin] = useState([''])
  const [cdata_address_state, setCdata_address_state] = useState([''])
  const [cdata_address_state_pinyin, setCdata_address_state_pinyin] = useState([''])
  const [cdata_name, setCdata_name] = useState([''])
  const [cdata_name_pinyin, setCdata_name_pinyin] = useState([''])
  const [cdata_phone_number, setCdata_phone_number] = useState([''])
  const [cdata_payload_items, setCdata_payload_items] = useState([''])

  //Package Data
  const [pdata_dimWeight, setPdata_dimWeight] = useState([''])
  const [pdata_dimensions_height, setPdata_dimensions_height] = useState([''])
  const [pdata_dimensions_length, setPdata_dimensions_length] = useState([''])
  const [pdata_dimensions_width, setPdata_dimensions_width] = useState([''])
  const [pdata_packageWeight, setPdata_packageWeight] = useState([''])
  const [pdata_package_shipping_label, setPdata_package_shipping_label] = useState([''])
  const [package_size_type, setPackage_size_type] = useState('')




  let location = useLocation()
  const pathArray = location.pathname.split('/')

  // var tracking_url
  // var tyl_tracking_url

  const [trackingUrl, setTrackingUrl] = useState('')
  const [tylTrackingUrl, setTylTrackingUrl] = useState('')

  useEffect(() => {
    getCustomers();
    getOrder()
    // console.log (user)

  }, []);

  useEffect(() => {
    const oldTitle = document.title;
    const title = "Luxanda | Order " + pathArray[3]
    title && (document.title = title);
    // following line is optional, but will reset title when component unmounts
    return () => document.title = oldTitle;
  }, [title])


  function downloadShippingLabel (e){
    e.preventDefault()
    // console.log ('Saving image...')

    var aDownloadLink = document.createElement('a')
    aDownloadLink.download = 'UPS_ShippingLabel_oid_' + pathArray[3] + '.png'
    aDownloadLink.href = 'data:image/jpeg;base64,' + pdata_package_shipping_label
    aDownloadLink.click()
  }

  function showShippingLabelPreview (e){
    e.preventDefault()
    // console.log ('Showing Image...')
    setShowingPreview(true)
  }

  function hideShippingLabelPreview (e){
    e.preventDefault()
    setShowingPreview(false)
  }




  async function makeShipment (e){
    e.preventDefault()
    // console.log ("Shipping...")

    let temp_addressString = cdata_addressLine_pinyin
    temp_addressString = temp_addressString.replace(" ( ", " (")
    temp_addressString = temp_addressString.replace(" ) ", ") ")
    temp_addressString = temp_addressString.replace(" - ", "-")
    temp_addressString = temp_addressString.replace(" -", "-")
    temp_addressString = temp_addressString.replace("- ", "-")

    if (temp_addressString.length > 105) {
      message.error("The Address is too long. " + temp_addressString.length + "/105 character limit")
    } else {

      const addressLine_array = temp_addressString.split(" ")


      // console.log (addressLine_array)
      let address_line_1 = ""
      let address_line_2 = ""
      let address_line_3 = ""


      for (var i = 0; i < addressLine_array.length; i++){
        if ((address_line_1 + addressLine_array[i]).length < 36 ) {
          address_line_1 += addressLine_array[i] + " "
        } else if ((address_line_2 + addressLine_array[i]).length < 36){
          address_line_2 += addressLine_array[i] + " "
        } else if ((address_line_3 + addressLine_array[i]).length < 36){
          address_line_3 += addressLine_array[i] + " "
        }

      }

      // let errored_characters = [" ( ", " ) "]
      //
      // for (var i = 0; i < errored_characters.length; i++){
      //   address_line_1 = address_line_1.replace(errored_characters[i], function (x) {
      //       return x.trim()
      //   })
      //
      // }

      //
      address_line_1 = address_line_1.trim()
      address_line_2 = address_line_2.trim()
      address_line_3 = address_line_3.trim()

      var data_shipment = {
        ShipmentRequest: {
          Shipment: {
            Description: "Canadian Apparel and Accessories",
            Shipper: {
              Name: "a Getzo Brand",
              AttentionName: "Luxanda",
              Phone: {
                  Number: "18334389633"
              },
              ShipperNumber: "9W4W26",
              Address: {
                AddressLine: "10271 Yonge St. Suite 398",
                City: "Richmond Hill",
                StateProvinceCode: "ON",
                CountryCode: "CA",
                PostalCode: "L4C3B5"
              }
            },
            ShipTo: {
              Name: cdata_name,
              AttentionName: cdata_attention_name,
              Phone: {
                  Number: cdata_phone_number
              },
              Address : {
                AddressLine: [address_line_3, address_line_2, address_line_1],
                City: cdata_address_city_pinyin,
                // StateProvinceCode: cdata_address_state_pinyin,
                CountryCode: cdata_address_country,
                PostalCode: cdata_address_zip
              }
            },
            PaymentInformation: {
              ShipmentCharge: {
                Type: "01",
                BillShipper: {
                  AccountNumber: "9W4W26"
                }
              }
            },
            Service: {
              Code: "08",
              Description: "Expedited"
            },
            Package: [
              {
                Code: "57",
                PackageWeight: {
                    UnitOfMeasurement: {
                        Code: "KGS"
                    },
                    Weight: "1"
                },
                Packaging: {
                    Code: "02",
                    Description: "Order Number: " + order_number
                }
              },
            ]
          },
          LabelSpecification:{
            LabelImageFormat:{
              Code:"GIF"
            }
          }
        }
      }
      // console.log ('SHIPMENT DATA')
      // console.log ('---------------')
      // console.log (data_shipment)
      // console.log ('temp')
      // console.log (temp_addressString)
      // console.log ('AddressLine1')
      // console.log (address_line_1)
      // console.log (address_line_1.length)
      // console.log ('AddressLine2')
      // console.log (address_line_2)
      // console.log (address_line_2.length)
      // console.log ('AddressLine3')
      // console.log (address_line_3)
      // console.log (address_line_3.length)


      try {
        setLoading(true)
        // const modified_by = user.credentials.email
        const date_shipping_label_made = new Date()


        const response = await fetch(`${process.env.REACT_APP_API_URL}ups/a=generateLabel/dev/${pathArray[3]}`, {
          method: "POST",
          headers: {"Content-Type": "application/json", },
          body: JSON.stringify(data_shipment),

        }).then(response => response.json())
        .then(data => {
          // console.log ("UPS REPLY")
          // console.log (data)
          if (data.code == 0){
            message.success('Shipping Label successfully generated and logged!')

            //UPDATE THE ORDER DETAILS
            // updateOrderDetails()
            if (data.data.ShipmentResponse.ShipmentResults.PackageResults.TrackingNumber){
              setTracking_id(data.data.ShipmentResponse.ShipmentResults.PackageResults.TrackingNumber)
              setPdata_package_shipping_label(data.data.ShipmentResponse.ShipmentResults.PackageResults.ShippingLabel.GraphicImage)

            }
            updateOrderShippingInformation(data.data.ShipmentResponse.ShipmentResults.PackageResults.TrackingNumber, data.data.ShipmentResponse.ShipmentResults.PackageResults.ShippingLabel.GraphicImage)

          } else {
            message.warning ('Something went wrong while generating the Shipping Label')
          }

          setLoading(false)
        })
        // window.location = "/admin/orders";
      } catch (err) {
        message.warning ('Something went wrong while generating the Shipping Label!')
        message.error (err.message)
        console.error(err.message);
        console.error(err);
        setLoading(false)
      }
    }
  }

  async function updateOrderShippingInformation (provider_trackingNumber, provider_label) {
    try {
       setLoading(true)
       // const modified_by = user.credentials.email
       const date_shipping_label_made = new Date()
       const new_status = 'shipping_label_created'
       const body = {date_shipping_label_made, provider_trackingNumber, provider_label, new_status };

       const response = await fetch(`${process.env.REACT_APP_API_URL}o/id/${pathArray[3]}/a=addShippingLabel`, {
         method: "PUT",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(body),
       }).then(response => response.json())
       .then(data => {

         if (data.code == 0){
           message.success('Order Label successfully updated')

           //UPDATE THE ORDER DETAILS
           // updateOrderDetails()

         } else {
           message.warning ('Something went wrong while updating the Order with Label Information!')
         }

         setLoading(false)
       })
       // window.location = "/admin/orders";
     } catch (err) {
       message.warning ('Something went wrong while updating the Order!')
       message.error (err.message)
       console.error(err.message);
       console.error(err);
       setLoading(false)
     }
  }

  // async function updateOrder(customer_id){
  //   try {
  //     setLoading(true)
  //     const modified_by = user.credentials.email
  //     const date_modified = new Date()
  //
  //     const body = {date_modified, modified_by, order_number, order_date, order_reference, customer_id, order_status, tracking_id, tyl_tracking_id, tyl_date_delivered };
  //
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}o/id/${pathArray[3]}/a=edit`, {
  //       method: "PUT",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(body),
  //     }).then(response => response.json())
  //     .then(data => {
  //
  //       if (data.code == 1002 && data.data.code == 1000 ){
  //         message.warning('Order with this Reference Number already exists for a different Order!')
  //       } else if (data.code == 0){
  //         message.success('Order successfully updated')
  //
  //         //UPDATE THE ORDER DETAILS
  //         updateOrderDetails()
  //
  //       } else {
  //         message.warning ('Something went wrong while updating the Order!')
  //       }
  //
  //       setLoading(false)
  //     })
  //     // window.location = "/admin/orders";
  //   } catch (err) {
  //     message.warning ('Something went wrong while updating the Order!')
  //     message.error (err.message)
  //     console.error(err.message);
  //     console.error(err);
  //     setLoading(false)
  //   }
  // }

  function getDimensions (type){
    if (type == 'S1'){
      return '25 x 20 x 8 cm (10 x 8 x 3")'
    }

  }


  async function getCustomers() {
    // console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}c/all`)
    const customersArray = await res.json()
    let list = {}
    for (var i = 0; i < customersArray.length; i++){
      list[customersArray[i].id] = {customer_name: customersArray[i].customer_name, phone_number: customersArray[i].phone_number}
    }
    setCustomerList_object(list)
    setCustomerList(customersArray);
    setLoadingDropdowns(false)
  }

  async function getBrands_onload() {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}b/all`)
      .then((res) => {
        resolve(res.json())
      })
    })
  }



  async function getOrder() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}o/id/${pathArray[3]}`)
    const orderArray = await res.json()



    const temp_order_status_sentence = orderArray.order_status
    const temp_order_status_words = temp_order_status_sentence.split("_")

    for (let i = 0; i < temp_order_status_words.length; i++){
      if (temp_order_status_words[i] != 'by' && temp_order_status_words[i] != 'for'){
        temp_order_status_words[i] = temp_order_status_words[i][0].toUpperCase() + temp_order_status_words[i].substr(1)
      }
    }


    setOrder_status(temp_order_status_words.join(" "))
    setTracking_id(orderArray.tracking_id)
    setOrder_date(orderArray.order_date)
    setOrder_number(orderArray.order_number)
    setOrder_reference(orderArray.order_reference)
    setIsNewCustomer(false)
    setCustomer_record_id(orderArray.customer_id)

    setTyl_tracking_id(orderArray.tyl_tracking_id)
    setTyl_date_delivered(orderArray.tyl_date_delivered)

    setCdata_name(orderArray.customer_ship_to_name)
    setCdata_attention_name(orderArray.customer_ship_to_attention_name)
    setCdata_addressLine(orderArray.customer_ship_to_address_line)
    setCdata_address_country(orderArray.customer_ship_to_address_country_code)
    setCdata_address_state(orderArray.customer_ship_to_address_state_province_code)
    setCdata_address_city(orderArray.customer_ship_to_address_city)
    setCdata_address_zip(orderArray.customer_ship_to_address_postal_code)
    setCdata_phone_number(orderArray.customer_ship_to_phone_number)

    setCdata_name_pinyin(orderArray.customer_ship_to_name_pinyin)
    setCdata_attention_name_pinyin(orderArray.customer_ship_to_attention_name_pinyin)
    setCdata_addressLine_pinyin(orderArray.customer_ship_to_address_line_pinyin)
    setCdata_address_country_pinyin(orderArray.customer_ship_to_address_country_code_pinyin)
    setCdata_address_state_pinyin(orderArray.customer_ship_to_address_state_province_code_pinyin)
    setCdata_address_city_pinyin(orderArray.customer_ship_to_address_city_pinyin)


    setPdata_dimWeight(orderArray.package_dim_weight)
    setPdata_dimensions_height(orderArray.package_dimensions_height)
    setPdata_dimensions_length(orderArray.package_dimensions_length)
    setPdata_dimensions_width(orderArray.package_dimensions_width)
    setPdata_packageWeight(orderArray.package_package_weight)

    // setPdata_package_shipping_label(orderArray.package_shipping_label)
    setPdata_package_shipping_label(orderArray.package_shipping_label)

    setPackage_size_type(orderArray.package_size_type)
    const payload_items_json = JSON.parse(JSON.stringify(orderArray.payload_items))
    setCdata_payload_items(payload_items_json)




    setOrder(orderArray)

    setTrackingUrl("https://www.ups.com/mobile/track?trackingNumber=" + orderArray.tracking_id)
    setTylTrackingUrl("https://www.ups.com/mobile/track?trackingNumber=" + orderArray.tyl_tracking_id)

    // console.log (orderArray)
    getOrderDetailsByOrderID(pathArray[3] )
  }

  async function getOrderDetailsByOrderID(order_id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}od/oid/${order_id}`)
    const orderDetailsArray = await res.json()
      setLoadingProperties(true)
      setOrderDetailList(orderDetailsArray)

      let selection_array = []
      let brand_selected_array = []
      let product_selected_array = []
      let sku_selected_array = []
      let promo_selected_array = []
      let price_selected_array = []
      let sku_view_selected_array = []
      let pics_view_selected_array = []
      let quantityArray = []
      let productsArray = []
      let skuArray = []
      let promoArray = []

      const brandsArray = [await getBrands_onload()]
      // console.log (orderDetailsArray)

      try{
        for (var i = 0; i < orderDetailsArray.length; i++){

          //this is templating, the Brands do not change during edit/creation, so we can replicate it for as many line items as we have
          selection_array.push('')

          //the is to populat the onload dropdown items
          brandsArray.push(brandsArray[0])

          const data_skus = await getSkusByProduct_onload(orderDetailsArray[i].product_id)
          const data_promos = await getPromotionsByActiveSku_onload(orderDetailsArray[i].id, orderDetailsArray[i].order_id)
          productsArray.push(await getProductsByBrand_onload(orderDetailsArray[i].brand_id))
          skuArray.push(data_skus)
          promoArray.push(data_promos)
          quantityArray.push(orderDetailsArray[i].quantity)

          for (var x = 0; x < data_promos.length; x++){
            //META DATA
            if (data_promos[x].id === orderDetailsArray[i].promotion_id){
              price_selected_array.push(data_promos[x].price)
            }
          }

          for (var x = 0; x < data_skus.length; x++){
            //META DATA
            if (data_skus[x].id === orderDetailsArray[i].sku_id){
              sku_view_selected_array.push(data_skus[x].sku)
              pics_view_selected_array.push(data_skus[x].pic_url)
            }
          }

          //this is to populate the selection arrays
          brand_selected_array.push(orderDetailsArray[i].brand_id)
          product_selected_array.push(orderDetailsArray[i].product_id)
          sku_selected_array.push(orderDetailsArray[i].sku_id)
          promo_selected_array.push(orderDetailsArray[i].promotion_id)
        }
      } catch (e) {
        console.log (e)
        message.error('The Network Request has Timed-Out, the page will now refresh.')
        window.location.reload()
      }





      // console.log (brand_selection_array)
      setSelectionList(selection_array)
      setBrandList(brandsArray)
      setProductList(productsArray)
      setSkuList(skuArray)
      setPromoList(promoArray)


      setSelected_brands(brand_selected_array)
      setSelected_products(product_selected_array)
      setSelected_skus(sku_selected_array)
      setSelected_promos(promo_selected_array)

      //META DATA
      setSelected_pics_view(pics_view_selected_array)
      setSelected_skus_view(sku_view_selected_array)
      setSelected_promos_price(price_selected_array)
      setQuantityList(quantityArray)

      getOrderTotal()
      getOrderQuantityTotal()

      setLoadingProperties(false)

  }

  async function getProductsByBrand_onload(record_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}p/b/${record_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  async function getProductsByBrand(record_id, index) {
    // console.log ("loading...")
    const loading_list = [...loadingDropdowns_products]
    loading_list[index] = true
    setLoadingDropdowns_products(loading_list)
    const res = await fetch(`${process.env.REACT_APP_API_URL}p/b/${record_id}`)
    const data = await res.json().then(productsArray => {
      let list = [...productList];
      list[index] = productsArray

      // setQuantityList(list)
      // setProductList(list);

      loading_list[index] = false
      setLoadingDropdowns_products(loading_list)

      setProductList(list);
    })


    // setLoadingDropdowns_products(false)
  }

  async function getSkusByProduct_onload(record_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}sku/pid/${record_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  async function getSkusByProduct(record_id, index) {
    // console.log ("loading...")
    const loading_list = [...loadingDropdowns_skus]
    loading_list[index] = true
    setLoadingDropdowns_skus(loading_list)
    const res = await fetch(`${process.env.REACT_APP_API_URL}sku/pid/${record_id}`)
    const skusArray = await res.json()
    // console.log (skusArray)

    const list = [...skuList];
    list[index] = skusArray

    loading_list[index] = false
    setLoadingDropdowns_skus(loading_list)
    // console.log (list)
    setSkuList(list);
    // setLoadingDropdowns_products(false)
  }

  async function getPromotionsByActiveSku_onload(order_detail_d, order_id) {
    return new Promise(function(resolve){
      fetch(`${process.env.REACT_APP_API_URL}promos/o_d/${order_detail_d}/order_id/${order_id}`)
      .then((res) => {
        resolve(res.json())
      })
    })
    // setLoadingDropdowns_products(false)
  }

  async function getPromotionsByActiveSku(record_id, index) {
    // console.log ("loading...")
    // console.log ("index: " + index)
    // console.log ("record id: " + record_id)
    const loading_list = [...loadingDropdowns_promos]
    loading_list[index] = true
    setLoadingDropdowns_promos(loading_list)

    const res = await fetch(`${process.env.REACT_APP_API_URL}promos/sku/${record_id}`)
    const promosArray = await res.json()
    // console.log (promosArray)

    const list = [...promoList];
    list[index] = promosArray

    loading_list[index] = false
    setLoadingDropdowns_promos(loading_list)

    // console.log (list)
    setPromoList(list);
    // setLoadingDropdowns_products(false)
  }




  const getOrderTotal = () => {
    let total = 0
    for (var i = 0; i < quantityList.length; i++){
      total += quantityList[i] * selected_promos_price[i]
    }
    return (formatter.format(total))
  }

  const getOrderLineItemTotal = (index) => {
    const total = quantityList[index] * selected_promos_price[index]
    return (formatter.format(total))
  }

  const getOrderQuantityTotal = () => {
    let total = 0

    for (var i = 0; i < quantityList.length; i++){
      if (quantityList[i] != ''){
        total += parseInt(quantityList[i])
      }
    }
    return (total)
  }

  const getProperty = (array, selected_item_id, type) => {
    // console.log (type)
    // console.log (array)
    // console.log (selected_item_id)

    for (var i = 0; i < array.length; i++){
      if (array[i].id == selected_item_id){
        if (type == 'brand'){
          return(`${array[i].name} - ${array[i].country}`)
        } else if (type == 'product'){
          return(`${array[i].name}`)
        } else if (type == 'sku'){
          return(`${array[i].combo_identifier} (Quantity: ${array[i].quantity})`)
        } else if (type == 'promotion'){
          return(`${formatter.format(array[i].price)} ${array[i].currency}`)
        }

      }
    }


  }

  function copyItem (e, item) {
    e.preventDefault()
    setCopying(true)
    copyToClipboard(item)

    message.success('Payload copied to Clipboard')

    setTimeout(function() {
      setCopying(false)
    }, 1000)

  }







  // const dropdown_customers = setSelectedId_View('customerList')

  const frozenColumn_left = {
    minWidth:'5rem',
    verticalAlign: 'middle',
    position: 'sticky',
    left: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    paddingRight: '25px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right = {
    minWidth:'6rem',
    verticalAlign: 'middle',
    position: 'sticky',
    right: '0',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '0px',
    paddingRight: '0px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }
  const frozenColumn_right_second = {
    minWidth:'100px',
    verticalAlign: 'middle',
    position: 'sticky',
    right: 'calc(-1px)',
    backgroundColor: '#f1f1f1',
    whiteSpace: 'nowrap',
    paddingLeft: '7px',
    paddingRight: '7px',
    // borderRight: '1px solid #dee2e6',
    zIndex:'999'
  }

  let modified_view

  if (order.date_modified) {
    modified_view = (<div>Last modified by {order.modified_by} on {moment(order.date_modified).format('lll')}</div>)
  }

  function handleSubmit(e) {
    console.log ("navigating")
  }




  return (
    <section className="ps-my-account ps-page--account">
    {loadingProperties ? <div style={{position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)', opacity: '0.25', zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h5>LOADING ORDER DETAILS</h5></div> : null}
    {showingPreview ? <div onClick={e => hideShippingLabelPreview(e)} style={{display: 'flex', flexDirection: 'column', position:'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', backgroundColor: 'rgba(0, 0, 0, 0.61)', backdropFilter: 'blur(500px)',  zIndex: '9999', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <img style={{transform: 'rotate(90deg)', maxHeight: '50vh'}}src={'data:image/jpeg;base64,' + pdata_package_shipping_label}/>
      <div style={{position: 'absolute', textAlign: 'center', bottom: '1.5rem', width: '100%' }}>
        <h5>PRESS ANYWHERE TO DISMISS</h5>
      </div>
    </div> : null}

    <div className="container">
      <div className="row">

        <div className="col-lg-12">
          <div className="ps-page__content">

            <form className="ps-form--product-form" >
              <div className="ps-form__header">
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <h3>Order ID: {order.id}</h3>
                    <h5><strong>Date Created: </strong>{moment(order.date_created).format('lll')} {modified_view}</h5>
                  </div>
                  {user.credentials.role == 'administrator' ?
                  <div className="form-group submit" style={{marginTop: '-25px', marginBottom: '0px'}}>
                    <Link to={`/admin/o/${order.id}/edit`}>
                      <button className="ps-btn" onClick={e => handleSubmit(e)}>Edit Order</button>
                    </Link>
                  </div> : null }
                </div>
              </div>

                <div className="ps-form__content">

                    <div className="row">
                      <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form"><h3>Customer Information</h3></label>
                            </div>
                      </div>

                          {customer_record_id != 0 && !isNewCustomer?
                          <div className="col-lg-3">
                              <div className="form-group">
                                  <label className="form">Name</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={customerList_object[customer_record_id] && customerList_object[customer_record_id].hasOwnProperty('customer_name') ? customerList_object[customer_record_id].customer_name : ''}
                                      readOnly={true}
                                  />
                              </div>
                          </div>: null}
                          {customer_record_id != 0 && !isNewCustomer ?
                            <div className="col-lg-3">
                              <div className="form-group">
                                  <label className="form">Phone Number</label>
                                  <input
                                      className='form-control-plaintext'
                                      style={{backgroundColor: 'white', fontSize: '16px'}}
                                      type="text"
                                      placeholder="Loading..."
                                      value={customerList_object[customer_record_id] && customerList_object[customer_record_id].hasOwnProperty('phone_number') ? formatPhoneNumber(customerList_object[customer_record_id].phone_number) : ''}
                                      readOnly={true}
                                  />
                              </div>
                          </div>: null}
                        </div>
                      </div>




                <div className="ps-form__content" style={{marginTop: '50px'}}>
                  <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form"><h3>Order Information</h3></label>
                          </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form">Order Status</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            placeholder="Loading..."
                            value={order_status}
                            readOnly={true}

                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                    {order_status === "Awaiting Fulfillment" || order_status === "Waiting for Payment" ? null :
                        <div className="form-group">
                            <label className="form">Tracking ID</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Loading..."
                                value={tracking_id}
                                readOnly={true}

                            />
                        </div>
                      }
                    </div>
                    <div className="col-lg-3">
                        {tracking_id && order_status == 'Shipped' ?

                          <div className="form-group" style={{textAlign: 'center', margin: '0px'}}>
                              <a className="ps-btn" target="_blank" href={trackingUrl}>
                                Track Shipment
                              </a>
                          </div>
                     : null}
                    </div>

                    <div className="separator"></div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Order Date</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Loading..."
                                value={moment(order_date).format('lll')}
                                readOnly={true}

                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Order Number</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Loading..."
                                value={order_number}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Order Reference</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Loading..."
                                value={order_reference}
                                readOnly={true}
                            />
                        </div>
                    </div>



                    <div className="separator"></div>

                    <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form"><h3>Package Information</h3></label>
                          </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form">Package Dimensions</label>
                        <input
                            className='form-control-plaintext'
                            style={{backgroundColor: 'white', fontSize: '16px'}}
                            type="text"
                            placeholder="Not Entered"
                            value={getDimensions(package_size_type)}
                            readOnly={true}
                        />


                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form">Package Weight</label>
                        <div className="input-group mb-2" >

                          <input
                              className='form-control-plaintext'
                              style={{backgroundColor: 'white', fontSize: '16px'}}
                              type="text"
                              placeholder="0.0"
                              value={pdata_packageWeight + " kgs"}
                              readOnly={true}
                          />


                        </div>
                      </div>
                    </div>


                    <div className="separator"></div>
                    <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form"><h3>Shipping Information</h3></label>
                          </div>
                    </div>





                    <div className="separator"></div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Name</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_name}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Name (Pinyin)</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_name_pinyin}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Attention Name</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_attention_name}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Attention Name (Pinyin)</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_attention_name_pinyin}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Phone Number</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_phone_number ? formatPhoneNumber(cdata_phone_number) : ''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Address Line</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_addressLine}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form">Address Line (Pinyin)</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_addressLine_pinyin}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">State/Province</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_state}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">State/Province (Pinyin)</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_state_pinyin}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">City</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_city}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">City (Pinyin)</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_city_pinyin}
                                readOnly={true}
                            />
                        </div>
                    </div>



                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Country Code</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_country}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">Country Code (Pinyin)</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_country_pinyin}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label className="form">ZIP</label>
                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Not Entered"
                                value={cdata_address_zip}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>

                    {pdata_package_shipping_label && tracking_id?
                      <div className="col-lg-6" style={{display: 'flex', justifyContent:'center', alignItems: 'center'}} >

                        <div className="form-group" style={{textAlign: 'center', marginRight: '15px', marginBottom: '2.5rem'}}>
                          <div className="ps-btn--link" onClick={e => showShippingLabelPreview(e)}>Preview Label</div>
                        </div>
                        <div className="form-group" style={{textAlign: 'center', margin: '0px', marginBottom: '2.5rem'}}>
                            <button className="ps-btn--download" onClick={e => downloadShippingLabel(e)}>Download Shipping Label</button>
                        </div>

                      </div>
                      : <div className="col-lg-6">
                      {user.credentials.role == 'administrator' ?
                          <div className="form-group" style={{textAlign: 'center', marginBottom: '2.5rem'}}>
                              <button className="ps-btn" onClick={e => makeShipment(e)}>{loading ? 'Generating label...' : 'Generate Shipping Label'}</button>
                          </div> : null }
                    </div>}


                  </div>



                  <div className="row" style={{backgroundColor: '#f1f1f1', paddingTop: '2.5rem', borderRadius: '4px'}}>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form"><h3 style={{color: '#d3d3d3'}}>Thank You Letter Information</h3></label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Tracking ID (Thank-You-Letter)</label>
                          <input
                              className="form-control-plaintext"
                              style={{ fontSize: '16px'}}
                              type="text"
                              placeholder="Not Provided"
                              value={tyl_tracking_id}
                              readOnly={true}
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                        {tyl_tracking_id ?

                          <div className="form-group" style={{textAlign: 'center', margin: '0px'}}>
                              <a className="ps-btn" target="_blank" href={tylTrackingUrl}>
                                Track Shipment

                              </a>
                          </div>
                     : null}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Date Delivered (Thank-You-Letter)</label>
                          <input
                              className="form-control-plaintext"
                              style={{ fontSize: '16px'}}
                              type="text"
                              placeholder="Not Provided"
                              value={tyl_date_delivered}
                              readOnly={true}
                          />
                      </div>
                    </div>
                  </div>



                </div>












                <div className="ps-form__content" style={{marginTop: '50px'}}>
                  <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form"><h3>Order Details</h3></label>
                          </div>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-lg-12">
                  {user.credentials.role == 'administrator' ?
                    <div className="form-group">
                      {cdata_payload_items ? <button className="ps-btn" onClick={e => copyItem(e, cdata_payload_items)}>{copying ? 'Copied!' : 'Copy Payload Data to Clipboard'}</button>: null}

                    </div> : null }




                  </div>
                    <div className="col-lg-12" style={{overflowX: 'overlay', paddingLeft: '0px', paddingRight: '0px'}}>
                      <table className="table mt-5" style={{display: 'block', tableLayout: 'fixed', borderCollapse: 'collapse', marginBottom: '0px'}}>
                        <thead>
                          <tr>
                            <th style={{position: 'sticky', left: '0', backgroundColor: '#f1f1f1'}}></th>
                            <th>Brand</th>
                            <th>Product</th>
                            <th>Variant <span>(only active)</span></th>
                            <th>Quantity</th>
                            <th>Promotion</th>
                            <th>SKU</th>
                            <th style={{position: 'sticky', right: 'calc(-1px)', backgroundColor: '#f1f1f1'}}>Preview</th>
                          </tr>
                        </thead>
                        <tbody>

                    {selectionList.map((x, i_x) => (
                      <tr key={i_x}>
                        <td style={frozenColumn_left}><b>Item {i_x + 1}</b></td>


                        <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                          <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>

                            <input
                                className='form-control-plaintext'
                                style={{backgroundColor: 'white', fontSize: '16px'}}
                                type="text"
                                placeholder="Loading..."
                                value={brandList[i_x] && brandList[i_x].length > 0 ? getProperty(brandList[i_x], selected_brands[i_x], 'brand') : 'Loading...'}
                                readOnly={true}
                            />


                          </div>
                        </td>

                        <td style={{minWidth:'20rem', verticalAlign: 'middle'}}>
                          <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  placeholder="Loading..."
                                  value={productList[i_x] && productList[i_x].length > 0 ? getProperty(productList[i_x], selected_products[i_x], 'product') : 'Loading...'}
                                  readOnly={true}
                              />
                          </div>
                        </td>
                        <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                          <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>

                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  placeholder="Loading..."
                                  value={skuList[i_x] && skuList[i_x].length > 0 ? getProperty(skuList[i_x], selected_skus[i_x], 'sku') : 'Loading...'}
                                  readOnly={true}
                              />
                              {orderDetailList[i_x] && orderDetailList[i_x].status_change ?
                              <div className='form-control-plaintext' style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600', color: 'red'}}>
                                  <ExclamationCircleOutlined/>
                                  {orderDetailList[i_x] ? ' '+ orderDetailList[i_x].status_change : ''}
                              </div> : null }

                          </div>
                        </td>
                        <td style={{minWidth:'10rem', verticalAlign: 'middle'}}>
                          <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                            <div className="box" style={{display: 'flex'}}>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                  type="number"
                                  placeholder='Loading...'
                                  value={quantityList[i_x]}
                              />
                            </div>
                          </div>
                        </td>
                        <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                          <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px', fontWeight: '600'}}
                                  type="text"
                                  placeholder="Loading..."
                                  value={promoList[i_x] && promoList[i_x].length > 0 ? getProperty(promoList[i_x], selected_promos[i_x], 'promotion') : 'Loading...'}
                                  readOnly={true}
                              />

                          </div>
                        </td>
                        <td style={{minWidth:'25rem', verticalAlign: 'middle'}}>
                          <div className="form-group" style={{margin: '0px', marginBottom: '0.5rem', marginTop:'0.5rem'}}>
                            <div className="box" style={{display: 'flex'}}>
                              <input
                                  className='form-control-plaintext'
                                  style={{backgroundColor: 'white', fontSize: '16px'}}
                                  type="text"
                                  placeholder="Loading..."
                                  value={selected_skus_view[i_x] ? selected_skus_view[i_x] : 'Laoding...'}
                                  readOnly={true}
                              />

                            </div>
                          </div>
                        </td>
                        <td style={frozenColumn_right_second}>
                          <img src={selected_pics_view[i_x] ? selected_pics_view[i_x] : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                          style={{display: 'block',
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover',
                            }} alt={selected_pics_view[i_x]} border="0" />
                        </td>

                      </tr>
                    ))}
                  </tbody>

                </table>














                    </div>

                    <div className="col-lg-1" style={{marginTop: '2.5rem'}}>
                        <div className="form-group">
                            <label className="form">QUANTITY</label>
                            <input
                                className="form-control-plaintext"
                                type="text"
                                style={{fontSize: '16px', textAlign: 'right'}}
                                value={getOrderQuantityTotal()}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2" style={{marginTop: '2.5rem'}}>
                        <div className="form-group">
                            <label className="form">ORDER TOTAL</label>
                            <input
                                className="form-control-plaintext"
                                type="text"
                                style={{fontSize: '16px'}}
                                value={getOrderTotal() +' USD'}
                            />
                        </div>
                    </div>

                  </div>


                </div>



            </form>
          </div>
        </div>
      </div>
      <div style={{height: '15vh'}}/>
    </div>
  </section>
  );
};

export default FormNewOrder;
