import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { DatePicker, Space, Form, Input, Radio } from 'antd';
import { Prompt } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, message } from 'antd'

import { variant_list } from './data/variant_list'

import { useLocation } from 'react-router-dom'

var QRCode = require('qrcode.react')

const Link = require("react-router-dom").Link




const mapState = ({ user }) => ({
  user: user,
})



const FormNewProductVariantOption = () => {

  const {user, selectedUnit } = useSelector(mapState)
  // console.log (user)



  const [pic, setPic] = useState("")
  const [name, setName] = useState("");
  const [product_variant_id, setProduct_variant_id] = useState();
  const [product_name, setProduct_name] = useState("")
  const [variants, setVariants] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingDropdowns, setLoadingDropdowns] = useState(false)


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);



  let location = useLocation()


  useEffect(() => {
    getVariants()
  }, []);

  async function getVariants() {
    console.log ("loading...")
    setLoadingDropdowns(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}v/all`)
    const variantsArray = await res.json()
    console.log (variantsArray)
    setVariants(variantsArray);
    setLoadingDropdowns(false)
  }

  // async function getProductByProductID(product_id){
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}p/id/${product_id}`)
  //
  //   const product = await res.json()
  //   // console.log (product.name)
  //   setProduct_name (product.name)
  // }


  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log (pic)
    // console.log (name)
    // console.log (product_variant_id)

    try {
      setLoading(true)
      const date_created = new Date()
      const created_by = user.credentials.email
      const body = { date_created, created_by, product_variant_id, name, pic };
      console.log (body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}variant_option`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }).then(response => response.json())
      .then(data =>{
        if (data.data.code == 409){
          message.error(data.data.msg)
        } else {
          window.location = "/admin/variant_options"
        }
        setLoading(false)
      })
    } catch (err) {
      console.error(err.message);
    }
  };



  function getValueFromDataArray (data,id) {
    for (let i = 0; i < data.length; i++){
        if (data[i].id == id){
          return data[i]
      }
    }
  }





  // const fieldChanged = (type, value) => {
  //   if (type == 'name'){
  //      setName(value);
  //   } else if (type == 'Variant Type')
  //
  //
  // };

  const handleSelect = (item, type) =>{
    if (type == 'product_variant_id'){
      setProduct_variant_id(item.target.value)
      // console.log (item.target)
      // console.log (item.target.value)
      // console.log (item.target.selectedOptions[0].getAttribute("product_id"))
      // getProductByProductID(item.target.selectedOptions[0].getAttribute("product_id"))
    } else if (type == 'name'){
      setName(item)
    } else if (type == 'pic'){
      setPic(item)
    }

  }

  function setDropdown (type) {
    if (type == 'variants'){
      if (variants.length > 0){
         return (
           variants.map((item) => (
            <option value={item.id} key={item.id} product_id={item.product_id} >
                    Product: {item.product_name} - Variant Type: {item.name}
            </option>
          )))
        } else {
          return <option value={0}>- - - No Selection Available - - -</option>
        }

    } else if (type == 'name'){
      return variant_list.map((item) => (
          <option value={item.code} key={item.code}  >
                  {item.name}
            </option>
      ));
    }
  }


  const dropdown_variant_names = setDropdown ('name')
  const dropdown_variant_options = setDropdown('variants')

  let variant_data = getValueFromDataArray(variants, product_variant_id)


  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'calc(25% - 8px)',
    height: 'calc(25% - 8px)',
    maxWidth: '200.13px',
    aspectRatio: '1',
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  };



    return (

      <div>
        <form className="ps-form--product-form" >
            <div className="ps-form__header">
                <h3>Create New Product Variant Option</h3>
            </div>

            <div className="ps-form__content">

                <div className="row">

                  <div className="col-lg-12">
                      <div className="form-group">
                        <div style={thumb} >
                          <div style={thumbInner}>
                            <img src={pic} style={img} alt="CBR0" border="0" />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div style={{height: '5vh'}}/>

                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Picture Url</label>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="https://luxanda-qr-serverless-frontend-1309414541.cos.ap-hongkong.myqcloud.com/static/imgs/products/CBR1.jpg"
                              value={pic}
                              onChange={e => handleSelect(e.target.value, 'pic')}
                          />
                      </div>
                  </div>


<div class="separator"></div>

                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Variant</label>
                          {loadingDropdowns ? <div>Loading...</div> :
                          <select className="form-control" defaultValue={0} value={product_variant_id} onChange={ e=> handleSelect(e, 'product_variant_id')} >
                                  {variants.length === 0 ? null : <option value={0}>- - - Please Select - - -</option>}
                                {dropdown_variant_options}
                              </select>}

                          <label className="form">{product_name}</label>
                      </div>
                  </div>


                  <div className="col-lg-6">
                      <div className="form-group">
                          <label className="form">Option Value</label>
                          <input
                              className="form-control"
                              type="text"
                              placeholder="e.g. Brown, Small, King..."
                              value={name}
                              onChange={e => handleSelect(e.target.value, 'name')}
                          />
                      </div>
                  </div>












                    <div class="separator"></div>

                </div>

                <div className="form-group submit">
                    <button
                      className="ps-btn"
                      onClick={e => handleSubmit(e)}
                      disabled={loading}
                      >
                      {loading ? (<>Saving... <LoadingOutlined size="large"/></>) : ('Save Product Variant Option')}
                      </button>
                </div>

            </div>

        </form>
        <Prompt when={isFormIncomplete} message="Are you sure you want to leave?" />
      </div>
    );
};

export default FormNewProductVariantOption;
