import React from 'react'
// import Link from 'next/link'
const Link = require("react-router-dom").Link

const MenuDropdown = ({ source }) => {
  return (
    <li className="menu-item-has-children dropdown">
      {
        <Link to={source.url}>
          {source.text}
        </Link>
      }
      {source.subMenu && (
        <ul className={source.subClass}>
          {source.subMenu.map((subMenuItem, index) => (
            <MenuDropdown source={subMenuItem} key={index} />
          ))}
          </ul>
      )}
      </li>
  )
}

export default MenuDropdown
